import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Box,
  Button,
  CircularProgress,
  Collapse,
  Card,
  CardContent,
  Chip,
  Avatar,
  Skeleton,
} from "@mui/material";
import { styled } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../services/authContext";
import axios from "axios";
import ReactMarkdown from "react-markdown";
import rehypeSanitize from "rehype-sanitize";
import { themeColors, typography } from "../theme";
import config from "../../../config";
// Styling for the SubjectCard
const SubjectCard = styled(Card)(({ theme }) => ({
  backgroundColor: themeColors.background.paper,
  borderRadius: "10px",
  transition: "0.3s",
  boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
  border: "1px solid #e0e0e0",
  "&:hover": {
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.15)",
  },
}));

const SubjectAnalyticsRevamp = () => {
  const [expanded, setExpanded] = useState(null); // Track the currently expanded subject
  const [subjects, setSubjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activityFeed, setActivityFeed] = useState({});
  const [activityLoading, setActivityLoading] = useState(true);
  const [aiStates, setAiStates] = useState({});

  const navigate = useNavigate();
  const { getToken } = useAuth();

  useEffect(() => {
    // API call to fetch subjects metadata
    const fetchSubjects = async () => {
      try {
        const token = await getToken();
        const response = await axios.get(`${config.API_URL}/api/mcq/mcq-sets/meta`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setSubjects(response.data);
        setExpanded(response.data[0]?.subject); // Set the first subject as expanded initially
      } catch (error) {
        console.error('Error fetching MCQ sets:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchSubjects();

    // Mock activity feed to simulate fetching activity data
    setTimeout(() => {
      const mockActivityFeed = {
        "2024-10-01": [{ activity: "Completed Genetics Set 1" }],
        "2024-10-03": [{ activity: "Completed Organic Chemistry Set 1" }],
      };
      setActivityFeed(mockActivityFeed);
      setActivityLoading(false);
    }, 1500);
  }, []);

  const handleAccordionChange = (subject) => {
    setExpanded((prev) => (prev === subject ? null : subject));
  };

  const handleReattempt = (subject, topic, uuid) => {
    navigate(`/explore/${uuid}`);
  };

  const handleAiSuggestion = async (subject, topic, setName, uuid) => {
    // Reset previous AI suggestion before fetching a new one
    setAiStates(prev => ({
      ...prev,
      [uuid]: { aiLoading: true, aiSummary: "" }
    }));
  
    try {
      // Get the authentication token
      const token = await getToken();
  
      // Make the API call to fetch AI suggestions
      const response = await fetch(`${config.API_URL}/api/mcq/getAIsuggestion/${uuid}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
  
      if (!response.ok) {
        throw new Error('Failed to fetch AI suggestions');
      }
  
      const data = await response.json();
  
      // Update the state with the fetched AI summary
      setAiStates(prev => ({
        ...prev,
        [uuid]: { aiLoading: false, aiSummary: data.suggestion }
      }));
    } catch (error) {
      console.error('Error fetching AI suggestions:', error);
      // Update the state to reflect the error
      setAiStates(prev => ({
        ...prev,
        [uuid]: { aiLoading: false, aiSummary: 'Error fetching AI suggestions. Please try again later.' }
      }));
    }
  };

  return (
    <Box sx={{ padding: 3, backgroundColor: themeColors.background.default }}>
      <Typography 
        variant="h5" 
        gutterBottom 
        sx={{ 
          color: themeColors.primary.main, 
          fontWeight: typography.fontWeightMedium, 
          marginBottom: 3,
          fontFamily: typography.fontFamily
        }}
      >
        Personalization & Analytics
      </Typography>

      <Grid container spacing={3}>
        {/* Left: Subject Wise List */}
        <Grid item xs={12} md={8} sx={{ overflowY: 'auto', maxHeight: '80vh' }}>
          {loading ? (
            Array.from(new Array(2)).map((_, index) => (
              <Box key={index} mb={2}>
                <Skeleton variant="rectangular" height={50} />
                <Skeleton variant="rectangular" height={100} sx={{ mt: 1 }} />
              </Box>
            ))
          ) : (
            subjects.map((subject) => (
              <SubjectCard key={subject.subject} sx={{ mb: 2 }}>
                <CardContent onClick={() => handleAccordionChange(subject.subject)} style={{ cursor: "pointer" }}>
                  <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Typography 
                      variant="h6" 
                      sx={{ 
                        fontWeight: typography.fontWeightMedium,
                        color: themeColors.text.primary,
                        fontFamily: typography.fontFamily
                      }}
                    >
                      {subject.subject}
                    </Typography>
                    <Chip
                      label={`${subject.topics.length} topics`}
                      color="primary"
                      avatar={<Avatar>{subject.topics.length}</Avatar>}
                      sx={{ backgroundColor: themeColors.primary.main }}
                    />
                  </Box>
                </CardContent>
                <Collapse in={expanded === subject.subject} timeout="auto" unmountOnExit>
                  <Box mt={2}>
                    {subject.topics.map((topic, idx) => (
                      <Box key={`${subject.subject}-${idx}`} mb={2} p={2} bgcolor={themeColors.neutral.grey[50]} borderRadius={4}>
                        <Typography 
                          variant="body1" 
                          sx={{ 
                            fontWeight: typography.fontWeightMedium,
                            color: themeColors.text.primary,
                            fontFamily: typography.fontFamily
                          }}
                        >
                          {topic.name}
                        </Typography>
                        {topic.mcqSets.map((mcqSet, setIdx) => {
                          const knowledgeLevel = mcqSet.successRate < 50 ? "Low" : mcqSet.successRate < 75 ? "Moderate" : "High";

                          return (
                            <Box key={`${topic.name}-${setIdx}`} mt={2} p={2} bgcolor={themeColors.background.paper} borderRadius={4}>
                              <Grid container alignItems="center">
                                <Grid item xs={6}>
                                  <Typography 
                                    variant="body1" 
                                    sx={{ 
                                      fontWeight: typography.fontWeightMedium,
                                      color: themeColors.text.primary,
                                      fontFamily: typography.fontFamily
                                    }}
                                  >
                                    {mcqSet.setName}
                                  </Typography>
                                  <Typography 
                                    variant="body2" 
                                    sx={{ 
                                      color: themeColors.text.secondary,
                                      fontFamily: typography.fontFamily
                                    }}
                                  >
                                    {mcqSet.solvedQuestions}/{mcqSet.totalQuestions} questions solved
                                  </Typography>
                                  <Typography 
                                    variant="body2" 
                                    sx={{ 
                                      color: themeColors.text.secondary,
                                      fontFamily: typography.fontFamily
                                    }}
                                  >
                                    Success Rate: {mcqSet.successRate}%
                                  </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                  <CircularProgress 
                                    variant="determinate" 
                                    value={mcqSet.successRate} 
                                    size={50} 
                                    sx={{ color: themeColors.primary.main }}
                                  />
                                  <Typography 
                                    variant="body2" 
                                    sx={{ 
                                      color: themeColors.text.secondary,
                                      fontFamily: typography.fontFamily
                                    }}
                                  >
                                    {mcqSet.successRate}% ({knowledgeLevel})
                                  </Typography>
                                </Grid>
                                <Grid item xs={3} textAlign="center">
                                  <Button
                                    onClick={() => handleAiSuggestion(subject.subject, topic.name, mcqSet.setName, mcqSet.uuid)}
                                    disabled={aiStates[mcqSet.uuid]?.aiLoading}
                                    sx={{ 
                                      mt: 1,
                                      color: themeColors.primary.main,
                                      fontFamily: typography.fontFamily,
                                      fontWeight: typography.fontWeightMedium
                                    }}
                                  >
                                    {aiStates[mcqSet.uuid]?.aiLoading ? "Loading..." : "AI Suggestion"}
                                  </Button>

                                  {mcqSet.canReattempt && (
                                    <Button
                                      variant="contained"
                                      sx={{
                                        mt: 1,
                                        ml: 1,
                                        backgroundColor: themeColors.primary.main,
                                        color: themeColors.primary.contrastText,
                                        fontFamily: typography.fontFamily,
                                        fontWeight: typography.fontWeightMedium,
                                        '&:hover': {
                                          backgroundColor: themeColors.primary.dark
                                        }
                                      }}
                                      onClick={() => handleReattempt(subject.subject, topic.name, mcqSet.uuid)}
                                    >
                                      Reattempt
                                    </Button>
                                  )}
                                </Grid>
                              </Grid>
                              {aiStates[mcqSet.uuid]?.aiSummary && (
                                <Box mt={2} p={2} bgcolor={themeColors.neutral.grey[100]} borderRadius={4}>
                                  <Typography 
                                    variant="body2" 
                                    sx={{ 
                                      color: themeColors.text.secondary,
                                      fontFamily: typography.fontFamily
                                    }}
                                  >
                                    <ReactMarkdown rehypePlugins={[rehypeSanitize]}>
                                      {aiStates[mcqSet.uuid]?.aiSummary}
                                    </ReactMarkdown>
                                  </Typography>
                                </Box>
                              )}
                            </Box>
                          );
                        })}
                      </Box>
                    ))}
                  </Box>
                </Collapse>
              </SubjectCard>
            ))
          )}
        </Grid>

        {/* Right: Activity Feed */}
        <Grid item xs={12} md={4}>
          <Box
            sx={{
              backgroundColor: themeColors.background.paper,
              borderRadius: "10px",
              boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
              border: "1px solid #e0e0e0",
              padding: 2,
              position: "sticky",
              top: 80,
            }}
          >
            <Typography 
              variant="h6" 
              gutterBottom 
              sx={{ 
                fontWeight: typography.fontWeightMedium,
                color: themeColors.text.primary,
                fontFamily: typography.fontFamily
              }}
            >
              Weekly Progress Summary
            </Typography>
            <Box mb={2}>
              <Typography 
                variant="body2" 
                sx={{ 
                  color: themeColors.text.secondary,
                  fontFamily: typography.fontFamily
                }}
              >
                Total Questions Solved: 0
              </Typography>
              <Typography 
                variant="body2" 
                sx={{ 
                  color: themeColors.text.secondary,
                  fontFamily: typography.fontFamily
                }}
              >
                Correct Answers: 0
              </Typography>
              <Typography 
                variant="body2" 
                sx={{ 
                  color: themeColors.text.secondary,
                  fontFamily: typography.fontFamily
                }}
              >
                Success Rate Improvement: Track your weak areas for consistent improvement!
              </Typography>
            </Box>

            <Typography 
              variant="h6" 
              gutterBottom 
              sx={{ 
                fontWeight: typography.fontWeightMedium, 
                marginTop: 3,
                color: themeColors.text.primary,
                fontFamily: typography.fontFamily
              }}
            >
              Recent Activity
            </Typography>
            {activityLoading ? (
              Array.from(new Array(3)).map((_, index) => (
                <Skeleton key={index} variant="rectangular" height={40} sx={{ mb: 1 }} />
              ))
            ) : Object.keys(activityFeed).length > 0 ? (
              Object.keys(activityFeed).map((date) => (
                <Box key={date} mb={2}>
                  <Typography 
                    variant="body2" 
                    sx={{ 
                      fontWeight: typography.fontWeightMedium,
                      color: themeColors.text.secondary,
                      fontFamily: typography.fontFamily
                    }}
                  >
                    {date}
                  </Typography>
                  {activityFeed[date].map((activity, idx) => (
                    <Box key={idx} mb={1} p={1} bgcolor={themeColors.neutral.grey[100]} borderRadius={2}>
                      <Typography 
                        variant="body2"
                        sx={{ 
                          color: themeColors.text.primary,
                          fontFamily: typography.fontFamily
                        }}
                      >
                        {activity.activity}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              ))
            ) : (
              <Box mb={2} p={2} bgcolor={themeColors.neutral.grey[100]} borderRadius={4}>
                <Typography 
                  variant="body1" 
                  sx={{ 
                    color: themeColors.text.secondary,
                    fontFamily: typography.fontFamily
                  }}
                >
                  No recent activity available. Start practicing to see your progress!
                </Typography>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SubjectAnalyticsRevamp;
