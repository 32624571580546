import React, { useState, useEffect } from 'react';
import {
  Box, Button, Typography, Tab, Tabs, Card, CardContent, Grid,
  Dialog, DialogTitle, DialogContent, DialogActions, TextField,
  MenuItem, Select, FormControl, InputLabel, Chip, Avatar,
  CircularProgress, InputAdornment, IconButton, Divider
} from '@mui/material';
import { styled } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';
import ClassIcon from '@mui/icons-material/Class';
import SubjectIcon from '@mui/icons-material/Subject';
import PersonIcon from '@mui/icons-material/Person';
import axios from 'axios';
import { useAuth } from '../../../services/authContext';
import config from '../../../config';

// Styled components
const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  transition: 'transform 0.3s, box-shadow 0.3s',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: theme.shadows[10],
  },
}));

const ClassTab = styled(Tab)(({ theme }) => ({
  fontWeight: 'bold',
  minWidth: 100,
  '&.Mui-selected': {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.light + '20',
  },
}));

const SubjectCard = ({ subject, onSelect, onAssignTeacher }) => {
  return (
    <StyledCard>
      <CardContent>
        <Box display="flex" alignItems="center" mb={2}>
          <Avatar sx={{ bgcolor: 'primary.main', mr: 2 }}>
            <SubjectIcon />
          </Avatar>
          <Typography variant="h6" noWrap title={subject.name}>
            {subject.name}
          </Typography>
        </Box>
        <Typography variant="body2" color="text.secondary" mb={2}>
          {subject.description || 'No description available'}
        </Typography>
        <Box display="flex" justifyContent="space-between" mt="auto">
          <Button 
            size="small" 
            variant="outlined" 
            onClick={() => onSelect(subject)}
          >
            View Topics
          </Button>
          <Button 
            size="small" 
            variant="contained" 
            startIcon={<PersonIcon />}
            onClick={() => onAssignTeacher(subject)}
          >
            Assign
          </Button>
        </Box>
      </CardContent>
    </StyledCard>
  );
};

const EnhancedSubjectManagement = () => {
  const { user, token } = useAuth();
  const [activeClass, setActiveClass] = useState('');
  const [classes, setClasses] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [globalSubjects, setGlobalSubjects] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [openImportDialog, setOpenImportDialog] = useState(false);
  const [openAssignDialog, setOpenAssignDialog] = useState(false);
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [selectedTeacher, setSelectedTeacher] = useState('');
  const [openTopicDialog, setOpenTopicDialog] = useState(false);
  const [topics, setTopics] = useState([]);
  const [globalTopics, setGlobalTopics] = useState([]);

  // Fetch classes for the institution
  useEffect(() => {
    if (user?.institution) {
      setLoading(true);
      axios.get(`${config.API_URL}/api/institutions/${user.institution}/classes`, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then(response => {
        const classData = response.data.classes || [];
        setClasses(classData);
        if (classData.length > 0) {
          setActiveClass(classData[0]._id);
        }
      })
      .catch(error => {
        console.error('Error fetching classes:', error);
      })
      .finally(() => {
        setLoading(false);
      });
    }
  }, [user, token]);

  // Fetch subjects when active class changes
  useEffect(() => {
    if (activeClass && user?.institution) {
      setLoading(true);
      axios.get(`${config.API_URL}/api/institutions/subjects?institutionId=${user.institution}&classId=${activeClass}`, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then(response => {
        setSubjects(response.data.subjects || []);
      })
      .catch(error => {
        console.error('Error fetching subjects:', error);
      })
      .finally(() => {
        setLoading(false);
      });
    }
  }, [activeClass, user, token]);

  // Fetch teachers for the institution
  useEffect(() => {
    if (user?.institution) {
      axios.get(`${config.API_URL}/api/institutions/teachers?institutionId=${user.institution}`, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then(response => {
        setTeachers(response.data.teachers || []);
      })
      .catch(error => {
        console.error('Error fetching teachers:', error);
      });
    }
  }, [user, token]);

  const handleClassChange = (event, newValue) => {
    setActiveClass(newValue);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleOpenImportDialog = () => {
    // Fetch global subjects for the selected class
    const classLevel = classes.find(c => c._id === activeClass)?.name || '';
    
    setLoading(true);
    axios.get(`${config.API_URL}/api/config/global/subjects?classLevel=${classLevel}`, {
      headers: { Authorization: `Bearer ${token}` }
    })
    .then(response => {
      setGlobalSubjects(response.data.subjects || []);
      setOpenImportDialog(true);
    })
    .catch(error => {
      console.error('Error fetching global subjects:', error);
    })
    .finally(() => {
      setLoading(false);
    });
  };

  const handleImportSubjects = (selectedGlobalSubjects) => {
    if (selectedGlobalSubjects.length === 0) return;
    
    const subjects = selectedGlobalSubjects.map(subject => ({
      globalSubjectId: subject._id
    }));
    
    axios.post(`${config.API_URL}/api/config/global/subjects/import`, {
      institutionId: user.institution,
      classId: activeClass,
      subjects
    }, {
      headers: { Authorization: `Bearer ${token}` }
    })
    .then(response => {
      // Refresh subjects list
      axios.get(`${config.API_URL}/api/institutions/subjects?institutionId=${user.institution}&classId=${activeClass}`, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then(response => {
        setSubjects(response.data.subjects || []);
        setOpenImportDialog(false);
      });
    })
    .catch(error => {
      console.error('Error importing subjects:', error);
    });
  };

  const handleOpenAssignDialog = (subject) => {
    setSelectedSubject(subject);
    setOpenAssignDialog(true);
  };

  const handleAssignTeacher = () => {
    if (!selectedTeacher || !selectedSubject) return;
    
    axios.post(`${config.API_URL}/api/config/subjects/assign-teacher`, {
      institutionId: user.institution,
      teacherId: selectedTeacher,
      subjectIds: [selectedSubject._id]
    }, {
      headers: { Authorization: `Bearer ${token}` }
    })
    .then(response => {
      setOpenAssignDialog(false);
      // You could add a success notification here
    })
    .catch(error => {
      console.error('Error assigning teacher:', error);
    });
  };

  const handleOpenTopicDialog = (subject) => {
    setSelectedSubject(subject);
    setLoading(true);
    
    // Fetch topics for the selected subject
    axios.get(`${config.API_URL}/api/institutions/subjects/${subject._id}/topics`, {
      headers: { Authorization: `Bearer ${token}` }
    })
    .then(response => {
      setTopics(response.data.topics || []);
      
      // Fetch global topics for the subject
      const classLevel = classes.find(c => c._id === activeClass)?.name || '';
      return axios.get(`${config.API_URL}/api/config/global/topics?subjectName=${subject.name}&classLevel=${classLevel}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
    })
    .then(response => {
      setGlobalTopics(response.data.topics || []);
      setOpenTopicDialog(true);
    })
    .catch(error => {
      console.error('Error fetching topics:', error);
    })
    .finally(() => {
      setLoading(false);
    });
  };

  const handleImportTopics = (selectedGlobalTopics) => {
    if (selectedGlobalTopics.length === 0 || !selectedSubject) return;
    
    const topics = selectedGlobalTopics.map(topic => ({
      globalTopicId: topic._id
    }));
    
    axios.post(`${config.API_URL}/api/config/global/topics/import`, {
      institutionId: user.institution,
      subjectId: selectedSubject._id,
      topics
    }, {
      headers: { Authorization: `Bearer ${token}` }
    })
    .then(response => {
      // Refresh topics list
      axios.get(`${config.API_URL}/api/institutions/subjects/${selectedSubject._id}/topics`, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then(response => {
        setTopics(response.data.topics || []);
      });
    })
    .catch(error => {
      console.error('Error importing topics:', error);
    });
  };

  // Filter subjects based on search term
  const filteredSubjects = subjects.filter(subject => 
    subject.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Box sx={{ p: 3 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <Typography variant="h4" component="h1">
          Subject Management
        </Typography>
        <Button 
          variant="contained" 
          startIcon={<AddIcon />}
          onClick={handleOpenImportDialog}
        >
          Add Subject
        </Button>
      </Box>

      <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 3 }}>
        <Tabs 
          value={activeClass} 
          onChange={handleClassChange} 
          variant="scrollable"
          scrollButtons="auto"
          aria-label="class tabs"
        >
          {classes.map((cls) => (
            <ClassTab 
              key={cls._id} 
              value={cls._id} 
              label={cls.name} 
              icon={<ClassIcon />} 
              iconPosition="start"
            />
          ))}
        </Tabs>
      </Box>

      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <TextField
          placeholder="Search subjects..."
          variant="outlined"
          size="small"
          value={searchTerm}
          onChange={handleSearchChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          sx={{ width: 300 }}
        />
        <Button 
          variant="outlined" 
          startIcon={<FilterListIcon />}
        >
          Filter
        </Button>
      </Box>

      {loading ? (
        <Box display="flex" justifyContent="center" my={5}>
          <CircularProgress />
        </Box>
      ) : filteredSubjects.length === 0 ? (
        <Box textAlign="center" my={5}>
          <Typography variant="h6" color="text.secondary" gutterBottom>
            No subjects found
          </Typography>
          <Button 
            variant="contained" 
            startIcon={<AddIcon />}
            onClick={handleOpenImportDialog}
            sx={{ mt: 2 }}
          >
            Add Subject
          </Button>
        </Box>
      ) : (
        <Grid container spacing={3}>
          {filteredSubjects.map((subject) => (
            <Grid item xs={12} sm={6} md={4} key={subject._id}>
              <SubjectCard 
                subject={subject} 
                onSelect={handleOpenTopicDialog}
                onAssignTeacher={handleOpenAssignDialog}
              />
            </Grid>
          ))}
        </Grid>
      )}

      {/* Import Subjects Dialog */}
      <Dialog 
        open={openImportDialog} 
        onClose={() => setOpenImportDialog(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Import Subjects</DialogTitle>
        <DialogContent>
          <ImportSubjectsDialog 
            globalSubjects={globalSubjects} 
            onImport={handleImportSubjects} 
          />
        </DialogContent>
      </Dialog>

      {/* Assign Teacher Dialog */}
      <Dialog 
        open={openAssignDialog} 
        onClose={() => setOpenAssignDialog(false)}
      >
        <DialogTitle>Assign Teacher to {selectedSubject?.name}</DialogTitle>
        <DialogContent>
          <Box sx={{ minWidth: 400, mt: 2 }}>
            <FormControl fullWidth>
              <InputLabel id="teacher-select-label">Teacher</InputLabel>
              <Select
                labelId="teacher-select-label"
                value={selectedTeacher}
                label="Teacher"
                onChange={(e) => setSelectedTeacher(e.target.value)}
              >
                {teachers.map((teacher) => (
                  <MenuItem key={teacher._id} value={teacher._id}>
                    {`${teacher.firstName} ${teacher.lastName}`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenAssignDialog(false)}>Cancel</Button>
          <Button 
            variant="contained" 
            onClick={handleAssignTeacher}
            disabled={!selectedTeacher}
          >
            Assign
          </Button>
        </DialogActions>
      </Dialog>

      {/* Topics Dialog */}
      <Dialog 
        open={openTopicDialog} 
        onClose={() => setOpenTopicDialog(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Topics for {selectedSubject?.name}</DialogTitle>
        <DialogContent>
          <TopicsDialog 
            topics={topics} 
            globalTopics={globalTopics}
            onImport={handleImportTopics}
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

// Component for the import subjects dialog content
const ImportSubjectsDialog = ({ globalSubjects, onImport }) => {
  const [selectedSubjects, setSelectedSubjects] = useState([]);
  
  const handleToggleSubject = (subject) => {
    const currentIndex = selectedSubjects.findIndex(s => s._id === subject._id);
    const newSelected = [...selectedSubjects];
    
    if (currentIndex === -1) {
      newSelected.push(subject);
    } else {
      newSelected.splice(currentIndex, 1);
    }
    
    setSelectedSubjects(newSelected);
  };
  
  return (
    <Box>
      <Typography variant="body1" gutterBottom>
        Select subjects to import:
      </Typography>
      
      <Box sx={{ my: 2 }}>
        {selectedSubjects.length > 0 && (
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle2" gutterBottom>
              Selected subjects:
            </Typography>
            <Box display="flex" flexWrap="wrap" gap={1}>
              {selectedSubjects.map((subject) => (
                <Chip 
                  key={subject._id}
                  label={subject.name}
                  onDelete={() => handleToggleSubject(subject)}
                  color="primary"
                />
              ))}
            </Box>
          </Box>
        )}
      </Box>
      
      <Divider sx={{ my: 2 }} />
      
      <Grid container spacing={2}>
        {globalSubjects.map((subject) => {
          const isSelected = selectedSubjects.some(s => s._id === subject._id);
          
          return (
            <Grid item xs={12} sm={6} md={4} key={subject._id}>
              <Card 
                sx={{ 
                  cursor: 'pointer',
                  border: isSelected ? '2px solid' : '1px solid',
                  borderColor: isSelected ? 'primary.main' : 'divider',
                }}
                onClick={() => handleToggleSubject(subject)}
              >
                <CardContent>
                  <Typography variant="h6">{subject.name}</Typography>
                  <Typography variant="body2" color="text.secondary">
                    {subject.description || 'No description'}
                  </Typography>
                  <Box display="flex" alignItems="center" mt={1}>
                    <Chip 
                      size="small" 
                      label={subject.classLevel} 
                      color="secondary"
                      sx={{ mr: 1 }}
                    />
                    <Chip 
                      size="small" 
                      label={subject.category} 
                    />
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          );
        })}
      </Grid>
      
      <Box display="flex" justifyContent="flex-end" mt={3}>
        <Button 
          variant="contained" 
          onClick={() => onImport(selectedSubjects)}
          disabled={selectedSubjects.length === 0}
        >
          Import Selected ({selectedSubjects.length})
        </Button>
      </Box>
    </Box>
  );
};

// Component for the topics dialog content
const TopicsDialog = ({ topics, globalTopics, onImport }) => {
  const [selectedTopics, setSelectedTopics] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  
  const handleToggleTopic = (topic) => {
    const currentIndex = selectedTopics.findIndex(t => t._id === topic._id);
    const newSelected = [...selectedTopics];
    
    if (currentIndex === -1) {
      newSelected.push(topic);
    } else {
      newSelected.splice(currentIndex, 1);
    }
    
    setSelectedTopics(newSelected);
  };
  
  return (
    <Box>
      <Tabs value={activeTab} onChange={(e, newValue) => setActiveTab(newValue)}>
        <Tab label="Current Topics" />
        <Tab label="Import Topics" />
      </Tabs>
      
      {activeTab === 0 && (
        <Box sx={{ mt: 2 }}>
          {topics.length === 0 ? (
            <Typography variant="body1" color="text.secondary" sx={{ my: 3, textAlign: 'center' }}>
              No topics found. Import topics from the next tab.
            </Typography>
          ) : (
            <Grid container spacing={2}>
              {topics.map((topic) => (
                <Grid item xs={12} sm={6} key={topic._id}>
                  <Card>
                    <CardContent>
                      <Typography variant="h6">{topic.name}</Typography>
                      <Typography variant="body2" color="text.secondary">
                        {topic.description || 'No description'}
                      </Typography>
                      {topic.keywords && topic.keywords.length > 0 && (
                        <Box display="flex" flexWrap="wrap" gap={0.5} mt={1}>
                          {topic.keywords.map((keyword, index) => (
                            <Chip 
                              key={index}
                              label={keyword} 
                              size="small" 
                              variant="outlined"
                            />
                          ))}
                        </Box>
                      )}
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          )}
        </Box>
      )}
      
      {activeTab === 1 && (
        <Box sx={{ mt: 2 }}>
          <Typography variant="body1" gutterBottom>
            Select topics to import:
          </Typography>
          
          <Box sx={{ my: 2 }}>
            {selectedTopics.length > 0 && (
              <Box sx={{ mb: 2 }}>
                <Typography variant="subtitle2" gutterBottom>
                  Selected topics:
                </Typography>
                <Box display="flex" flexWrap="wrap" gap={1}>
                  {selectedTopics.map((topic) => (
                    <Chip 
                      key={topic._id}
                      label={topic.name}
                      onDelete={() => handleToggleTopic(topic)}
                      color="primary"
                    />
                  ))}
                </Box>
              </Box>
            )}
          </Box>
          
          <Divider sx={{ my: 2 }} />
          
          <Grid container spacing={2}>
            {globalTopics.map((topic) => {
              const isSelected = selectedTopics.some(t => t._id === topic._id);
              
              return (
                <Grid item xs={12} sm={6} key={topic._id}>
                  <Card 
                    sx={{ 
                      cursor: 'pointer',
                      border: isSelected ? '2px solid' : '1px solid',
                      borderColor: isSelected ? 'primary.main' : 'divider',
                    }}
                    onClick={() => handleToggleTopic(topic)}
                  >
                    <CardContent>
                      <Typography variant="h6">{topic.name}</Typography>
                      <Typography variant="body2" color="text.secondary">
                        {topic.description || 'No description'}
                      </Typography>
                      <Box display="flex" alignItems="center" mt={1}>
                        <Chip 
                          size="small" 
                          label={topic.difficulty} 
                          color="secondary"
                          sx={{ mr: 1 }}
                        />
                        {topic.keywords && topic.keywords.length > 0 && (
                          <Typography variant="caption" color="text.secondary">
                            {topic.keywords.join(', ')}
                          </Typography>
                        )}
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
          
          <Box display="flex" justifyContent="flex-end" mt={3}>
            <Button 
              variant="contained" 
              onClick={() => onImport(selectedTopics)}
              disabled={selectedTopics.length === 0}
            >
              Import Selected ({selectedTopics.length})
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default EnhancedSubjectManagement;
