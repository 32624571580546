import React, { useState, useEffect, useRef } from 'react';
import { 
  Box, 
  Typography, 
  TextField, 
  Button, 
  IconButton, 
  Paper, 
  CircularProgress, 
  Card, 
  CardContent, 
  Radio, 
  RadioGroup, 
  FormControlLabel, 
  FormControl, 
  Divider, 
  Chip, 
  Grid, 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions, 
  Select, 
  MenuItem,
  InputAdornment,
  Avatar,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Alert,
  Snackbar,
  Slide,
  Fade
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { 
  ThumbUp, 
  ThumbDown, 
  Flag, 
  CloudUpload, 
  Send, 
  AttachFile,
  CheckCircle,
  Cancel,
  School,
  CloudDownload
} from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import config from '../../../config';
import { useAuth } from '../../../services/authContext';
import { useNavigate } from 'react-router-dom';

// Transition for MCQ cards appearing
const SlideUp = (props) => {
  return <Slide {...props} direction="up" />;
};

// Styled components
const MCQContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: 'calc(100vh - 80px)',
  position: 'relative',
  backgroundColor: theme.palette.background.default,
  overflow: 'hidden'
}));

const MCQHeader = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2, 3),
  backgroundColor: theme.palette.background.paper,
  borderBottom: `1px solid ${theme.palette.divider}`,
  zIndex: 10
}));

const MCQContentContainer = styled(Box)(({ theme }) => ({
  flex: 1,
  overflowY: 'auto',
  padding: theme.spacing(3),
  paddingBottom: theme.spacing(2)
}));

const MCQInputContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
  borderTop: `1px solid ${theme.palette.divider}`,
  zIndex: 10
}));

const MCQInputField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: '20px',
    backgroundColor: 'rgba(0, 0, 0, 0.03)',
    '&:hover': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'transparent'
      }
    },
    '&.Mui-focused': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'transparent',
        borderWidth: '1px',
      }
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent'
    }
  }
}));

const SendButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  backgroundColor: theme.palette.primary.main,
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
  marginLeft: theme.spacing(1),
  width: 40,
  height: 40,
  borderRadius: '50%'
}));

const UploadIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.text.secondary,
  '&:hover': {
    color: theme.palette.primary.main,
  },
  padding: '8px'
}));

const MCQCard = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  borderRadius: 8,
  boxShadow: theme.shadows[1],
  overflow: 'visible',
  transition: 'transform 0.3s, box-shadow 0.3s',
  '&:hover': {
    boxShadow: theme.shadows[3],
  }
}));

const OptionRadio = styled(Radio)(({ theme }) => ({
  padding: '8px',
}));

const MCQPractice = ({ topicId, onHistoryUpdate, refreshHistory }) => {
  // State for input management
  const [topic, setTopic] = useState('');
  const [loading, setLoading] = useState(false);
  
  // State for MCQ data
  const [mcqSets, setMcqSets] = useState([]);
  const [currentMCQs, setCurrentMCQs] = useState([]);
  const [userAnswers, setUserAnswers] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [currentMcqId, setCurrentMcqId] = useState(null);
  
  // File upload states
  const [openFileModal, setOpenFileModal] = useState(false);
  const [fileToUpload, setFileToUpload] = useState(null);
  const [numQuestions, setNumQuestions] = useState(5);
  const [difficulty, setDifficulty] = useState('medium');
  const [uploadLoading, setUploadLoading] = useState(false);
  
  // UI states
  const [error, setError] = useState('');
  const [showError, setShowError] = useState(false);
  const [transitionIn, setTransitionIn] = useState(true);
  
  // Refs
  const inputRef = useRef(null);
  const fileInputRef = useRef(null);
  const contentRef = useRef(null);
  
  const { getToken, logout } = useAuth();
  const navigate = useNavigate();

  // Create an axios instance with interceptors to handle 401 errors
  const axiosInstance = axios.create();
  
  // Setup response interceptor for 401 handling
  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && error.response.status === 401) {
        // Handle 401 Unauthorized error - logout and redirect to login
        logout();
        navigate('/auth/signin');
        return Promise.reject(new Error('Session expired, please sign in again.'));
      }
      return Promise.reject(error);
    }
  );

  // Fetch MCQ history on component mount
  useEffect(() => {
    fetchMCQHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Handle topic selection
  useEffect(() => {
    if (topicId) {
      console.log('Topic ID changed to:', topicId);
      // Clear current state when topicId changes
      setCurrentMCQs([]);
      setUserAnswers({});
      setSubmitted(false);
      setTransitionIn(false);
      setLoading(true);
      
      // Fetch data for the selected topic with a slight delay for transition
      setTimeout(() => {
        fetchMCQData(topicId);
      }, 300);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topicId]);

  // Fetch all MCQ history for the user
  const fetchMCQHistory = async () => {
    try {
      setLoading(true);
      const token = await getToken();
      
      const response = await axiosInstance.get(
        `${config.API_URL}/api/mcq/userMcqHistory`, 
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );
      
      // Sort history with newest items at the top (descending)
      const sortedHistory = response.data.sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
      
      // Limit to last 10 entries
      const limitedHistory = sortedHistory.slice(0, 10);
      
      console.log('Fetched MCQ history in MCQPractice:', limitedHistory);
      
      // Send history update to parent component for the recent topics
      if (onHistoryUpdate) {
        onHistoryUpdate(limitedHistory);
      }
      
      setLoading(false);
    } catch (error) {
      console.error('Error fetching MCQ history:', error);
      setError('Failed to load MCQ history. Please try again.');
      setShowError(true);
      setLoading(false);
    }
  };

  // Function to fetch MCQ data by ID
  const fetchMCQData = async (id) => {
    try {
      setLoading(true);
      setTransitionIn(false);
      
      const token = await getToken();
      
      const response = await axiosInstance.get(
        `${config.API_URL}/api/mcq/questHistory/${id}`,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );
      
      console.log('Fetched MCQ data by ID:', response.data);
      
      // The API returns data with history as an array
      if (response.data && response.data.history && response.data.history.length > 0) {
        // Get the first history entry (or find the one we want if there are multiple)
        // In most cases, we just need the first one
        const historyEntry = response.data.history[0];
        
        if (historyEntry && historyEntry.questionData) {
          const mcqs = historyEntry.questionData;
          
          // Initialize user answers from history
          const answers = {};
          mcqs.forEach(mcq => {
            if (mcq.userAnswer && mcq.userAnswer.length > 0) {
              answers[mcq._id] = mcq.userAnswer;
            }
          });
          
          setUserAnswers(answers);
          setSubmitted(true);
          setCurrentMcqId(id);
          
          // Add slight delay before showing the new MCQs for better transition
          setTimeout(() => {
            setCurrentMCQs(mcqs);
            setLoading(false);
            setTransitionIn(true);
          }, 300);
        } else {
          setError('No question data found for this topic.');
          setShowError(true);
          setLoading(false);
          setTransitionIn(true);
        }
      } else {
        setError('No history data found for this topic.');
        setShowError(true);
        setLoading(false);
        setTransitionIn(true);
      }
    } catch (error) {
      console.error('Error in fetchData:', error);
      setError('Failed to load MCQ set. Please try again.');
      setShowError(true);
      setLoading(false);
      setTransitionIn(true);
    }
  };

  // Generate new MCQs based on topic
  const generateMCQs = async () => {
    if (!topic.trim()) {
      setError('Please enter a topic to generate MCQs');
      setShowError(true);
      return;
    }
    
    try {
      // Set loading state for new MCQs while keeping previous ones visible
      setLoading(true);
      
      // If we have current MCQs, save them to history first
      if (currentMCQs.length > 0) {
        // Add current set to the mcqSets array
        setMcqSets(prev => [...prev, {
          mcqs: currentMCQs,
          userAnswers: userAnswers,
          submitted: submitted,
          mcqId: currentMcqId,
          timestamp: new Date().toISOString()
        }]);
        
        // Clear current MCQs to show loader for new generation
        // but keep transition in true to show the history items
        setCurrentMCQs([]);
      }
      
      const token = await getToken();
      
      const response = await axiosInstance.post(
        `${config.API_URL}/api/mcq/generate`,
        {
          topic: topic,
          textId: null,
          type: "Enhanced questions",
          numberOfQuestions: 0
        },
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );
      
      // If successful, add the new MCQs to the set
      if (response.data && response.data.length > 0) {
        // Reset states for new MCQ set
        setUserAnswers({});
        setSubmitted(false);
        setCurrentMcqId(null);
        
        // Set the new MCQs as current with slight delay for better transition
        setTimeout(() => {
          setCurrentMCQs(response.data);
          setLoading(false);
        }, 300);
        
        // Clear the input field after successful generation
        setTopic('');
        
        // Refresh history to update the recent topics
        setTimeout(() => {
          // Use the parent's refreshHistory function if provided (preferred)
          if (refreshHistory) {
            console.log('Using parent refreshHistory');
            refreshHistory();
          } else {
            console.log('Using local fetchMCQHistory');
            fetchMCQHistory();
          }
        }, 1000);
      } else {
        setError('Failed to generate MCQs. Please try again.');
        setShowError(true);
        setLoading(false);
      }
    } catch (error) {
      console.error('Error generating MCQs:', error);
      setError('Failed to generate MCQs. Please try again.');
      setShowError(true);
      setLoading(false);
    }
  };

  // Handle answer selection
  const handleAnswerChange = (questionId, option) => {
    setUserAnswers(prev => ({
      ...prev,
      [questionId]: [option]
    }));
  };

  // Submit MCQ answers
  const submitAnswers = async () => {
    try {
      setLoading(true);
      const token = await getToken();
      
      // Prepare data for submission
      const questionData = currentMCQs.map(mcq => ({
        text: mcq.text,
        type: mcq.type,
        options: mcq.options,
        correct: mcq.correct,
        explanation: mcq.explanation,
        subject: mcq.subject,
        userAnswer: userAnswers[mcq.id || mcq._id] || []
      }));
      
      const payload = {
        topic: currentMCQs[0]?.subject || topic,
        totalQuestions: currentMCQs.length,
        questionData: questionData,
        marks: Object.keys(userAnswers).length
      };
      
      const response = await axiosInstance.post(
        `${config.API_URL}/api/mcq/mcqHistory`,
        payload,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );
      
      setSubmitted(true);
      setCurrentMcqId(response.data.id);
      
      // Refresh history after submission to update recent topics
      if (refreshHistory) {
        console.log('Using parent refreshHistory after submission');
        refreshHistory();
      } else {
        console.log('Using local fetchMCQHistory after submission');
        fetchMCQHistory();
      }
      
      setLoading(false);
    } catch (error) {
      console.error('Error submitting answers:', error);
      setError('Failed to submit answers. Please try again.');
      setShowError(true);
      setLoading(false);
    }
  };

  // Handle file upload for MCQs
  const handleFileUpload = async () => {
    if (!fileToUpload) {
      setError('Please select a file to upload');
      setShowError(true);
      return;
    }
    
    try {
      setUploadLoading(true);
      setTransitionIn(false);
      
      // Process with delay for transition
      setTimeout(async () => {
        const token = await getToken();
        
        const formData = new FormData();
        formData.append('file', fileToUpload);
        formData.append('prompt', '');
        formData.append('numQuestions', numQuestions);
        formData.append('difficulty', difficulty);
        formData.append('topics', JSON.stringify([]));
        
        const response = await axiosInstance.post(
          `${config.API_URL}/api/mcq/upload`,
          formData,
          {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          }
        );
        
        // Set new state with delay for transition
        setTimeout(() => {
          setCurrentMCQs(response.data);
          setUserAnswers({});
          setSubmitted(false);
          setCurrentMcqId(null);
          
          // Close modal
          setOpenFileModal(false);
          setFileToUpload(null);
          setUploadLoading(false);
          setTransitionIn(true);
          
          // Refresh history to update the recent topics
          setTimeout(() => {
            fetchMCQHistory();
          }, 1000);
        }, 300);
      }, 300);
      
    } catch (error) {
      console.error('Error uploading file:', error);
      setError('Failed to generate MCQs from file. Please try again.');
      setShowError(true);
      setUploadLoading(false);
      setTransitionIn(true);
    }
  };

  // Export MCQs as PDF
  const exportToPdf = async () => {
    try {
      const token = await getToken();
      
      // Create link to API endpoint
      const url = `${config.API_URL}/api/mcq/exportPdf/${currentMcqId}`;
      
      // Create a temporary anchor element and trigger download
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `MCQ_${currentMcqId}.pdf`);
      link.setAttribute('target', '_blank');
      
      // Add auth header to the request (if supported by browser)
      fetch(url, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        link.href = url;
        document.body.appendChild(link);
        link.click();
        link.remove();
      });
      
    } catch (error) {
      console.error('Error exporting PDF:', error);
      setError('Failed to export PDF. Please try again.');
      setShowError(true);
    }
  };

  // Render MCQ cards
  const renderMCQCards = () => {
    // Completely empty state - no history, no current MCQs, not loading
    if (currentMCQs.length === 0 && mcqSets.length === 0 && !loading) {
      return (
        <Box sx={{ 
          textAlign: 'center', 
          py: 4,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          height: '50vh'
        }}>
          <Typography variant="body1" color="text.secondary">
            No MCQs to display. Enter a medical topic below to generate new ones.
          </Typography>
        </Box>
      );
    }

    return (
      <Box sx={{ mb: 8 }}>
        {/* Display previous MCQ sets */}
        {mcqSets.map((setData, setIndex) => (
          <Fade in={true} key={`set-${setIndex}`} timeout={500}>
            <Box sx={{ mb: 5, opacity: 0.8 }}>
              <Typography variant="h6" sx={{ mb: 2, fontWeight: 500, color: 'text.secondary' }}>
                Previous MCQ Set {mcqSets.length - setIndex}
              </Typography>
              
              {setData.mcqs.map((mcq, index) => {
                const questionId = mcq.id || mcq._id;
                const selectedOption = setData.userAnswers[questionId]?.[0] || '';
                const isCorrect = setData.submitted && selectedOption && mcq.correct.includes(selectedOption);
                const isIncorrect = setData.submitted && selectedOption && !mcq.correct.includes(selectedOption);
                
                return (
                  <MCQCard key={`prev-${setIndex}-${index}`} sx={{ opacity: 0.8 }}>
                    <CardContent>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                        <Typography variant="subtitle1" fontWeight="bold">
                          Question {index + 1}
                        </Typography>
                        <Chip 
                          label={mcq.subject || 'General'} 
                          color="primary" 
                          size="small" 
                        />
                      </Box>
                      
                      <Typography variant="body1" gutterBottom>
                        {mcq.text}
                      </Typography>
                      
                      <FormControl fullWidth component="fieldset" sx={{ my: 2 }}>
                        <RadioGroup value={selectedOption}>
                          {mcq.options.map((option, optIndex) => (
                            <FormControlLabel 
                              key={optIndex}
                              value={option}
                              control={<OptionRadio />}
                              label={option}
                              disabled={true}
                              sx={{ 
                                mb: 1,
                                p: 1,
                                borderRadius: 1,
                                backgroundColor: setData.submitted 
                                  ? mcq.correct.includes(option)
                                    ? 'rgba(76, 175, 80, 0.1)'
                                    : selectedOption === option && !mcq.correct.includes(option)
                                      ? 'rgba(244, 67, 54, 0.1)'
                                      : 'transparent'
                                  : 'transparent',
                              }}
                            />
                          ))}
                        </RadioGroup>
                      </FormControl>
                      
                      {setData.submitted && (
                        <Box sx={{ 
                          mt: 2, 
                          p: 2, 
                          borderRadius: 1, 
                          backgroundColor: isCorrect 
                            ? 'rgba(76, 175, 80, 0.05)' 
                            : isIncorrect 
                              ? 'rgba(244, 67, 54, 0.05)' 
                              : 'rgba(0, 0, 0, 0.02)'
                        }}>
                          {isCorrect && (
                            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                              <CheckCircle color="success" fontSize="small" sx={{ mr: 1 }} />
                              <Typography variant="subtitle2" color="success.main">
                                Correct!
                              </Typography>
                            </Box>
                          )}
                          
                          {isIncorrect && (
                            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                              <Cancel color="error" fontSize="small" sx={{ mr: 1 }} />
                              <Typography variant="subtitle2" color="error.main">
                                Incorrect
                              </Typography>
                            </Box>
                          )}
                          
                          {(isIncorrect || !selectedOption) && (
                            <Typography variant="body2" gutterBottom>
                              <strong>Correct Answer:</strong> {mcq.correct.join(', ')}
                            </Typography>
                          )}
                          
                          <Typography variant="body2">
                            <strong>Explanation:</strong> {mcq.explanation}
                          </Typography>
                        </Box>
                      )}
                    </CardContent>
                  </MCQCard>
                );
              })}
            </Box>
          </Fade>
        ))}
        
        {/* Display current MCQ set */}
        {currentMCQs.length > 0 && (
          <Fade in={true} timeout={500}>
            <Box>
              <Typography variant="h6" sx={{ mb: 2, fontWeight: 500 }}>
                Current MCQ Set
              </Typography>
              
              {currentMCQs.map((mcq, index) => {
                const questionId = mcq.id || mcq._id;
                const selectedOption = userAnswers[questionId]?.[0] || '';
                const isCorrect = submitted && selectedOption && mcq.correct.includes(selectedOption);
                const isIncorrect = submitted && selectedOption && !mcq.correct.includes(selectedOption);
                
                return (
                  <Fade 
                    in={transitionIn} 
                    timeout={500} 
                    style={{ transitionDelay: `${index * 100}ms` }}
                    key={questionId || `current-${index}`}
                  >
                    <MCQCard>
                      <CardContent>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                          <Typography variant="subtitle1" fontWeight="bold">
                            Question {index + 1}
                          </Typography>
                          <Chip 
                            label={mcq.subject || 'General'} 
                            color="primary" 
                            size="small" 
                          />
                        </Box>
                        
                        <Typography variant="body1" gutterBottom>
                          {mcq.text}
                        </Typography>
                        
                        <FormControl fullWidth component="fieldset" sx={{ my: 2 }}>
                          <RadioGroup 
                            value={selectedOption}
                            onChange={(e) => {
                              if (!submitted) {
                                handleAnswerChange(questionId, e.target.value);
                              }
                            }}
                          >
                            {mcq.options.map((option, optIndex) => (
                              <FormControlLabel 
                                key={optIndex}
                                value={option}
                                control={<OptionRadio />}
                                label={option}
                                disabled={submitted}
                                sx={{ 
                                  mb: 1,
                                  p: 1,
                                  borderRadius: 1,
                                  backgroundColor: submitted 
                                    ? mcq.correct.includes(option)
                                      ? 'rgba(76, 175, 80, 0.1)'
                                      : selectedOption === option && !mcq.correct.includes(option)
                                        ? 'rgba(244, 67, 54, 0.1)'
                                        : 'transparent'
                                    : 'transparent',
                                }}
                              />
                            ))}
                          </RadioGroup>
                        </FormControl>
                        
                        {submitted && (
                          <Box sx={{ 
                            mt: 2, 
                            p: 2, 
                            borderRadius: 1, 
                            backgroundColor: isCorrect 
                              ? 'rgba(76, 175, 80, 0.05)' 
                              : isIncorrect 
                                ? 'rgba(244, 67, 54, 0.05)' 
                                : 'rgba(0, 0, 0, 0.02)'
                          }}>
                            {isCorrect && (
                              <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                                <CheckCircle color="success" fontSize="small" sx={{ mr: 1 }} />
                                <Typography variant="subtitle2" color="success.main">
                                  Correct!
                                </Typography>
                              </Box>
                            )}
                            
                            {isIncorrect && (
                              <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                                <Cancel color="error" fontSize="small" sx={{ mr: 1 }} />
                                <Typography variant="subtitle2" color="error.main">
                                  Incorrect
                                </Typography>
                              </Box>
                            )}
                            
                            {(isIncorrect || !selectedOption) && (
                              <Typography variant="body2" gutterBottom>
                                <strong>Correct Answer:</strong> {mcq.correct.join(', ')}
                              </Typography>
                            )}
                            
                            <Typography variant="body2">
                              <strong>Explanation:</strong> {mcq.explanation}
                            </Typography>
                          </Box>
                        )}
                      </CardContent>
                    </MCQCard>
                  </Fade>
                );
              })}
            </Box>
          </Fade>
        )}
        
        {/* Buttons for current MCQ set */}
        {currentMCQs.length > 0 && (
          <Fade in={true} timeout={500}>
            <Box>
              {!submitted && (
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2, mb: 4 }}>
                  <Button 
                    variant="contained" 
                    color="primary" 
                    size="large"
                    onClick={submitAnswers}
                    disabled={loading || Object.keys(userAnswers).length !== currentMCQs.length}
                    startIcon={loading ? <CircularProgress size={20} /> : null}
                  >
                    Submit Answers
                  </Button>
                </Box>
              )}
              
              {submitted && currentMcqId && (
                <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, mt: 2, mb: 4 }}>
                  <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<CloudDownload />}
                    onClick={exportToPdf}
                  >
                    Download PDF
                  </Button>
                </Box>
              )}
            </Box>
          </Fade>
        )}
        
        {/* Show loader when generating new MCQs */}
        {loading && (
          <Fade in={true} timeout={500}>
            <Box sx={{ 
              display: 'flex', 
              flexDirection: 'column',
              justifyContent: 'center', 
              alignItems: 'center', 
              py: 4,
              mt: 3,
              minHeight: '200px'
            }}>
              <CircularProgress size={50} thickness={4} color="primary" />
              <Typography variant="subtitle1" color="text.secondary" sx={{ mt: 3 }}>
                Generating new MCQs...
              </Typography>
            </Box>
          </Fade>
        )}
      </Box>
    );
  };

  return (
    <MCQContainer>
      <MCQHeader>
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center',
          mb: 2
        }}>
          {/* Title removed as requested */}
        </Box>
      </MCQHeader>
      
      <MCQContentContainer ref={contentRef}>
        {renderMCQCards()}
      </MCQContentContainer>
      
      <MCQInputContainer>
        <Box 
          component="form" 
          sx={{ 
            display: 'flex',
            alignItems: 'center',
            gap: 1
          }}
          onSubmit={(e) => {
            e.preventDefault();
            generateMCQs();
          }}
        >
          <MCQInputField
            fullWidth
            placeholder="Enter a medical topic to generate MCQs..."
            value={topic}
            onChange={(e) => setTopic(e.target.value)}
            disabled={loading}
            variant="outlined"
            size="medium"
            inputRef={inputRef}
            sx={{ flexGrow: 1 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <UploadIconButton
                    onClick={(e) => {
                      e.preventDefault();
                      setOpenFileModal(true);
                    }}
                  >
                    <AttachFile />
                  </UploadIconButton>
                </InputAdornment>
              )
            }}
          />
          
          <SendButton
            type="submit"
            disabled={!topic.trim() || loading}
          >
            <Send />
          </SendButton>
        </Box>
      </MCQInputContainer>
      
      {/* File Upload Dialog */}
      <Dialog
        open={openFileModal}
        onClose={() => !uploadLoading && setOpenFileModal(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          Upload PDF for MCQ Generation
          {!uploadLoading && (
            <IconButton
              aria-label="close"
              onClick={() => setOpenFileModal(false)}
              sx={{
                position: 'absolute',
                right: 12,
                top: 12,
              }}
            >
              <CloseIcon />
            </IconButton>
          )}
        </DialogTitle>
        
        <DialogContent dividers>
          <Box sx={{ mb: 3 }}>
            <input
              accept=".pdf"
              type="file"
              id="file-upload"
              hidden
              ref={fileInputRef}
              onChange={(e) => setFileToUpload(e.target.files[0])}
            />
            <label htmlFor="file-upload">
              <Button
                component="span"
                variant="outlined"
                fullWidth
                startIcon={<CloudUpload />}
                sx={{ py: 1.5 }}
              >
                {fileToUpload ? fileToUpload.name : 'Select PDF File'}
              </Button>
            </label>
          </Box>
          
          <Box sx={{ mb: 3 }}>
            <Typography variant="subtitle2" gutterBottom>
              Number of Questions
            </Typography>
            <Select
              fullWidth
              value={numQuestions}
              onChange={(e) => setNumQuestions(e.target.value)}
            >
              <MenuItem value={5}>5 Questions</MenuItem>
              <MenuItem value={10}>10 Questions</MenuItem>
              <MenuItem value={15}>15 Questions</MenuItem>
              <MenuItem value={20}>20 Questions</MenuItem>
            </Select>
          </Box>
          
          <Box>
            <Typography variant="subtitle2" gutterBottom>
              Difficulty Level
            </Typography>
            <Select
              fullWidth
              value={difficulty}
              onChange={(e) => setDifficulty(e.target.value)}
            >
              <MenuItem value="easy">Easy</MenuItem>
              <MenuItem value="medium">Medium</MenuItem>
              <MenuItem value="hard">Hard</MenuItem>
            </Select>
          </Box>
        </DialogContent>
        
        <DialogActions>
          <Button 
            disabled={uploadLoading}
            onClick={() => setOpenFileModal(false)}
          >
            Cancel
          </Button>
          <Button 
            variant="contained" 
            color="primary"
            disabled={!fileToUpload || uploadLoading}
            onClick={handleFileUpload}
            startIcon={uploadLoading ? <CircularProgress size={20} /> : null}
          >
            {uploadLoading ? 'Uploading...' : 'Generate MCQs'}
          </Button>
        </DialogActions>
      </Dialog>
      
      {/* Error Snackbar */}
      <Snackbar
        open={showError}
        autoHideDuration={6000}
        onClose={() => setShowError(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert 
          onClose={() => setShowError(false)} 
          severity="error" 
          sx={{ width: '100%' }}
        >
          {error}
        </Alert>
      </Snackbar>
    </MCQContainer>
  );
};

export default MCQPractice;
