import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Button,
  Grid,
  Collapse,
  IconButton,
  AppBar,
  Toolbar,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import MenuIcon from "@mui/icons-material/Menu";
import HospitalIcon from "@mui/icons-material/LocalHospital";
import QuestionAttempt from "./QuestionAttempt";
import { useAuth } from "../../services/authContext";
import { useNavigate } from "react-router-dom";
import config from "../../config";

const API_BASE_URL = `${config.API_URL}/api`;
//const API_BASE_URL = "https://neetlybackend.vercel.app/api";

const TopBar = ({ isMobile }) => (
  <AppBar position="fixed" sx={{ backgroundColor: "#3498db" }}>
    <Toolbar>
      {isMobile && (
        <IconButton color="inherit" aria-label="open drawer" edge="start" sx={{ mr: 2 }}>
          <MenuIcon />
        </IconButton>
      )}
      <Box sx={{ display: "flex", alignItems: "center", textDecoration: "none", color: "inherit" }}>
        <HospitalIcon sx={{ mr: 1 }} />
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          Neetly AI
        </Typography>
      </Box>
    </Toolbar>
  </AppBar>
);

const SubjectWiseList = () => {
  const [subjects, setSubjects] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [topics, setTopics] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [expandedTopic, setExpandedTopic] = useState(null);
  const [selectedQuestionType, setSelectedQuestionType] = useState(null);

  const [currentTopicPage, setCurrentTopicPage] = useState(0);
  const [totalTopicPages, setTotalTopicPages] = useState(0);

  const [currentQuestionPage, setCurrentQuestionPage] = useState(0);
  const [totalQuestionPages, setTotalQuestionPages] = useState(0);

  const topicsPerPage = 6;
  const questionsPerPage = 6;

  const { getToken } = useAuth();
  const navigate = useNavigate();



  useEffect(() => {
    fetchSubjects();
  }, []);

  const fetchSubjects = async () => {
    try {
        const token = await getToken();
      const response = await axios.get(`${API_BASE_URL}/subjects`,{
        headers: {
          Authorization: `Bearer ${token}`,
        },
    });
      setSubjects(response.data);
    } catch (error) {
      console.error("Error fetching subjects:", error.message);
    }
  };

  const fetchTopics = async (subjectId, page = 1) => {
    try {
        const token = await getToken();
      const response = await axios.get(
        `${API_BASE_URL}/subjects/${subjectId}/topics?page=${page}&limit=${topicsPerPage}`,{
            headers: {
              Authorization: `Bearer ${token}`,
            },
        }
      );
      setTopics(response.data.data.topics);
      setCurrentTopicPage(response.data.data.pagination.currentPage - 1);
      setTotalTopicPages(response.data.data.pagination.totalPages);
    } catch (error) {
      console.error("Error fetching topics:", error.message);
    }
  };

  const fetchQuestions = async (subjectId, topicId, page = 1, limit = questionsPerPage) => {
    try {
        const token = await getToken();
      const response = await axios.get(
        `${API_BASE_URL}/mcq/get?subjectId=${subjectId}&topicId=${topicId}&page=${page}&limit=${limit}`,{
            headers: {
              Authorization: `Bearer ${token}`,
            },
        }
      );
      setQuestions(response.data.mcqs); // Set fetched questions
      setCurrentQuestionPage(page - 1); // Update current page
      setTotalQuestionPages(response.data.totalPages); // Update total pages
    } catch (error) {
      console.error("Error fetching questions:", error.message);
    }
  };
  

  const handleNextQuestionPage = () => {
    if (currentQuestionPage < totalQuestionPages - 1) {
      fetchQuestions(
        selectedSubject._id,
        expandedTopic._id,
        currentQuestionPage + 2,
        questionsPerPage
      );
    }
  };

  // const handleStartTest = (level, levelId) => {
  //   fetchQuestions(level === "subject" ? levelId : selectedSubject._id, levelId);
  //   setSelectedQuestionType("Test"); // Redirect to test mode
  // };
  const handleStartTest = (subjectId) => {
    navigate(`/test/subject?subjectId=${subjectId}`); // Navigate to the test page with subjectId
  };
  
  
  const handlePreviousQuestionPage = () => {
    if (currentQuestionPage > 0) {
      fetchQuestions(
        selectedSubject._id,
        expandedTopic._id,
        currentQuestionPage,
        questionsPerPage
      );
    }
  };
  
  const handleSubjectClick = (subject) => {
    setSelectedSubject(subject);
    fetchTopics(subject._id);
  };

  const handleTopicClick = (topic) => {
    setExpandedTopic((prev) => (prev === topic ? null : topic));
  };

  const handleQuestionTypeSelection = (questionType) => {
    setSelectedQuestionType(questionType);
    if (expandedTopic) {
      fetchQuestions(selectedSubject._id, expandedTopic._id, currentQuestionPage + 1);
    }
  };

  const handleBackToSubjects = () => {
    setSelectedSubject(null);
    setExpandedTopic(null);
    setSelectedQuestionType(null);
  };

  const handleBackToTopics = () => {
    setSelectedQuestionType(null); // Clear the selected question type
    setQuestions([]); // Reset questions to an empty array
    setExpandedTopic(null); // Reset the expanded topic
  };

  const handleNextTopicPage = () => {
    if (currentTopicPage < totalTopicPages - 1) {
      fetchTopics(selectedSubject._id, currentTopicPage + 2);
    }
  };

  const handlePreviousTopicPage = () => {
    if (currentTopicPage > 0) {
      fetchTopics(selectedSubject._id, currentTopicPage);
    }
  };

//   const handleNextQuestionPage = () => {
//     if (currentQuestionPage < totalQuestionPages - 1) {
//       fetchQuestions(selectedSubject._id, expandedTopic._id, currentQuestionPage + 2);
//     }
//   };

//   const handlePreviousQuestionPage = () => {
//     if (currentQuestionPage > 0) {
//       fetchQuestions(selectedSubject._id, expandedTopic._id, currentQuestionPage);
//     }
//   };

  return (
    <Box>
      <TopBar isMobile={window.innerWidth <= 768} />
      <Box sx={{ height: "64px" }} />
      <Box sx={{ padding: "20px" }}>
        <Typography variant="h4" gutterBottom>
          Subject-Wise Topics
        </Typography>
        {!selectedSubject && (
          <Grid container spacing={2}>
            {subjects.map((subject, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Card
                  sx={{
                    cursor: "pointer",
                    "&:hover": { boxShadow: 4 },
                  }}
                  onClick={() => handleSubjectClick(subject)}
                >
                  <CardContent>
                    <Typography variant="h6">{subject.name}</Typography>
                    <Button
            variant="contained"
            onClick={() => handleStartTest(subject._id)}
            sx={{ mt: 1 }}
          >
            Start Test
          </Button>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        )}
        {selectedSubject && !selectedQuestionType && (
          <Box>
            <Typography variant="h5" gutterBottom>
              {selectedSubject.name} Topics
            </Typography>
            {topics.map((topic, index) => (
              <Card key={index} sx={{ marginBottom: "10px" }}>
                <CardContent
                  sx={{ cursor: "pointer" }}
                  onClick={() => handleTopicClick(topic)}
                >
                  <Typography variant="h6">{topic.name}</Typography>
                </CardContent>
                <Collapse in={expandedTopic === topic}>
                  <Box sx={{ padding: "10px" }}>
                    {["Previous Year", "Case Study-Based", "Diagnosis-Based"].map((type, idx) => (
                      <Card
                        key={idx}
                        sx={{
                          marginBottom: "10px",
                          cursor: "pointer",
                          "&:hover": { boxShadow: 4 },
                        }}
                        onClick={() => handleQuestionTypeSelection(type)}
                      >
                        <CardContent>
                          <Typography variant="body1">{type}</Typography>
                        </CardContent>
                      </Card>
                    ))}
                  </Box>
                </Collapse>
              </Card>
            ))}
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <IconButton
                onClick={handlePreviousTopicPage}
                disabled={currentTopicPage === 0}
              >
                <ArrowBackIosIcon />
              </IconButton>
              <Typography>
                Page {currentTopicPage + 1} of {totalTopicPages}
              </Typography>
              <IconButton
                onClick={handleNextTopicPage}
                disabled={currentTopicPage >= totalTopicPages - 1}
              >
                <ArrowForwardIosIcon />
              </IconButton>
            </Box>
            <Button variant="contained" onClick={handleBackToSubjects}>
              Back to Subjects
            </Button>
          </Box>
        )}
        {selectedQuestionType && (
          <QuestionAttempt
            questionType={selectedQuestionType}
            questions={questions}
            onNext={handleNextQuestionPage}
            onPrevious={handlePreviousQuestionPage}
            currentPage={currentQuestionPage}
            totalPages={totalQuestionPages}
            onBack={handleBackToTopics}
          />
        )}
      </Box>
    </Box>
  );
};

export default SubjectWiseList;
