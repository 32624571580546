import React from "react";
import "./style.css";
import RootLayout from "../../components/rootLayout";
import DashboardLayout from "../../components/dashboardLayout/DashBoardLayout";
import ChatWindow from "../../components/chatWindow";
import { useAuth } from '../../services/authContext';
import { useLocation } from 'react-router-dom';
import { useParams,useNavigate } from 'react-router-dom'; // Import useParams



const Explore = () => {
    const location = useLocation();
    const { initialTopic } = location.state || {};
    const { mcqId } = useParams(); 

    const navigate = useNavigate();
    const clearMcqId = () => {
        navigate('/explore', { replace: true }); // Remove mcqId from the URL
    };

    const searchParams = new URLSearchParams(location.search);
    const textId =searchParams.get("id") || "";

  console.log(mcqId);
    const { getToken, signOut } = useAuth();

    return (
        <main className="exploreheader">
            <ChatWindow getToken={getToken} signOut={signOut} mcqId={mcqId} clearMcqId={clearMcqId} textId={textId} />
        </main>
    );
};

export default Explore;
