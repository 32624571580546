import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Paper,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  CircularProgress,
  Alert,
  Snackbar
} from '@mui/material';
import { Add, Edit, Delete, Visibility } from '@mui/icons-material';
import classService from '../../services/classService';

const ClassManagement = ({ institutionId, token, showNotification, onClassSelect }) => {
  const [classes, setClasses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [currentClass, setCurrentClass] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    displayName: ''
  });
  const [error, setError] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  // Sample mock data for demonstration
  const mockClasses = [
    {
      _id: 'class-1',
      name: 'Class 11 Medical',
      displayName: 'Class 11 Medical Stream',
      studentsCount: 45,
      testsCount: 12,
      teacher: {
        name: 'Dr. Rajesh Kumar',
        id: 't1'
      }
    },
    {
      _id: 'class-2',
      name: 'Class 12 Medical',
      displayName: 'Class 12 Medical Stream',
      studentsCount: 38,
      testsCount: 18,
      teacher: {
        name: 'Dr. Priya Sharma',
        id: 't2'
      }
    },
    {
      _id: 'class-3',
      name: 'Class 11 Non-Medical',
      displayName: 'Class 11 Non-Medical Stream',
      studentsCount: 42,
      testsCount: 10,
      teacher: {
        name: 'Dr. Anand Verma',
        id: 't3'
      }
    },
    {
      _id: 'class-4',
      name: 'NEET Crash Course',
      displayName: 'NEET Crash Course (6 months)',
      studentsCount: 65,
      testsCount: 24,
      teacher: {
        name: 'Prof. Meera Patel',
        id: 't4'
      }
    },
    {
      _id: 'class-5',
      name: 'Foundation Course',
      displayName: 'Medical Foundation Course',
      studentsCount: 32,
      testsCount: 8,
      teacher: {
        name: 'Dr. Sanjay Gupta',
        id: 't5'
      }
    }
  ];

  // Load classes on component mount
  useEffect(() => {
    if (institutionId) {
      fetchClasses();
    } else {
      // If no institution ID, use mock data and set loading to false
      setTimeout(() => {
        setClasses(mockClasses);
        setLoading(false);
      }, 1000); // Simulate loading for 1 second
    }
  }, [institutionId, token]);

  const fetchClasses = async () => {
    try {
      setLoading(true);
      const result = await classService.getClasses(institutionId, token);
      
      if (result && result.classes && result.classes.length > 0) {
        setClasses(result.classes);
      } else {
        // If no classes returned from API, use mock data
        setClasses(mockClasses);
        console.log('Using mock class data for demonstration');
      }
      
      setLoading(false);
    } catch (error) {
      console.error('Error fetching classes:', error);
      showNotification('warning', 'Using demo data for classes');
      // Use mock data on error
      setClasses(mockClasses);
      setLoading(false);
    }
  };

  const handleCreateClick = () => {
    setFormData({ name: '', displayName: '' });
    setIsEditing(false);
    setError('');
    setOpenDialog(true);
  };

  const handleEditClick = (classItem) => {
    setFormData({
      name: classItem.name,
      displayName: classItem.displayName || classItem.name
    });
    setCurrentClass(classItem);
    setIsEditing(true);
    setError('');
    setOpenDialog(true);
  };

  const handleDeleteClick = async (classItem) => {
    if (window.confirm(`Are you sure you want to delete ${classItem.displayName || classItem.name}?`)) {
      try {
        if (institutionId) {
          await classService.deleteClass(institutionId, classItem._id, token);
          showNotification('success', 'Class deleted successfully');
        } else {
          // Mock delete for demo
          setClasses(classes.filter(c => c._id !== classItem._id));
          showNotification('success', 'Class deleted successfully (Demo)');
        }
        fetchClasses();
      } catch (error) {
        console.error('Error deleting class:', error);
        showNotification('error', error.response?.data?.message || 'Failed to delete class');
      }
    }
  };

  const handleViewClick = (classItem) => {
    if (onClassSelect) {
      console.log('Selecting class:', classItem);
      onClassSelect(classItem);
    } else {
      console.error('onClassSelect function not provided as prop');
    }
  };

  const handleSubmit = async () => {
    try {
      if (!formData.name.trim()) {
        setError('Class name is required');
        return;
      }

      if (isEditing) {
        if (institutionId) {
          await classService.updateClass(
            institutionId,
            currentClass._id,
            {
              name: formData.name,
              displayName: formData.displayName
            },
            token
          );
          showNotification('success', 'Class updated successfully');
        } else {
          // Mock update for demo
          const updatedClasses = classes.map(c => 
            c._id === currentClass._id 
            ? { ...c, name: formData.name, displayName: formData.displayName }
            : c
          );
          setClasses(updatedClasses);
          showNotification('success', 'Class updated successfully (Demo)');
        }
      } else {
        if (institutionId) {
          await classService.createClass(
            institutionId,
            {
              name: formData.name,
              displayName: formData.displayName
            },
            token
          );
          showNotification('success', 'Class created successfully');
        } else {
          // Mock create for demo
          const newClass = {
            _id: `class-${Date.now()}`,
            name: formData.name,
            displayName: formData.displayName,
            studentsCount: Math.floor(Math.random() * 50) + 10,
            testsCount: Math.floor(Math.random() * 15)
          };
          setClasses([...classes, newClass]);
          showNotification('success', 'Class created successfully (Demo)');
        }
      }
      setOpenDialog(false);
      fetchClasses();
    } catch (error) {
      console.error('Error saving class:', error);
      showNotification('error', error.response?.data?.message || 'Failed to save class');
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Typography variant="h5">Classes</Typography>
        <Button
          variant="contained"
          color="primary"
          startIcon={<Add />}
          onClick={handleCreateClick}
        >
          Add Class
        </Button>
      </Box>

      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <TableContainer sx={{ maxHeight: 440 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Display Name</TableCell>
                    <TableCell>Students</TableCell>
                    <TableCell>Teacher</TableCell>
                    <TableCell align="right">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {classes.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={5} align="center">
                        No classes found. Click "Add Class" to create one.
                      </TableCell>
                    </TableRow>
                  ) : (
                    classes
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((classItem) => (
                        <TableRow hover role="checkbox" tabIndex={-1} key={classItem._id}>
                          <TableCell>{classItem.name}</TableCell>
                          <TableCell>{classItem.displayName || classItem.name}</TableCell>
                          <TableCell>{classItem.studentsCount || 0}</TableCell>
                          <TableCell>{classItem.teacher?.name || '-'}</TableCell>
                          <TableCell align="right">
                            <IconButton 
                              color="primary" 
                              onClick={() => handleViewClick(classItem)}
                              title="View details"
                            >
                              <Visibility />
                            </IconButton>
                            <IconButton
                              color="primary"
                              onClick={() => handleEditClick(classItem)}
                              title="Edit class"
                            >
                              <Edit />
                            </IconButton>
                            <IconButton
                              color="error"
                              onClick={() => handleDeleteClick(classItem)}
                              title="Delete class"
                            >
                              <Delete />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={classes.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </>
        )}
      </Paper>

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>{isEditing ? 'Edit Class' : 'Add Class'}</DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 2 }}>
            <TextField
              fullWidth
              label="Class Name"
              value={formData.name}
              onChange={(e) => setFormData({ ...formData, name: e.target.value })}
              variant="outlined"
              margin="normal"
              error={!!error}
              helperText={error}
            />
            <TextField
              fullWidth
              label="Display Name (Optional)"
              value={formData.displayName}
              onChange={(e) => setFormData({ ...formData, displayName: e.target.value })}
              variant="outlined"
              margin="normal"
              placeholder="Will use Class Name if left empty"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          <Button onClick={handleSubmit} color="primary" variant="contained">
            {isEditing ? 'Update' : 'Create'}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ClassManagement;
