import axios from 'axios';
import config from '../config';

/**
 * Service for interacting with the diagram generation API
 */
const diagramService = {
  /**
   * Get all diagram templates with optional filtering
   * @param {Object} filters - Optional filters (subject, category)
   * @returns {Promise} - Promise with diagram templates
   */
  getDiagramTemplates: async (filters = {}) => {
    try {
      const queryParams = new URLSearchParams();
      if (filters.subject) queryParams.append('subject', filters.subject);
      if (filters.category) queryParams.append('category', filters.category);
      
      const response = await axios.get(`${config.API_URL}/diagram/templates?${queryParams}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching diagram templates:', error);
      throw error;
    }
  },

  /**
   * Get a specific diagram template by ID
   * @param {String} templateId - The template ID
   * @returns {Promise} - Promise with diagram template
   */
  getDiagramTemplate: async (templateId) => {
    try {
      const response = await axios.get(`${config.API_URL}/diagram/templates/${templateId}`);
      return response.data;
    } catch (error) {
      console.error(`Error fetching diagram template ${templateId}:`, error);
      throw error;
    }
  },

  /**
   * Render a diagram with provided parameters
   * @param {String} templateId - The template ID
   * @param {Object} parameters - The parameters for the diagram
   * @returns {Promise} - Promise with rendered SVG
   */
  renderDiagram: async (templateId, parameters) => {
    try {
      const response = await axios.post(`${config.API_URL}/diagram/render`, {
        templateId,
        parameters
      });
      return response.data;
    } catch (error) {
      console.error('Error rendering diagram:', error);
      throw error;
    }
  },

  /**
   * Generate a static PNG image from SVG
   * @param {String} svg - The SVG content
   * @param {Number} width - The width of the image
   * @param {Number} height - The height of the image
   * @returns {Promise} - Promise with base64 encoded PNG
   */
  generateStaticImage: async (svg, width = 800, height = 600) => {
    try {
      const response = await axios.post(`${config.API_URL}/diagram/generate-image`, {
        svg,
        width,
        height
      });
      return response.data;
    } catch (error) {
      console.error('Error generating static image:', error);
      throw error;
    }
  },

  /**
   * Get diagram for a specific MCQ
   * @param {String} mcqId - The MCQ ID
   * @returns {Promise} - Promise with MCQ diagram data
   */
  getMCQDiagram: async (mcqId) => {
    try {
      const response = await axios.get(`${config.API_URL}/api/diagram/mcq/${mcqId}`);
      return response.data;
    } catch (error) {
      console.error(`Error fetching diagram for MCQ ${mcqId}:`, error);
      throw error;
    }
  }
};

export default diagramService;
