import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../../services/authContext';
import config from '../../config';
import './style.css';

const InstitutionUsersManagement = () => {
  const { institutionId } = useParams();
  const navigate = useNavigate();
  const { user, getToken, hasRole } = useAuth();
  const [institution, setInstitution] = useState(null);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showAddUserForm, setShowAddUserForm] = useState(false);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    role: 'student'
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const authToken = await getToken();
        
        // Fetch institution details
        const institutionResponse = await axios.get(
          `${config.API_URL}/institutions/${institutionId}`,
          {
            headers: {
              Authorization: `Bearer ${authToken}`
            }
          }
        );
        
        setInstitution(institutionResponse.data.institution);
        
        // Fetch institution users
        const usersResponse = await axios.get(
          `${config.API_URL}/institutions/${institutionId}/users`,
          {
            headers: {
              Authorization: `Bearer ${authToken}`
            }
          }
        );
        
        setUsers(usersResponse.data.users);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching data:', err);
        setError('Failed to load data. Please try again later.');
        setLoading(false);
      }
    };

    // Check permissions
    if (user) {
      if (hasRole('super_admin') || (hasRole('institution_admin') && user.institution === institutionId)) {
        fetchData();
      } else {
        setError('You do not have permission to access this page.');
        setLoading(false);
      }
    }
  }, [institutionId, user, getToken, hasRole]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const authToken = await getToken();
      
      // Add institution ID to the form data
      const userData = {
        ...formData,
        institution: institutionId
      };
      
      await axios.post(
        `${config.API_URL}/institutions/${institutionId}/users`,
        userData,
        {
          headers: {
            Authorization: `Bearer ${authToken}`
          }
        }
      );
      
      // Refresh users list
      const response = await axios.get(
        `${config.API_URL}/institutions/${institutionId}/users`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`
          }
        }
      );
      
      setUsers(response.data.users);
      
      // Reset form
      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        role: 'student'
      });
      
      setShowAddUserForm(false);
      setLoading(false);
    } catch (err) {
      console.error('Error creating user:', err);
      setError(err.response?.data?.message || 'Failed to create user');
      setLoading(false);
    }
  };

  const handleDeactivateUser = async (userId, isActive) => {
    try {
      const authToken = await getToken();
      
      await axios.patch(
        `${config.API_URL}/institutions/${institutionId}/users/${userId}`,
        { isActive: !isActive },
        {
          headers: {
            Authorization: `Bearer ${authToken}`
          }
        }
      );
      
      // Update user in the list
      setUsers(users.map(u => 
        u._id === userId ? { ...u, isActive: !isActive } : u
      ));
    } catch (err) {
      console.error('Error updating user status:', err);
      setError('Failed to update user status. Please try again.');
    }
  };

  const handleBack = () => {
    if (hasRole('super_admin')) {
      navigate('/admin/super');
    } else {
      navigate('/admin/institution');
    }
  };

  if (loading) {
    return <div className="loading">Loading...</div>;
  }

  if (error) {
    return <div className="error">{error}</div>;
  }

  return (
    <div className="institution-users-management">
      <div className="page-header">
        <button className="back-button" onClick={handleBack}>
          &larr; Back
        </button>
        <h1>Manage Users - {institution?.name}</h1>
      </div>
      
      <div className="dashboard-actions">
        <button 
          className="add-user-btn"
          onClick={() => setShowAddUserForm(!showAddUserForm)}
        >
          {showAddUserForm ? 'Cancel' : 'Add New User'}
        </button>
      </div>

      {showAddUserForm && (
        <div className="add-user-form">
          <h2>Add New User</h2>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label>First Name</label>
              <input 
                type="text" 
                name="firstName" 
                value={formData.firstName} 
                onChange={handleInputChange} 
                required 
              />
            </div>
            <div className="form-group">
              <label>Last Name</label>
              <input 
                type="text" 
                name="lastName" 
                value={formData.lastName} 
                onChange={handleInputChange} 
                required 
              />
            </div>
            <div className="form-group">
              <label>Email</label>
              <input 
                type="email" 
                name="email" 
                value={formData.email} 
                onChange={handleInputChange} 
                required 
              />
            </div>
            <div className="form-group">
              <label>Password</label>
              <input 
                type="password" 
                name="password" 
                value={formData.password} 
                onChange={handleInputChange} 
                required 
                minLength="6"
              />
            </div>
            <div className="form-group">
              <label>Role</label>
              <select 
                name="role" 
                value={formData.role} 
                onChange={handleInputChange}
              >
                <option value="student">Student</option>
                <option value="teacher">Teacher</option>
                <option value="institution_admin">Institution Admin</option>
                {hasRole('super_admin') && (
                  <option value="super_admin">Super Admin</option>
                )}
              </select>
            </div>
            <button type="submit" className="submit-btn">Create User</button>
          </form>
        </div>
      )}

      <div className="users-list">
        <h2>Users</h2>
        {users.length === 0 ? (
          <p>No users found.</p>
        ) : (
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Role</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user) => (
                <tr key={user._id}>
                  <td>{`${user.firstName} ${user.lastName}`}</td>
                  <td>{user.email}</td>
                  <td>{user.role}</td>
                  <td>{user.isActive ? 'Active' : 'Inactive'}</td>
                  <td>
                    <button 
                      className={user.isActive ? 'deactivate-btn' : 'activate-btn'}
                      onClick={() => handleDeactivateUser(user._id, user.isActive)}
                    >
                      {user.isActive ? 'Deactivate' : 'Activate'}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default InstitutionUsersManagement;