import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../services/authContext';
import './signInPage.css';

const CustomSignInPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const { signIn } = useAuth();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setIsLoading(true);

        try {
            const result = await signIn(email, password);
            if (result.success) {
                // Redirect based on user role
                const userRole = result.user.role;
                if (userRole === 'super_admin') {
                    navigate('/admin/super'); // Keep super admin redirect
                } else if (userRole === 'institution_admin' || userRole === 'admin' || userRole === 'teacher') {
                    // Redirect institution admins, regular admins, and teachers to teacher dashboard
                    navigate('/admin/teacher-dashboard'); 
                } else {
                    // Default redirect for students (and any other unspecified roles) to the UI revamp page
                    navigate('/ui-revamp'); 
                }
            } else {
                setError(result.error || 'Failed to sign in');
            }
        } catch (err) {
            setError('An unexpected error occurred. Please try again.');
            console.error('Sign in error:', err);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="signInPage">
            <div className="auth-container">
                <h2>Sign In to Neetly</h2>
                {error && <div className="auth-error">{error}</div>}
                <form onSubmit={handleSubmit} className="auth-form">
                    <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input
                            type="email"
                            id="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            placeholder="Enter your email"
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="password">Password</label>
                        <input
                            type="password"
                            id="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                            placeholder="Enter your password"
                        />
                    </div>
                    <button 
                        type="submit" 
                        className="auth-button" 
                        disabled={isLoading}
                    >
                        {isLoading ? 'Signing In...' : 'Sign In'}
                    </button>
                </form>
                {/* <div className="auth-links">
                    <p>Don't have an account? <a href="/sign-up">Sign Up</a></p>
                </div> */}
            </div>
        </div>
    );
};

export default CustomSignInPage;