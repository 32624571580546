// Configuration file for environment-specific settings

// Determine if we're in production based on the hostname
const isProduction = 
  typeof window !== 'undefined' && 
  window.location.hostname !== 'localhost' && 
  window.location.hostname !== '127.0.0.1';

// Set the base API URL based on the environment
const API_BASE_URL = isProduction 
  ? "https://neetlybackend.vercel.app" 
  : "http://localhost:2004";

const config = {
  API_URL: `${API_BASE_URL}`,
  MCQ_API_URL: `${API_BASE_URL}/api/mcq/generate`,
  DASHBOARD_API_URL: `${API_BASE_URL}/api/dashboard`,
  USERS_API_URL: `${API_BASE_URL}/api/users`,
  // Add other configuration variables as needed
};

export default config;