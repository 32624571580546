import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import MainLayout from '../../components/UIRevamp/layout/MainLayout';
import NewMCQBank from '../../components/UIRevamp/mcq/NewMCQBank';

/**
 * MCQ Bank Page Component
 * Provides a hierarchical selection of subject, topic, and difficulty
 * for attempting multiple-choice questions
 */
const MCQBankPage = () => {
  const location = useLocation();
  const [selectedClassId, setSelectedClassId] = useState(1); // Default to Class 9 (ID: 1)
  
  // Check if there's a class ID in the location state (from sidebar navigation)
  useEffect(() => {
    if (location.state && location.state.classId) {
      setSelectedClassId(location.state.classId);
    }
  }, [location.state]);

  return (
    <MainLayout activeItem="mcq-bank">
      <NewMCQBank classId={selectedClassId} onClassChange={setSelectedClassId} />
    </MainLayout>
  );
};

export default MCQBankPage;
