import axios from "axios";
import config from '../config';

const API_URL = config.MCQ_API_URL;

const authService = {
    signup: async (firstName, lastName, email, password) => {
        // Simulating API call
        return new Promise((resolve) => {
            setTimeout(() => {
                const dummyToken = "dummy-jwt-token";
                localStorage.setItem("token", dummyToken);
                resolve({
                    token: dummyToken,
                    user: { firstName, lastName, email },
                });
            }, 500); // Simulate network delay
        });
    },

    login: async (email, password) => {
        // Implement login logic
        return true;
    },
    logout: async () => {
        // Implement logout logic
    },
    getQuestions: async (topic, textId=null, token, mcqType) => {
        const headers = {
            accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        };
        const data = {
            topic: topic,
            textId: textId,
            type: mcqType,
            numberOfQuestions: 0,
        };
       
        try {
            const response = await axios.post(`${API_URL}`, data, { headers });
            console.log("data is received", response.data);
            const arrayList = [...response.data];
            return arrayList;
        } catch (error) {
            // Check for 401 Unauthorized error
            if (error.response && error.response.status === 401) {
                console.log("Authentication expired. Redirecting to login...");
                // We'll handle the logout at the component level
                throw new Error(JSON.stringify({ status: 401, message: "Authentication expired" }));
            }
            throw new Error(error.response?.data?.message || "An error occurred while fetching questions.");
        }
    },
};

export default authService;