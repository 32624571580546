/**
 * Neetly AI UI Revamp - Theme Configuration
 * Contains color schemes, typography, and shared styling variables
 */

export const themeColors = {
  // Primary brand colors
  primary: {
    main: '#2196F3', // Blue primary color from the original theme
    light: '#64B5F6',
    dark: '#1976D2',
    contrastText: '#FFFFFF',
  },
  // Secondary accent colors
  secondary: {
    main: '#4CAF50', // Green for success/positive actions
    light: '#81C784',
    dark: '#388E3C',
    contrastText: '#FFFFFF',
  },
  // Status colors
  status: {
    success: '#4CAF50',
    warning: '#FF9800',
    error: '#F44336',
    info: '#2196F3',
  },
  // Neutral colors for text, backgrounds, etc.
  neutral: {
    black: '#000000',
    white: '#FFFFFF',
    grey: {
      50: '#FAFAFA',
      100: '#F5F5F5',
      200: '#EEEEEE',
      300: '#E0E0E0',
      400: '#BDBDBD',
      500: '#9E9E9E',
      600: '#757575',
      700: '#616161',
      800: '#424242',
      900: '#212121',
    },
  },
  // Background colors
  background: {
    default: '#F5F7FA',
    paper: '#FFFFFF',
    sidebar: '#FFFFFF',
    navbar: '#2196F3',
  },
  // Text colors
  text: {
    primary: '#212121',
    secondary: '#757575',
    disabled: '#9E9E9E',
    hint: '#9E9E9E',
  },
};

// Typography settings
export const typography = {
  fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
  fontSize: 14,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 700,
  h1: {
    fontSize: '2.5rem',
    fontWeight: 500,
  },
  h2: {
    fontSize: '2rem',
    fontWeight: 500,
  },
  h3: {
    fontSize: '1.75rem',
    fontWeight: 500,
  },
  h4: {
    fontSize: '1.5rem',
    fontWeight: 500,
  },
  h5: {
    fontSize: '1.25rem',
    fontWeight: 500,
  },
  h6: {
    fontSize: '1rem',
    fontWeight: 500,
  },
  body1: {
    fontSize: '1rem',
    fontWeight: 400,
  },
  body2: {
    fontSize: '0.875rem',
    fontWeight: 400,
  },
  button: {
    fontSize: '0.875rem',
    fontWeight: 500,
    textTransform: 'uppercase',
  },
  caption: {
    fontSize: '0.75rem',
    fontWeight: 400,
  },
};

// Spacing units for consistent margins, padding, etc.
export const spacing = (multiplier = 1) => `${8 * multiplier}px`;

// Shadows for elevation effects
export const shadows = {
  sm: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
  md: '0 4px 6px rgba(0,0,0,0.1), 0 1px 3px rgba(0,0,0,0.08)',
  lg: '0 10px 20px rgba(0,0,0,0.1), 0 3px 6px rgba(0,0,0,0.05)',
  xl: '0 15px 25px rgba(0,0,0,0.1), 0 5px 10px rgba(0,0,0,0.05)',
};

// Border radius for consistent component styling
export const borderRadius = {
  sm: '4px',
  md: '8px',
  lg: '12px',
  xl: '16px',
  round: '50%',
};

// Animation timing for consistent transitions
export const animation = {
  fast: '0.2s',
  normal: '0.3s',
  slow: '0.5s',
  timing: 'cubic-bezier(0.4, 0, 0.2, 1)',
};

// Responsive breakpoints
export const breakpoints = {
  xs: '0px',
  sm: '600px',
  md: '960px',
  lg: '1280px',
  xl: '1920px',
};
