import React from 'react';
import MainLayout from '../../components/UIRevamp/layout/MainLayout';
import SubjectAnalyticsRevamp from '../../components/UIRevamp/analytics/SubjectAnalyticsRevamp';

/**
 * Personalization page for the UI Revamp section
 * Integrates the revamped SubjectAnalytics component into the new UI
 */
const PersonalizationPage = () => {
  return (
    <MainLayout activeItem="analytics">
      <SubjectAnalyticsRevamp />
    </MainLayout>
  );
};

export default PersonalizationPage;
