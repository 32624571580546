import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Grid, 
  Paper, 
  Typography, 
  CircularProgress, 
  Card, 
  CardContent,
  Divider,
  Button,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  useTheme,
  useMediaQuery
} from '@mui/material';
import {
  People as PeopleIcon,
  School as SchoolIcon,
  Book as BookIcon,
  Assignment as AssignmentIcon,
  TrendingUp as TrendingUpIcon,
  ArrowUpward as ArrowUpwardIcon,
  ArrowDownward as ArrowDownwardIcon,
  Stars as StarsIcon
} from '@mui/icons-material';
import { 
  BarChart, 
  Bar, 
  XAxis, 
  YAxis, 
  CartesianGrid, 
  Tooltip, 
  Legend, 
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  LineChart,
  Line
} from 'recharts';
import API from '../../services/api';
import blueTheme from '../../theme/blueTheme';

const AnalyticsDashboard = ({ getToken, user, showNotification }) => {
  const [loading, setLoading] = useState(true);
  const [dashboardData, setDashboardData] = useState(null);
  const [studentPerformance, setStudentPerformance] = useState([]);
  const [subjectPopularity, setSubjectPopularity] = useState([]);
  const [testPerformance, setTestPerformance] = useState([]);
  const [classPerformance, setClassPerformance] = useState([]);
  const [selectedClass, setSelectedClass] = useState('');
  const [classes, setClasses] = useState([]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  // Colors for charts
  const COLORS = [
    blueTheme.primary.main,
    blueTheme.secondary.main,
    '#00C49F',
    '#FFBB28',
    '#FF8042',
    '#8884d8',
    '#82ca9d'
  ];

  useEffect(() => {
    fetchAnalyticsData();
    fetchClasses();
  }, []);

  useEffect(() => {
    if (selectedClass) {
      fetchStudentPerformanceByClass();
    }
  }, [selectedClass]);

  const fetchAnalyticsData = async () => {
    setLoading(true);
    
    try {
      const authToken = await getToken();
      
      if (!authToken) {
        showNotification('Authentication error. Please sign in again.', 'error');
        setLoading(false);
        return;
      }
      
      // Fetch dashboard overview data
      const dashboardRes = await API.institution.analytics.getDashboard(authToken, user.institution);
      setDashboardData(dashboardRes.data);
      
      // Fetch student performance data
      const studentPerformanceRes = await API.institution.analytics.getStudentPerformance(
        authToken, 
        { institutionId: user.institution }
      );
      setStudentPerformance(studentPerformanceRes.data);
      
      // Fetch subject popularity data
      const subjectPopularityRes = await API.institution.analytics.getSubjectPopularity(
        authToken,
        user.institution
      );
      setSubjectPopularity(subjectPopularityRes.data.subjects || []);
      
      // Fetch test performance data
      const testPerformanceRes = await API.institution.analytics.getTestPerformance(
        authToken,
        user.institution
      );
      setTestPerformance(testPerformanceRes.data.tests || []);
      
      // Fetch class performance data
      const classPerformanceRes = await API.institution.analytics.getClassPerformance(
        authToken,
        user.institution
      );
      setClassPerformance(classPerformanceRes.data.classes || []);
      
      setLoading(false);
    } catch (error) {
      console.error('Error fetching analytics data:', error);
      showNotification('Failed to load analytics data. Please try again.', 'error');
      setLoading(false);
    }
  };

  const fetchClasses = async () => {
    try {
      const authToken = await getToken();
      
      if (!authToken) {
        showNotification('Authentication error. Please sign in again.', 'error');
        return;
      }
      
      const response = await API.config.getClasses(authToken);
      setClasses(response.data.classes || []);
      
      if (response.data.classes && response.data.classes.length > 0) {
        setSelectedClass(response.data.classes[0]._id);
      }
    } catch (error) {
      console.error('Error fetching classes:', error);
      showNotification('Failed to load classes. Please try again.', 'error');
    }
  };

  const fetchStudentPerformanceByClass = async () => {
    try {
      const authToken = await getToken();
      
      if (!authToken) {
        showNotification('Authentication error. Please sign in again.', 'error');
        return;
      }
      
      const studentPerformanceRes = await API.institution.analytics.getStudentPerformance(
        authToken, 
        { 
          institutionId: user.institution,
          classId: selectedClass 
        }
      );
      
      setStudentPerformance(studentPerformanceRes.data);
    } catch (error) {
      console.error('Error fetching student performance by class:', error);
      showNotification('Failed to load student performance data. Please try again.', 'error');
    }
  };

  const formatSubjectData = () => {
    return subjectPopularity.map(subject => ({
      name: subject.name,
      value: subject.usageCount
    }));
  };

  const formatTestData = () => {
    return testPerformance.map(test => ({
      name: test.name,
      submissions: test.submissionCount,
      avgScore: test.averageScore
    }));
  };

  const formatClassData = () => {
    return classPerformance.map(cls => ({
      name: cls.name,
      avgScore: cls.averageScore
    }));
  };

  const StatCard = ({ title, value, icon, color, percentage, change, subtitle }) => (
    <Card 
      sx={{ 
        height: '100%',
        borderRadius: 3,
        boxShadow: '0 4px 20px rgba(0,0,0,0.08)',
        transition: 'all 0.3s ease',
        '&:hover': {
          transform: 'translateY(-5px)',
          boxShadow: '0 8px 30px rgba(0,0,0,0.12)'
        }
      }}
    >
      <CardContent>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <Box 
            sx={{ 
              p: 1.5, 
              borderRadius: 2, 
              bgcolor: `${color}20`,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              mr: 2
            }}
          >
            {icon}
          </Box>
          <Box>
            <Typography variant="h6" sx={{ fontWeight: 'medium', color: 'text.primary' }}>
              {title}
            </Typography>
            {subtitle && (
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {subtitle}
              </Typography>
            )}
          </Box>
        </Box>
        
        <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 1, color: 'text.primary' }}>
          {value || '0'}
        </Typography>
        
        {percentage !== undefined && (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box 
              sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                bgcolor: change === 'up' ? 'success.light' : 'error.light',
                color: change === 'up' ? 'success.dark' : 'error.dark',
                borderRadius: 5,
                px: 1,
                py: 0.5
              }}
            >
              {change === 'up' ? <ArrowUpwardIcon fontSize="small" /> : <ArrowDownwardIcon fontSize="small" />}
              <Typography variant="body2" sx={{ fontWeight: 'medium', ml: 0.5 }}>
                {percentage}%
              </Typography>
            </Box>
            <Typography variant="body2" sx={{ color: 'text.secondary', ml: 1 }}>
              from last month
            </Typography>
          </Box>
        )}
      </CardContent>
    </Card>
  );

  const TopPerformerCard = ({ student, index }) => (
    <Card 
      sx={{ 
        mb: 2, 
        borderRadius: 2,
        boxShadow: '0 2px 10px rgba(0,0,0,0.05)',
        transition: 'all 0.3s ease',
        '&:hover': {
          transform: 'translateX(5px)',
          boxShadow: '0 4px 15px rgba(0,0,0,0.08)'
        }
      }}
    >
      <CardContent sx={{ p: 2, '&:last-child': { pb: 2 } }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box 
            sx={{ 
              width: 36, 
              height: 36, 
              borderRadius: '50%', 
              bgcolor: index < 3 ? blueTheme.primary.main : blueTheme.background.paper,
              color: index < 3 ? 'white' : 'text.primary',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontWeight: 'bold',
              mr: 2,
              border: index < 3 ? 'none' : `1px solid ${blueTheme.primary.light}`
            }}
          >
            {index + 1}
          </Box>
          
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="body1" sx={{ fontWeight: 'medium' }}>
              {student.name}
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {student.className}
            </Typography>
          </Box>
          
          <Box 
            sx={{ 
              p: 1, 
              borderRadius: 2, 
              bgcolor: blueTheme.primary.light + '20',
              color: blueTheme.primary.dark
            }}
          >
            <Typography variant="body2" sx={{ fontWeight: 'medium' }}>
              {student.averageScore}%
            </Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );

  return (
    <Box>
      <Typography variant="h4" gutterBottom sx={{ fontWeight: 'medium', color: blueTheme.primary.dark }}>
        Analytics Dashboard
      </Typography>
      
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 5 }}>
          <CircularProgress sx={{ color: blueTheme.primary.main }} />
        </Box>
      ) : (
        <>
          {/* Stats Overview */}
          <Grid container spacing={3} sx={{ mb: 4 }}>
            <Grid item xs={12} sm={6} md={3}>
              <StatCard 
                title="Total Students" 
                value={dashboardData?.studentCount} 
                icon={<PeopleIcon sx={{ color: blueTheme.primary.main }} />}
                color={blueTheme.primary.main}
                percentage="5.2"
                change="up"
              />
            </Grid>
            
            <Grid item xs={12} sm={6} md={3}>
              <StatCard 
                title="Total Teachers" 
                value={dashboardData?.teacherCount} 
                icon={<SchoolIcon sx={{ color: blueTheme.secondary.main }} />}
                color={blueTheme.secondary.main}
                percentage="2.1"
                change="up"
              />
            </Grid>
            
            <Grid item xs={12} sm={6} md={3}>
              <StatCard 
                title="Total Subjects" 
                value={dashboardData?.subjectCount} 
                icon={<BookIcon sx={{ color: '#00C49F' }} />}
                color="#00C49F"
                percentage="3.8"
                change="up"
              />
            </Grid>
            
            <Grid item xs={12} sm={6} md={3}>
              <StatCard 
                title="Active Tests" 
                value={dashboardData?.activeTestCount} 
                icon={<AssignmentIcon sx={{ color: '#FFBB28' }} />}
                color="#FFBB28"
                percentage="1.5"
                change="down"
              />
            </Grid>
          </Grid>
          
          {/* Charts Row 1 */}
          <Grid container spacing={3} sx={{ mb: 4 }}>
            {/* Student Performance */}
            <Grid item xs={12} md={8}>
              <Paper 
                sx={{ 
                  p: 3, 
                  height: '100%', 
                  borderRadius: 3,
                  boxShadow: '0 4px 20px rgba(0,0,0,0.08)'
                }}
              >
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
                  <Typography variant="h6" sx={{ fontWeight: 'medium' }}>
                    Class Performance Overview
                  </Typography>
                  <FormControl size="small" sx={{ minWidth: 150 }}>
                    <InputLabel>Select Class</InputLabel>
                    <Select
                      value={selectedClass}
                      label="Select Class"
                      onChange={(e) => setSelectedClass(e.target.value)}
                      sx={{ borderRadius: 2 }}
                    >
                      {classes.map((cls) => (
                        <MenuItem key={cls._id} value={cls._id}>
                          {cls.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                
                <Box sx={{ height: 300 }}>
                  <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                      data={formatClassData()}
                      margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                    >
                      <CartesianGrid strokeDasharray="3 3" stroke="#f0f0f0" />
                      <XAxis dataKey="name" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Bar dataKey="avgScore" name="Average Score (%)" fill={blueTheme.primary.main} radius={[4, 4, 0, 0]} />
                    </BarChart>
                  </ResponsiveContainer>
                </Box>
              </Paper>
            </Grid>
            
            {/* Subject Popularity */}
            <Grid item xs={12} md={4}>
              <Paper 
                sx={{ 
                  p: 3, 
                  height: '100%', 
                  borderRadius: 3,
                  boxShadow: '0 4px 20px rgba(0,0,0,0.08)'
                }}
              >
                <Typography variant="h6" sx={{ fontWeight: 'medium', mb: 3 }}>
                  Subject Popularity
                </Typography>
                
                <Box sx={{ height: 300, display: 'flex', justifyContent: 'center' }}>
                  <ResponsiveContainer width="100%" height="100%">
                    <PieChart>
                      <Pie
                        data={formatSubjectData()}
                        cx="50%"
                        cy="50%"
                        labelLine={false}
                        outerRadius={80}
                        fill="#8884d8"
                        dataKey="value"
                        nameKey="name"
                        label={({ name, percent }) => `${name}: ${(percent * 100).toFixed(0)}%`}
                      >
                        {formatSubjectData().map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                      </Pie>
                      <Tooltip />
                    </PieChart>
                  </ResponsiveContainer>
                </Box>
              </Paper>
            </Grid>
          </Grid>
          
          {/* Charts Row 2 */}
          <Grid container spacing={3} sx={{ mb: 4 }}>
            {/* Test Performance */}
            <Grid item xs={12} md={8}>
              <Paper 
                sx={{ 
                  p: 3, 
                  height: '100%', 
                  borderRadius: 3,
                  boxShadow: '0 4px 20px rgba(0,0,0,0.08)'
                }}
              >
                <Typography variant="h6" sx={{ fontWeight: 'medium', mb: 3 }}>
                  Test Performance Overview
                </Typography>
                
                <Box sx={{ height: 300 }}>
                  <ResponsiveContainer width="100%" height="100%">
                    <LineChart
                      data={formatTestData()}
                      margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                    >
                      <CartesianGrid strokeDasharray="3 3" stroke="#f0f0f0" />
                      <XAxis dataKey="name" />
                      <YAxis yAxisId="left" orientation="left" stroke={blueTheme.primary.main} />
                      <YAxis yAxisId="right" orientation="right" stroke={blueTheme.secondary.main} />
                      <Tooltip />
                      <Legend />
                      <Line 
                        yAxisId="left"
                        type="monotone" 
                        dataKey="submissions" 
                        name="Submissions" 
                        stroke={blueTheme.primary.main} 
                        activeDot={{ r: 8 }} 
                      />
                      <Line 
                        yAxisId="right"
                        type="monotone" 
                        dataKey="avgScore" 
                        name="Avg Score (%)" 
                        stroke={blueTheme.secondary.main} 
                      />
                    </LineChart>
                  </ResponsiveContainer>
                </Box>
              </Paper>
            </Grid>
            
            {/* Top Performers */}
            <Grid item xs={12} md={4}>
              <Paper 
                sx={{ 
                  p: 3, 
                  height: '100%', 
                  borderRadius: 3,
                  boxShadow: '0 4px 20px rgba(0,0,0,0.08)',
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  <StarsIcon sx={{ color: '#FFD700', mr: 1 }} />
                  <Typography variant="h6" sx={{ fontWeight: 'medium' }}>
                    Top Performers
                  </Typography>
                </Box>
                
                <Box sx={{ flexGrow: 1, overflow: 'auto' }}>
                  {studentPerformance.topPerformers && studentPerformance.topPerformers.map((student, index) => (
                    <TopPerformerCard key={index} student={student} index={index} />
                  ))}
                  
                  {(!studentPerformance.topPerformers || studentPerformance.topPerformers.length === 0) && (
                    <Box sx={{ textAlign: 'center', py: 4 }}>
                      <Typography variant="body1" color="text.secondary">
                        No data available
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Paper>
            </Grid>
          </Grid>
          
          {/* Recent Activity */}
          <Paper 
            sx={{ 
              p: 3, 
              borderRadius: 3,
              boxShadow: '0 4px 20px rgba(0,0,0,0.08)'
            }}
          >
            <Typography variant="h6" sx={{ fontWeight: 'medium', mb: 3 }}>
              Recent Test Submissions
            </Typography>
            
            {dashboardData?.recentSubmissions && dashboardData.recentSubmissions.length > 0 ? (
              <Grid container spacing={2}>
                {dashboardData.recentSubmissions.map((submission, index) => (
                  <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                    <Card 
                      sx={{ 
                        borderRadius: 2,
                        transition: 'all 0.3s ease',
                        '&:hover': {
                          transform: 'translateY(-4px)',
                          boxShadow: '0 6px 16px rgba(0,0,0,0.1)'
                        }
                      }}
                    >
                      <CardContent>
                        <Typography variant="body1" sx={{ fontWeight: 'medium', mb: 1 }}>
                          {submission.studentName}
                        </Typography>
                        <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                          {submission.testName}
                        </Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                          <Typography 
                            variant="body2" 
                            sx={{ 
                              fontWeight: 'medium', 
                              color: 'white',
                              bgcolor: submission.score > 75 ? 'success.main' : 
                                      submission.score > 50 ? 'warning.main' : 'error.main',
                              px: 1.5,
                              py: 0.5,
                              borderRadius: 5
                            }}
                          >
                            {submission.score}%
                          </Typography>
                          <Typography variant="caption" color="text.secondary">
                            {new Date(submission.submittedAt).toLocaleDateString()}
                          </Typography>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            ) : (
              <Box sx={{ textAlign: 'center', py: 4 }}>
                <Typography variant="body1" color="text.secondary">
                  No recent test submissions
                </Typography>
              </Box>
            )}
          </Paper>
        </>
      )}
    </Box>
  );
};

export default AnalyticsDashboard;
