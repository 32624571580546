import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Paper, 
  Typography, 
  TextField, 
  Button, 
  Grid, 
  Card, 
  CardContent, 
  CardActions,
  IconButton, 
  InputAdornment,
  Pagination,
  CircularProgress,
  Chip,
  Breadcrumbs,
  Link,
  useTheme,
  Tooltip,
  Avatar,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tab,
  Tabs,
  Badge
} from '@mui/material';
import { 
  Add as AddIcon, 
  Search as SearchIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  ArrowBack as ArrowBackIcon,
  Topic as TopicIcon,
  Class as ClassIcon,
  QuestionAnswer as QuestionAnswerIcon,
  FilterList as FilterListIcon,
  Sort as SortIcon,
  CloudUpload as CloudUploadIcon,
  Check as CheckIcon,
  Close as CloseIcon,
  Help as HelpIcon
} from '@mui/icons-material';
import API from '../../services/api';
import MCQEditor from './MCQEditor';
import MCQBulkUpload from './MCQBulkUpload';

const MCQManagement = ({ 
  mcqs,
  selectedTopic,
  selectedSubject,
  setSelectedTopic,
  setSelectedSubject,
  onAddMCQ,
  onEditMCQ,
  onDeleteMCQ,
  onBulkUpload,
  getToken, 
  user, 
  showNotification
}) => {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredMCQs, setFilteredMCQs] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedMCQ, setSelectedMCQ] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(12);
  const [dialogTab, setDialogTab] = useState(0);
  const [sortOption, setSortOption] = useState('date-desc');
  const [filterOptions, setFilterOptions] = useState({
    difficulty: 'all', // 'all', 'easy', 'medium', 'hard'
    status: 'all' // 'all', 'active', 'draft'
  });

  useEffect(() => {
    if (mcqs && mcqs.length > 0) {
      applyFiltersAndSort();
    } else {
      setFilteredMCQs([]);
    }
  }, [mcqs, searchTerm, filterOptions, sortOption]);

  const applyFiltersAndSort = () => {
    let result = [...mcqs];
    
    // Apply search filter
    if (searchTerm) {
      result = result.filter(mcq => 
        mcq.question.toLowerCase().includes(searchTerm.toLowerCase()) ||
        mcq.options.some(opt => opt.text.toLowerCase().includes(searchTerm.toLowerCase())) ||
        mcq.explanation?.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
    
    // Apply difficulty filter
    if (filterOptions.difficulty !== 'all') {
      const difficultyMap = {
        'easy': 1,
        'medium': 2,
        'hard': 3
      };
      
      result = result.filter(mcq => 
        mcq.difficulty === difficultyMap[filterOptions.difficulty]
      );
    }
    
    // Apply status filter
    if (filterOptions.status !== 'all') {
      result = result.filter(mcq => 
        filterOptions.status === 'active' ? mcq.isActive : !mcq.isActive
      );
    }
    
    // Apply sorting
    result.sort((a, b) => {
      const [field, direction] = sortOption.split('-');
      
      if (field === 'date') {
        return direction === 'asc' 
          ? new Date(a.createdAt) - new Date(b.createdAt) 
          : new Date(b.createdAt) - new Date(a.createdAt);
      }
      
      if (field === 'difficulty') {
        return direction === 'asc' 
          ? (a.difficulty || 0) - (b.difficulty || 0) 
          : (b.difficulty || 0) - (a.difficulty || 0);
      }
      
      return 0;
    });
    
    setFilteredMCQs(result);
  };

  const handleOpenDialog = (mcq = null) => {
    if (mcq) {
      setSelectedMCQ(mcq);
    } else {
      setSelectedMCQ(null);
    }
    setDialogTab(0);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedMCQ(null);
  };

  const handleDialogTabChange = (event, newValue) => {
    setDialogTab(newValue);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleDelete = async (mcq) => {
    if (window.confirm(`Are you sure you want to delete this MCQ?`)) {
      try {
        setLoading(true);
        await onDeleteMCQ(mcq);
        showNotification('success', 'MCQ deleted successfully');
      } catch (error) {
        console.error('Error deleting MCQ:', error);
        showNotification('error', 'Failed to delete MCQ');
      } finally {
        setLoading(false);
      }
    }
  };

  const handleSaveMCQ = async (mcqData) => {
    try {
      setLoading(true);
      
      if (selectedMCQ) {
        // Update existing MCQ
        await onEditMCQ({
          ...selectedMCQ,
          ...mcqData
        });
        showNotification('success', 'MCQ updated successfully');
      } else {
        // Create new MCQ
        await onAddMCQ({
          ...mcqData,
          topicId: selectedTopic?.id,
          subjectId: selectedSubject?.id
        });
        showNotification('success', 'MCQ created successfully');
      }
      
      handleCloseDialog();
    } catch (error) {
      console.error('Error saving MCQ:', error);
      showNotification('error', 'Failed to save MCQ');
    } finally {
      setLoading(false);
    }
  };

  const handleBulkUploadComplete = async (results) => {
    try {
      setLoading(true);
      await onBulkUpload(results);
      showNotification('success', `${results.length} MCQs uploaded successfully`);
      handleCloseDialog();
    } catch (error) {
      console.error('Error bulk uploading MCQs:', error);
      showNotification('error', 'Failed to bulk upload MCQs');
    } finally {
      setLoading(false);
    }
  };

  const getDifficultyLabel = (level) => {
    switch (level) {
      case 1: return 'Easy';
      case 2: return 'Medium';
      case 3: return 'Hard';
      default: return 'Medium';
    }
  };

  const getDifficultyColor = (level) => {
    switch (level) {
      case 1: return 'success';
      case 2: return 'warning';
      case 3: return 'error';
      default: return 'warning';
    }
  };

  // Render MCQ card
  const renderMCQCard = (mcq) => {
    return (
      <Card sx={{ 
        height: '100%', 
        display: 'flex', 
        flexDirection: 'column',
        transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
        '&:hover': {
          transform: 'translateY(-4px)',
          boxShadow: 4
        }
      }}>
        <CardContent sx={{ flexGrow: 1, pb: 1 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
            <Chip 
              label={getDifficultyLabel(mcq.difficulty)} 
              size="small" 
              color={getDifficultyColor(mcq.difficulty)}
            />
            {!mcq.isActive && (
              <Chip 
                label="Draft" 
                size="small" 
                variant="outlined"
              />
            )}
          </Box>
          
          <Typography 
            variant="subtitle1" 
            component="div" 
            sx={{ 
              mb: 2,
              display: '-webkit-box',
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              height: '48px',
              fontWeight: 'medium'
            }}
            dangerouslySetInnerHTML={{ __html: mcq.question }}
          />
          
          <Box sx={{ mb: 2 }}>
            {mcq.options.map((option, index) => (
              <Box 
                key={index} 
                sx={{ 
                  display: 'flex', 
                  alignItems: 'flex-start', 
                  mb: 1,
                  p: 1,
                  borderRadius: 1,
                  bgcolor: option.isCorrect ? 'success.50' : 'background.paper',
                  border: '1px solid',
                  borderColor: option.isCorrect ? 'success.main' : 'divider'
                }}
              >
                <Box 
                  sx={{ 
                    minWidth: 24, 
                    height: 24, 
                    borderRadius: '50%', 
                    bgcolor: option.isCorrect ? 'success.main' : 'action.selected',
                    color: option.isCorrect ? 'white' : 'text.primary',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    mr: 1,
                    fontSize: '0.75rem',
                    fontWeight: 'bold'
                  }}
                >
                  {option.isCorrect ? <CheckIcon fontSize="small" /> : String.fromCharCode(65 + index)}
                </Box>
                <Typography 
                  variant="body2" 
                  sx={{ 
                    display: '-webkit-box',
                    WebkitLineClamp: 1,
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}
                  dangerouslySetInnerHTML={{ __html: option.text }}
                />
              </Box>
            ))}
          </Box>
          
          {mcq.explanation && (
            <Box sx={{ mt: 'auto' }}>
              <Typography variant="caption" color="text.secondary" fontWeight="medium">
                Explanation:
              </Typography>
              <Typography 
                variant="body2" 
                color="text.secondary" 
                sx={{ 
                  display: '-webkit-box',
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}
                dangerouslySetInnerHTML={{ __html: mcq.explanation }}
              />
            </Box>
          )}
        </CardContent>
        <Divider />
        <CardActions sx={{ justifyContent: 'space-between', px: 2, py: 1 }}>
          <Box>
            <Tooltip title="Edit MCQ">
              <IconButton 
                size="small" 
                onClick={() => handleOpenDialog(mcq)}
                aria-label="edit mcq"
              >
                <EditIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete MCQ">
              <IconButton 
                size="small" 
                onClick={() => handleDelete(mcq)}
                aria-label="delete mcq"
                color="error"
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Box>
          <Typography variant="caption" color="text.secondary">
            {mcq.topicName || selectedTopic?.name || 'No Topic'}
          </Typography>
        </CardActions>
      </Card>
    );
  };

  // Render breadcrumb navigation
  const renderBreadcrumbs = () => {
    return (
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2 }}>
        <Link
          underline="hover"
          color="inherit"
          sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
          onClick={() => {
            setSelectedSubject(null);
            setSelectedTopic(null);
          }}
        >
          <ClassIcon sx={{ mr: 0.5 }} fontSize="small" />
          Subjects
        </Link>
        {selectedSubject && (
          <Link
            underline="hover"
            color="inherit"
            sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
            onClick={() => {
              setSelectedTopic(null);
            }}
          >
            <TopicIcon sx={{ mr: 0.5 }} fontSize="small" />
            {selectedSubject.name}
          </Link>
        )}
        <Typography color="text.primary" sx={{ display: 'flex', alignItems: 'center' }}>
          <QuestionAnswerIcon sx={{ mr: 0.5 }} fontSize="small" />
          {selectedTopic?.name || 'MCQs'}
        </Typography>
      </Breadcrumbs>
    );
  };

  return (
    <Box>
      <Box sx={{ mb: 3 }}>
        {renderBreadcrumbs()}
        
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {selectedTopic && (
              <IconButton 
                color="primary" 
                onClick={() => setSelectedTopic(null)}
                sx={{ mr: 1 }}
              >
                <ArrowBackIcon />
              </IconButton>
            )}
            <Typography variant="h4" component="h1">
              {selectedTopic ? `${selectedTopic.name} - MCQs` : 'MCQ Management'}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', gap: 2, mt: { xs: 2, sm: 0 } }}>
            <Button 
              variant="outlined" 
              color="primary" 
              startIcon={<CloudUploadIcon />}
              onClick={() => {
                setDialogTab(1);
                setOpenDialog(true);
              }}
            >
              Bulk Upload
            </Button>
            <Button 
              variant="contained" 
              color="primary" 
              startIcon={<AddIcon />}
              onClick={() => handleOpenDialog()}
            >
              Add MCQ
            </Button>
          </Box>
        </Box>
      </Box>

      <Paper sx={{ p: 2, mb: 3 }}>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, alignItems: 'center', mb: 2 }}>
          <TextField
            placeholder="Search MCQs..."
            variant="outlined"
            size="small"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon fontSize="small" />
                </InputAdornment>
              ),
            }}
            sx={{ flexGrow: 1, maxWidth: { xs: '100%', sm: 300 } }}
          />
          
          <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
            <FormControl size="small" sx={{ minWidth: 120 }}>
              <InputLabel id="difficulty-filter-label">Difficulty</InputLabel>
              <Select
                labelId="difficulty-filter-label"
                value={filterOptions.difficulty}
                label="Difficulty"
                onChange={(e) => setFilterOptions(prev => ({ ...prev, difficulty: e.target.value }))}
              >
                <MenuItem value="all">All</MenuItem>
                <MenuItem value="easy">Easy</MenuItem>
                <MenuItem value="medium">Medium</MenuItem>
                <MenuItem value="hard">Hard</MenuItem>
              </Select>
            </FormControl>
            
            <FormControl size="small" sx={{ minWidth: 120 }}>
              <InputLabel id="status-filter-label">Status</InputLabel>
              <Select
                labelId="status-filter-label"
                value={filterOptions.status}
                label="Status"
                onChange={(e) => setFilterOptions(prev => ({ ...prev, status: e.target.value }))}
              >
                <MenuItem value="all">All</MenuItem>
                <MenuItem value="active">Active</MenuItem>
                <MenuItem value="draft">Draft</MenuItem>
              </Select>
            </FormControl>
            
            <FormControl size="small" sx={{ minWidth: 150 }}>
              <InputLabel id="sort-label">Sort By</InputLabel>
              <Select
                labelId="sort-label"
                value={sortOption}
                label="Sort By"
                onChange={(e) => setSortOption(e.target.value)}
              >
                <MenuItem value="date-desc">Newest</MenuItem>
                <MenuItem value="date-asc">Oldest</MenuItem>
                <MenuItem value="difficulty-asc">Difficulty (Low-High)</MenuItem>
                <MenuItem value="difficulty-desc">Difficulty (High-Low)</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Box>
        
        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
            <CircularProgress />
          </Box>
        ) : filteredMCQs.length === 0 ? (
          <Box sx={{ textAlign: 'center', py: 4 }}>
            <Typography variant="body1" color="text.secondary">
              No MCQs found. {searchTerm && 'Try a different search term.'}
            </Typography>
            <Button 
              variant="contained" 
              color="primary" 
              startIcon={<AddIcon />}
              onClick={() => handleOpenDialog()}
              sx={{ mt: 2 }}
            >
              Add MCQ
            </Button>
          </Box>
        ) : (
          <>
            <Grid container spacing={3}>
              {filteredMCQs
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((mcq) => (
                  <Grid item xs={12} sm={6} md={4} key={mcq.id}>
                    {renderMCQCard(mcq)}
                  </Grid>
                ))}
            </Grid>
            
            {filteredMCQs.length > rowsPerPage && (
              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
                <Pagination 
                  count={Math.ceil(filteredMCQs.length / rowsPerPage)} 
                  page={page + 1} 
                  onChange={(e, newPage) => handlePageChange(e, newPage - 1)}
                  color="primary"
                />
              </Box>
            )}
          </>
        )}
      </Paper>

      {/* Add/Edit MCQ Dialog */}
      <Dialog 
        open={openDialog} 
        onClose={handleCloseDialog}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          <Tabs value={dialogTab} onChange={handleDialogTabChange} aria-label="mcq management tabs">
            <Tab label={selectedMCQ ? "Edit MCQ" : "Add MCQ"} />
            <Tab label="Bulk Upload" />
          </Tabs>
        </DialogTitle>
        <DialogContent dividers>
          {dialogTab === 0 && (
            <MCQEditor 
              mcq={selectedMCQ} 
              onSave={handleSaveMCQ}
              topics={[]} // This would be populated from props
              subjects={[]} // This would be populated from props
              selectedTopic={selectedTopic}
              selectedSubject={selectedSubject}
            />
          )}
          {dialogTab === 1 && (
            <MCQBulkUpload 
              onUploadComplete={handleBulkUploadComplete}
              selectedTopic={selectedTopic}
              selectedSubject={selectedSubject}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default MCQManagement;
