import React from 'react';
import CaseStudyLayout from '../../components/UIRevamp/case-study/CaseStudyLayout';

/**
 * Case Study Page component
 * Renders the CaseStudyLayout component which contains the case study functionality
 */
const CaseStudyPage = () => {
  return <CaseStudyLayout />;
};

export default CaseStudyPage;
