import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Paper, 
  Typography, 
  Tabs, 
  Tab, 
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Chip,
  CircularProgress,
  Alert,
  Stepper,
  Step,
  StepLabel,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Snackbar,
  FormControlLabel,
  Checkbox
} from '@mui/material';
import {
  Add as AddIcon,
  Search as SearchIcon,
  CloudUpload as UploadIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Check as CheckIcon,
  Close as CloseIcon,
  Download as DownloadIcon
} from '@mui/icons-material';
import * as XLSX from 'xlsx';
import API from '../../services/api';
import axios from 'axios';
import config from '../../config';
import { useAuth } from '../../services/authContext';

const UserManagement = ({ getToken, showNotification }) => {
  const { user } = useAuth();
  const [tabValue, setTabValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const [students, setStudents] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [openBulkImport, setOpenBulkImport] = useState(false);
  const [openAddUser, setOpenAddUser] = useState(false);
  const [editUser, setEditUser] = useState(null);
  const [deleteDialog, setDeleteDialog] = useState({ open: false, userId: null });
  const [classes, setClasses] = useState([]);
  const [classFilter, setClassFilter] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [notification, setNotification] = useState({ open: false, message: '', severity: 'info' });
  const [institutionId, setInstitutionId] = useState('');
  
  useEffect(() => {
    // Get institution ID from user object or localStorage
    const storedUser = user || JSON.parse(localStorage.getItem('user') || '{}');
    if (storedUser && storedUser.institution) {
      setInstitutionId(storedUser.institution);
    }
    
    fetchUsers();
    fetchClasses();
  }, [tabValue, page, rowsPerPage]);
  
  const fetchUsers = async () => {
    setLoading(true);
    try {
      const authToken = await getToken();
      
      if (!authToken) {
        handleShowNotification('Authentication error. Please sign in again.', 'error');
        setLoading(false);
        return;
      }
      
      // Get institution ID from user object or localStorage if not already set
      let instId = institutionId;
      if (!instId) {
        const storedUser = user || JSON.parse(localStorage.getItem('user') || '{}');
        if (storedUser && storedUser.institution) {
          instId = storedUser.institution;
          setInstitutionId(instId);
        } else {
          handleShowNotification('Institution ID not found. Please sign in again.', 'error');
          setLoading(false);
          return;
        }
      }
      
      if (tabValue === 0) {
        // Fetch students
        const response = await axios.get(
          `${config.API_URL}/api/institutions/students`,
          {
            headers: {
              Authorization: `Bearer ${authToken}`
            },
            params: {
              institutionId: instId,
              page: page + 1,
              limit: rowsPerPage,
              search: searchTerm || undefined,
              classId: classFilter || undefined,
              status: statusFilter || undefined
            }
          }
        );
        
        setStudents(response.data.students || []);
        setTotalCount(response.data.totalCount || 0);
      } else {
        // Fetch teachers
        const response = await axios.get(
          `${config.API_URL}/api/institutions/teachers`,
          {
            headers: {
              Authorization: `Bearer ${authToken}`
            },
            params: {
              institutionId: instId,
              page: page + 1,
              limit: rowsPerPage,
              search: searchTerm || undefined
            }
          }
        );
        
        setTeachers(response.data.teachers || []);
        setTotalCount(response.data.totalCount || 0);
      }
    } catch (error) {
      console.error('Error fetching users:', error);
      handleShowNotification('Error fetching users', 'error');
    } finally {
      setLoading(false);
    }
  };
  
  const fetchClasses = async () => {
    try {
      const authToken = await getToken();
      
      if (!authToken) {
        handleShowNotification('Authentication error. Please sign in again.', 'error');
        return;
      }
      
      // Get institution ID if not already set
      let instId = institutionId;
      if (!instId) {
        const storedUser = user || JSON.parse(localStorage.getItem('user') || '{}');
        if (storedUser && storedUser.institution) {
          instId = storedUser.institution;
          setInstitutionId(instId);
        }
      }
      
      // First try to get classes from the institution config endpoint
      try {
        const response = await axios.get(
          `${config.API_URL}/api/institutions/config/classes`,
          {
            headers: {
              Authorization: `Bearer ${authToken}`
            },
            params: {
              institutionId: instId
            }
          }
        );
        
        if (response.data.classes && response.data.classes.length > 0) {
          setClasses(response.data.classes);
          return;
        }
      } catch (configError) {
        console.error('Error fetching classes from institution config:', configError);
      }
      
      // Fallback to default classes
      setClasses([
        { id: '1', name: 'Class 8' },
        { id: '2', name: 'Class 9' },
        { id: '3', name: 'Class 10' },
        { id: '4', name: 'Class 11' },
        { id: '5', name: 'Class 12' }
      ]);
    } catch (error) {
      console.error('Error in fetchClasses:', error);
      
      // Final fallback
      setClasses([
        { id: '1', name: 'Class 8' },
        { id: '2', name: 'Class 9' },
        { id: '3', name: 'Class 10' },
        { id: '4', name: 'Class 11' },
        { id: '5', name: 'Class 12' }
      ]);
    }
  };
  
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    setPage(0);
  };
  
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  
  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };
  
  const handleSearchSubmit = (event) => {
    if (event.key === 'Enter') {
      fetchUsers();
    }
  };
  
  const handleAddUser = () => {
    setEditUser(null);
    setOpenAddUser(true);
  };
  
  const handleEditUser = (user) => {
    setEditUser(user);
    setOpenAddUser(true);
  };
  
  const handleDeleteUser = (userId) => {
    setDeleteDialog({ open: true, userId });
  };
  
  const handleConfirmDelete = async () => {
    try {
      const authToken = await getToken();
      
      if (!authToken) {
        handleShowNotification('Authentication error. Please sign in again.', 'error');
        return;
      }
      
      const endpoint = tabValue === 0 
        ? `${config.API_URL}/api/institutions/students/${deleteDialog.userId}`
        : `${config.API_URL}/api/institutions/teachers/${deleteDialog.userId}`;
      
      await axios.delete(endpoint, {
        headers: {
          Authorization: `Bearer ${authToken}`
        },
        params: {
          institutionId: institutionId
        }
      });
      
      handleShowNotification(`${tabValue === 0 ? 'Student' : 'Teacher'} deleted successfully!`, 'success');
      setDeleteDialog({ open: false, userId: null });
      fetchUsers();
    } catch (error) {
      console.error('Error deleting user:', error);
      handleShowNotification(`Failed to delete ${tabValue === 0 ? 'student' : 'teacher'}. Please try again.`, 'error');
    }
  };
  
  const handleBulkImport = () => {
    setOpenBulkImport(true);
  };
  
  const handleClassFilterChange = (event) => {
    setClassFilter(event.target.value);
  };
  
  const handleStatusFilterChange = (event) => {
    setStatusFilter(event.target.value);
  };
  
  const handleApplyFilters = () => {
    fetchUsers();
  };
  
  const handleClearFilters = () => {
    setSearchTerm('');
    setClassFilter('');
    setStatusFilter('');
    fetchUsers();
  };
  
  const handleShowNotification = (message, severity) => {
    if (showNotification) {
      showNotification(severity, message);
    } else {
      setNotification({
        open: true,
        message,
        severity
      });
    }
  };
  
  const handleCloseNotification = () => {
    setNotification({ ...notification, open: false });
  };
  
  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
        <Typography variant="h4">User Management</Typography>
        <Box>
          <Button 
            variant="contained" 
            color="primary" 
            startIcon={<AddIcon />}
            onClick={handleAddUser}
            sx={{ mr: 2 }}
          >
            Add {tabValue === 0 ? 'Student' : 'Teacher'}
          </Button>
          <Button 
            variant="outlined" 
            color="primary"
            startIcon={<UploadIcon />}
            onClick={handleBulkImport}
          >
            Bulk Import
          </Button>
        </Box>
      </Box>
      
      <Paper sx={{ mb: 3 }}>
        <Tabs 
          value={tabValue} 
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab label="Students" />
          <Tab label="Teachers" />
        </Tabs>
      </Paper>
      
      <Paper sx={{ mb: 3, p: 2 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              placeholder={`Search ${tabValue === 0 ? 'students' : 'teachers'}...`}
              variant="outlined"
              value={searchTerm}
              onChange={handleSearch}
              onKeyPress={handleSearchSubmit}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                endAdornment: searchTerm && (
                  <InputAdornment position="end">
                    <IconButton size="small" onClick={() => setSearchTerm('')}>
                      <CloseIcon />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          
          {tabValue === 0 && (
            <>
              <Grid item xs={12} md={3}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel id="class-filter-label">Class</InputLabel>
                  <Select
                    labelId="class-filter-label"
                    value={classFilter}
                    onChange={handleClassFilterChange}
                    label="Class"
                  >
                    <MenuItem value="">All Classes</MenuItem>
                    {classes.map((cls) => (
                      <MenuItem key={cls.id} value={cls.id}>
                        {cls.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              
              <Grid item xs={12} md={3}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel id="status-filter-label">Status</InputLabel>
                  <Select
                    labelId="status-filter-label"
                    value={statusFilter}
                    onChange={handleStatusFilterChange}
                    label="Status"
                  >
                    <MenuItem value="">All Status</MenuItem>
                    <MenuItem value="active">Active</MenuItem>
                    <MenuItem value="inactive">Inactive</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </>
          )}
          
          <Grid item xs={12} md={tabValue === 0 ? 2 : 8} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button 
              variant="contained" 
              color="primary"
              onClick={handleApplyFilters}
              sx={{ mr: 1 }}
            >
              Apply Filters
            </Button>
            <Button 
              variant="outlined"
              onClick={handleClearFilters}
            >
              Clear
            </Button>
          </Grid>
        </Grid>
      </Paper>
      
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
          <CircularProgress />
        </Box>
      ) : (
        <Paper>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  {tabValue === 0 ? (
                    <>
                      <TableCell>Class</TableCell>
                      <TableCell>Roll Number</TableCell>
                    </>
                  ) : (
                    <>
                      <TableCell>Subjects</TableCell>
                      <TableCell>Designation</TableCell>
                    </>
                  )}
                  <TableCell>Status</TableCell>
                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tabValue === 0 ? (
                  students.length > 0 ? (
                    students.map((student) => (
                      <TableRow key={student._id}>
                        <TableCell>{`${student.firstName} ${student.lastName}`}</TableCell>
                        <TableCell>{student.email}</TableCell>
                        <TableCell>
                          {classes.find(c => c.id === student.classId)?.name || `Class ${student.classId}`}
                        </TableCell>
                        <TableCell>{student.rollNumber || '-'}</TableCell>
                        <TableCell>
                          <Chip 
                            label={student.isActive ? "Active" : "Inactive"} 
                            color={student.isActive ? "success" : "default"}
                            size="small"
                          />
                        </TableCell>
                        <TableCell align="right">
                          <IconButton size="small" color="primary" onClick={() => handleEditUser(student)}>
                            <EditIcon fontSize="small" />
                          </IconButton>
                          <IconButton size="small" color="error" onClick={() => handleDeleteUser(student._id)}>
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={6} align="center">
                        No students found
                      </TableCell>
                    </TableRow>
                  )
                ) : (
                  teachers.length > 0 ? (
                    teachers.map((teacher) => (
                      <TableRow key={teacher._id}>
                        <TableCell>{`${teacher.firstName} ${teacher.lastName}`}</TableCell>
                        <TableCell>{teacher.email}</TableCell>
                        <TableCell>{teacher.subjects?.join(', ') || '-'}</TableCell>
                        <TableCell>{teacher.designation || '-'}</TableCell>
                        <TableCell>
                          <Chip 
                            label={teacher.isActive ? "Active" : "Inactive"} 
                            color={teacher.isActive ? "success" : "default"}
                            size="small"
                          />
                        </TableCell>
                        <TableCell align="right">
                          <IconButton size="small" color="primary" onClick={() => handleEditUser(teacher)}>
                            <EditIcon fontSize="small" />
                          </IconButton>
                          <IconButton size="small" color="error" onClick={() => handleDeleteUser(teacher._id)}>
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={6} align="center">
                        No teachers found
                      </TableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      )}
      
      {notification.open && (
        <Snackbar 
          open={notification.open} 
          autoHideDuration={6000} 
          onClose={handleCloseNotification}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert onClose={handleCloseNotification} severity={notification.severity}>
            {notification.message}
          </Alert>
        </Snackbar>
      )}
      
      <BulkImportDialog 
        open={openBulkImport}
        onClose={() => setOpenBulkImport(false)}
        userType={tabValue === 0 ? 'student' : 'teacher'}
        getToken={getToken}
        showNotification={handleShowNotification}
        onImportComplete={fetchUsers}
        classes={classes}
        institutionId={institutionId}
      />
      
      <AddUserDialog
        open={openAddUser}
        onClose={() => setOpenAddUser(false)}
        userType={tabValue === 0 ? 'student' : 'teacher'}
        getToken={getToken}
        showNotification={handleShowNotification}
        onUserAdded={fetchUsers}
        userData={editUser}
        classes={classes}
        institutionId={institutionId}
      />
      
      <Dialog open={deleteDialog.open} onClose={() => setDeleteDialog({ open: false, userId: null })}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete this {tabValue === 0 ? 'student' : 'teacher'}? This action cannot be undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialog({ open: false, userId: null })} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

// Bulk Import Dialog Component
const BulkImportDialog = ({ open, onClose, userType, getToken, showNotification, onImportComplete, classes, institutionId }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [file, setFile] = useState(null);
  const [parsedData, setParsedData] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [uploadResults, setUploadResults] = useState(null);
  const [mappings, setMappings] = useState({
    firstName: '',
    lastName: '',
    email: '',
    classId: '',
    rollNumber: '',
    parentName: '',
    parentPhone: '',
    temporaryPassword: '',
    subjects: '',
    designation: ''
  });
  const [availableSubjects, setAvailableSubjects] = useState([]);
  const [subjectMap, setSubjectMap] = useState({});
  
  const steps = ['Upload File', 'Map Columns', 'Review & Import'];
  
  useEffect(() => {
    if (open && userType === 'teacher') {
      fetchSubjects();
    }
  }, [open, userType]);
  
  const fetchSubjects = async () => {
    try {
      const authToken = await getToken();
      
      if (!authToken) {
        showNotification('Authentication error. Please sign in again.', 'error');
        return;
      }
      
      const response = await axios.get(
        `${config.API_URL}/api/subjects`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`
          },
          params: {
            institutionId: institutionId
          }
        }
      );
      
      if (response.data && response.data.subjects) {
        const subjects = response.data.subjects;
        setAvailableSubjects(subjects);
        
        // Create a map of subject name to subject ID for easier lookup
        const nameToIdMap = {};
        subjects.forEach(subject => {
          nameToIdMap[subject.name.toLowerCase()] = subject._id;
        });
        setSubjectMap(nameToIdMap);
      } else {
        setAvailableSubjects([]);
        setSubjectMap({});
      }
    } catch (error) {
      console.error('Error fetching subjects:', error);
      showNotification('Failed to load subjects. Please try again.', 'error');
    }
  };
  
  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };
  
  const handleNext = () => {
    if (activeStep === 0 && file) {
      parseExcelFile();
    } else if (activeStep === 1) {
      // Validate mappings
      const requiredFields = userType === 'student' 
        ? ['firstName', 'lastName', 'email', 'classId'] 
        : ['firstName', 'lastName', 'email'];
      
      const missingFields = requiredFields.filter(field => mappings[field] === '');
      
      if (missingFields.length > 0) {
        showNotification(`Please map the following required fields: ${missingFields.join(', ')}`, 'error');
        return;
      }
      
      // If all required fields are mapped, proceed to next step
      setActiveStep((prevStep) => prevStep + 1);
    } else {
      // For any other step, just move forward
      setActiveStep((prevStep) => prevStep + 1);
    }
  };
  
  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };
  
  const handleClose = () => {
    setActiveStep(0);
    setFile(null);
    setParsedData([]);
    setMappings({
      firstName: '',
      lastName: '',
      email: '',
      classId: '',
      rollNumber: '',
      parentName: '',
      parentPhone: '',
      temporaryPassword: '',
      subjects: '',
      designation: ''
    });
    setUploadResults(null);
    onClose();
  };
  
  const parseExcelFile = () => {
    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
        const jsonData = XLSX.utils.sheet_to_json(firstSheet, { header: 1 });
        
        if (jsonData.length < 2) {
          showNotification('File must contain at least a header row and one data row', 'error');
          return;
        }
        
        // First row is headers
        const headers = jsonData[0];
        const rows = jsonData.slice(1).filter(row => row.length > 0);
        
        setParsedData({ headers, rows });
        
        // Try to auto-map columns based on header names
        const newMappings = { ...mappings };
        headers.forEach((header, index) => {
          const headerLower = header.toLowerCase();
          
          if (headerLower.includes('first') && headerLower.includes('name')) {
            newMappings.firstName = index;
          } else if (headerLower.includes('last') && headerLower.includes('name')) {
            newMappings.lastName = index;
          } else if (headerLower.includes('email')) {
            newMappings.email = index;
          } else if (headerLower.includes('class')) {
            newMappings.classId = index;
          } else if (headerLower.includes('roll')) {
            newMappings.rollNumber = index;
          } else if (headerLower.includes('parent') && headerLower.includes('name')) {
            newMappings.parentName = index;
          } else if (headerLower.includes('parent') && headerLower.includes('phone')) {
            newMappings.parentPhone = index;
          } else if (headerLower.includes('password')) {
            newMappings.temporaryPassword = index;
          } else if (headerLower.includes('subject')) {
            newMappings.subjects = index;
          } else if (headerLower.includes('designation')) {
            newMappings.designation = index;
          }
        });
        
        setMappings(newMappings);
        setActiveStep(1);
      } catch (error) {
        console.error('Error parsing Excel file:', error);
        showNotification('Failed to parse Excel file. Please check the file format.', 'error');
      }
    };
    
    reader.readAsArrayBuffer(file);
  };
  
  const handleMappingChange = (field, value) => {
    setMappings({
      ...mappings,
      [field]: value
    });
  };
  
  // Helper function to map subject names to IDs
  const mapSubjectNamesToIds = (subjectNames) => {
    if (!subjectNames) return [];
    
    // If it's already an array, process each item
    if (Array.isArray(subjectNames)) {
      return subjectNames.map(name => {
        const nameLower = name.trim().toLowerCase();
        return subjectMap[nameLower] || name; // Fall back to the name if ID not found
      }).filter(id => id); // Remove any empty values
    }
    
    // If it's a string, split by commas and process
    if (typeof subjectNames === 'string') {
      return subjectNames.split(',').map(name => {
        const nameLower = name.trim().toLowerCase();
        return subjectMap[nameLower] || null; // Return null if ID not found
      }).filter(id => id); // Remove any null values
    }
    
    return [];
  };
  
  const handleUpload = async () => {
    setUploading(true);
    
    try {
      const authToken = await getToken();
      
      if (!authToken) {
        showNotification('Authentication error. Please sign in again.', 'error');
        setUploading(false);
        return;
      }
      
      // Transform data based on mappings
      const transformedData = parsedData.rows.map(row => {
        // Common fields for both student and teacher
        const commonFields = {
          firstName: mappings.firstName !== '' ? row[mappings.firstName] || '' : '',
          lastName: mappings.lastName !== '' ? row[mappings.lastName] || '' : '',
          email: mappings.email !== '' ? row[mappings.email] || '' : '',
          password: mappings.temporaryPassword !== '' ? row[mappings.temporaryPassword] || '' : '',
          institutionId: institutionId
        };
        
        if (userType === 'student') {
          // Student specific fields
          const studentData = {
            ...commonFields
          };
          
          if (mappings.classId !== '') {
            // Try to map class name to class ID
            const classValue = row[mappings.classId];
            if (typeof classValue === 'string' && classValue.toLowerCase().includes('class')) {
              // Extract class number from string like "Class 8" or "class 9"
              const classNumber = classValue.match(/\d+/);
              if (classNumber) {
                // Map class 8 to 1, 9 to 2, etc.
                const mappedClass = parseInt(classNumber[0]) - 7;
                if (mappedClass >= 1 && mappedClass <= 5) {
                  studentData.class = mappedClass.toString(); // Use "class" instead of "classId" for API
                }
              }
            } else if (typeof classValue === 'number') {
              // If it's already a number, assume it's the actual class ID
              studentData.class = classValue.toString();
            } else {
              studentData.class = classValue;
            }
          }
          
          if (mappings.rollNumber !== '') studentData.rollNumber = row[mappings.rollNumber] || '';
          if (mappings.parentName !== '') studentData.parentName = row[mappings.parentName] || '';
          if (mappings.parentPhone !== '') studentData.parentPhone = row[mappings.parentPhone] || '';
          
          return studentData;
        } else {
          // Teacher specific fields
          const teacherData = {
            ...commonFields,
            status: 'active', // Default to active
            assignedSubjects: []
          };
          
          // If there's a subjects column, map the names to IDs
          if (mappings.subjects !== undefined && mappings.subjects !== '') {
            const subjectsValue = row[mappings.subjects];
            if (subjectsValue) {
              teacherData.assignedSubjects = mapSubjectNamesToIds(subjectsValue);
            }
          }
          
          // If there's a designation column
          if (mappings.designation !== undefined && mappings.designation !== '') {
            teacherData.designation = row[mappings.designation] || '';
          }
          
          return teacherData;
        }
      });
      
      // Filter out rows with missing required fields
      const validData = transformedData.filter(user => 
        user.firstName && user.lastName && user.email && 
        (userType !== 'student' || user.class)
      );
      
      if (validData.length === 0) {
        showNotification('No valid users found in the data. Please check your mappings.', 'error');
        setUploading(false);
        return;
      }
      
      console.log('Sending bulk data to API:', validData);
      
      // Call API to upload users
      const endpoint = userType === 'student' 
        ? `${config.API_URL}/api/institutions/students/bulk-import` 
        : `${config.API_URL}/api/institutions/teachers/bulk-import`;
      
      // Send data directly as JSON instead of FormData
      const response = await axios.post(endpoint, 
        { users: validData }, 
        {
          headers: {
            'Authorization': `Bearer ${authToken}`,
            'Content-Type': 'application/json'
          }
        }
      );
      
      setUploadResults({
        success: true,
        message: `Successfully imported ${response.data.successful || validData.length} ${userType}s.`,
        details: response.data
      });
      
      // Refresh user list
      onImportComplete();
    } catch (error) {
      console.error(`Error uploading ${userType}s:`, error);
      setUploadResults({
        success: false,
        message: `Failed to import ${userType}s. ${error.response?.data?.message || 'Please try again.'}`,
        details: error.response?.data
      });
    } finally {
      setUploading(false);
    }
  };

  const renderStepContent = () => {
    switch (activeStep) {
      case 0:
        return (
          <Box sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              Upload Excel File
            </Typography>
            
            <Box sx={{ mb: 3 }}>
              <Button 
                variant="outlined" 
                color="primary" 
                startIcon={<DownloadIcon />}
                onClick={() => {
                  // Create template data
                  const headers = ['First Name', 'Last Name', 'Email'];
                  
                  if (userType === 'student') {
                    headers.push('Class', 'Roll Number', 'Parent Name', 'Parent Phone');
                  } else {
                    headers.push('Subjects', 'Designation');
                  }
                  
                  headers.push('Temporary Password');
                  
                  // Add sample data row
                  const sampleData = userType === 'student' 
                    ? ['John', 'Doe', 'john.doe@example.com', 'Class 8', '101', 'Parent Name', '9876543210', 'tempPass123'] 
                    : ['Jane', 'Smith', 'jane.smith@example.com', 'Physics, Chemistry', 'Senior Teacher', 'tempPass456'];
                  
                  // Create worksheet
                  const ws = XLSX.utils.aoa_to_sheet([headers, sampleData]);
                  
                  // Create workbook
                  const wb = XLSX.utils.book_new();
                  XLSX.utils.book_append_sheet(wb, ws, `${userType.charAt(0).toUpperCase() + userType.slice(1)}s Template`);
                  
                  // Generate file and trigger download
                  XLSX.writeFile(wb, `${userType}s_template.xlsx`);
                }}
              >
                Download Template
              </Button>
            </Box>
            
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: 3, border: '1px dashed #ccc', borderRadius: 1 }}>
              <input
                accept=".xlsx,.xls"
                style={{ display: 'none' }}
                id="upload-file-button"
                type="file"
                onChange={handleFileChange}
              />
              <label htmlFor="upload-file-button">
                <Button 
                  variant="contained" 
                  component="span"
                  startIcon={<UploadIcon />}
                >
                  Select File
                </Button>
              </label>
              
              {file && (
                <Typography variant="body1" sx={{ mt: 2 }}>
                  Selected file: {file.name}
                </Typography>
              )}
            </Box>
          </Box>
        );
      
      case 1:
        return (
          <Box sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              Map Columns
            </Typography>
            
            <Typography variant="body2" color="textSecondary" paragraph>
              Match the columns in your Excel file to the required fields. Fields marked with * are required.
            </Typography>
            
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
                  <InputLabel>First Name *</InputLabel>
                  <Select
                    labelId="first-name-label"
                    value={mappings.firstName}
                    onChange={(e) => handleMappingChange('firstName', e.target.value)}
                    label="First Name *"
                  >
                    <MenuItem value="">
                      <em>Not Mapped</em>
                    </MenuItem>
                    {parsedData.headers?.map((header, index) => (
                      <MenuItem key={index} value={index}>
                        {header}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              
              <Grid item xs={12} md={6}>
                <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
                  <InputLabel>Last Name *</InputLabel>
                  <Select
                    labelId="last-name-label"
                    value={mappings.lastName}
                    onChange={(e) => handleMappingChange('lastName', e.target.value)}
                    label="Last Name *"
                  >
                    <MenuItem value="">
                      <em>Not Mapped</em>
                    </MenuItem>
                    {parsedData.headers?.map((header, index) => (
                      <MenuItem key={index} value={index}>
                        {header}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              
              <Grid item xs={12} md={6}>
                <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
                  <InputLabel>Email *</InputLabel>
                  <Select
                    labelId="email-label"
                    value={mappings.email}
                    onChange={(e) => handleMappingChange('email', e.target.value)}
                    label="Email *"
                  >
                    <MenuItem value="">
                      <em>Not Mapped</em>
                    </MenuItem>
                    {parsedData.headers?.map((header, index) => (
                      <MenuItem key={index} value={index}>
                        {header}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              
              {userType === 'student' && (
                <>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
                      <InputLabel>Class *</InputLabel>
                      <Select
                        labelId="class-label"
                        value={mappings.classId}
                        onChange={(e) => handleMappingChange('classId', e.target.value)}
                        label="Class *"
                      >
                        <MenuItem value="">
                          <em>Not Mapped</em>
                        </MenuItem>
                        {parsedData.headers?.map((header, index) => (
                          <MenuItem key={index} value={index}>
                            {header}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
                      <InputLabel>Roll Number</InputLabel>
                      <Select
                        labelId="roll-number-label"
                        value={mappings.rollNumber}
                        onChange={(e) => handleMappingChange('rollNumber', e.target.value)}
                        label="Roll Number"
                      >
                        <MenuItem value="">
                          <em>Not Mapped</em>
                        </MenuItem>
                        {parsedData.headers?.map((header, index) => (
                          <MenuItem key={index} value={index}>
                            {header}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
                      <InputLabel>Parent Name</InputLabel>
                      <Select
                        labelId="parent-name-label"
                        value={mappings.parentName}
                        onChange={(e) => handleMappingChange('parentName', e.target.value)}
                        label="Parent Name"
                      >
                        <MenuItem value="">
                          <em>Not Mapped</em>
                        </MenuItem>
                        {parsedData.headers?.map((header, index) => (
                          <MenuItem key={index} value={index}>
                            {header}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
                      <InputLabel>Parent Phone</InputLabel>
                      <Select
                        labelId="parent-phone-label"
                        value={mappings.parentPhone}
                        onChange={(e) => handleMappingChange('parentPhone', e.target.value)}
                        label="Parent Phone"
                      >
                        <MenuItem value="">
                          <em>Not Mapped</em>
                        </MenuItem>
                        {parsedData.headers?.map((header, index) => (
                          <MenuItem key={index} value={index}>
                            {header}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </>
              )}
              
              <Grid item xs={12} md={6}>
                <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
                  <InputLabel>Temporary Password</InputLabel>
                  <Select
                    labelId="temporary-password-label"
                    value={mappings.temporaryPassword}
                    onChange={(e) => handleMappingChange('temporaryPassword', e.target.value)}
                    label="Temporary Password"
                  >
                    <MenuItem value="">
                      <em>Not Mapped</em>
                    </MenuItem>
                    {parsedData.headers?.map((header, index) => (
                      <MenuItem key={index} value={index}>
                        {header}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              
              {userType === 'teacher' && (
                <>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
                      <InputLabel>Subjects *</InputLabel>
                      <Select
                        labelId="subjects-label"
                        value={mappings.subjects}
                        onChange={(e) => handleMappingChange('subjects', e.target.value)}
                        label="Subjects *"
                      >
                        <MenuItem value="">
                          <em>Not Mapped</em>
                        </MenuItem>
                        {parsedData.headers?.map((header, index) => (
                          <MenuItem key={index} value={index}>
                            {header}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
                      <InputLabel>Designation</InputLabel>
                      <Select
                        labelId="designation-label"
                        value={mappings.designation}
                        onChange={(e) => handleMappingChange('designation', e.target.value)}
                        label="Designation"
                      >
                        <MenuItem value="">
                          <em>Not Mapped</em>
                        </MenuItem>
                        {parsedData.headers?.map((header, index) => (
                          <MenuItem key={index} value={index}>
                            {header}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </>
              )}
            </Grid>
          </Box>
        );
      
      case 2:
        return (
          <Box sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              Review & Import
            </Typography>
            
            {uploadResults ? (
              <Box sx={{ mt: 2 }}>
                <Alert severity={uploadResults.success ? 'success' : 'error'}>
                  {uploadResults.message}
                </Alert>
                
                {uploadResults.details?.errors?.length > 0 && (
                  <Box sx={{ mt: 2 }}>
                    <Typography variant="subtitle1">Errors:</Typography>
                    <ul>
                      {uploadResults.details.errors.map((error, index) => (
                        <li key={index}>{error}</li>
                      ))}
                    </ul>
                  </Box>
                )}
              </Box>
            ) : (
              <>
                <Typography variant="body2" paragraph>
                  You are about to import {parsedData.rows?.length || 0} {userType}s. Please review the data below before proceeding.
                </Typography>
                
                <TableContainer component={Paper} sx={{ maxHeight: 300 }}>
                  <Table stickyHeader size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>First Name</TableCell>
                        <TableCell>Last Name</TableCell>
                        <TableCell>Email</TableCell>
                        {userType === 'student' && (
                          <>
                            <TableCell>Class</TableCell>
                            <TableCell>Roll Number</TableCell>
                          </>
                        )}
                        <TableCell>Temporary Password</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {parsedData.rows?.slice(0, 10).map((row, rowIndex) => (
                        <TableRow key={rowIndex}>
                          <TableCell>{mappings.firstName !== '' ? row[mappings.firstName] : '-'}</TableCell>
                          <TableCell>{mappings.lastName !== '' ? row[mappings.lastName] : '-'}</TableCell>
                          <TableCell>{mappings.email !== '' ? row[mappings.email] : '-'}</TableCell>
                          {userType === 'student' && (
                            <>
                              <TableCell>{mappings.classId !== '' ? row[mappings.classId] : '-'}</TableCell>
                              <TableCell>{mappings.rollNumber !== '' ? row[mappings.rollNumber] : '-'}</TableCell>
                            </>
                          )}
                          <TableCell>{mappings.temporaryPassword !== '' ? row[mappings.temporaryPassword] : '-'}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                
                {parsedData.rows?.length > 10 && (
                  <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                    Showing 10 of {parsedData.rows.length} rows
                  </Typography>
                )}
                
                <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleUpload}
                    disabled={uploading}
                    startIcon={uploading ? <CircularProgress size={20} /> : null}
                  >
                    {uploading ? 'Importing...' : 'Import Users'}
                  </Button>
                </Box>
              </>
            )}
          </Box>
        );
      
      default:
        return null;
    }
  };
  
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>
        Bulk Import {userType === 'student' ? 'Students' : 'Teachers'}
      </DialogTitle>
      
      <DialogContent dividers>
        <Stepper activeStep={activeStep} sx={{ mb: 3 }}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        
        {renderStepContent()}
      </DialogContent>
      
      <DialogActions>
        <Button onClick={handleClose}>
          {uploadResults ? 'Close' : 'Cancel'}
        </Button>
        
        {!uploadResults && (
          <>
            {activeStep > 0 && (
              <Button onClick={handleBack} disabled={uploading}>
                Back
              </Button>
            )}
            
            {activeStep < steps.length - 1 && (
              <Button 
                variant="contained" 
                color="primary" 
                onClick={handleNext}
                disabled={activeStep === 0 && !file}
              >
                Next
              </Button>
            )}
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

// Add User Dialog Component
const AddUserDialog = ({ open, onClose, userType, getToken, showNotification, onUserAdded, userData, classes, institutionId }) => {
  const initialFormData = userType === 'student' 
    ? {
        firstName: '',
        lastName: '',
        email: '',
        classId: '',
        section: '',
        rollNumber: '',
        parentName: '',
        parentPhone: '',
        temporaryPassword: '',
        isActive: true
      }
    : {
        firstName: '',
        lastName: '',
        email: '',
        subjects: [],
        designation: '',
        temporaryPassword: '',
        isActive: true
      };
  
  const [formData, setFormData] = useState(userData ? {
    ...initialFormData,
    ...userData,
    // Convert array of subjects to array of IDs for editing
    subjects: userData?.assignedSubjects || []
  } : initialFormData);
  
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [availableSubjects, setAvailableSubjects] = useState([]);
  const [loadingSubjects, setLoadingSubjects] = useState(false);
  
  useEffect(() => {
    if (userData) {
      setFormData({
        ...initialFormData,
        ...userData,
        // Convert array of subjects to array of IDs for editing
        subjects: userData?.assignedSubjects || []
      });
    } else {
      setFormData(initialFormData);
    }
    
    // Fetch available subjects if this is a teacher form
    if (userType === 'teacher' && open) {
      fetchSubjects();
    }
  }, [userData, userType, open]);
  
  const fetchSubjects = async () => {
    setLoadingSubjects(true);
    try {
      const authToken = await getToken();
      
      if (!authToken) {
        showNotification('Authentication error. Please sign in again.', 'error');
        setLoadingSubjects(false);
        return;
      }
      
      const response = await axios.get(
        `${config.API_URL}/api/subjects`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`
          },
          params: {
            institutionId: institutionId
          }
        }
      );
      
      if (response.data && response.data.subjects) {
        setAvailableSubjects(response.data.subjects);
      } else {
        setAvailableSubjects([]);
      }
    } catch (error) {
      console.error('Error fetching subjects:', error);
      showNotification('Failed to load subjects. Please try again.', 'error');
    } finally {
      setLoadingSubjects(false);
    }
  };
  
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value
    });
  };
  
  const handleSubjectsChange = (event) => {
    const { value } = event.target;
    setFormData({
      ...formData,
      subjects: value
    });
  };
  
  const handleSubmit = async () => {
    // Validate form
    if (!formData.firstName || !formData.lastName || !formData.email) {
      showNotification('Please fill all required fields', 'error');
      return;
    }
    
    if (userType === 'student' && !formData.classId) {
      showNotification('Class is required for students', 'error');
      return;
    }
    
    // If creating a new user and no temporary password is provided, show a warning
    if (!userData && !formData.temporaryPassword) {
      showNotification('Warning: No temporary password provided. A random password will be generated.', 'warning');
    }
    
    setLoading(true);
    
    try {
      const authToken = await getToken();
      
      if (!authToken) {
        showNotification('Authentication error. Please sign in again.', 'error');
        setLoading(false);
        return;
      }
      
      // Prepare data for API
      let apiData = { 
        ...formData,
        institutionId: institutionId
      };
      
      // Format data based on user type
      if (userType === 'student') {
        // For student, rename classId to class for API compatibility
        apiData.class = apiData.classId;
        delete apiData.classId;
      } else if (userType === 'teacher') {
        // For teacher, format the data according to the API expectations
        apiData = {
          institutionId: institutionId,
          firstName: formData.firstName,
          lastName: formData.lastName,
          email: formData.email,
          password: formData.temporaryPassword || undefined, // Use temporaryPassword as password
          assignedSubjects: formData.subjects, // Send subject IDs directly
          status: formData.isActive ? 'active' : 'inactive',
          designation: formData.designation || undefined
        };
      }
      
      // Handle API endpoints based on user type and mode (create/edit)
      const endpoint = userType === 'student'
        ? userData 
          ? `${config.API_URL}/api/institutions/students/${userData._id}`
          : `${config.API_URL}/api/institutions/students`
        : userData
          ? `${config.API_URL}/api/institutions/teachers/${userData._id}`
          : `${config.API_URL}/api/institutions/teachers`;
      
      const method = userData ? 'put' : 'post';
      
      console.log('Sending data to API:', apiData);
      
      const response = await axios({
        method,
        url: endpoint,
        data: apiData,
        headers: {
          'Authorization': `Bearer ${authToken}`,
          'Content-Type': 'application/json'
        }
      });
      
      showNotification(
        `${userData ? 'Updated' : 'Created'} ${userType} successfully!`, 
        'success'
      );
      
      onClose();
      onUserAdded();
    } catch (error) {
      console.error(`Error ${userData ? 'updating' : 'creating'} ${userType}:`, error);
      showNotification(
        `Failed to ${userData ? 'update' : 'create'} ${userType}: ${error.response?.data?.message || 'Unknown error'}`, 
        'error'
      );
    } finally {
      setLoading(false);
    }
  };
  
  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  
  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>
        {userData ? 'Edit' : 'Add'} {userType === 'student' ? 'Student' : 'Teacher'}
      </DialogTitle>
      
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="First Name"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              required
              margin="normal"
            />
          </Grid>
          
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Last Name"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              required
              margin="normal"
            />
          </Grid>
          
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Email"
              name="email"
              type="email"
              value={formData.email}
              onChange={handleChange}
              required
              margin="normal"
            />
          </Grid>
          
          {userType === 'student' ? (
            <>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth margin="normal">
                  <InputLabel id="class-select-label">Class</InputLabel>
                  <Select
                    labelId="class-select-label"
                    name="classId"
                    value={formData.classId}
                    onChange={handleChange}
                    label="Class"
                    required
                  >
                    <MenuItem value="">
                      <em>Select Class</em>
                    </MenuItem>
                    {classes.map((cls) => (
                      <MenuItem key={cls.id} value={cls.id}>
                        {cls.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Section"
                  name="section"
                  value={formData.section}
                  onChange={handleChange}
                  margin="normal"
                />
              </Grid>
              
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Roll Number"
                  name="rollNumber"
                  value={formData.rollNumber}
                  onChange={handleChange}
                  margin="normal"
                />
              </Grid>
              
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Parent Name"
                  name="parentName"
                  value={formData.parentName}
                  onChange={handleChange}
                  margin="normal"
                />
              </Grid>
              
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Parent Phone"
                  name="parentPhone"
                  value={formData.parentPhone}
                  onChange={handleChange}
                  margin="normal"
                />
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12}>
                <FormControl fullWidth margin="normal">
                  <InputLabel id="subjects-select-label">Assigned Subjects</InputLabel>
                  <Select
                    labelId="subjects-select-label"
                    multiple
                    value={formData.subjects}
                    onChange={handleSubjectsChange}
                    name="subjects"
                    label="Assigned Subjects"
                    renderValue={(selected) => (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((subjectId) => {
                          const subject = availableSubjects.find(s => s._id === subjectId);
                          return (
                            <Chip 
                              key={subjectId} 
                              label={subject ? subject.name : subjectId} 
                              size="small" 
                            />
                          );
                        })}
                      </Box>
                    )}
                  >
                    {loadingSubjects ? (
                      <MenuItem disabled>
                        <CircularProgress size={20} sx={{ mr: 1 }} /> Loading subjects...
                      </MenuItem>
                    ) : availableSubjects.length === 0 ? (
                      <MenuItem disabled>No subjects available</MenuItem>
                    ) : (
                      availableSubjects.map((subject) => (
                        <MenuItem key={subject._id} value={subject._id}>
                          {subject.name}
                        </MenuItem>
                      ))
                    )}
                  </Select>
                </FormControl>
              </Grid>
              
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Designation"
                  name="designation"
                  value={formData.designation}
                  onChange={handleChange}
                  margin="normal"
                />
              </Grid>
            </>
          )}
          
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Temporary Password"
              name="temporaryPassword"
              type={showPassword ? "text" : "password"}
              value={formData.temporaryPassword}
              onChange={handleChange}
              margin="normal"
              helperText="Leave blank to auto-generate a password. Note: No notifications will be sent."
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleTogglePasswordVisibility}
                      edge="end"
                    >
                      {showPassword ? <CloseIcon /> : <CheckIcon />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.isActive}
                  onChange={handleChange}
                  name="isActive"
                  color="primary"
                />
              }
              label="Active"
            />
          </Grid>
        </Grid>
      </DialogContent>
      
      <DialogActions>
        <Button onClick={onClose} color="inherit">
          Cancel
        </Button>
        <Button 
          variant="contained" 
          color="primary" 
          onClick={handleSubmit}
          disabled={loading || !formData.firstName || !formData.lastName || !formData.email || (userType === 'student' && !formData.classId)}
        >
          {loading ? (
            <CircularProgress size={24} />
          ) : (
            userData ? 'Update' : 'Add'
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UserManagement;
