import axios from 'axios';
import config from '../config';

const API = {
  // Authentication methods
  auth: {
    login: async (credentials) => {
      return axios.post(`${config.API_URL}/api/signin`, credentials);
    },
    signUp: async (userData) => {
      return axios.post(`${config.API_URL}/api/signup`, userData);
    }
  },
  
  // Institution methods
  institution: {
    // Student management
    students: {
      getAll: async (token, params = {}) => {
        return axios.get(`${config.API_URL}/api/institutions/students`, {
          headers: { Authorization: `Bearer ${token}` },
          params: { 
            institutionId: params.institutionId,
            page: params.page || 1,
            limit: params.limit || 10,
            search: params.search || '',
            classId: params.classId || ''
          }
        });
      },
      getById: async (token, id, institutionId) => {
        return axios.get(`${config.API_URL}/api/institutions/students/${id}`, {
          headers: { Authorization: `Bearer ${token}` },
          params: { institutionId }
        });
      },
      create: async (token, data) => {
        return axios.post(`${config.API_URL}/api/institutions/students`, data, {
          headers: { Authorization: `Bearer ${token}` }
        });
      },
      update: async (token, id, data) => {
        return axios.put(`${config.API_URL}/api/institutions/students/${id}`, data, {
          headers: { Authorization: `Bearer ${token}` }
        });
      },
      delete: async (token, id) => {
        return axios.delete(`${config.API_URL}/api/institutions/students/${id}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
      },
      bulkUpload: async (token, formData) => {
        return axios.post(`${config.API_URL}/api/institutions/students/bulk-upload`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data'
          }
        });
      }
    },
    
    // Teacher management
    teachers: {
      getAll: async (token, params = {}) => {
        return axios.get(`${config.API_URL}/api/institutions/teachers`, {
          headers: { Authorization: `Bearer ${token}` },
          params: { 
            institutionId: params.institutionId,
            page: params.page || 1,
            limit: params.limit || 10,
            search: params.search || '',
            subjectId: params.subjectId || ''
          }
        });
      },
      getById: async (token, id, institutionId) => {
        return axios.get(`${config.API_URL}/api/institutions/teachers/${id}`, {
          headers: { Authorization: `Bearer ${token}` },
          params: { institutionId }
        });
      },
      create: async (token, data) => {
        return axios.post(`${config.API_URL}/api/institutions/teachers`, data, {
          headers: { Authorization: `Bearer ${token}` }
        });
      },
      update: async (token, id, data) => {
        return axios.put(`${config.API_URL}/api/institutions/teachers/${id}`, data, {
          headers: { Authorization: `Bearer ${token}` }
        });
      },
      delete: async (token, id) => {
        return axios.delete(`${config.API_URL}/api/institutions/teachers/${id}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
      }
    },
    
    // Subject management
    subjects: {
      getAll: async (token, params = {}) => {
        return axios.get(`${config.API_URL}/api/institutions/subjects`, {
          headers: { Authorization: `Bearer ${token}` },
          params: { 
            institutionId: params.institutionId,
            page: params.page || 1,
            limit: params.limit || 10,
            search: params.search || '',
            classId: params.classId || ''
          }
        });
      },
      getById: async (token, id) => {
        return axios.get(`${config.API_URL}/api/institutions/subjects/${id}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
      },
      create: async (token, data) => {
        return axios.post(`${config.API_URL}/api/institutions/subjects`, data, {
          headers: { Authorization: `Bearer ${token}` }
        });
      },
      update: async (token, id, data) => {
        return axios.put(`${config.API_URL}/api/institutions/subjects/${id}`, data, {
          headers: { Authorization: `Bearer ${token}` }
        });
      },
      delete: async (token, id) => {
        return axios.delete(`${config.API_URL}/api/institutions/subjects/${id}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
      },
      
      topics: {
        getAll: async (token, subjectId, params = {}) => {
          return axios.get(`${config.API_URL}/api/institutions/subjects/${subjectId}/topics`, {
            headers: { Authorization: `Bearer ${token}` },
            params: { 
              page: params.page || 1,
              limit: params.limit || 10,
              search: params.search || ''
            }
          });
        },
        getById: async (token, subjectId, topicId) => {
          return axios.get(`${config.API_URL}/api/institutions/subjects/${subjectId}/topics/${topicId}`, {
            headers: { Authorization: `Bearer ${token}` }
          });
        },
        create: async (token, subjectId, data) => {
          return axios.post(`${config.API_URL}/api/institutions/subjects/${subjectId}/topics`, data, {
            headers: { Authorization: `Bearer ${token}` }
          });
        },
        update: async (token, subjectId, topicId, data) => {
          return axios.put(`${config.API_URL}/api/institutions/subjects/${subjectId}/topics/${topicId}`, data, {
            headers: { Authorization: `Bearer ${token}` }
          });
        },
        delete: async (token, subjectId, topicId) => {
          return axios.delete(`${config.API_URL}/api/institutions/subjects/${subjectId}/topics/${topicId}`, {
            headers: { Authorization: `Bearer ${token}` }
          });
        }
      }
    },
    
    // MCQ management
    mcqs: {
      getAll: async (token, params = {}) => {
        return axios.get(`${config.API_URL}/api/institutions/mcqs`, {
          headers: { Authorization: `Bearer ${token}` },
          params: {
            institutionId: params.institutionId,
            page: params.page || 1,
            limit: params.limit || 10,
            search: params.search || '',
            subjectId: params.subjectId || '',
            classId: params.classId || '',
            difficulty: params.difficulty || ''
          }
        });
      },
      getById: async (token, id) => {
        return axios.get(`${config.API_URL}/api/institutions/mcqs/${id}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
      },
      create: async (token, data) => {
        return axios.post(`${config.API_URL}/api/institutions/mcqs`, data, {
          headers: { Authorization: `Bearer ${token}` }
        });
      },
      update: async (token, id, data) => {
        return axios.put(`${config.API_URL}/api/institutions/mcqs/${id}`, data, {
          headers: { Authorization: `Bearer ${token}` }
        });
      },
      delete: async (token, id) => {
        return axios.delete(`${config.API_URL}/api/institutions/mcqs/${id}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
      },
      bulkUpload: async (token, formData) => {
        return axios.post(`${config.API_URL}/api/institutions/mcqs/bulk-upload`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data'
          }
        });
      },
      getBySubject: async (token, subjectId, params = {}) => {
        return axios.get(`${config.API_URL}/api/institutions/subjects/${subjectId}/mcqs`, {
          headers: { Authorization: `Bearer ${token}` },
          params: {
            page: params.page || 1,
            limit: params.limit || 10,
            search: params.search || ''
          }
        });
      },
      getByTopic: async (token, subjectId, topicId, params = {}) => {
        return axios.get(`${config.API_URL}/api/institutions/subjects/${subjectId}/topics/${topicId}/mcqs`, {
          headers: { Authorization: `Bearer ${token}` },
          params: {
            page: params.page || 1,
            limit: params.limit || 10,
            search: params.search || ''
          }
        });
      },
      exportTemplate: async (token) => {
        return axios.get(`${config.API_URL}/api/institutions/mcqs/export-template`, {
          headers: { Authorization: `Bearer ${token}` },
          responseType: 'blob'
        });
      }
    },
    
    // Test management
    tests: {
      getAll: async (token, params = {}) => {
        return axios.get(`${config.API_URL}/api/institutions/tests`, {
          headers: { Authorization: `Bearer ${token}` },
          params: {
            institutionId: params.institutionId,
            page: params.page || 1,
            limit: params.limit || 10,
            search: params.search || '',
            subjectId: params.subjectId || '',
            classId: params.classId || ''
          }
        });
      }
    },
    
    // Analytics
    analytics: {
      getDashboard: async (token, institutionId) => {
        return axios.get(`${config.API_URL}/api/institutions/analytics/dashboard`, {
          headers: { Authorization: `Bearer ${token}` },
          params: { institutionId }
        });
      },
      getStudentPerformance: async (token, params = {}) => {
        return axios.get(`${config.API_URL}/api/institutions/analytics/students/performance`, {
          headers: { Authorization: `Bearer ${token}` },
          params: {
            institutionId: params.institutionId,
            classId: params.classId || '',
            limit: params.limit || 10
          }
        });
      },
      getSubjectPopularity: async (token, institutionId) => {
        return axios.get(`${config.API_URL}/api/institutions/analytics/subjects/popularity`, {
          headers: { Authorization: `Bearer ${token}` },
          params: { institutionId }
        });
      },
      getTestPerformance: async (token, institutionId) => {
        return axios.get(`${config.API_URL}/api/institutions/analytics/tests/performance`, {
          headers: { Authorization: `Bearer ${token}` },
          params: { institutionId }
        });
      },
      getClassPerformance: async (token, institutionId) => {
        return axios.get(`${config.API_URL}/api/institutions/analytics/classes/performance`, {
          headers: { Authorization: `Bearer ${token}` },
          params: { institutionId }
        });
      }
    },
    
    // Configuration services
    config: {
      getClasses: async (token) => {
        return axios.get(`${config.API_URL}/api/config/classes`, {
          headers: { Authorization: `Bearer ${token}` }
        });
      }
    }
  },
  
  // Configuration methods
  config: {
    getClasses: async (token) => {
      return axios.get(`${config.API_URL}/api/config/classes`, {
        headers: { Authorization: `Bearer ${token}` }
      });
    }
  }
};

export default API;
