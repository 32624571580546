import React from "react";
import "./style.css";
import ChatLayout from "../../components/chatLayout";
import { useAuth } from "../../services/authContext";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";

const ChatPage = () => {
    const { isSignedIn, signOut } = useAuth();
    return (
        <div className="rootLayout">
            <header className="rootLayoutheader">
                <Link to="/" className="logo">
                    <HospitalIcon className="icon" />
                    <span>Neetly AI</span>
                </Link>
                {!isSignedIn ? (
                    <Link to="/sign-in" style={{ textDecoration: 'none' }}>
                        <Button variant="contained" color="primary">
                            Sign In
                        </Button>
                    </Link>
                ) : (
                    <div className="user">
                        <Button variant="contained" color="primary" onClick={signOut}>
                            Sign Out
                        </Button>
                    </div>
                )}
            </header>
            <main>
                <ChatLayout />
            </main>
        </div>
    );
};

function HospitalIcon(props) {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path d="M12 7v10M9 12h6" />
            <path d="M5 22V2h14v20Z" />
        </svg>
    );
}

export default ChatPage;
