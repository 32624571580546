import axios from "axios";
import config from "../config";


const API_URL = config.API_BASE_URL || "http://localhost:2004"; // Fallback to Vercel URL if config not available

export const getText = async (topic,chatId=null,token) => {
    try {
        let payload = Array.isArray(topic) ? { data: topic } : { topic };
        console.log("******************");
        console.log(topic);
        if(chatId){
         payload.chatId=chatId;
        }
        const response = await axios.post(`${API_URL}/api/generateText`, { payload }, {
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          });

        if (response.status === 200) {
             return typeof response.data === 'string' ? response.data: response.data.results;
        } else {
            console.error("Error fetching Text");
        }
    } catch (error) {
        console.error("Error fetching MCQs:", error);
        throw error;
    }
};
