import React, { useState } from 'react';
import { themeColors, shadows, spacing, borderRadius } from '../theme';
import { useAuth } from '../../../services/authContext';

/**
 * Revamped TopBar component with responsive design and modern styling
 */
const TopBar = ({ title = "Neetly AI", onMenuToggle, userInfo }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { signOut } = useAuth();

  // Toggle dropdown menu
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <header
      style={{
        backgroundColor: themeColors.background.navbar,
        color: themeColors.primary.contrastText,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: `${spacing(1.5)} ${spacing(3)}`,
        height: '64px',
        boxShadow: shadows.md,
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 1100,
      }}
    >
      {/* Left section with menu toggle and logo */}
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {/* Hamburger menu icon for smaller screens */}
        <button
          onClick={onMenuToggle}
          style={{
            background: 'none',
            border: 'none',
            color: 'white',
            fontSize: '24px',
            cursor: 'pointer',
            marginRight: spacing(2),
            padding: spacing(1),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '@media (min-width: 960px)': {
              display: 'none',
            }
          }}
          aria-label="Toggle navigation menu"
        >
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3 18H21V16H3V18ZM3 13H21V11H3V13ZM3 6V8H21V6H3Z" fill="currentColor"/>
          </svg>
        </button>

        {/* Logo and app title */}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="28" height="28" rx="4" fill="white" fillOpacity="0.2" />
            <path d="M14 6C9.58 6 6 9.58 6 14C6 18.42 9.58 22 14 22C18.42 22 22 18.42 22 14C22 9.58 18.42 6 14 6ZM15.5 19L10.5 14L15.5 9L17 10.5L13.5 14L17 17.5L15.5 19Z" fill="white" />
          </svg>
          <h1 style={{ 
            fontSize: '1.25rem', 
            fontWeight: 500, 
            margin: 0, 
            marginLeft: spacing(1.5) 
          }}>
            {title}
          </h1>
        </div>
      </div>

      {/* Center section - Can be used for search or other elements */}
      <div style={{ display: 'flex', alignItems: 'center', flex: 1, justifyContent: 'center' }}>
        {/* Active mode button - e.g. "Case Study" */}
        <button
          style={{
            backgroundColor: 'rgba(255, 255, 255, 0.2)',
            color: 'white',
            border: 'none',
            borderRadius: borderRadius.md,
            padding: `${spacing(0.75)} ${spacing(2)}`,
            fontWeight: 500,
            cursor: 'pointer',
            transition: 'background-color 0.2s',
            fontSize: '0.875rem',
          }}
        >
          Case Study
        </button>
      </div>

      {/* Right section with user info and actions */}
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {/* User profile section */}
        <div style={{ position: 'relative' }}>
          <button
            style={{
              display: 'flex',
              alignItems: 'center',
              background: 'none',
              border: 'none',
              color: 'white',
              cursor: 'pointer',
              padding: spacing(1),
            }}
            onClick={toggleDropdown}
            aria-label="Open user menu"
          >
            {/* User avatar - circular with initials or image */}
            <div
              style={{
                width: '36px',
                height: '36px',
                borderRadius: '50%',
                background: 'rgba(255, 255, 255, 0.2)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontWeight: 500,
                color: 'white',
                marginRight: spacing(1),
              }}
            >
              {userInfo?.initial || 'H'}
            </div>
            
            {/* Dropdown arrow icon */}
            <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7 10L12 15L17 10H7Z" fill="currentColor"/>
            </svg>
          </button>

          {/* Dropdown menu */}
          {dropdownOpen && (
            <div
              style={{
                position: 'absolute',
                top: '56px',
                right: 0,
                width: '200px',
                backgroundColor: themeColors.background.paper,
                borderRadius: borderRadius.md,
                boxShadow: shadows.lg,
                zIndex: 1200,
                overflow: 'hidden',
              }}
            >
              <div
                style={{
                  padding: spacing(2),
                  borderBottom: `1px solid ${themeColors.neutral.grey[200]}`,
                }}
              >
                <p style={{ margin: 0, fontWeight: 500, color: themeColors.text.primary }}>{userInfo?.name || 'Hrushikesh Chavare'}</p>
                <p style={{ margin: 0, fontSize: '0.875rem', color: themeColors.text.secondary }}>{userInfo?.email || 'hrushi@example.com'}</p>
              </div>
              <div>
                <button
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                    padding: `${spacing(1.5)} ${spacing(2)}`,
                    backgroundColor: 'transparent',
                    border: 'none',
                    textAlign: 'left',
                    cursor: 'pointer',
                    color: themeColors.text.primary,
                    fontSize: '0.875rem',
                    transition: 'background-color 0.2s',
                  }}
                  onMouseOver={(e) => e.currentTarget.style.backgroundColor = themeColors.neutral.grey[100]}
                  onMouseOut={(e) => e.currentTarget.style.backgroundColor = 'transparent'}
                >
                  <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginRight: spacing(1.5) }}>
                    <path d="M12 12C14.21 12 16 10.21 16 8C16 5.79 14.21 4 12 4C9.79 4 8 5.79 8 8C8 10.21 9.79 12 12 12ZM12 14C9.33 14 4 15.34 4 18V20H20V18C20 15.34 14.67 14 12 14Z" fill="currentColor" />
                  </svg>
                  Profile
                </button>
                <button
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                    padding: `${spacing(1.5)} ${spacing(2)}`,
                    backgroundColor: 'transparent',
                    border: 'none',
                    textAlign: 'left',
                    cursor: 'pointer',
                    color: themeColors.text.primary,
                    fontSize: '0.875rem',
                    transition: 'background-color 0.2s',
                  }}
                  onMouseOver={(e) => e.currentTarget.style.backgroundColor = themeColors.neutral.grey[100]}
                  onMouseOut={(e) => e.currentTarget.style.backgroundColor = 'transparent'}
                >
                  <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginRight: spacing(1.5) }}>
                    <path d="M19.14 12.94C19.18 12.64 19.2 12.33 19.2 12C19.2 11.68 19.18 11.36 19.13 11.06L21.16 9.48C21.34 9.34 21.39 9.07 21.28 8.87L19.36 5.55C19.24 5.33 18.99 5.26 18.77 5.33L16.38 6.29C15.88 5.91 15.35 5.59 14.76 5.35L14.4 2.81C14.36 2.57 14.16 2.4 13.92 2.4H10.08C9.84 2.4 9.65 2.57 9.61 2.81L9.25 5.35C8.66 5.59 8.12 5.92 7.63 6.29L5.24 5.33C5.02 5.25 4.77 5.33 4.65 5.55L2.74 8.87C2.62 9.08 2.66 9.34 2.86 9.48L4.89 11.06C4.84 11.36 4.8 11.69 4.8 12C4.8 12.31 4.82 12.64 4.87 12.94L2.84 14.52C2.66 14.66 2.61 14.93 2.72 15.13L4.64 18.45C4.76 18.67 5.01 18.74 5.23 18.67L7.62 17.71C8.12 18.09 8.65 18.41 9.24 18.65L9.6 21.19C9.65 21.43 9.84 21.6 10.08 21.6H13.92C14.16 21.6 14.36 21.43 14.39 21.19L14.75 18.65C15.34 18.41 15.88 18.09 16.37 17.71L18.76 18.67C18.98 18.75 19.23 18.67 19.35 18.45L21.27 15.13C21.39 14.91 21.34 14.66 21.15 14.52L19.14 12.94ZM12 15.6C10.02 15.6 8.4 13.98 8.4 12C8.4 10.02 10.02 8.4 12 8.4C13.98 8.4 15.6 10.02 15.6 12C15.6 13.98 13.98 15.6 12 15.6Z" fill="currentColor" />
                  </svg>
                  Settings
                </button>
                <button
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                    padding: `${spacing(1.5)} ${spacing(2)}`,
                    backgroundColor: 'transparent',
                    border: 'none',
                    textAlign: 'left',
                    cursor: 'pointer',
                    color: themeColors.status.error,
                    fontSize: '0.875rem',
                    transition: 'background-color 0.2s',
                  }}
                  onMouseOver={(e) => e.currentTarget.style.backgroundColor = themeColors.neutral.grey[100]}
                  onMouseOut={(e) => e.currentTarget.style.backgroundColor = 'transparent'}
                  onClick={async () => {
                    await signOut();
                    window.location.href = '/sign-in';
                  }}
                >
                  <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginRight: spacing(1.5) }}>
                    <path d="M17 7L15.59 8.41L18.17 11H8V13H18.17L15.59 15.58L17 17L22 12L17 7ZM4 5H12V3H4C2.9 3 2 3.9 2 5V19C2 20.1 2.9 21 4 21H12V19H4V5Z" fill="currentColor" />
                  </svg>
                  Sign Out
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

export default TopBar;
