import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useAuth } from '../../services/authContext';
import config from '../../config';
import './style.css';
import MaterialSuperAdminDashboard from './MaterialSuperAdminDashboard';

const SuperAdminDashboard = () => {
  return <MaterialSuperAdminDashboard />;
};

export default SuperAdminDashboard;