import React, { useState, useEffect, useRef } from 'react';
import { themeColors, spacing, borderRadius, shadows } from '../theme';
import diagramService from '../../../services/diagramService';

/**
 * Component for rendering dynamic SVG diagrams for MCQs
 */
const DiagramRenderer = ({ 
  mcqId, 
  templateId, 
  parameters, 
  svg,
  width = 500, 
  height = 400,
  className,
  style = {}
}) => {
  const [renderedSvg, setRenderedSvg] = useState(svg || '');
  const [loading, setLoading] = useState(!svg);
  const [error, setError] = useState(null);
  const svgContainerRef = useRef(null);

  useEffect(() => {
    // If SVG is already provided, use it
    if (svg) {
      setRenderedSvg(svg);
      setLoading(false);
      return;
    }

    // If mcqId is provided, fetch the diagram for this MCQ
    if (mcqId) {
      fetchMCQDiagram();
    } 
    // If templateId and parameters are provided, render the diagram
    else if (templateId && parameters) {
      renderDiagram();
    }
  }, [mcqId, templateId, parameters, svg]);

  /**
   * Fetch diagram for a specific MCQ
   */
  const fetchMCQDiagram = async () => {
    try {
      setLoading(true);
      setError(null);
      
      const response = await diagramService.getMCQDiagram(mcqId);
      
      if (response.success) {
        setRenderedSvg(response.data.svg);
      } else {
        setError('Failed to load diagram');
      }
    } catch (err) {
      console.error('Error fetching MCQ diagram:', err);
      setError('Error loading diagram');
    } finally {
      setLoading(false);
    }
  };

  /**
   * Render a diagram with provided template and parameters
   */
  const renderDiagram = async () => {
    try {
      setLoading(true);
      setError(null);
      
      const response = await diagramService.renderDiagram(templateId, parameters);
      
      if (response.success) {
        setRenderedSvg(response.data.svg);
      } else {
        setError('Failed to render diagram');
      }
    } catch (err) {
      console.error('Error rendering diagram:', err);
      setError('Error rendering diagram');
    } finally {
      setLoading(false);
    }
  };

  /**
   * Render the SVG content safely
   */
  const renderSvgContent = () => {
    if (loading) {
      return (
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '100%',
          minHeight: '200px'
        }}>
          <div style={{
            width: '40px',
            height: '40px',
            border: `3px solid ${themeColors.primary.light}`,
            borderTopColor: themeColors.primary.main,
            borderRadius: '50%',
            animation: 'spin 1s linear infinite'
          }} />
          <style jsx>{`
            @keyframes spin {
              0% { transform: rotate(0deg); }
              100% { transform: rotate(360deg); }
            }
          `}</style>
        </div>
      );
    }

    if (error) {
      return (
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '100%',
          minHeight: '200px',
          color: themeColors.status.error
        }}>
          <svg width="40" height="40" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 100-16 8 8 0 000 16zm-1-5h2v2h-2v-2zm0-8h2v6h-2V7z" 
                  fill={themeColors.status.error} />
          </svg>
          <p style={{ marginTop: spacing(1) }}>{error}</p>
        </div>
      );
    }

    // Sanitize SVG content (basic approach)
    const sanitizedSvg = renderedSvg
      .replace(/on\w+=/g, '') // Remove event handlers
      .replace(/javascript:/g, ''); // Remove javascript: URLs

    return (
      <div
        ref={svgContainerRef}
        style={{ width: '100%', height: '100%' }}
        dangerouslySetInnerHTML={{ __html: sanitizedSvg }}
      />
    );
  };

  return (
    <div 
      className={className}
      style={{
        width: width,
        height: height,
        backgroundColor: themeColors.background.paper,
        borderRadius: borderRadius.md,
        boxShadow: shadows.sm,
        overflow: 'hidden',
        padding: spacing(2),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        ...style
      }}
    >
      {renderSvgContent()}
    </div>
  );
};

export default DiagramRenderer;
