import React, { useState, useEffect, useMemo } from 'react';
import { 
  Box, 
  Typography, 
  Card, 
  CardContent, 
  CardActions,
  Grid, 
  Button, 
  Chip,
  CircularProgress,
  Divider,
  useTheme,
  ToggleButtonGroup,
  ToggleButton,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemSecondaryAction,
  Skeleton,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  InputAdornment,
  IconButton,
  Tooltip
} from '@mui/material';
import { 
  AccessTime as AccessTimeIcon,
  Assignment as AssignmentIcon,
  CalendarToday as CalendarTodayIcon,
  CheckCircle as CheckCircleIcon,
  HourglassEmpty as HourglassEmptyIcon,
  PlayArrow as PlayArrowIcon,
  ViewModule as ViewModuleIcon,
  ViewList as ViewListIcon,
  Block as BlockIcon,
  Sort as SortIcon
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import testService from '../../../services/testService';
import { formatDate, formatDuration } from '../../../utils/dateUtils';
import { useAuth } from '../../../services/authContext';

const TestList = () => {
  const [tests, setTests] = useState([]);
  const [statusFilter, setStatusFilter] = useState('all');
  const [viewMode, setViewMode] = useState('grid');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sortBy, setSortBy] = useState('date-desc'); 
  const navigate = useNavigate();
  const theme = useTheme();
  const { user } = useAuth();

  useEffect(() => {
    const fetchTests = async () => {
      try {
        setLoading(true);
        
        // Get user data from localStorage as fallback if needed
        let userData = user;
        if (!userData) {
          const storedUser = localStorage.getItem('user');
          if (storedUser) {
            userData = JSON.parse(storedUser);
          }
        }
        
        // Fetch tests from API
        const response = await testService.getTests();
        
        // Check if the response has the expected structure
        if (response.success) {
          console.log('Tests fetched successfully:', response.tests);
          
          // Map the response to include status
          const testsWithStatus = response.tests.map(test => {
            // If the API already provides a status, use it
            if (test.status) {
              return test;
            }
            
            // Otherwise, calculate status based on dates
            const now = new Date();
            const startTime = new Date(test.startTime);
            const endTime = new Date(test.endTime);
            
            let status = 'upcoming';
            if (now >= startTime && now <= endTime) {
              status = 'available';
            } else if (now > endTime) {
              status = test.completed ? 'completed' : 'expired';
            }
            
            return {
              ...test,
              status
            };
          });
          
          setTests(testsWithStatus);
        } else {
          console.error('Failed to fetch tests:', response);
          setError('Failed to load tests. Please try again later.');
        }
      } catch (err) {
        console.error('Error fetching tests:', err);
        setError('Failed to load tests. Please try again later.');
      } finally {
        setLoading(false);
      }
    };
    
    fetchTests();
  }, [user]);
  
  const filteredTests = useMemo(() => {
    if (statusFilter === 'all') {
      return tests;
    }
    // Case-insensitive status comparison
    return tests.filter(test => 
      test.status && test.status.toLowerCase() === statusFilter.toLowerCase()
    );
  }, [tests, statusFilter]);
  
  const sortedTests = useMemo(() => {
    const testsToSort = [...filteredTests];
    
    switch (sortBy) {
      case 'title-asc':
        return testsToSort.sort((a, b) => a.title.localeCompare(b.title));
      case 'title-desc':
        return testsToSort.sort((a, b) => b.title.localeCompare(a.title));
      case 'date-asc':
        return testsToSort.sort((a, b) => new Date(a.startTime) - new Date(b.startTime));
      case 'date-desc':
        return testsToSort.sort((a, b) => new Date(b.startTime) - new Date(a.startTime));
      default:
        return testsToSort;
    }
  }, [filteredTests, sortBy]);

  const getStatusChip = (status, score, percentage) => {
    let color = 'primary'; 
    let label = status.charAt(0).toUpperCase() + status.slice(1);
    let icon = null;

    if (status === 'completed') {
      icon = <CheckCircleIcon fontSize="small" />;
      if (score !== undefined || percentage !== undefined) {
        label = `${label} ${percentage !== undefined ? percentage + '%' : score}`;
      }
    } else if (status === 'available') {
      icon = <PlayArrowIcon fontSize="small" />;
    } else if (status === 'upcoming') {
      icon = <AccessTimeIcon fontSize="small" />;
    } else if (status === 'expired') {
      color = 'error';
      icon = <BlockIcon fontSize="small" />;
    }

    return (
      <Chip
        icon={icon}
        size="small"
        color={color}
        label={label}
        sx={{ 
          fontWeight: 500,
          textTransform: 'capitalize',
          '& .MuiChip-icon': { ml: 0.5 }
        }}
      />
    );
  };

  const handleViewModeChange = (event, newViewMode) => {
    if (newViewMode !== null) {
      setViewMode(newViewMode);
    }
  };

  const handleStatusFilterChange = (status) => {
    setStatusFilter(status);
  };

  const handleSortChange = (event) => {
    setSortBy(event.target.value);
  };

  const handleStartTest = (testId) => {
    navigate(`/ui-revamp/tests/${testId}`);
  };

  const handleViewResults = (testId) => {
    navigate(`/ui-revamp/tests/${testId}/results`);
  };

  if (loading) {
    return (
      <Box sx={{ p: 3 }}>
        {/* Skeleton for Filters/View Toggle */}
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
          <Box sx={{ display: 'flex', gap: 1 }}>
            {[...Array(5)].map((_, i) => <Skeleton key={i} variant="rounded" width={80} height={32} />)}
          </Box>
          <Skeleton variant="rounded" width={80} height={36} />
        </Box>

        {/* Skeleton for Grid View */}
        <Grid container spacing={3}>
          {[...Array(3)].map((_, i) => (
            <Grid item xs={12} md={6} lg={4} key={i}>
              <Card elevation={3}>
                <CardContent>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                    <Skeleton variant="text" width="60%" height={28} />
                    <Skeleton variant="rounded" width="80px" height={24} />
                  </Box>
                  <Skeleton variant="text" width="40%" height={20} sx={{ mb: 1.5 }} />
                  <Divider sx={{ mb: 1.5 }} />
                  <Skeleton variant="text" width="80%" height={20} />
                  <Skeleton variant="text" width="70%" height={20} />
                  <Skeleton variant="text" width="90%" height={20} sx={{ mb: 2 }} />
                  <Skeleton variant="rounded" width="100%" height={40} />
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    );
  }

  if (error) {
    return (
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="50vh">
        <Typography color="error" variant="h6">Oops! Something went wrong.</Typography>
        <Typography color="textSecondary">{error}</Typography>
      </Box>
    );
  }

  const NoTestsMessage = ({ filter }) => (
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="40vh" textAlign="center" sx={{ p: 2 }}>
      <AssignmentIcon sx={{ fontSize: 60, color: 'grey.400', mb: 2 }} />
      <Typography variant="h6" color="textSecondary" gutterBottom>
        {filter === 'all' ? 'No Tests Available Yet' : `No tests match the filter "${filter}"`}
      </Typography>
      <Typography variant="body1" color="textSecondary">
        {filter === 'all' ? 'Check back later for assigned tests.' : 'Try selecting a different filter.'}
      </Typography>
    </Box>
  );

  return (
    <Box sx={{ p: 3 }}>
      {/* Filter, Sort and View Controls */}
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center', 
        mb: 3, 
        flexWrap: 'wrap', 
        gap: 2,
        borderBottom: `1px solid ${theme.palette.divider}`,
        pb: 2
      }}>
        {/* Status Filter */}
        <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
          {['all', 'upcoming', 'available', 'completed', 'expired'].map((status) => (
            <Chip
              key={status}
              label={status.charAt(0).toUpperCase() + status.slice(1)}
              color={statusFilter === status ? 'primary' : 'default'}
              onClick={() => handleStatusFilterChange(status)}
              variant={statusFilter === status ? 'filled' : 'outlined'}
              sx={{ 
                fontWeight: statusFilter === status ? 600 : 400,
                '&:hover': {
                  backgroundColor: statusFilter === status ? theme.palette.primary.main : theme.palette.action.hover
                }
              }}
            />
          ))}
        </Box>

        {/* Sort and View Controls */}
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          {/* Sort Dropdown */}
          <FormControl size="small" sx={{ minWidth: 180 }}>
            <InputLabel id="sort-select-label">Sort By</InputLabel>
            <Select
              labelId="sort-select-label"
              value={sortBy}
              label="Sort By"
              onChange={handleSortChange}
              startAdornment={
                <InputAdornment position="start">
                  <SortIcon fontSize="small" />
                </InputAdornment>
              }
            >
              <MenuItem value="date-desc">Date (Newest First)</MenuItem>
              <MenuItem value="date-asc">Date (Oldest First)</MenuItem>
              <MenuItem value="title-asc">Title (A-Z)</MenuItem>
              <MenuItem value="title-desc">Title (Z-A)</MenuItem>
            </Select>
          </FormControl>

          {/* View Mode Toggle */}
          <ToggleButtonGroup
            value={viewMode}
            exclusive
            onChange={handleViewModeChange}
            aria-label="view mode"
            size="small"
          >
            <ToggleButton value="grid" aria-label="Grid view">
              <ViewModuleIcon />
            </ToggleButton>
            <ToggleButton value="list" aria-label="List view">
              <ViewListIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
      </Box>

      {/* Test Cards/List */}
      {viewMode === 'grid' ? (
        <Grid container spacing={3}>
          {sortedTests.length > 0 ? (
            sortedTests.map((test) => {
              const testId = test.id || test._id;
              let actionButton = null;

              if (test.status === 'available') {
                actionButton = (
                  <Button 
                    variant="contained" 
                    color="primary" 
                    fullWidth 
                    onClick={() => handleStartTest(testId)}
                    startIcon={<PlayArrowIcon />}
                  >
                    Start Test
                  </Button>
                );
              } else if (test.status === 'completed') {
                actionButton = (
                  <Button 
                    variant="contained" 
                    color="primary" 
                    fullWidth 
                    onClick={() => handleViewResults(testId)}
                    sx={{ 
                      bgcolor: theme.palette.primary.main,
                      '&:hover': {
                        bgcolor: theme.palette.primary.dark
                      }
                    }}
                  >
                    VIEW RESULTS
                  </Button>
                );
              } else if (test.status === 'upcoming') {
                actionButton = (
                  <Button 
                    variant="outlined" 
                    color="primary" 
                    fullWidth 
                    disabled
                    startIcon={<HourglassEmptyIcon />}
                  >
                    Coming Soon
                  </Button>
                );
              } else if (test.status === 'expired') {
                actionButton = (
                  <Button 
                    variant="outlined" 
                    color="error" 
                    fullWidth 
                    disabled
                  >
                    Expired
                  </Button>
                );
              }

              return (
                <Grid item xs={12} md={6} lg={4} key={testId}>
                  <Card 
                    elevation={3} 
                    sx={{ 
                      height: '100%', 
                      display: 'flex', 
                      flexDirection: 'column',
                      transition: 'transform 0.2s, box-shadow 0.2s',
                      borderLeft: '5px solid #1976d2', 
                      '&:hover': {
                        transform: 'translateY(-4px)',
                        boxShadow: theme.shadows[6]
                      }
                    }}
                  >
                    <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', p: 2.5 }}>
                      <Box display="flex" justifyContent="space-between" alignItems="flex-start" mb={1.5}>
                        <Typography variant="h6" component="div" sx={{ fontWeight: 600, lineHeight: 1.3 }}>
                          {test.title}
                        </Typography>
                        {getStatusChip(test.status, test.score, test.percentage)}
                      </Box>
                      
                      <Typography variant="body2" color="text.secondary" sx={{ mb: 1.5 }}>
                        {test.subject} | {test.class || 'N/A'}
                      </Typography>
                      
                      <Divider sx={{ mb: 1.5 }} />
                      
                      <Box sx={{ mt: 'auto' }}>
                        <Box display="flex" alignItems="center" gap={1} mb={1}>
                          <AssignmentIcon fontSize="small" color="action" />
                          <Typography variant="body2">
                            {test.totalQuestions || 0} Questions | {test.totalMarks || 100} Marks
                          </Typography>
                        </Box>
                        
                        <Box display="flex" alignItems="center" gap={1} mb={1}>
                          <AccessTimeIcon fontSize="small" color="action" />
                          <Typography variant="body2">
                            Duration: {formatDuration(test.duration)}
                          </Typography>
                        </Box>
                        
                        <Box display="flex" alignItems="center" gap={1}>
                          <CalendarTodayIcon fontSize="small" color="action" sx={{ opacity: 0.7 }}/>
                          <Typography variant="body2">
                            {test.status === 'upcoming' 
                              ? `Starts: ${formatDate(test.startTime)}` 
                              : test.status === 'available' 
                                ? `Available until: ${formatDate(test.endTime)}`
                                : test.status === 'completed'
                                  ? `Completed on: ${formatDate(test.completedOn || test.endTime)}`
                                  : `Expired on: ${formatDate(test.endTime)}`
                            }
                          </Typography>
                        </Box>
                      </Box>
                    </CardContent>
                    
                    <CardActions sx={{ p: 2, pt: 0 }}>
                      {actionButton}
                    </CardActions>
                  </Card>
                </Grid>
              );
            })
          ) : (
            <NoTestsMessage filter={statusFilter} />
          )}
        </Grid>
      ) : (
        <List dense>
          {sortedTests.length > 0 ? (
            sortedTests.map((test) => {
              const testId = test.id || test._id;
              let actionButton = null;
              let dateInfo = '';

              if (test.status === 'available') {
                actionButton = (
                  <Button 
                    variant="contained" 
                    size="small"
                    color="primary" 
                    onClick={() => handleStartTest(testId)}
                    startIcon={<PlayArrowIcon />}
                    sx={{ ml: 1 }}
                  >
                    Start Test
                  </Button>
                );
                dateInfo = `Available until: ${formatDate(test.endTime)}`;
              } else if (test.status === 'completed') {
                actionButton = (
                  <Button 
                    variant="contained" 
                    size="small"
                    color="primary" 
                    onClick={() => handleViewResults(testId)}
                    sx={{ ml: 1 }}
                  >
                    VIEW RESULTS
                  </Button>
                );
                dateInfo = `Completed on: ${formatDate(test.completedOn || test.endTime)}`;
              } else if (test.status === 'upcoming') {
                dateInfo = `Starts: ${formatDate(test.startTime)}`;
              } else if (test.status === 'expired') {
                dateInfo = `Expired on: ${formatDate(test.endTime)}`;
              }

              return (
                <ListItem
                  key={testId}
                  divider
                  sx={{ 
                    bgcolor: 'background.paper', 
                    mb: 1.5, 
                    borderRadius: theme.shape.borderRadius,
                    boxShadow: theme.shadows[2],
                    borderLeft: '5px solid #1976d2', 
                    p: 1.5 
                  }}
                >
                  <ListItemIcon sx={{ minWidth: 'auto', mr: 2, alignSelf: 'center' }}>
                    <AssignmentIcon color="primary" fontSize='medium'/>
                  </ListItemIcon>
                  <ListItemText 
                    primary={test.title}
                    secondary={
                      <Box component="span" sx={{ display: 'block', fontSize: '0.8rem', color: 'text.secondary', mt: 0.5 }}>
                        {`${test.subject || 'N/A'} • ${test.totalQuestions} Qs • ${formatDuration(test.duration)} • ${dateInfo}`}
                      </Box>
                    }
                    primaryTypographyProps={{ fontWeight: 600, mb: 0.5 }} 
                    sx={{ mr: 1 }} 
                  />
                  <ListItemSecondaryAction sx={{ display: 'flex', alignItems: 'center', gap: 1, pr: 1.5 }}>
                    {getStatusChip(test.status, test.score, test.percentage)}
                    {actionButton}
                  </ListItemSecondaryAction>
                </ListItem>
              );
            })
          ) : (
            <NoTestsMessage filter={statusFilter} />
          )}
        </List>
      )}

    </Box>
  );
};

export default TestList;
