import React, { useState, useEffect } from 'react';
import { 
  Box, 
  TextField, 
  Grid, 
  FormControl, 
  InputLabel, 
  Select, 
  MenuItem, 
  Typography, 
  Paper, 
  Chip,
  OutlinedInput,
  FormHelperText,
  IconButton,
  ListItemText,
  Checkbox,
  Button
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const TestBasicInfo = ({ 
  testData,
  onSave,
  subjects,
  topics,
  classes,
  onClassChange,
  onSubjectChange,
  loading,
  onSubmit
}) => {
  // Debug props
  useEffect(() => {
    console.log('TestBasicInfo rendered with:', { 
      classesCount: classes?.length,
      subjectsCount: subjects?.length,
      topicsCount: topics?.length,
      loading
    });
  }, [classes, subjects, topics, loading]);

  // Local state for form data
  const [localData, setLocalData] = useState({
    title: testData?.title || '',
    description: testData?.description || '',
    instructions: testData?.instructions || [''],
    subject: testData?.subject || '',
    topics: testData?.topics || [],
    classId: testData?.classId || '',
    duration: testData?.duration || 60,
    passingScore: testData?.passingScore || 40,
    startDate: testData?.startDate || '',
    endDate: testData?.endDate || ''
  });
  
  // Update local data when testData changes
  useEffect(() => {
    console.log('TestBasicInfo testData updated:', testData);
    if (testData) {
      setLocalData({
        ...testData,
        startDate: testData.startDate || '',
        endDate: testData.endDate || ''
      });
    }
  }, [testData]);
  
  // Form validation state
  const [errors, setErrors] = useState({});
  
  // Handle text field changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    
    console.log(`Changed field: ${name} to value: ${value}`);
    
    setLocalData(prev => ({ ...prev, [name]: value }));
    
    // Clear error when field is edited
    if (errors[name]) {
      setErrors(prev => ({ ...prev, [name]: '' }));
    }
    
    // Special handling for class change
    if (name === 'classId') {
      console.log('Class changed, calling onClassChange with:', value);
      // Reset subject when class changes
      setLocalData(prev => ({ ...prev, subject: '', topics: [] }));
      onClassChange(value);
      onSave({ ...localData, [name]: value, subject: '', topics: [] });
      return;
    }
    
    // Special handling for subject change
    if (name === 'subject') {
      console.log('Subject changed, calling onSubjectChange with:', value);
      onSubjectChange(value);
      // Reset topics when subject changes
      setLocalData(prev => ({ ...prev, topics: [] }));
      // Save to parent component (without topics to avoid overwriting before reset)
      onSave({ ...localData, [name]: value, topics: [] });
      return;
    }
    
    // Save to parent component
    onSave({ ...localData, [name]: value });
  };
  
  // Handle topic multi-select
  const handleTopicChange = (event) => {
    const { value } = event.target;
    setLocalData(prev => ({ ...prev, topics: value }));
    onSave({ ...localData, topics: value });
  };
  
  // Handle date changes
  const handleDateChange = (name, value) => {
    setLocalData(prev => ({ ...prev, [name]: value }));
    onSave({ ...localData, [name]: value });
  };
  
  // Handle instruction changes
  const handleInstructionChange = (index, value) => {
    const updatedInstructions = [...localData.instructions];
    updatedInstructions[index] = value;
    
    setLocalData(prev => ({ ...prev, instructions: updatedInstructions }));
    onSave({ ...localData, instructions: updatedInstructions });
  };
  
  // Add a new instruction field
  const addInstruction = () => {
    const updatedInstructions = [...localData.instructions, ''];
    setLocalData(prev => ({ ...prev, instructions: updatedInstructions }));
    onSave({ ...localData, instructions: updatedInstructions });
  };
  
  // Remove an instruction field
  const removeInstruction = (index) => {
    const updatedInstructions = localData.instructions.filter((_, i) => i !== index);
    setLocalData(prev => ({ ...prev, instructions: updatedInstructions }));
    onSave({ ...localData, instructions: updatedInstructions });
  };
  
  // Get subject name by ID
  const getSubjectName = (id) => {
    if (!id) return '';
    const subject = subjects.find(s => s._id === id);
    return subject ? subject.name : '';
  };

  // Get class name by ID
  const getClassName = (id) => {
    if (!id) return '';
    const classObj = classes.find(c => c._id === id);
    return classObj ? classObj.name : '';
  };

  // Get topic name by ID
  const getTopicName = (id) => {
    if (!id) return '';
    const topic = topics.find(t => t._id === id);
    return topic ? topic.name : '';
  };

  // Validate numeric inputs
  const validateNumeric = (event) => {
    if (!/[0-9]/.test(event.key) && event.key !== 'Backspace' && event.key !== 'Delete' && event.key !== 'ArrowLeft' && event.key !== 'ArrowRight') {
      event.preventDefault();
    }
  };

  // Handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();
    if (onSubmit) {
      onSubmit(localData);
    }
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Paper elevation={0} sx={{ p: 2, mb: 3 }}>
        <Typography variant="h6" gutterBottom>
          Test Basic Information
        </Typography>
        <Typography variant="body2" color="text.secondary" gutterBottom>
          Provide the basic details for your test. Fields marked with * are required.
        </Typography>
        
        <Grid container spacing={3}>
          {/* Test Title */}
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              label="Test Title"
              name="title"
              value={localData.title}
              onChange={handleChange}
              error={!!errors.title}
              helperText={errors.title}
              disabled={loading}
              placeholder="e.g., Physics Midterm Exam"
            />
          </Grid>
          
          {/* Test Description */}
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Description"
              name="description"
              value={localData.description}
              onChange={handleChange}
              multiline
              rows={3}
              disabled={loading}
              placeholder="Brief description of this test"
            />
          </Grid>
          
          {/* Class/Grade */}
          <Grid item xs={12} md={6}>
            <FormControl required fullWidth error={!!errors.classId} disabled={loading}>
              <InputLabel id="class-select-label">Class/Grade</InputLabel>
              <Select
                labelId="class-select-label"
                id="class-select"
                name="classId"
                value={localData.classId}
                onChange={handleChange}
                label="Class/Grade"
              >
                {classes.map((cls) => (
                  <MenuItem key={cls._id} value={cls._id}>
                    {cls.name}
                  </MenuItem>
                ))}
              </Select>
              {errors.classId && <FormHelperText>{errors.classId}</FormHelperText>}
            </FormControl>
          </Grid>
          
          {/* Subject */}
          <Grid item xs={12} md={6}>
            <FormControl required fullWidth error={!!errors.subject} disabled={loading}>
              <InputLabel id="subject-select-label">Subject</InputLabel>
              <Select
                labelId="subject-select-label"
                id="subject-select"
                name="subject"
                value={localData.subject}
                onChange={handleChange}
                label="Subject"
              >
                {subjects.map((subject) => (
                  <MenuItem key={subject._id} value={subject._id}>
                    {subject.name}
                  </MenuItem>
                ))}
              </Select>
              {errors.subject && <FormHelperText>{errors.subject}</FormHelperText>}
            </FormControl>
          </Grid>
          
          {/* Topics (Multi-select) */}
          <Grid item xs={12}>
            <FormControl fullWidth disabled={!localData.subject || loading}>
              <InputLabel id="topics-select-label">Topics (Optional)</InputLabel>
              <Select
                labelId="topics-select-label"
                id="topics-select"
                multiple
                value={localData.topics}
                onChange={handleTopicChange}
                input={<OutlinedInput label="Topics (Optional)" />}
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={getTopicName(value)} />
                    ))}
                  </Box>
                )}
                MenuProps={MenuProps}
              >
                {topics.map((topic) => (
                  <MenuItem key={topic._id} value={topic._id}>
                    <Checkbox checked={localData.topics.indexOf(topic._id) > -1} />
                    <ListItemText primary={topic.name} />
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>
                {!localData.subject 
                  ? 'Select a subject first to see available topics' 
                  : topics.length === 0 
                    ? 'No topics available for this subject' 
                    : 'Select topics to include in this test'}
              </FormHelperText>
            </FormControl>
          </Grid>
          
          {/* Duration */}
          <Grid item xs={12} md={6}>
            <TextField
              required
              fullWidth
              label="Duration (minutes)"
              name="duration"
              type="number"
              value={localData.duration}
              onChange={handleChange}
              onKeyDown={validateNumeric}
              inputProps={{ min: 1 }}
              error={!!errors.duration}
              helperText={errors.duration}
              disabled={loading}
            />
          </Grid>
          
          {/* Passing Score */}
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Passing Score (%)"
              name="passingScore"
              type="number"
              value={localData.passingScore}
              onChange={handleChange}
              onKeyDown={validateNumeric}
              inputProps={{ min: 0, max: 100 }}
              error={!!errors.passingScore}
              helperText={errors.passingScore ? errors.passingScore : 'Leave blank for no passing score'}
              disabled={loading}
            />
          </Grid>
          
          {/* Test Schedule */}
          <Grid item xs={12}>
            <Typography variant="subtitle1" gutterBottom>
              Test Schedule (Optional)
            </Typography>
          </Grid>
          
          {/* Start Date */}
          <Grid item xs={12} md={6}>
            <TextField
              label="Start Date & Time"
              name="startDate"
              type="datetime-local"
              value={localData.startDate}
              onChange={(e) => handleDateChange('startDate', e.target.value)}
              fullWidth
              disabled={loading}
            />
          </Grid>
          
          {/* End Date */}
          <Grid item xs={12} md={6}>
            <TextField
              label="End Date & Time"
              name="endDate"
              type="datetime-local"
              value={localData.endDate}
              onChange={(e) => handleDateChange('endDate', e.target.value)}
              fullWidth
              disabled={loading}
            />
          </Grid>
          
          {/* Instructions */}
          <Grid item xs={12}>
            <Typography variant="subtitle1" gutterBottom sx={{ mt: 2 }}>
              Test Instructions
            </Typography>
            
            {localData.instructions.map((instruction, index) => (
              <Box key={index} sx={{ display: 'flex', mb: 2 }}>
                <TextField
                  fullWidth
                  label={`Instruction ${index + 1}`}
                  value={instruction}
                  onChange={(e) => handleInstructionChange(index, e.target.value)}
                  disabled={loading}
                  multiline
                />
                <IconButton 
                  onClick={() => removeInstruction(index)}
                  disabled={localData.instructions.length <= 1 || loading}
                  sx={{ ml: 1 }}
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            ))}
            
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 1 }}>
              <IconButton 
                onClick={addInstruction}
                disabled={loading}
                color="primary"
                sx={{ border: '1px dashed', borderColor: 'primary.main' }}
              >
                <AddIcon />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
        
      </Paper>
    </Box>
  );
};

export default TestBasicInfo;
