import React, { useState, useRef, useEffect } from 'react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
// import { useAuth, SignedIn, SignedOut, SignInButton, UserButton } from '@clerk/clerk-react';
import { useAuth } from "../../services/authContext";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Box,
  TextField,
  IconButton,
  Drawer,
  useMediaQuery,
  useTheme,
  CssBaseline,
  CircularProgress,
  Skeleton,
  CardContent,
  Divider,
  styled,Card,Avatar,
} from '@mui/material';
import {
  Send as SendIcon,
  AttachFile as AttachFileIcon,
  Menu as MenuIcon,
} from '@mui/icons-material';
import ReactMarkdown from 'react-markdown';
import { CopyAll, Refresh, ThumbUp, ThumbDown } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import HospitalIcon from '@mui/icons-material/LocalHospital';
import { getText } from '../../actions/gemini.action'; 
import TopicSelectionCards from "../chatWindow/TopicSelectionCards";
// Importing getText
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import FlagIcon from '@mui/icons-material/Flag';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import ErrorNotification from '../../components/ErrorNotifcation';
import { ChatProvider } from './ChatContext';
import config from '../../config';
const API_URL = `${config.API_URL}`
const StyledCard = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  borderRadius: "8px",
  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  transition: "all 0.3s ease-in-out",
  "&:hover": {
      transform: "scale(1.02)",
      boxShadow: "0 6px 12px rgba(0, 0, 0, 0.2)",
  },
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: "#fff",
  marginRight: theme.spacing(2),
}));
// // RootLayout Component
const RootLayout = ({ onNewChat }) => {
  const { isSignedIn, user, signOut } = useAuth();
  
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
      <Link
        to="/"
        style={{
          display: 'flex',
          alignItems: 'center',
          textDecoration: 'none',
          color: 'inherit',
        }}
      >
        <HospitalIcon sx={{ mr: 1 }} />
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          Neetly AI
        </Typography>
      </Link>
      <Box sx={{ flexGrow: 1 }} />
      <Link
        to="/explore"
        style={{ textDecoration: 'none', display: 'inline-block' }}
      >
        <Button
          variant="contained"
          sx={{
            background: 'linear-gradient(135deg, #ffffff, #f0f0f0)',
            color: '#333',
            fontWeight: 'bold',
            borderRadius: '8px',
            padding: { xs: '6px 12px', sm: '8px 16px' },
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
            '&:hover': {
              background: 'linear-gradient(135deg, #f0f0f0, #e0e0e0)',
              boxShadow: '0 6px 12px rgba(0, 0, 0, 0.3)',
            },
            textTransform: 'none',
          }}
        >
          Ask MCQ's
        </Button>
      </Link>
      <Box sx={{ flexGrow: 1 }} />
      {!isSignedIn ? (
        <Link to="/sign-in">
          <Button color="primary" variant="contained">
            Sign In
          </Button>
        </Link>
      ) : (
        <IconButton 
          onClick={signOut} 
          color="primary"
          sx={{ ml: 1 }}
        >
          <Avatar sx={{ width: 32, height: 32 }}>
            {user?.firstName?.charAt(0) || 'U'}
          </Avatar>
        </IconButton>
      )}
    </Box>
  );
};
const ChatBubble = ({ message, onLike, onDislike,role,handleGetMCQs,modelResponseId,handleReport}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: message.role === 'user' ? 'flex-end' : 'flex-start',
        mb: 2,
        width: '100%',
      }}
    >
      {/* Display uploaded image if it exists */}
      {message.img && (
        <img
          src={message.img}
          alt="Uploaded content"
          style={{
            borderRadius: 8,
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
            marginBottom: 8,
            maxWidth: '100%',
            maxHeight: '300px',
            objectFit: 'cover',
          }}
        />
      )}

      <Box
        sx={{
          p: 2,
          backgroundColor: message.role === 'user' ? '#e3f2fd' : '#f5f5f5',
          borderRadius: 2,
          maxWidth: '80%',
          color: 'text.primary',
          boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
          transition: 'box-shadow 0.3s ease',
          '&:hover': {
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
          },
          wordBreak: 'break-word',
          overflowWrap: 'break-word',
        }}
      >
        <ReactMarkdown>{message.text}</ReactMarkdown>
        {role !== 'user' && <Box
        sx={{
          display: 'flex',
          justifyContent: message.role === 'user' ? 'flex-end' : 'flex-start',
          mt: 1,
        }}
      >
        <IconButton onClick={() => onLike(message)}>
          <ThumbUp />
        </IconButton>
        <IconButton onClick={() => onDislike(message)}>
          <ThumbDown />
        </IconButton>
        <Typography
          component="a"
          href="#"
          onClick={(e) => {
            e.preventDefault();
            handleGetMCQs(modelResponseId);
          }}
          sx={{
            color: 'primary.main',
            textDecoration: 'underline',
            cursor: 'pointer',
            justifyContent:'flex-end',
            mt: 1,
          }}
        >
          Get MCQs
        </Typography>
        <IconButton
    color="secondary"
    onClick={() => handleReport(message._id)}
    aria-label="Report"
  >
    <FlagIcon />
  </IconButton>
      </Box>}
      </Box>
    </Box>
  );
};
// TopBar Component
const TopBar = ({ isMobile, onToggleDrawer, onNewChat }) => (
  <AppBar position="static">
    <Toolbar>
      {isMobile && (
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={onToggleDrawer}
          sx={{ mr: 2 }}
        >
          <MenuIcon />
        </IconButton>
      )}
      <RootLayout onNewChat={onNewChat} />
    </Toolbar>
  </AppBar>
);

// ChatList Component
const ChatList = ({
  selectedChatId,
  setSelectedChatId,
  handleDrawerToggle,
  onNewChat,
}) => {
  const { getToken } = useAuth();
  const [loading, setLoading] = useState(true); // Add loading state
  const { data, isLoading, isError, error } = useQuery({
    queryKey: ['userChats'],
    queryFn: async () => {
      const token = await getToken();
      setLoading(true);
      const response = await fetch(`${API_URL}/api/userchats`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch user chats');
      }
      setLoading(false);
      const history = await response.json();
      history.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      return history;
    },
    onError: () => setLoading(false), // Ensure loading is false on error

  });
  if (isLoading || loading) {
    return (
      Array.from({ length: 8 }).map((_, index) => (
          <Box key={index} sx={{ mb: 2 }}>
              <Skeleton variant="rectangular" height={60} />
          </Box>
      ))
  )
  }
  if (isError) {
    return (
      <Box sx={{ p: 2 }}>Error loading chat history: {error.message}</Box>
    );
  }

  if (!data || data.length === 0) {
    return <Box sx={{ p: 2 }}>No chat history available.</Box>;
  }

  return (
    <Box
      sx={{
        width: 200,
        paddingTop: '50px',
        paddingBottom: '80px',
        height: '100vh',
        overflowY: 'auto',
        backgroundColor: 'white',
      }}
    >
      {/* New Chat Button */}
      <Typography
        variant="h6"
        sx={{
          fontWeight: 'bold',fontSize:18,    textAlign: 'center', // Use textAlign instead of alignContent for centering text


          p: 2,
          borderBottom: '1px solid #e0e0e0',
          color: 'black',
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: '#f5f5f5',
          },
        }}
        onClick={() => {
          onNewChat();
          handleDrawerToggle();
        }}
      >
        New Chat
      </Typography>

      {/* Chat History List */}
      {data.map((chat) => (
        <StyledCard
          key={chat._id}
          onClick={() => {
            setSelectedChatId(chat._id);
            handleDrawerToggle();
          }}
          sx={{
            cursor: 'pointer',
            backgroundColor: selectedChatId === chat._id ? '#e3f2fd' : '#fff',
            boxShadow:
              selectedChatId === chat._id
                ? '0px 4px 10px rgba(0, 0, 0, 0.15)'
                : '0px 2px 5px rgba(0, 0, 0, 0.1)',
            '&:hover': {
              backgroundColor: '#f5f5f5',
            },
            mb: 1,
          }}
        >
          <CardContent sx={{ display: 'flex', alignItems: 'center' }}>
            {/* <StyledAvatar>
              <HistoryIcon />
            </StyledAvatar> */}
            <Box sx={{ flexGrow: 1, ml: 2 }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold',fontSize:12 }}>
                {chat.title ? chat.title.toUpperCase() : 'Untitled Chat'}
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                <AccessTimeIcon fontSize="small" sx={{ mr: 1, color: 'gray' }} />
                <Typography variant="caption" color="text.secondary">
                  {new Date(chat.createdAt).toLocaleString()}
                </Typography>
              </Box>
            </Box>
          </CardContent>
          <Divider />
        </StyledCard>
      ))}
    </Box>
  );
};

// ChatConversation Component
// const ChatConversation = ({ chatId, localMessages }) => {
//   const { getToken } = useAuth();
//   const chatContainerRef = useRef(null);
//   const lastMessageRef = useRef(null);

//   const { data } = useQuery({
//     queryKey: ['chat', chatId],
//     queryFn: async () => {
//       if (!chatId) return null;
//       const token = await getToken();
//       const response = await fetch(`${API_URL}/api/chats/${chatId}`, {
//         method: 'GET',
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       });
//       if (!response.ok) {
//         throw new Error('Failed to fetch chat');
//       }
//       return response.json();
//     },
//     enabled: !!chatId,
//   });

//   const serverMessages =
//     data?.history?.map((msg) => ({
//       role: msg.role,
//       text:
//         msg.parts && msg.parts[0] && msg.parts[0].text
//           ? msg.parts[0].text
//           : msg.text || '',
//       img: msg.img,
//     })) || [];

//   const allMessages = [...serverMessages, ...localMessages];

//   useEffect(() => {
//     if (lastMessageRef.current) {
//       lastMessageRef.current.scrollIntoView({
//         behavior: 'smooth',
//         block: 'start',
//       });
//     }
//   }, [allMessages.length]);

//   if (!chatId && localMessages.length === 0) {
//     return (
//       <Box
//         sx={{
//           flexGrow: 1,
//           display: 'flex',
//           justifyContent: 'center',
//           alignItems: 'center',
//           color: 'text.secondary',
//           backgroundColor: 'background.default',
//           p: 2,
//         }}
//       >
//         <TopicSelectionCards/>
//       </Box>
//     );
//   }

//   return (
//     <Box
//       ref={chatContainerRef}
//       sx={{
//         flexGrow: 1,
//         overflowY: 'auto',
//         p: 2,
//         bgcolor: 'background.default',
//         display: 'flex',
//         flexDirection: 'column',
//         width: '100%',
//         height: '100%',
//         maxHeight: '100vh',
//         boxSizing: 'border-box',
//       }}
//     >
//       {allMessages.map((message, index) => (
//         <Box
//           key={index}
//           ref={index === allMessages.length - 1 ? lastMessageRef : null}
//           sx={{
//             display: 'flex',
//             flexDirection: 'column',
//             alignItems:
//               message.role === 'user' ? 'flex-end' : 'flex-start',
//             mb: 2,
//             width: '100%',
//           }}
//         >
//           {message.img && (
//             <img
//               src={message.img}
//               alt="Uploaded"
//               style={{
//                 borderRadius: 8,
//                 boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
//                 marginBottom: 8,
//                 maxWidth: '100%',
//                 maxHeight: '300px',
//                 objectFit: 'cover',
//               }}
//             />
//           )}
//           <Box
//             sx={{
//               p: 2,
//               backgroundColor:
//                 message.role === 'user' ? '#e3f2fd' : '#f5f5f5',
//               borderRadius: 2,
//               maxWidth: '80%',
//               color: 'text.primary',
//               boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
//               transition: 'box-shadow 0.3s ease',
//               '&:hover': {
//                 boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
//               },
//               wordBreak: 'break-word',
//               overflowWrap: 'break-word',
//             }}
//           >
//             <ReactMarkdown>{message.text}</ReactMarkdown>
//           </Box>
//         </Box>
//       ))}
//     </Box>
//   );
// };

const ChatConversation = ({ chatId, localMessages }) => {
  const { getToken } = useAuth();
  const chatContainerRef = useRef(null);
  const lastMessageRef = useRef(null);
  const [isLoadingDataFromServer,setLoaderForServer]=useState(false);
  const [likedMessages, setLikedMessages] = useState({});
  const [dislikedMessages, setDislikedMessages] = useState({});
  const [reportDialogOpen, setReportDialogOpen] = useState(false);
const [reportReason, setReportReason] = useState('');
const [selectedMessageId, setSelectedMessageId] = useState(null);
const [errorNotification, setErrorNotification] = useState(null);


  // Handle Like button
  const sendFeedback = async (uniqueId, action, reportMessage = '') => {
    try {
      const token = await getToken();
      const response = await fetch('https://neetlybackend.vercel.app/api/messages/action', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ uniqueId, action, reportMessage }),
      });

      const result = await response.json();
      if (response.ok) {
        setErrorNotification(`Successfully ${action}d`);
      } else {
        setErrorNotification(result.error || 'An error occurred');
      }
    } catch (error) {
      setErrorNotification('Failed to send feedback');
    }
  };
  
  const handleGetMCQs = (id) => {
    const url = `/explore?id=${id}`;
  window.open(url, '_blank');
    // Implement the logic to fetch MCQs related to this message
    console.log(`Fetching MCQs for message at index: ${id}`);
  };
  // Handle Like button
  const handleLike = (messageId) => {
    setLikedMessages((prev) => ({ ...prev, [messageId]: true }));
    setDislikedMessages((prev) => ({ ...prev, [messageId]: false }));
    sendFeedback(messageId, 'like');
  };

  // Handle Dislike button
  const handleDislike = (messageId) => {
    setLikedMessages((prev) => ({ ...prev, [messageId]: false }));
    setDislikedMessages((prev) => ({ ...prev, [messageId]: true }));
    sendFeedback(messageId, 'dislike');
  };

  // Handle Report button
  const handleReport = (messageId) => {
    setSelectedMessageId(messageId);
    setReportDialogOpen(true);
  };

  // Submit Report feedback
  const handleSubmitReport = () => {
    if (selectedMessageId && reportReason) {
      sendFeedback(selectedMessageId, 'report', reportReason);
      setReportDialogOpen(false);
      setReportReason('');
    } else {
      setErrorNotification('Please provide a reason for reporting.');
    }
  };
  // Handle Get MCQs butto
  const { data, isLoading, isError, error } = useQuery({
    queryKey: ['chat', chatId],
    queryFn: async () => {
      if (!chatId) return null;
      setLoaderForServer(true);
      const token = await getToken();
      const response = await fetch(`${API_URL}/api/chats/${chatId}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        setLoaderForServer(false);
        throw new Error('Failed to fetch chat');
      }
      setLoaderForServer(false);
      return response.json();
    },
    onError: () => setLoaderForServer(false), // Ensure loading is false on error
    enabled: !!chatId,
  });

  const serverMessages =
    data?.history?.map((msg) => ({
      _id: msg._id,
      role: msg.role,
      text:
        msg.parts && msg.parts[0] && msg.parts[0].text
          ? msg.parts[0].text
          : msg.text || '',
      img: msg.img,
    })) || [];

  const allMessages = [...serverMessages, ...localMessages];

  useEffect(() => {
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [allMessages.length]);


  if (isLoading || isLoadingDataFromServer) {
    // Render skeletons mimicking two-person conversation
    return (
      <Box
      sx={{
        flexGrow: 1,
        overflowY: 'auto',
        p: 2,
        backgroundColor: 'transparent', // Ensure no background color
        border: 'none',                 // Ensure no border
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: 'calc(100vh - 160px)',  // Adjust height for AppBar and ChatInput
        paddingTop: '64px',             // Adjust for top padding if needed
        paddingBottom: '80px',          // Adjust for bottom padding to prevent overlap
        boxSizing: 'border-box',
      }}
      >
        {Array.from({ length: 20 }).map((_, index) => (
          <Box
            key={index}
            sx={{
              display: 'flex',
              justifyContent: index % 2 === 0 ? 'flex-start' : 'flex-end', // Alternate alignment
              mb: 2,
            }}
          >
            <Skeleton
              variant="rectangular"
              width={index % 2 === 0 ? '90%' : '50%'} // Vary width to simulate user and other participant
              height={index % 2 === 0 ? 100 : 50}
              sx={{
                borderRadius: 2,
                backgroundColor: '#e0e0e0',
              }}
            />
          </Box>
        ))}
      </Box>
    );
  }

  if (isError) {
    return (
      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          color: 'text.secondary',
          backgroundColor: 'background.default',
          p: 2,
        }}
      >
        <Typography>Error loading chat: {error.message}</Typography>
      </Box>
    );
  }

  if (!chatId && localMessages.length === 0) {
    return (
      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          color: 'text.secondary',
          backgroundColor: 'background.default',
          p: 2,
        }}
      >
        <TopicSelectionCards />
      </Box>
    );
  }

  return (
    <Box
      ref={chatContainerRef}
      sx={{
        flexGrow: 1,
        overflowY: 'auto',
        p: 2,
        paddingBottom: '100px',          // Extra space to prevent overlap with ChatInput
        backgroundColor: 'transparent',   // Ensure transparent background
        border: 'none',                   // No border
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: 'calc(100vh - 80px)',     // Adjust height to fit ChatInput above
        boxSizing: 'border-box',
      }}
    >
      {allMessages.map((message, index) => (
        <Box
          key={index}
          ref={index === allMessages.length - 1 ? lastMessageRef : null}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: message.role === 'user' ? 'flex-end' : 'flex-start',
            mb: 2,
          }}
        >
          <ChatBubble
            message={message}
            onLike={() => handleLike(message._id)}
            onDislike={() => handleDislike(message._id)}
            handleReport={() => handleReport(message._id)}
            handleGetMCQs={handleGetMCQs}
            role={message.role}
            modelResponseId={message._id}
          />
        </Box>
      ))}
            <ErrorNotification message={errorNotification} duration={5000} />

       <Dialog
        open={reportDialogOpen}
        onClose={() => setReportDialogOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Report Message</DialogTitle>
        <DialogContent>
          <TextField
            label="Reason for reporting"
            value={reportReason}
            onChange={(e) => setReportReason(e.target.value)}
            fullWidth
            multiline
            rows={4}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setReportDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmitReport} color="primary">
            Submit Report
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

const ChatInput = ({
  chatId,
  setChatId,
  onNewMessage,
  onClearLocalMessages,
}) => {
  const [inputValue, setInputValue] = useState('');
  const [file, setFile] = useState(null);
  const { getToken } = useAuth();
  const queryClient = useQueryClient();
  const fileInputRef = useRef(null);

  const theme = useTheme(); // Add this line to get the theme
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const getImageString = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result); // Base64 string
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  const sendMessageMutation = useMutation({
    mutationFn: async ({ chatIdParam, message, imageString }) => {
      const token = await getToken();

      // If no chatId, create a new chat first
      if (!chatIdParam) {
        const createChatPayload = {
          text: message,
        };

        const createChatResponse = await fetch(`${API_URL}/api/chats`, {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(createChatPayload),
        });

        if (!createChatResponse.ok) {
          throw new Error('Failed to create new chat');
        }

        const responseText = await createChatResponse.text();
        const newChatId = responseText.trim().replace(/^"|"$/g, '');

        // Update chatId in state
        setChatId(newChatId);
        chatIdParam = newChatId;
      }

      // Get assistant's response from getText
      
      const answer = await getText(
        imageString ? [imageString, message] : [message],chatIdParam?chatIdParam:null,token
      );

      // Prepare payload to update chat
      const payload = {
        question: message,
        answer: answer,
      };
      if (imageString) {
        payload.img = imageString;
      }

      const url = `${API_URL}/api/chats/${chatIdParam}`;

      const response = await fetch(url, {
        method: 'PUT',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error('Failed to update chat');
      }

      // Return assistant's answer to use in onSuccess
      let responseData= await response.json();
      console.log(responseData);
      return { answer, img: imageString,id:responseData.id};
    },
    onSuccess: (data) => {
      // Update local messages with assistant's response
      console.log("on latest response",data.id)
      onNewMessage({ role: 'assistant', text: data.answer ,_id:data.id});
      onClearLocalMessages();
      queryClient.invalidateQueries(['chat', chatId]);
      queryClient.invalidateQueries(['userChats']);
    },
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (inputValue.trim() || file) {
      try {
        let imageString = null;
        if (file) {
          imageString = await getImageString(file);
        }

        // Optimistically update the UI with the user's message
        onNewMessage({ role: 'user', text: inputValue, img: imageString });

        // Call the mutation function
        sendMessageMutation.mutate({
          chatIdParam: chatId,
          message: inputValue,
          imageString,
        });

        setInputValue('');
        setFile(null);
      } catch (err) {
        console.error('Error:', err);
        // Handle error appropriately
      }
    }
  };

  const handleFileChange = (e) => {
    if (e.target.files[0]) {
      setFile(e.target.files[0]);
      e.target.value = '';
    }
  };

  return (
    <Box
    component="form"
    onSubmit={handleSubmit}
    sx={{
      position: isMobile ? 'fixed' : 'auto',
      bottom: isMobile ? 0 : 'auto',
      //position: 'auto',             // Fix position to bottom
     // bottom: 10,                    // Small gap from the bottom for styling
      left: 0,
      right: 0,
      zIndex: 1000,
      display: 'flex',
      justifyContent: 'center',
      borderTop: isMobile ? '1px solid #e0e0e0' : 'none',
      padding: isMobile ? '10px' : '5px',
    }}
  >
    <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      borderRadius: '30px',          // Rounded look
      backgroundColor: '#ffffff',    // White background
      padding: '5px 15px',
      border: '1px solid #000000',   // Black border
      width: 'calc(100% - 20px)',    // Centered within screen with margin
      boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',  // Subtle shadow
    }}
    >
      <Avatar sx={{ backgroundColor: 'primary.main', width: 24, height: 24, mr: 1 }}>
        {/* Add user icon or relevant icon here */}
      </Avatar>
      <TextField
        fullWidth
        variant="standard"
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        placeholder="Ask for any topic on medscience..."
        InputProps={{
          disableUnderline: true,
          sx: {
            paddingLeft: 1,
            paddingRight: 1,
            color: 'black',
          },
        }}
        sx={{
          '& .MuiInputBase-input': {
            color: 'black',
          },
        }}
      />
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleFileChange}
        accept="image/*"
      />
      <IconButton
        onClick={() => fileInputRef.current.click()}
        sx={{ ml: 1 }}
      >
        <AttachFileIcon />
      </IconButton>
      <IconButton
        type="submit"
        disabled={sendMessageMutation.isPending}
        sx={{
          ml: 1,
          backgroundColor: 'primary.main',
          color: 'white',
          borderRadius: '50%',
          '&:hover': {
            backgroundColor: 'primary.dark',
          },
        }}
      >
        {sendMessageMutation.isPending ? (
          <CircularProgress size={24} sx={{ color: 'blue' }} />
        ) : (
          <SendIcon />
        )}
      </IconButton>
    </Box>
    {file && (
      <Typography variant="caption" sx={{ color: 'black', mt: 1 }}>
        {file.name}
      </Typography>
    )}
  </Box>
  );
};


const NeetlyAIDashboard = () => {
    const [selectedChatId, setSelectedChatId] = useState(null);
    const [localMessages, setLocalMessages] = useState([]);
    const [mobileOpen, setMobileOpen] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
    const prevSelectedChatIdRef = useRef(null);
  
    const handleDrawerToggle = () => {
      setMobileOpen(!mobileOpen);
    };
  
    const handleNewMessage = (message) => {
      setLocalMessages((prevMessages) => [...prevMessages, message]);
    };
    const handleClearLocalMessages = () => {
      setLocalMessages([]);
    };
    
    const handleNewChat = () => {
      setSelectedChatId(null);
      setLocalMessages([]);
    };
  
    useEffect(() => {
      const prevSelectedChatId = prevSelectedChatIdRef.current;
      if (prevSelectedChatId && selectedChatId && selectedChatId !== prevSelectedChatId) {
        // Only clear localMessages when switching between existing chats
        setLocalMessages([]);
      }
      prevSelectedChatIdRef.current = selectedChatId;
    }, [selectedChatId]);
  
    return (
      <ChatProvider>
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh', backgroundColor: 'white' }}>
        <CssBaseline />
        <Box sx={{ position: 'fixed', top: 0, left: 0, right: 0, zIndex: (theme) => theme.zIndex.drawer + 1 }}>
          <TopBar isMobile={isMobile} onToggleDrawer={handleDrawerToggle} onNewChat={handleNewChat}/>
        </Box>
        <Box sx={{ display: 'flex', flexGrow: 1, overflow: 'hidden' }}>
          {!isMobile && (
            <Box sx={{ width: 240, flexShrink: 0, borderRight: '1px solid #e0e0e0' }}>
              <ChatList
                selectedChatId={selectedChatId}
                setSelectedChatId={setSelectedChatId}
                handleDrawerToggle={handleDrawerToggle}
                onNewChat={handleNewChat}
              />
            </Box>
          )}
          <Drawer
            variant="temporary"
            open={isMobile && mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
            sx={{
              display: { xs: 'block', sm: 'none' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 240, backgroundColor: 'white' },
            }}
          >
            <ChatList
              selectedChatId={selectedChatId}
              setSelectedChatId={setSelectedChatId}
              handleDrawerToggle={handleDrawerToggle}
              onNewChat={handleNewChat}
            />
          </Drawer>
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              display: 'flex',
              flexDirection: 'column',
              width: { xs: '100%', sm: `calc(100% - 240px)` },
              backgroundColor: 'white',
            }}
          >
            <Box sx={{ flexGrow: 1, overflowY: 'auto', paddingTop: "64px", paddingBottom: "64px" }}>
              <ChatConversation chatId={selectedChatId} localMessages={localMessages} />
            </Box>
            <ChatInput
              chatId={selectedChatId}
              setChatId={setSelectedChatId}
              onNewMessage={handleNewMessage}
            />
          </Box>
        </Box>
      </Box>
    </ChatProvider>
    );
  };

export default NeetlyAIDashboard;