import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./style.css";
import { useQuery } from "@tanstack/react-query";
import { useAuth } from "../../services/authContext";
import config from "../../config";

//const API_URL = "https://neetlybackend.vercel.app";
const API_URL = config.API_URL;

const ChatList = () => {
    // const [data, setData] = useState(null);
    // const [isPending, setIsPending] = useState(true);
    // const [error, setError] = useState(null);
    const { getToken, signOut, user, hasRole } = useAuth();
    // useEffect(() => {
    //     const fetchData = async () => {
    //         try {
    //             const token = await getToken();
    //             const response = await fetch(`${API_URL}/api/userchats`, {
    //                 method: "GET",
    //                 headers: {
    //                     Authorization: `Bearer ${token}`,
    //                     mode: "cors",
    //                 },
    //             });
    //             if (!response.ok) {
    //                 throw new Error("Failed to fetch user chats");
    //             }
    //             const data = await response.json();
    //             setData(data);
    //             setIsPending(false);
    //         } catch (error) {
    //             setError(error.message);
    //             setIsPending(false);
    //         }
    //     };

    //     fetchData();
    // }, [getToken]);

    const { isPending, error, data } = useQuery({
        queryKey: ["userChats"],
        queryFn: async () => {
            const token = await getToken(); // Assuming getToken is defined and returns a token
            try {
                const response = await fetch(`${API_URL}/api/userchats`, {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${token}`,
                        mode: "cors",
                    },
                });

                if (!response.ok) {
                    // Check specifically for 401 Unauthorized errors
                    if (response.status === 401) {
                        console.log("Authentication expired. Logging out...");
                        // Trigger logout
                        await signOut();
                        // No need to throw here as we're handling it by logging out
                        return [];
                    }
                    throw new Error("Failed to fetch user chats");
                }

                return response.json();
            } catch (error) {
                console.error("Error fetching chats:", error);
                throw error;
            }
        },
    });

    // const { isPending, error, data } = useQuery({
    //     queryKey: ["userChats"],
    //     queryFn: () =>
    //         fetch(`${API_URL}/api/userchats`, {
    //             credentials: "include",
    //         }).then((res) => res.json()),
    // });
    // const { isPending, error, data } = useQuery({
    //     queryKey: ["userChats"],
    //     queryFn: () =>
    //         fetch(`${API_URL}/api/userchats`, {
    //             credentials: "include",
    //         }).then((res) => res.json()),
    // });
    return (
        <div className="chatList">
            <span className="title">DASHBOARD</span>
            <Link to="/dashboard">Create a new Chat</Link>
            <Link to="/explore">solve MCQ'S</Link>
            <hr />
            <span className="title">RECENT CHATS</span>
            <div className="list">
                {isPending
                    ? "Loading..."
                    : error
                    ? "Something went wrong!"
                    : data?.map((chat) => (
                          <Link to={`/dashboard/chats/${chat._id}`} key={chat._id}>
                              {chat.title}
                          </Link>
                      ))}
            </div>
            <hr />
            {/* <div className="upgrade">
                <img src="/logo.png" alt="" />
                <div className="texts">
                    <span>Upgrade to Lama AI Pro</span>
                    <span>Get unlimited access to all features</span>
                </div>
            </div> */}
        </div>
    );
};

export default ChatList;
