import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Paper, 
  Typography, 
  TextField, 
  Button, 
  Grid, 
  Card, 
  CardContent, 
  CardActions, 
  Chip,
  IconButton, 
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tabs,
  Tab,
  CircularProgress,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Tooltip,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { 
  Add as AddIcon, 
  Search as SearchIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  CloudUpload as CloudUploadIcon,
  Assignment as AssignmentIcon,
  CheckCircle as CheckCircleIcon,
  Error as ErrorIcon,
  Help as HelpIcon
} from '@mui/icons-material';
import API from '../../services/api';
import blueTheme from '../../theme/blueTheme';

// Custom component for tabbed interface
function TabPanel({ children, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`mcq-tabpanel-${index}`}
      aria-labelledby={`mcq-tab-${index}`}
      {...other}
      style={{ padding: '16px 0' }}
    >
      {value === index && (
        <Box>
          {children}
        </Box>
      )}
    </div>
  );
}

const MCQManagement = ({ 
  mcqs, 
  totalMcqs,
  subjects,
  topics,
  page,
  rowsPerPage,
  onPageChange,
  onRowsPerPageChange,
  onAddMcq,
  onEditMcq,
  onDeleteMcq,
  onBulkUpload,
  getToken, 
  user, 
  showNotification,
  selectedSubject,
  selectedTopic
}) => {
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [subjectFilter, setSubjectFilter] = useState(selectedSubject || '');
  const [topicFilter, setTopicFilter] = useState(selectedTopic || '');
  const [difficultyFilter, setDifficultyFilter] = useState('');
  const [filteredMcqsLocal, setFilteredMcqsLocal] = useState([]);
  const [tabValue, setTabValue] = useState(0);
  const [topicsLocal, setTopicsLocal] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadResults, setUploadResults] = useState(null);
  
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  // Set initial filters when selectedSubject or selectedTopic changes
  useEffect(() => {
    if (selectedSubject) {
      setSubjectFilter(selectedSubject);
    }
    if (selectedTopic) {
      setTopicFilter(selectedTopic);
    }
  }, [selectedSubject, selectedTopic]);

  // Filter MCQs when search term or filters change
  useEffect(() => {
    if (mcqs && mcqs.length > 0) {
      const filtered = mcqs.filter(mcq => {
        const matchesSearch = mcq.question.toLowerCase().includes(searchTerm.toLowerCase());
        const matchesSubject = !subjectFilter || mcq.subjectId === subjectFilter;
        const matchesTopic = !topicFilter || mcq.topicId === topicFilter;
        const matchesDifficulty = !difficultyFilter || mcq.difficulty === difficultyFilter;
        
        return matchesSearch && matchesSubject && matchesTopic && matchesDifficulty;
      });
      
      setFilteredMcqsLocal(filtered);
    } else {
      setFilteredMcqsLocal([]);
    }
  }, [mcqs, searchTerm, subjectFilter, topicFilter, difficultyFilter]);
  
  // Fetch topics when subject filter changes
  useEffect(() => {
    if (subjectFilter) {
      if (topics && topics[subjectFilter]) {
        // Use topics from props if available
        setTopicsLocal(topics[subjectFilter]);
      } else {
        // Fetch topics if not available in props
        fetchTopics(subjectFilter);
      }
    } else {
      setTopicsLocal([]);
      setTopicFilter('');
    }
  }, [subjectFilter, topics]);
  
  const fetchTopics = async (subjectId) => {
    try {
      const authToken = await getToken();
      
      if (!authToken) {
        showNotification('Authentication error. Please sign in again.', 'error');
        return;
      }
      
      const response = await API.institution.subjects.topics.getAll(
        authToken, 
        subjectId
      );
      
      setTopicsLocal(response.data.topics || []);
    } catch (error) {
      console.error('Error fetching topics:', error);
      showNotification('Failed to load topics. Please try again.', 'error');
    }
  };
  
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };
  
  const handleSubjectFilterChange = (e) => {
    setSubjectFilter(e.target.value);
  };
  
  const handleTopicFilterChange = (e) => {
    setTopicFilter(e.target.value);
  };
  
  const handleDifficultyFilterChange = (e) => {
    setDifficultyFilter(e.target.value);
  };
  
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };
  
  const handleBulkUpload = async () => {
    if (!selectedFile) {
      showNotification('Please select a file first', 'error');
      return;
    }
    
    if (!subjectFilter) {
      showNotification('Please select a subject for the MCQs', 'error');
      return;
    }
    
    setLoading(true);
    setUploadProgress(0);
    setUploadResults(null);
    
    try {
      const formData = new FormData();
      formData.append('file', selectedFile);
      formData.append('subjectId', subjectFilter);
      if (topicFilter) {
        formData.append('topicId', topicFilter);
      }
      formData.append('institutionId', user.institution);
      
      // Simulating progress for better UX
      const progressInterval = setInterval(() => {
        setUploadProgress(prev => {
          if (prev >= 90) {
            clearInterval(progressInterval);
            return 90;
          }
          return prev + 10;
        });
      }, 300);
      
      await onBulkUpload(formData);
      
      clearInterval(progressInterval);
      setUploadProgress(100);
      
      // Show success message
      showNotification('MCQs uploaded successfully', 'success');
      
      // Simulate results
      setUploadResults({
        success: true,
        total: 10, // This would come from the API response
        successful: 8,
        failed: 2,
        errors: [
          { row: 3, message: 'Missing correct answer' },
          { row: 7, message: 'Invalid option format' }
        ]
      });
      
      setSelectedFile(null);
    } catch (error) {
      console.error('Error uploading MCQs:', error);
      
      setUploadResults({
        success: false,
        message: error.response?.data?.message || 'Failed to upload MCQs'
      });
      
      showNotification('Failed to upload MCQs. Please try again.', 'error');
    } finally {
      setLoading(false);
    }
  };
  
  const getDifficultyColor = (difficulty) => {
    switch (difficulty?.toLowerCase()) {
      case 'easy':
        return { bgcolor: '#e6f7ea', color: '#2e7d32' };
      case 'medium':
        return { bgcolor: '#fff8e1', color: '#ff8f00' };
      case 'hard':
        return { bgcolor: '#fbe9e7', color: '#d32f2f' };
      default:
        return { bgcolor: '#f5f5f5', color: '#757575' };
    }
  };

  const renderManualCreation = () => (
    <Box>
      <Typography variant="h6" gutterBottom sx={{ fontWeight: 'medium', color: blueTheme.primary.dark }}>
        Create MCQs Manually
      </Typography>
      
      <Button 
        variant="contained" 
        color="primary"
        startIcon={<AddIcon />}
        onClick={onAddMcq}
        sx={{ 
          mb: 3,
          borderRadius: 2,
          bgcolor: blueTheme.primary.main,
          '&:hover': {
            bgcolor: blueTheme.primary.dark,
            transform: 'translateY(-2px)',
          },
          transition: 'all 0.3s ease',
          boxShadow: '0 4px 12px rgba(0,0,0,0.15)'
        }}
      >
        Add New MCQ
      </Button>
      
      <TableContainer component={Paper} sx={{ boxShadow: '0 4px 20px rgba(0,0,0,0.08)', borderRadius: 2, overflow: 'hidden' }}>
        <Table>
          <TableHead sx={{ bgcolor: blueTheme.primary.main }}>
            <TableRow>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Question</TableCell>
              {!isMobile && <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Subject</TableCell>}
              {!isMobile && !isTablet && <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Topic</TableCell>}
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Difficulty</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold', width: 120 }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredMcqsLocal.length > 0 ? (
              filteredMcqsLocal.map((mcq) => (
                <TableRow key={mcq._id} hover>
                  <TableCell>
                    <Tooltip title={mcq.question}>
                      <Typography sx={{
                        maxWidth: { xs: 150, sm: 300, md: 400 },
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap'
                      }}>
                        {mcq.question}
                      </Typography>
                    </Tooltip>
                  </TableCell>
                  
                  {!isMobile && (
                    <TableCell>
                      {subjects.find(s => s._id === mcq.subjectId)?.name || 'Unknown'}
                    </TableCell>
                  )}
                  
                  {!isMobile && !isTablet && (
                    <TableCell>
                      {mcq.topicName || 'N/A'}
                    </TableCell>
                  )}
                  
                  <TableCell>
                    <Chip 
                      label={mcq.difficulty || 'Medium'} 
                      size="small"
                      sx={{ 
                        ...getDifficultyColor(mcq.difficulty),
                        fontWeight: 500
                      }}
                    />
                  </TableCell>
                  
                  <TableCell>
                    <Tooltip title="Edit MCQ">
                      <IconButton 
                        size="small"
                        onClick={() => onEditMcq(mcq)}
                        sx={{ color: blueTheme.primary.main }}
                      >
                        <EditIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                    
                    <Tooltip title="Delete MCQ">
                      <IconButton 
                        size="small"
                        onClick={() => onDeleteMcq(mcq._id)}
                        sx={{ color: '#f44336' }}
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={5} align="center" sx={{ py: 3 }}>
                  <Typography variant="body1" color="textSecondary">
                    No MCQs found. Add a new MCQ to get started.
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        
        {totalMcqs > 0 && (
          <TablePagination
            component="div"
            count={totalMcqs}
            page={page}
            onPageChange={onPageChange}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={onRowsPerPageChange}
            rowsPerPageOptions={[5, 10, 25, 50]}
          />
        )}
      </TableContainer>
    </Box>
  );
  
  const renderBulkUpload = () => (
    <Box>
      <Typography variant="h6" gutterBottom sx={{ fontWeight: 'medium', color: blueTheme.primary.dark }}>
        Bulk Upload MCQs
      </Typography>
      
      <Paper sx={{ p: 3, mb: 3, borderRadius: 2, boxShadow: '0 4px 20px rgba(0,0,0,0.08)' }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Box sx={{ mb: 3 }}>
              <Typography variant="body1" sx={{ mb: 1, fontWeight: 500 }}>
                Upload Excel/CSV File
              </Typography>
              <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
                Upload a file containing multiple MCQs. The file should have the following columns: Question, Options (comma-separated), Correct Answer, and Difficulty (optional).
              </Typography>
              
              <Box 
                sx={{ 
                  border: '2px dashed #e0e0e0', 
                  borderRadius: 2, 
                  p: 3, 
                  textAlign: 'center',
                  bgcolor: '#fafafa',
                  transition: 'all 0.3s ease',
                  '&:hover': {
                    borderColor: blueTheme.primary.light,
                    bgcolor: `${blueTheme.primary.light}10`
                  }
                }}
              >
                <input
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  id="upload-mcq-file"
                  type="file"
                  onChange={handleFileChange}
                  style={{ display: 'none' }}
                />
                <label htmlFor="upload-mcq-file">
                  <Button
                    variant="outlined"
                    component="span"
                    startIcon={<CloudUploadIcon />}
                    sx={{ 
                      mb: 2,
                      borderRadius: 2,
                      borderColor: blueTheme.primary.light,
                      color: blueTheme.primary.main,
                      '&:hover': {
                        borderColor: blueTheme.primary.main,
                        bgcolor: `${blueTheme.primary.light}10`
                      }
                    }}
                  >
                    Choose File
                  </Button>
                </label>
                
                <Typography variant="body2" color="textSecondary">
                  {selectedFile ? `Selected: ${selectedFile.name}` : 'No file selected'}
                </Typography>
              </Box>
            </Box>
            
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel>Select Subject *</InputLabel>
              <Select
                value={subjectFilter}
                label="Select Subject *"
                onChange={handleSubjectFilterChange}
                required
                sx={{ borderRadius: 2 }}
              >
                <MenuItem value="">
                  <em>Select a subject</em>
                </MenuItem>
                {subjects.map((subject) => (
                  <MenuItem key={subject._id} value={subject._id}>{subject.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
            
            <FormControl fullWidth sx={{ mb: 3 }}>
              <InputLabel>Select Topic (Optional)</InputLabel>
              <Select
                value={topicFilter}
                label="Select Topic (Optional)"
                onChange={handleTopicFilterChange}
                disabled={!subjectFilter}
                sx={{ borderRadius: 2 }}
              >
                <MenuItem value="">
                  <em>All topics</em>
                </MenuItem>
                {topicsLocal.map((topic) => (
                  <MenuItem key={topic._id} value={topic._id}>{topic.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
            
            <Button 
              variant="contained" 
              color="primary"
              disabled={!selectedFile || !subjectFilter || loading}
              onClick={handleBulkUpload}
              startIcon={loading ? <CircularProgress size={20} color="inherit" /> : <CloudUploadIcon />}
              fullWidth
              sx={{ 
                py: 1.2,
                borderRadius: 2,
                bgcolor: blueTheme.primary.main,
                '&:hover': {
                  bgcolor: blueTheme.primary.dark,
                  transform: 'translateY(-2px)',
                },
                transition: 'all 0.3s ease',
                boxShadow: '0 4px 12px rgba(0,0,0,0.15)'
              }}
            >
              {loading ? 'Uploading...' : 'Upload MCQs'}
            </Button>
            
            {loading && (
              <Box sx={{ width: '100%', mt: 2 }}>
                <Typography variant="body2" color="textSecondary" sx={{ mb: 1 }}>
                  {`Processing file... ${uploadProgress}%`}
                </Typography>
                <Box sx={{ width: '100%', bgcolor: '#e0e0e0', borderRadius: 5, height: 10 }}>
                  <Box
                    sx={{
                      width: `${uploadProgress}%`,
                      bgcolor: blueTheme.primary.main,
                      height: 10,
                      borderRadius: 5,
                      transition: 'width 0.3s ease'
                    }}
                  />
                </Box>
              </Box>
            )}
          </Grid>
          
          <Grid item xs={12} md={6}>
            <Typography variant="body1" sx={{ mb: 1, fontWeight: 500 }}>
              Template & Instructions
            </Typography>
            
            <Paper sx={{ p: 2, mb: 3, bgcolor: '#f5f7fa', borderRadius: 2 }}>
              <Typography variant="body2" sx={{ mb: 2 }}>
                Download our template for bulk uploading MCQs.
              </Typography>
              
              <Button 
                variant="outlined"
                size="small"
                sx={{ 
                  borderRadius: 2,
                  borderColor: blueTheme.primary.light,
                  color: blueTheme.primary.main,
                  '&:hover': {
                    borderColor: blueTheme.primary.main,
                    bgcolor: `${blueTheme.primary.light}10`
                  }
                }}
              >
                Download Template
              </Button>
            </Paper>
            
            <Typography variant="body2" color="textSecondary" sx={{ mb: 1 }}>
              Format Instructions:
            </Typography>
            
            <Box component="ul" sx={{ pl: 2, mb: 3 }}>
              <li>
                <Typography variant="body2" color="textSecondary">
                  Question: The full text of the question
                </Typography>
              </li>
              <li>
                <Typography variant="body2" color="textSecondary">
                  Options: Four options separated by commas
                </Typography>
              </li>
              <li>
                <Typography variant="body2" color="textSecondary">
                  Correct Answer: Index (1-4) or the exact text of the correct option
                </Typography>
              </li>
              <li>
                <Typography variant="body2" color="textSecondary">
                  Difficulty: Easy, Medium, or Hard (optional)
                </Typography>
              </li>
            </Box>
            
            {uploadResults && (
              <Paper sx={{ p: 2, mb: 3, borderRadius: 2, border: '1px solid', borderColor: uploadResults.success ? '#e6f7ea' : '#fbe9e7' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  {uploadResults.success ? (
                    <CheckCircleIcon sx={{ color: '#2e7d32', mr: 1 }} />
                  ) : (
                    <ErrorIcon sx={{ color: '#d32f2f', mr: 1 }} />
                  )}
                  
                  <Typography variant="body1" sx={{ fontWeight: 500, color: uploadResults.success ? '#2e7d32' : '#d32f2f' }}>
                    {uploadResults.success ? 'Upload Summary' : 'Upload Failed'}
                  </Typography>
                </Box>
                
                {uploadResults.success ? (
                  <>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                      <Typography variant="body2">Total MCQs:</Typography>
                      <Typography variant="body2" sx={{ fontWeight: 500 }}>{uploadResults.total}</Typography>
                    </Box>
                    
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                      <Typography variant="body2">Successfully Added:</Typography>
                      <Typography variant="body2" sx={{ fontWeight: 500, color: '#2e7d32' }}>{uploadResults.successful}</Typography>
                    </Box>
                    
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                      <Typography variant="body2">Failed:</Typography>
                      <Typography variant="body2" sx={{ fontWeight: 500, color: '#d32f2f' }}>{uploadResults.failed}</Typography>
                    </Box>
                    
                    {uploadResults.failed > 0 && (
                      <>
                        <Typography variant="body2" sx={{ fontWeight: 500, mb: 1 }}>Errors:</Typography>
                        {uploadResults.errors.map((error, index) => (
                          <Box key={index} sx={{ mb: 1, display: 'flex', alignItems: 'flex-start' }}>
                            <ErrorIcon sx={{ color: '#d32f2f', mr: 1, fontSize: 18 }} />
                            <Typography variant="body2">
                              Row {error.row}: {error.message}
                            </Typography>
                          </Box>
                        ))}
                      </>
                    )}
                  </>
                ) : (
                  <Typography variant="body2">
                    {uploadResults.message}
                  </Typography>
                )}
              </Paper>
            )}
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );

  return (
    <Box>
      <Typography variant="h4" gutterBottom sx={{ fontWeight: 'medium', color: blueTheme.primary.dark }}>
        MCQ Management
      </Typography>
      
      <Paper sx={{ p: 3, mb: 3, borderRadius: 2, boxShadow: '0 4px 20px rgba(0,0,0,0.08)' }}>
        <Box sx={{ mb: 3 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={5}>
              <TextField
                label="Search MCQs"
                variant="outlined"
                size="small"
                value={searchTerm}
                onChange={handleSearchChange}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                placeholder="Search by question..."
                sx={{ 
                  '& .MuiOutlinedInput-root': {
                    borderRadius: 2,
                    transition: 'all 0.3s ease',
                    '&:hover': {
                      borderColor: blueTheme.primary.main,
                    },
                    '&.Mui-focused': {
                      boxShadow: `0 0 0 2px ${blueTheme.primary.light}40`,
                    }
                  }
                }}
              />
            </Grid>
            
            <Grid item xs={12} sm={6} md={3}>
              <FormControl size="small" fullWidth>
                <InputLabel>Filter by Subject</InputLabel>
                <Select
                  value={subjectFilter}
                  label="Filter by Subject"
                  onChange={handleSubjectFilterChange}
                  sx={{ borderRadius: 2 }}
                >
                  <MenuItem value="">All Subjects</MenuItem>
                  {subjects.map((subject) => (
                    <MenuItem key={subject._id} value={subject._id}>{subject.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            
            <Grid item xs={12} sm={6} md={3}>
              <FormControl size="small" fullWidth>
                <InputLabel>Filter by Difficulty</InputLabel>
                <Select
                  value={difficultyFilter}
                  label="Filter by Difficulty"
                  onChange={handleDifficultyFilterChange}
                  sx={{ borderRadius: 2 }}
                >
                  <MenuItem value="">All Difficulties</MenuItem>
                  <MenuItem value="easy">Easy</MenuItem>
                  <MenuItem value="medium">Medium</MenuItem>
                  <MenuItem value="hard">Hard</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            
            <Grid item xs={12}>
              <Divider sx={{ my: 2 }} />
            </Grid>
            
            <Grid item xs={12}>
              <Tabs 
                value={tabValue} 
                onChange={handleTabChange}
                variant="fullWidth"
                sx={{
                  '& .MuiTabs-indicator': {
                    backgroundColor: blueTheme.primary.main,
                  },
                  '& .MuiTab-root': {
                    textTransform: 'none',
                    minHeight: 48,
                    fontWeight: 500,
                    transition: 'all 0.3s ease',
                    '&.Mui-selected': {
                      color: blueTheme.primary.main,
                      fontWeight: 600,
                    },
                  },
                }}
              >
                <Tab 
                  label="Manual Creation" 
                  icon={<AssignmentIcon />} 
                  iconPosition="start" 
                />
                <Tab 
                  label="Bulk Upload" 
                  icon={<CloudUploadIcon />} 
                  iconPosition="start" 
                />
              </Tabs>
            </Grid>
          </Grid>
        </Box>
        
        <TabPanel value={tabValue} index={0}>
          {renderManualCreation()}
        </TabPanel>
        
        <TabPanel value={tabValue} index={1}>
          {renderBulkUpload()}
        </TabPanel>
      </Paper>
    </Box>
  );
};

export default MCQManagement;
