import React, { useState, useEffect, useRef } from 'react';
import { 
  Box, 
  Typography, 
  Button, 
  CircularProgress, 
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Paper,
  Stepper,
  Step,
  StepLabel,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Divider,
  Alert,
  useTheme,
  LinearProgress,
  Fade,
  Zoom
} from '@mui/material';
import {
  Warning as WarningIcon,
  ExitToApp as ExitToAppIcon,
  NavigateNext as NavigateNextIcon,
  NavigateBefore as NavigateBeforeIcon,
  Flag as FlagIcon,
  Check as CheckIcon,
  LockOutlined as LockOutlinedIcon
} from '@mui/icons-material';
import { useParams, useNavigate } from 'react-router-dom';
import testService from '../../../services/testService';
import { useAuth } from '../../../services/authContext';

const SecureTestEnvironment = () => {
  const { testId } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const { user } = useAuth();
  
  // Test data states
  const [test, setTest] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [attemptId, setAttemptId] = useState(null);
  
  // Test progress states
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState({});
  const [flaggedQuestions, setFlaggedQuestions] = useState([]);
  const [timeRemaining, setTimeRemaining] = useState(null);
  const [testStartTime, setTestStartTime] = useState(null);
  
  // Security states
  const [warningCount, setWarningCount] = useState(0);
  const [showWarningDialog, setShowWarningDialog] = useState(false);
  const [warningMessage, setWarningMessage] = useState('');
  const [showExitDialog, setShowExitDialog] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [testEnded, setTestEnded] = useState(false);
  const [testResult, setTestResult] = useState(null);
  
  // Transition states
  const [showCountdown, setShowCountdown] = useState(false);
  const [countdownValue, setCountdownValue] = useState(3);
  const [testStarted, setTestStarted] = useState(false);
  const [showInstructions, setShowInstructions] = useState(true);
  
  // Refs
  const timerRef = useRef(null);
  const visibilityRef = useRef(null);
  const fullscreenRef = useRef(null);
  const countdownRef = useRef(null);
  const progressSaveRef = useRef(null);
  
  // Load test data
  useEffect(() => {
    const fetchTestData = async () => {
      try {
        setLoading(true);
        
        // Get user data from localStorage as fallback
        let userData = user;
        if (!userData) {
          const storedUser = localStorage.getItem('user');
          if (storedUser) {
            userData = JSON.parse(storedUser);
            console.log('User data from localStorage:', userData);
          }
        }
        
        // Fetch test details
        const testResponse = await testService.getTestById(testId);
        if (!testResponse.success) {
          throw new Error('Failed to fetch test details');
        }
        setTest(testResponse.test);
        
        // Check if test is available
        if (testResponse.test.isAvailable === false) {
          throw new Error('This test is not available for you to take at this time');
        }
        
        // Check if there's an existing attempt
        if (testResponse.test.attempt && testResponse.test.attempt.status === 'in_progress') {
          setAttemptId(testResponse.test.attempt._id);
          const startTime = new Date(testResponse.test.attempt.startTime);
          setTestStartTime(startTime);
          
          // Calculate remaining time
          const durationMs = testResponse.test.duration * 60 * 1000;
          const elapsedMs = Date.now() - startTime.getTime();
          const remainingMs = Math.max(0, durationMs - elapsedMs);
          setTimeRemaining(Math.floor(remainingMs / 1000));
        }
        
      } catch (error) {
        console.error('Error setting up test:', error);
        setError(error.message || 'An error occurred while setting up the test');
      } finally {
        setLoading(false);
      }
    };
    
    fetchTestData();
    
    // Cleanup function
    return () => {
      if (timerRef.current) clearInterval(timerRef.current);
      if (visibilityRef.current) document.removeEventListener('visibilitychange', visibilityRef.current);
      if (fullscreenRef.current) document.removeEventListener('fullscreenchange', fullscreenRef.current);
      if (countdownRef.current) clearInterval(countdownRef.current);
      if (progressSaveRef.current) clearInterval(progressSaveRef.current);
    };
  }, [testId, user]);
  
  // Start countdown and test
  const startTest = async () => {
    setShowInstructions(false);
    setShowCountdown(true);
    
    countdownRef.current = setInterval(() => {
      setCountdownValue(prev => {
        if (prev <= 1) {
          clearInterval(countdownRef.current);
          initiateSecureTest();
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
  };
  
  // Initiate secure test after countdown
  const initiateSecureTest = async () => {
    try {
      // Start the test
      console.log('Starting test with ID:', testId);
      const startResponse = await testService.startTest(testId);
      console.log('Start test response:', startResponse);
      
      if (!startResponse.success) {
        throw new Error(startResponse.message || 'Failed to start test');
      }
      
      if (startResponse.attempt) {
        setAttemptId(startResponse.attempt._id);
        setTestStartTime(new Date(startResponse.attempt.startTime));
        setTimeRemaining(startResponse.attempt.timeRemaining);
      }
      
      // Set questions if returned from API
      if (startResponse.questions && startResponse.questions.length > 0) {
        console.log('Questions received from start test API:', startResponse.questions);
        setQuestions(startResponse.questions);
      } else {
        // Fallback to separate API call for questions
        console.log('No questions in start response, fetching separately...');
        const questionsResponse = await testService.getTestQuestions(testId);
        console.log('Questions API response:', questionsResponse);
        
        if (!questionsResponse.success) {
          throw new Error(questionsResponse.message || 'Failed to fetch test questions');
        }
        
        console.log('Questions received from questions API:', questionsResponse.questions);
        if (questionsResponse.questions && questionsResponse.questions.length > 0) {
          setQuestions(questionsResponse.questions);
        } else {
          throw new Error('No questions available for this test');
        }
        
        // Update attempt and test info if provided
        if (questionsResponse.attempt) {
          setAttemptId(questionsResponse.attempt._id);
          setTestStartTime(new Date(questionsResponse.attempt.startTime));
          setTimeRemaining(questionsResponse.attempt.timeRemaining);
        }
        
        if (questionsResponse.test) {
          setTest(prevTest => ({
            ...prevTest,
            ...questionsResponse.test
          }));
        }
      }
      
      // Request fullscreen
      document.documentElement.requestFullscreen().catch(err => {
        console.error('Error attempting to enable fullscreen:', err);
        showSecurityWarning('Fullscreen mode is required for this test. Please allow fullscreen when prompted.');
      });
      
      setShowCountdown(false);
      setTestStarted(true);
      
      // Set up timer
      timerRef.current = setInterval(() => {
        setTimeRemaining(prev => {
          if (prev <= 1) {
            // Time's up, submit the test
            clearInterval(timerRef.current);
            handleSubmitTest();
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
      
      // Set up visibility change detection
      visibilityRef.current = () => {
        if (document.visibilityState === 'hidden' && test.settings.preventTabSwitching) {
          showSecurityWarning('You have navigated away from the test. This may be considered cheating.');
        }
      };
      document.addEventListener('visibilitychange', visibilityRef.current);
      
      // Set up fullscreen change detection
      fullscreenRef.current = () => {
        if (!document.fullscreenElement && test.settings.requireFullscreen) {
          showSecurityWarning('You have exited fullscreen mode. This may be considered cheating.');
        }
      };
      document.addEventListener('fullscreenchange', fullscreenRef.current);
      
      // Set up periodic progress saving
      progressSaveRef.current = setInterval(() => {
        saveProgress();
      }, 30000); // Save every 30 seconds
      
    } catch (error) {
      console.error('Error initiating test:', error);
      setError(error.message || 'An error occurred while starting the test');
      setShowCountdown(false);
    }
  };
  
  // Set up security measures
  const setupSecurityMeasures = () => {
    // Monitor tab visibility
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'hidden') {
        showSecurityWarning('You left the test window. This will be recorded as a violation.');
        reportSecurityViolation('tab_switch');
      }
    };
    visibilityRef.current = handleVisibilityChange;
    document.addEventListener('visibilitychange', handleVisibilityChange);
    
    // Monitor fullscreen exit
    const handleFullscreenChange = () => {
      if (!document.fullscreenElement && testStarted) {
        showSecurityWarning('Fullscreen mode is required. Please return to fullscreen mode.');
        reportSecurityViolation('fullscreen_exit');
        document.documentElement.requestFullscreen().catch(err => {
          console.error('Error attempting to re-enable fullscreen:', err);
        });
      }
    };
    fullscreenRef.current = handleFullscreenChange;
    document.addEventListener('fullscreenchange', handleFullscreenChange);
    
    // Disable right-click
    document.addEventListener('contextmenu', e => {
      if (testStarted) {
        e.preventDefault();
        showSecurityWarning('Right-clicking is disabled during the test.');
      }
    });
    
    // Disable keyboard shortcuts
    document.addEventListener('keydown', e => {
      if (testStarted) {
        // Prevent Ctrl+C, Ctrl+V, Ctrl+P, Alt+Tab, etc.
        if ((e.ctrlKey && (e.key === 'c' || e.key === 'v' || e.key === 'p')) || 
            (e.altKey && e.key === 'Tab')) {
          e.preventDefault();
          showSecurityWarning('Keyboard shortcuts are disabled during the test.');
          reportSecurityViolation('copy_attempt');
        }
      }
    });
  };
  
  // Show security warning
  const showSecurityWarning = (message) => {
    setWarningMessage(message);
    setShowWarningDialog(true);
    setWarningCount(prev => prev + 1);
  };
  
  // Report security violation to server
  const reportSecurityViolation = async (violationType) => {
    if (attemptId) {
      try {
        await testService.reportSecurityViolation(testId, attemptId, violationType);
      } catch (error) {
        console.error('Error reporting security violation:', error);
      }
    }
  };
  
  // Save test progress
  const saveProgress = async () => {
    if (attemptId && Object.keys(answers).length > 0) {
      try {
        console.log('Saving test progress...', {
          testId,
          attemptId,
          answersCount: Object.keys(answers).length
        });
        
        // Format answers for API
        const formattedAnswers = Object.keys(answers).map(questionId => ({
          questionId,
          selectedOption: answers[questionId],
          timeTaken: 0 // We're not tracking time per question in this implementation
        }));
        
        await testService.saveTestProgress(testId, attemptId, formattedAnswers);
        console.log('Progress saved successfully');
      } catch (error) {
        console.error('Error saving progress:', error);
      }
    }
  };
  
  // Handle answer selection
  const handleAnswerSelect = (questionId, optionId) => {
    setAnswers(prev => ({
      ...prev,
      [questionId]: optionId
    }));
  };
  
  // Handle flagging a question
  const handleFlagQuestion = (questionId) => {
    setFlaggedQuestions(prev => {
      if (prev.includes(questionId)) {
        return prev.filter(id => id !== questionId);
      } else {
        return [...prev, questionId];
      }
    });
  };
  
  // Navigate to next question
  const handleNextQuestion = () => {
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(prev => prev + 1);
    }
  };
  
  // Navigate to previous question
  const handlePrevQuestion = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(prev => prev - 1);
    }
  };
  
  // Navigate to a specific question
  const handleGoToQuestion = (index) => {
    if (index >= 0 && index < questions.length) {
      setCurrentQuestionIndex(index);
    }
  };
  
  // Format time remaining
  const formatTimeRemaining = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };
  
  // Submit the test
  const handleSubmitTest = async () => {
    try {
      setIsSubmitting(true);
      
      console.log('Starting test submission process...');
      
      // Save progress one last time
      await saveProgress();
      
      // Calculate time spent
      const timeSpent = test.duration * 60 - timeRemaining;
      console.log(`Time spent on test: ${timeSpent} seconds`);
      
      // Format answers for API
      const formattedAnswers = Object.keys(answers).map(questionId => ({
        questionId,
        selectedOption: answers[questionId],
        timeTaken: 0 // We're not tracking time per question in this implementation
      }));
      
      console.log(`Submitting test with ${formattedAnswers.length} answered questions`);
      
      // Submit test
      const response = await testService.submitTest(testId, attemptId, formattedAnswers, timeSpent);
      console.log('Test submission response:', response);
      
      if (response.success) {
        setTestResult(response.result);
        setTestEnded(true);
        console.log('Test ended successfully with result:', response.result);
        
        // Clean up
        if (timerRef.current) clearInterval(timerRef.current);
        if (progressSaveRef.current) clearInterval(progressSaveRef.current);
        
        // Exit fullscreen
        if (document.fullscreenElement) {
          document.exitFullscreen().catch(err => {
            console.error('Error exiting fullscreen:', err);
          });
        }
      } else {
        throw new Error(response.message || 'Failed to submit test');
      }
    } catch (error) {
      console.error('Error submitting test:', error);
      showSecurityWarning('An error occurred while submitting the test. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };
  
  // Handle exit confirmation
  const handleExitConfirm = () => {
    // Exit fullscreen
    if (document.fullscreenElement) {
      document.exitFullscreen().catch(err => {
        console.error('Error exiting fullscreen:', err);
      });
    }
    
    // Navigate back to tests list
    navigate('/ui-revamp/tests');
  };
  
  // Render current question
  const renderCurrentQuestion = () => {
    if (!questions || questions.length === 0 || currentQuestionIndex >= questions.length) {
      return (
        <Box p={3} textAlign="center">
          <Typography>No questions available</Typography>
        </Box>
      );
    }
    
    const currentQuestion = questions[currentQuestionIndex];
    const questionNumber = currentQuestionIndex + 1;
    
    // Debug logging to see the question structure
    console.log('Current question structure:', currentQuestion);
    
    // Use either id or _id field from the question
    const questionId = currentQuestion.id || currentQuestion._id;
    const isFlagged = flaggedQuestions.includes(questionId);
    const selectedOption = answers[questionId];
    
    return (
      <Box p={3}>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Typography variant="h6">
            Question {questionNumber} of {questions.length}
          </Typography>
          <Button
            variant={isFlagged ? "contained" : "outlined"}
            color={isFlagged ? "secondary" : "primary"}
            size="small"
            startIcon={<FlagIcon />}
            onClick={() => handleFlagQuestion(questionId)}
          >
            {isFlagged ? 'Flagged' : 'Flag for Review'}
          </Button>
        </Box>
        
        <Paper elevation={2} sx={{ p: 3, mb: 3 }}>
          <Typography variant="body1" gutterBottom>
            {currentQuestion.text || currentQuestion.question || currentQuestion.questionText}
          </Typography>
          
          <FormControl component="fieldset" sx={{ mt: 2, width: '100%' }}>
            <RadioGroup
              value={selectedOption || ''}
              onChange={(e) => handleAnswerSelect(questionId, e.target.value)}
            >
              {(currentQuestion.options || []).map((option) => (
                <FormControlLabel
                  key={option.id || option._id}
                  value={option.id || option._id}
                  control={<Radio />}
                  label={option.text}
                  sx={{
                    mb: 1,
                    p: 1,
                    borderRadius: 1,
                    '&:hover': {
                      backgroundColor: 'rgba(0, 0, 0, 0.04)'
                    }
                  }}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Paper>
        
        <Box display="flex" justifyContent="space-between" mt={3}>
          <Button
            variant="outlined"
            startIcon={<NavigateBeforeIcon />}
            onClick={handlePrevQuestion}
            disabled={currentQuestionIndex === 0}
          >
            Previous
          </Button>
          
          {currentQuestionIndex < questions.length - 1 ? (
            <Button
              variant="contained"
              endIcon={<NavigateNextIcon />}
              onClick={handleNextQuestion}
            >
              Next
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              endIcon={<CheckIcon />}
              onClick={() => setShowExitDialog(true)}
            >
              Finish & Submit
            </Button>
          )}
        </Box>
      </Box>
    );
  };
  
  // Render question navigator
  const renderQuestionNavigator = () => {
    return (
      <Box p={2} borderTop={1} borderColor="divider">
        <Typography variant="subtitle2" gutterBottom>
          Question Navigator
        </Typography>
        <Box display="flex" flexWrap="wrap" gap={1} mt={1}>
          {questions.map((question, index) => {
            const isAnswered = !!answers[question.id || question._id];
            const isFlagged = flaggedQuestions.includes(question.id || question._id);
            const isCurrent = index === currentQuestionIndex;
            
            let bgColor = 'grey.200';
            if (isCurrent) bgColor = 'primary.main';
            else if (isFlagged && isAnswered) bgColor = 'warning.main';
            else if (isFlagged) bgColor = 'warning.light';
            else if (isAnswered) bgColor = 'success.main';
            
            return (
              <Box
                key={question.id || question._id}
                sx={{
                  width: 36,
                  height: 36,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '50%',
                  backgroundColor: bgColor,
                  color: isCurrent ? 'white' : (isAnswered ? 'white' : 'text.primary'),
                  cursor: 'pointer',
                  '&:hover': {
                    opacity: 0.8
                  }
                }}
                onClick={() => handleGoToQuestion(index)}
              >
                {index + 1}
              </Box>
            );
          })}
        </Box>
      </Box>
    );
  };
  
  // Render test instructions
  const renderInstructions = () => {
    if (!test) return null;
    
    return (
      <Box p={4} maxWidth={800} mx="auto">
        <Typography variant="h4" gutterBottom color="primary">
          {test.title}
        </Typography>
        
        <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
          <Typography variant="h6" gutterBottom>
            Test Instructions
          </Typography>
          
          <Divider sx={{ mb: 2 }} />
          
          <Box mb={3}>
            {test.instructions && test.instructions.length > 0 ? (
              test.instructions.map((instruction, index) => (
                <Typography key={index} variant="body2" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
                  • {instruction}
                </Typography>
              ))
            ) : (
              <>
                <Typography variant="body2" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
                  • This test contains {test.totalQuestions} questions.
                </Typography>
                <Typography variant="body2" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
                  • The total duration of the test is {test.duration} minutes.
                </Typography>
                <Typography variant="body2" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
                  • Each question has only one correct answer.
                </Typography>
                <Typography variant="body2" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
                  • There is no negative marking.
                </Typography>
              </>
            )}
          </Box>
          
          <Alert severity="warning" sx={{ mb: 3 }}>
            <Typography variant="body2" fontWeight="bold">
              Security Measures:
            </Typography>
            <Typography variant="body2">
              • You must remain in fullscreen mode throughout the test.
            </Typography>
            <Typography variant="body2">
              • Switching tabs or windows is not allowed and will be recorded.
            </Typography>
            <Typography variant="body2">
              • Copying content is disabled during the test.
            </Typography>
            <Typography variant="body2">
              • Multiple security violations may result in automatic test submission.
            </Typography>
          </Alert>
          
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Box>
              <Typography variant="body2">
                <strong>Duration:</strong> {test.duration} minutes
              </Typography>
              <Typography variant="body2">
                <strong>Total Questions:</strong> {test.totalQuestions}
              </Typography>
              <Typography variant="body2">
                <strong>Total Marks:</strong> {test.totalMarks}
              </Typography>
            </Box>
            
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={startTest}
              startIcon={<LockOutlinedIcon />}
            >
              Start Secure Test
            </Button>
          </Box>
        </Paper>
      </Box>
    );
  };
  
  // Render test environment
  const renderTestEnvironment = () => {
    return (
      <Box display="flex" flexDirection="column" height="100vh">
        {/* Header */}
        <Box 
          p={2} 
          bgcolor="primary.main" 
          color="white" 
          display="flex" 
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h6">{test?.title}</Typography>
          
          <Box display="flex" alignItems="center" gap={3}>
            <Box textAlign="center">
              <Typography variant="caption">Questions Answered</Typography>
              <Typography variant="h6">
                {Object.keys(answers).length} / {questions.length}
              </Typography>
            </Box>
            
            <Box textAlign="center">
              <Typography variant="caption">Time Remaining</Typography>
              <Typography variant="h6">
                {formatTimeRemaining(timeRemaining)}
              </Typography>
            </Box>
            
            <Button 
              variant="outlined" 
              color="inherit"
              size="small"
              onClick={() => setShowExitDialog(true)}
            >
              Submit Test
            </Button>
          </Box>
        </Box>
        
        {/* Progress bar */}
        <LinearProgress 
          variant="determinate" 
          value={(Object.keys(answers).length / questions.length) * 100} 
          sx={{ height: 4 }}
        />
        
        {/* Main content */}
        <Box display="flex" flexGrow={1} overflow="hidden">
          {/* Question area */}
          <Box flexGrow={1} overflow="auto">
            {renderCurrentQuestion()}
          </Box>
        </Box>
        
        {/* Question navigator */}
        {renderQuestionNavigator()}
      </Box>
    );
  };
  
  // Render test results
  const renderTestResults = () => {
    if (!testResult) return null;
    
    return (
      <Box p={4} maxWidth={800} mx="auto" textAlign="center">
        <Typography variant="h4" gutterBottom color="primary">
          Test Submitted Successfully
        </Typography>
        
        <Paper elevation={3} sx={{ p: 4, mb: 4 }}>
          <Box
            sx={{
              position: 'relative',
              display: 'inline-flex',
              width: 160,
              height: 160,
              mb: 3
            }}
          >
            <CircularProgress
              variant="determinate"
              value={testResult.percentageScore || 0}
              size={160}
              thickness={5}
              sx={{
                color: (testResult.percentageScore || 0) >= 60 ? theme.palette.success.main : theme.palette.error.main,
              }}
            />
            <Box
              sx={{
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                position: 'absolute',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            >
              <Typography variant="h3" component="div" color="text.secondary">
                {testResult.percentageScore || 0}%
              </Typography>
            </Box>
          </Box>
          
          <Typography variant="h6" gutterBottom>
            Your Score: {testResult.score} / {testResult.totalMarks}
          </Typography>
          
          <Typography variant="body1" color={testResult.passed ? "success.main" : "error.main"} fontWeight="bold" gutterBottom>
            {testResult.passed ? "Passed" : "Failed"}
          </Typography>
          
          <Typography variant="body2" color="text.secondary" paragraph>
            Time Spent: {Math.floor(testResult.timeSpent / 60)} minutes {testResult.timeSpent % 60} seconds
          </Typography>
          
          <Divider sx={{ my: 3 }} />
          
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate(`/ui-revamp/tests/${testId}/results`)}
          >
            View Detailed Results
          </Button>
          
          <Button
            variant="outlined"
            sx={{ ml: 2 }}
            onClick={() => navigate('/ui-revamp/tests')}
          >
            Back to Tests
          </Button>
        </Paper>
      </Box>
    );
  };
  
  // Render countdown screen
  const renderCountdown = () => {
    return (
      <Box 
        display="flex" 
        flexDirection="column" 
        alignItems="center" 
        justifyContent="center" 
        height="100vh"
        bgcolor={theme.palette.background.default}
      >
        <Zoom in={true}>
          <Typography variant="h1" color="primary" sx={{ fontSize: 120, fontWeight: 'bold' }}>
            {countdownValue}
          </Typography>
        </Zoom>
        <Typography variant="h6" color="text.secondary">
          Test starting...
        </Typography>
      </Box>
    );
  };
  
  // Main render
  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }
  
  if (error) {
    return (
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="100vh" p={3}>
        <Typography color="error" variant="h6" gutterBottom>
          {error}
        </Typography>
        <Button 
          variant="contained" 
          color="primary"
          onClick={() => navigate('/ui-revamp/tests')}
          sx={{ mt: 2 }}
        >
          Back to Tests
        </Button>
      </Box>
    );
  }
  
  return (
    <>
      {/* Main content based on state */}
      {showInstructions && renderInstructions()}
      {showCountdown && renderCountdown()}
      {testStarted && !testEnded && renderTestEnvironment()}
      {testEnded && renderTestResults()}
      
      {/* Warning dialog */}
      <Dialog
        open={showWarningDialog}
        onClose={() => setShowWarningDialog(false)}
      >
        <DialogTitle sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <WarningIcon color="warning" />
          Security Warning
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {warningMessage}
          </DialogContentText>
          <DialogContentText sx={{ mt: 2, color: 'error.main' }}>
            Warning count: {warningCount}/3
            {warningCount >= 3 && " - Your test may be automatically submitted if violations continue."}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowWarningDialog(false)} color="primary">
            Understand
          </Button>
        </DialogActions>
      </Dialog>
      
      {/* Exit confirmation dialog */}
      <Dialog
        open={showExitDialog}
        onClose={() => setShowExitDialog(false)}
      >
        <DialogTitle>Submit Test?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {Object.keys(answers).length < questions.length ? (
              `You have answered ${Object.keys(answers).length} out of ${questions.length} questions. Are you sure you want to submit the test?`
            ) : (
              "Are you sure you want to submit the test?"
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowExitDialog(false)} color="inherit">
            Cancel
          </Button>
          <Button 
            onClick={handleSubmitTest} 
            color="primary" 
            variant="contained"
            disabled={isSubmitting}
          >
            {isSubmitting ? 'Submitting...' : 'Submit Test'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SecureTestEnvironment;
