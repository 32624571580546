import React from 'react';
import { Box, Typography } from '@mui/material';
import MainLayout from '../layout/MainLayout';
import CaseStudyProvider from './CaseStudyProvider';
import CaseStudy from './CaseStudy';
import { themeColors } from '../theme';

/**
 * Layout component for the Case Study section
 * Wraps the CaseStudy component in the MainLayout
 */
const CaseStudyLayout = () => {
  return (
    <MainLayout>
      <Box
        sx={{
          padding: { xs: 2, md: 3 },
          height: 'calc(100vh - 64px)',
          backgroundColor: themeColors.background.default
        }}
      >
        <CaseStudyProvider>
          <CaseStudy />
        </CaseStudyProvider>
      </Box>
    </MainLayout>
  );
};

export default CaseStudyLayout;
