import React from 'react';
import MainLayout from '../../components/UIRevamp/layout/MainLayout';
import MCQPracticeLayout from '../../components/UIRevamp/mcq/MCQPracticeLayout';

/**
 * Main UI Revamp demonstration page
 * Integrates all the new UI components into a cohesive experience
 * Focuses on MCQ practice functionality for medical education
 */
const UIRevampPage = () => {
  return (
    <MainLayout>
      <MCQPracticeLayout />
    </MainLayout>
  );
};

export default UIRevampPage;
