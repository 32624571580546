import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Grid, 
  Paper, 
  Typography, 
  TextField, 
  Button, 
  Divider,
  List,
  ListItem,
  ListItemText,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Card,
  CardContent,
  CardActions,
  Radio,
  RadioGroup,
  FormControlLabel,
  OutlinedInput
} from '@mui/material';
import { 
  Search as SearchIcon, 
  Upload as UploadIcon, 
  Add as AddIcon, 
  Edit as EditIcon,
  Delete as DeleteIcon,
  KeyboardArrowUp as UpIcon,
  KeyboardArrowDown as DownIcon,
  Close as CloseIcon,
  Psychology as AIIcon
} from '@mui/icons-material';

const QuestionSelection = ({
  testData,
  onSave,
  subjects,
  topics,
  mcqs,
  onFetchMCQs,
  onUploadMCQSet,
  onGenerateAIQuestions,
  loading
}) => {
  // Local state
  const [searchParams, setSearchParams] = useState({
    subject: testData.subject || '',
    topics: testData.topics || [],
    search: '',
    difficulty: ''
  });
  
  const [selectedMCQs, setSelectedMCQs] = useState(testData.questions || []);
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
  const [aiGenerateDialogOpen, setAIGenerateDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [currentEditMCQ, setCurrentEditMCQ] = useState(null);
  const [aiGenerateParams, setAIGenerateParams] = useState({
    subject: testData.subject || '',
    topic: testData.topics.length > 0 ? testData.topics[0] : '',
    count: 5,
    difficulty: 'medium'
  });
  
  // Initialize with test data on mount
  useEffect(() => {
    setSelectedMCQs(testData.questions || []);
  }, [testData.questions]);
  
  // Update search params when test subject/topic changes
  useEffect(() => {
    setSearchParams(prev => ({
      ...prev,
      subject: testData.subject || '',
      topics: testData.topics || []
    }));
    
    setAIGenerateParams(prev => ({
      ...prev,
      subject: testData.subject || '',
      topic: testData.topics.length > 0 ? testData.topics[0] : ''
    }));
    
    // Fetch MCQs based on initial params
    if (testData.subject) {
      onFetchMCQs({
        subject: testData.subject,
        topics: testData.topics
      });
    }
  }, [testData.subject, testData.topics]);
  
  // Handle search parameter changes
  const handleSearchParamChange = (e) => {
    const { name, value } = e.target;
    setSearchParams(prev => ({ ...prev, [name]: value }));
  };
  
  // Handle search button click
  const handleSearch = () => {
    onFetchMCQs(searchParams);
  };
  
  // Add a question to the test
  const handleAddQuestion = (mcq) => {
    // Check if it's already in the selected list
    if (selectedMCQs.some(q => q.mcqId === mcq._id)) {
      return;
    }
    
    const newQuestion = {
      mcqId: mcq._id,
      questionText: mcq.question,
      points: 1,
      order: selectedMCQs.length + 1
    };
    
    const updatedQuestions = [...selectedMCQs, newQuestion];
    setSelectedMCQs(updatedQuestions);
    onSave({ questions: updatedQuestions });
  };
  
  // Remove a question from the test
  const handleRemoveQuestion = (index) => {
    const updatedQuestions = selectedMCQs.filter((_, i) => i !== index);
    
    // Update order numbers
    const reorderedQuestions = updatedQuestions.map((q, i) => ({
      ...q,
      order: i + 1
    }));
    
    setSelectedMCQs(reorderedQuestions);
    onSave({ questions: reorderedQuestions });
  };
  
  // Update question points
  const handlePointsChange = (index, points) => {
    const updatedQuestions = [...selectedMCQs];
    updatedQuestions[index] = {
      ...updatedQuestions[index],
      points: Math.max(1, parseInt(points) || 1)
    };
    
    setSelectedMCQs(updatedQuestions);
    onSave({ questions: updatedQuestions });
  };
  
  // Move question up
  const handleMoveUp = (index) => {
    if (index === 0) return;
    
    const updatedQuestions = [...selectedMCQs];
    const temp = updatedQuestions[index];
    updatedQuestions[index] = updatedQuestions[index - 1];
    updatedQuestions[index - 1] = temp;
    
    // Update order numbers
    const reorderedQuestions = updatedQuestions.map((q, i) => ({
      ...q,
      order: i + 1
    }));
    
    setSelectedMCQs(reorderedQuestions);
    onSave({ questions: reorderedQuestions });
  };
  
  // Move question down
  const handleMoveDown = (index) => {
    if (index === selectedMCQs.length - 1) return;
    
    const updatedQuestions = [...selectedMCQs];
    const temp = updatedQuestions[index];
    updatedQuestions[index] = updatedQuestions[index + 1];
    updatedQuestions[index + 1] = temp;
    
    // Update order numbers
    const reorderedQuestions = updatedQuestions.map((q, i) => ({
      ...q,
      order: i + 1
    }));
    
    setSelectedMCQs(reorderedQuestions);
    onSave({ questions: reorderedQuestions });
  };
  
  // Open the file upload dialog
  const handleOpenUploadDialog = () => {
    setUploadDialogOpen(true);
  };
  
  // Close the file upload dialog
  const handleCloseUploadDialog = () => {
    setUploadDialogOpen(false);
  };
  
  // Handle file upload
  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (!file) return;
    
    const formData = new FormData();
    formData.append('file', file);
    formData.append('subject', testData.subject);
    formData.append('topics', JSON.stringify(testData.topics));
    
    onUploadMCQSet(formData);
    handleCloseUploadDialog();
  };
  
  // Open AI generation dialog
  const handleOpenAIDialog = () => {
    setAIGenerateDialogOpen(true);
  };
  
  // Close AI generation dialog
  const handleCloseAIDialog = () => {
    setAIGenerateDialogOpen(false);
  };
  
  // Handle AI parameter changes
  const handleAIParamChange = (e) => {
    const { name, value } = e.target;
    setAIGenerateParams(prev => ({ ...prev, [name]: value }));
  };
  
  // Generate AI questions
  const handleGenerateAIQuestions = async () => {
    const generatedQuestions = await onGenerateAIQuestions(aiGenerateParams);
    
    // Add generated questions to selected list
    if (generatedQuestions && generatedQuestions.length > 0) {
      const newQuestions = generatedQuestions.map((q, index) => ({
        mcqId: `ai-generated-${Date.now()}-${index}`,
        questionText: q.question,
        options: q.options,
        correctOption: q.correctOptionIndex,
        points: 1,
        order: selectedMCQs.length + index + 1,
        isAIGenerated: true,
        difficulty: q.difficulty,
        explanation: q.explanation
      }));
      
      const updatedQuestions = [...selectedMCQs, ...newQuestions];
      setSelectedMCQs(updatedQuestions);
      onSave({ questions: updatedQuestions });
    }
    
    handleCloseAIDialog();
  };
  
  // Open edit dialog for a question
  const handleOpenEditDialog = (question, index) => {
    setCurrentEditMCQ({ ...question, index });
    setEditDialogOpen(true);
  };
  
  // Close edit dialog
  const handleCloseEditDialog = () => {
    setEditDialogOpen(false);
    setCurrentEditMCQ(null);
  };
  
  // Save edited question
  const handleSaveEditedQuestion = () => {
    if (!currentEditMCQ) return;
    
    const updatedQuestions = [...selectedMCQs];
    updatedQuestions[currentEditMCQ.index] = {
      ...updatedQuestions[currentEditMCQ.index],
      questionText: currentEditMCQ.questionText,
      options: currentEditMCQ.options,
      correctOption: currentEditMCQ.correctOption
    };
    
    setSelectedMCQs(updatedQuestions);
    onSave({ questions: updatedQuestions });
    handleCloseEditDialog();
  };
  
  // Get subject name by ID
  const getSubjectName = (id) => {
    if (!id) return '';
    const subject = subjects.find(s => s._id === id);
    return subject ? subject.name : '';
  };
  
  // Get topic name by ID
  const getTopicName = (id) => {
    if (!id) return '';
    const topic = topics.find(t => t._id === id);
    return topic ? topic.name : '';
  };
  
  return (
    <Box sx={{ width: '100%' }}>
      <Grid container spacing={3}>
        {/* Left pane - Question bank and search */}
        <Grid item xs={12} md={6}>
          <Paper elevation={1} sx={{ p: 2, height: '100%' }}>
            <Typography variant="h6" gutterBottom>
              Question Bank
            </Typography>
            
            <Box sx={{ mb: 3 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Search Questions"
                    name="search"
                    value={searchParams.search}
                    onChange={handleSearchParamChange}
                    placeholder="Enter keywords to search..."
                    InputProps={{
                      endAdornment: (
                        <IconButton onClick={handleSearch} edge="end">
                          <SearchIcon />
                        </IconButton>
                      )
                    }}
                  />
                </Grid>
                
                <Grid item xs={12}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="difficulty-select-label">Difficulty</InputLabel>
                    <Select
                      labelId="difficulty-select-label"
                      name="difficulty"
                      value={searchParams.difficulty}
                      onChange={handleSearchParamChange}
                      label="Difficulty"
                    >
                      <MenuItem value="">All Difficulties</MenuItem>
                      <MenuItem value="easy">Easy</MenuItem>
                      <MenuItem value="medium">Medium</MenuItem>
                      <MenuItem value="hard">Hard</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                
                <Grid item xs={12}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button 
                      variant="outlined" 
                      startIcon={<UploadIcon />}
                      onClick={handleOpenUploadDialog}
                      disabled={!testData.subject}
                    >
                      Upload MCQ Set
                    </Button>
                    
                    <Button 
                      variant="outlined" 
                      startIcon={<AIIcon />}
                      onClick={handleOpenAIDialog}
                      disabled={!testData.subject}
                      color="primary"
                    >
                      Generate AI Questions
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            
            <Divider sx={{ mb: 2 }} />
            
            <Box sx={{ maxHeight: 400, overflow: 'auto' }}>
              <List>
                {mcqs.length > 0 ? (
                  mcqs.map((mcq, index) => (
                    <Card key={mcq._id} variant="outlined" sx={{ mb: 2 }}>
                      <CardContent>
                        <Typography variant="subtitle1" gutterBottom>
                          {mcq.question}
                        </Typography>
                        <Typography variant="caption" color="text.secondary">
                          Difficulty: {mcq.difficulty || 'Not specified'} | 
                          Subject: {getSubjectName(mcq.subject)}
                        </Typography>
                        
                        <List dense>
                          {mcq.options && mcq.options.map((option, idx) => (
                            <ListItem key={idx} dense>
                              <ListItemText 
                                primary={option.text} 
                                primaryTypographyProps={{
                                  style: {
                                    fontWeight: mcq.correctOptions && mcq.correctOptions.includes(option._id) ? 'bold' : 'normal',
                                    color: mcq.correctOptions && mcq.correctOptions.includes(option._id) ? 'green' : 'inherit'
                                  }
                                }}
                              />
                            </ListItem>
                          ))}
                        </List>
                      </CardContent>
                      <CardActions>
                        <Button 
                          size="small" 
                          variant="contained" 
                          onClick={() => handleAddQuestion(mcq)}
                          startIcon={<AddIcon />}
                          disabled={selectedMCQs.some(q => q.mcqId === mcq._id)}
                        >
                          Add to Test
                        </Button>
                      </CardActions>
                    </Card>
                  ))
                ) : (
                  <Typography align="center" color="text.secondary">
                    {loading ? 'Loading questions...' : 'No questions found. Try adjusting your search criteria.'}
                  </Typography>
                )}
              </List>
            </Box>
          </Paper>
        </Grid>
        
        {/* Right pane - Selected questions */}
        <Grid item xs={12} md={6}>
          <Paper elevation={1} sx={{ p: 2, height: '100%' }}>
            <Typography variant="h6" gutterBottom>
              Selected Questions
            </Typography>
            <Typography variant="body2" color="text.secondary" paragraph>
              {selectedMCQs.length} questions selected, {selectedMCQs.reduce((sum, q) => sum + (parseInt(q.points) || 1), 0)} total points
            </Typography>
            
            <Box sx={{ maxHeight: 500, overflow: 'auto' }}>
              <List>
                {selectedMCQs.length > 0 ? (
                  selectedMCQs.map((question, index) => (
                    <Card key={question.mcqId || `question-${index}`} variant="outlined" sx={{ mb: 2 }}>
                      <CardContent>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Typography variant="body1" sx={{ mr: 2 }}>
                            {question.order}.
                          </Typography>
                          <Typography variant="body1" sx={{ flex: 1 }}>
                            {question.questionText}
                          </Typography>
                        </Box>
                        
                        {question.isAIGenerated && (
                          <Chip 
                            label="AI Generated" 
                            color="primary" 
                            size="small" 
                            icon={<AIIcon />} 
                            sx={{ mt: 1 }}
                          />
                        )}
                      </CardContent>
                      <CardActions>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Typography variant="body2" sx={{ mr: 1 }}>
                            Points:
                          </Typography>
                          <TextField
                            size="small"
                            type="number"
                            value={question.points || 1}
                            onChange={(e) => handlePointsChange(index, e.target.value)}
                            inputProps={{ min: 1, max: 10, style: { width: '40px' } }}
                            sx={{ mr: 2 }}
                          />
                        </Box>
                        
                        <IconButton 
                          color="primary" 
                          onClick={() => handleMoveUp(index)}
                        >
                          <UpIcon />
                        </IconButton>
                        
                        <IconButton 
                          color="primary" 
                          onClick={() => handleMoveDown(index)}
                        >
                          <DownIcon />
                        </IconButton>
                        
                        <IconButton 
                          color="primary" 
                          onClick={() => handleOpenEditDialog(question, index)}
                        >
                          <EditIcon />
                        </IconButton>
                        
                        <IconButton 
                          color="error" 
                          onClick={() => handleRemoveQuestion(index)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </CardActions>
                    </Card>
                  ))
                ) : (
                  <Typography align="center" color="text.secondary">
                    No questions selected yet. Add questions from the question bank.
                  </Typography>
                )}
              </List>
            </Box>
          </Paper>
        </Grid>
      </Grid>
      
      {/* Upload MCQ Set Dialog */}
      <Dialog open={uploadDialogOpen} onClose={handleCloseUploadDialog}>
        <DialogTitle>
          Upload MCQ Set
          <IconButton
            aria-label="close"
            onClick={handleCloseUploadDialog}
            sx={{ position: 'absolute', right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body2" paragraph>
            Upload a CSV or JSON file containing MCQs. The file should include columns for question text, options, and correct answers.
          </Typography>
          
          <Button
            variant="contained"
            component="label"
            startIcon={<UploadIcon />}
            fullWidth
          >
            Select File
            <input
              type="file"
              accept=".csv,.json"
              hidden
              onChange={handleFileUpload}
            />
          </Button>
        </DialogContent>
      </Dialog>
      
      {/* AI Question Generation Dialog */}
      <Dialog open={aiGenerateDialogOpen} onClose={handleCloseAIDialog} maxWidth="sm" fullWidth>
        <DialogTitle>
          Generate AI Questions
          <IconButton
            aria-label="close"
            onClick={handleCloseAIDialog}
            sx={{ position: 'absolute', right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="ai-topic-select-label">Topic</InputLabel>
                <Select
                  labelId="ai-topic-select-label"
                  name="topic"
                  value={aiGenerateParams.topic}
                  onChange={handleAIParamChange}
                  label="Topic"
                >
                  {topics.map((topic) => (
                    <MenuItem key={topic._id} value={topic._id}>
                      {topic.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="ai-difficulty-select-label">Difficulty</InputLabel>
                <Select
                  labelId="ai-difficulty-select-label"
                  name="difficulty"
                  value={aiGenerateParams.difficulty}
                  onChange={handleAIParamChange}
                  label="Difficulty"
                >
                  <MenuItem value="easy">Easy</MenuItem>
                  <MenuItem value="medium">Medium</MenuItem>
                  <MenuItem value="hard">Hard</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Number of Questions"
                name="count"
                type="number"
                value={aiGenerateParams.count}
                onChange={handleAIParamChange}
                inputProps={{ min: 1, max: 10 }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAIDialog}>Cancel</Button>
          <Button 
            onClick={handleGenerateAIQuestions} 
            variant="contained" 
            color="primary"
            disabled={!aiGenerateParams.topic}
          >
            Generate
          </Button>
        </DialogActions>
      </Dialog>
      
      {/* Edit Question Dialog */}
      <Dialog open={editDialogOpen} onClose={handleCloseEditDialog} maxWidth="md" fullWidth>
        <DialogTitle>
          Edit Question
          <IconButton
            aria-label="close"
            onClick={handleCloseEditDialog}
            sx={{ position: 'absolute', right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {currentEditMCQ && (
            <Grid container spacing={2} sx={{ mt: 1 }}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Question Text"
                  value={currentEditMCQ.questionText}
                  onChange={(e) => setCurrentEditMCQ({ ...currentEditMCQ, questionText: e.target.value })}
                  multiline
                  rows={3}
                />
              </Grid>
              
              {currentEditMCQ.options && currentEditMCQ.options.map((option, index) => (
                <Grid item xs={12} key={index}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <TextField
                      fullWidth
                      label={`Option ${index + 1}`}
                      value={option.text}
                      onChange={(e) => {
                        const updatedOptions = [...currentEditMCQ.options];
                        updatedOptions[index] = { ...option, text: e.target.value };
                        setCurrentEditMCQ({ ...currentEditMCQ, options: updatedOptions });
                      }}
                      sx={{ mr: 2 }}
                    />
                    
                    <Radio
                      checked={currentEditMCQ.correctOption === index}
                      onChange={() => setCurrentEditMCQ({ ...currentEditMCQ, correctOption: index })}
                    />
                  </Box>
                </Grid>
              ))}
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEditDialog}>Cancel</Button>
          <Button 
            onClick={handleSaveEditedQuestion} 
            variant="contained" 
            color="primary"
          >
            Save Changes
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default QuestionSelection;
