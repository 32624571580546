import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Grid, 
  Paper, 
  Typography, 
  CircularProgress, 
  Card, 
  CardContent,
  Divider,
  Button,
  useTheme,
  useMediaQuery,
  Container,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  AppBar,
  Toolbar,
  CssBaseline,
  Avatar,
  Menu,
  MenuItem,
  Snackbar,
  Alert
} from '@mui/material';
import {
  Menu as MenuIcon,
  Dashboard as DashboardIcon,
  Book as BookIcon,
  Topic as TopicIcon,
  QuestionAnswer as QuestionAnswerIcon,
  Assignment as AssignmentIcon,
  People as PeopleIcon,
  School as SchoolIcon,
  Settings as SettingsIcon,
  ExitToApp as LogoutIcon,
  ChevronLeft as ChevronLeftIcon,
  AccountCircle as AccountCircleIcon,
  Notifications as NotificationsIcon
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import API from '../../services/api';
import EnhancedSubjectManagement from './SubjectManagement/EnhancedSubjectManagement';
import TopicManagement from './TopicManagement';
import MCQManagement from './MCQManagement';
import DashboardHome from './DashboardHome';
import UserManagement from './UserManagement';
import ClassManagement from './ClassManagement';
import ClassDetail from './ClassDetail';
import classService from '../../services/classService';
import subjectService from '../../services/subjectService';
import topicService from '../../services/topicService';
import axios from 'axios';
import config from '../../config';
import TestManagement from './TestManagement'; // Import TestManagement component

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const AppBarStyled = styled(AppBar, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: `${drawerWidth}px`,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }),
);

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const TeacherDashboard = ({ getToken, user, showNotification }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  
  const [open, setOpen] = useState(!isMobile);
  const [activeSection, setActiveSection] = useState('dashboard');
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [institutionData, setInstitutionData] = useState(null);
  
  // State for subject management
  const [subjects, setSubjects] = useState([]);
  const [totalSubjects, setTotalSubjects] = useState(0);
  const [subjectPage, setSubjectPage] = useState(0);
  const [subjectsPerPage, setSubjectsPerPage] = useState(8);
  
  // State for topic management
  const [topics, setTopics] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState(null);
  
  // State for MCQ management
  const [mcqs, setMcqs] = useState([]);
  const [totalMcqs, setTotalMcqs] = useState(0);
  const [mcqPage, setMcqPage] = useState(0);
  const [mcqsPerPage, setMcqsPerPage] = useState(10);
  const [selectedTopic, setSelectedTopic] = useState(null);

  // Enhanced state for class management
  const [classes, setClasses] = useState([]);
  const [selectedClass, setSelectedClass] = useState(null);
  const [classSubjects, setClassSubjects] = useState([]);
  const [classTopics, setClassTopics] = useState([]);
  const [classLoading, setClassLoading] = useState(false);
  const [alertInfo, setAlertInfo] = useState({ open: false, message: '', severity: 'info' });

  useEffect(() => {
    // Adjust drawer state based on screen size
    setOpen(!isMobile);
  }, [isMobile]);

  useEffect(() => {
    // Get institution data from user prop 
    if (user && user.institution) {
      console.log('User has institution ID:', user.institution);
      // Create basic institutionData object with the ID
      setInstitutionData({
        _id: user.institution,
        name: user.fullName ? `${user.fullName}'s Institution` : 'My Institution'
      });
    } else {
      console.log('No institution data found in user object, attempting to fetch');
      fetchInstitutionData();
    }
  }, [user]);

  const fetchInstitutionData = async () => {
    setLoading(true);
    try {
      // Use axios directly instead of incorrect API.get
      const response = await axios.get(`${config.API_URL}/api/teacher/institution`, {
        headers: {
          'Authorization': `Bearer ${getToken()}`,
        }
      });
      
      if (response.data.success) {
        console.log('Institution data fetched successfully:', response.data);
        setInstitutionData(response.data.data);
      } else {
        showNotification('error', 'Failed to fetch institution data');
      }
    } catch (error) {
      console.error('Error fetching institution data:', error);
      showNotification('error', 'Error fetching institution data');
    } finally {
      setLoading(false);
    }
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    // Implement logout functionality
    handleMenuClose();
  };

  const handleSectionChange = (section) => {
    setActiveSection(section);
    if (isMobile) {
      setOpen(false);
    }
  };

  // Class management functions
  const fetchClassDetails = async (classId) => {
    if (!institutionData?._id) return;
    
    setClassLoading(true);
    try {
      // Fetch class details
      const classResult = await classService.getClassById(institutionData._id, classId, getToken());
      setSelectedClass(classResult.class);

      // Fetch subjects for the selected class using the correct API endpoint
      try {
        const response = await axios.get(
          `${config.API_URL}/api/subjects?institutionId=${institutionData._id}&classId=${classId}`,
          {
            headers: {
              'Authorization': `Bearer ${getToken()}`
            }
          }
        );
        
        if (response.data.success) {
          setClassSubjects(response.data.subjects || []);
          
          // Fetch topics for each subject
          let allTopics = [];
          for (const subject of response.data.subjects || []) {
            try {
              const topicsResponse = await axios.get(
                `${config.API_URL}/api/subjects/${subject._id}/topics`,
                {
                  headers: {
                    'Authorization': `Bearer ${getToken()}`
                  }
                }
              );
              
              if (topicsResponse.data.success) {
                allTopics = [...allTopics, ...(topicsResponse.data.topics || [])];
              }
            } catch (topicError) {
              console.error('Error fetching topics for subject:', subject._id, topicError);
            }
          }
          
          setClassTopics(allTopics);
        }
      } catch (subjectError) {
        console.error('Error fetching subjects for class:', classId, subjectError);
        setClassSubjects([
          { _id: 'mock-subject-1', name: 'Mock Subject 1' },
          { _id: 'mock-subject-2', name: 'Mock Subject 2' },
        ]);
        setClassTopics([
          { _id: 'mock-topic-1', name: 'Mock Topic 1', subjectId: 'mock-subject-1' },
          { _id: 'mock-topic-2', name: 'Mock Topic 2', subjectId: 'mock-subject-1' },
        ]);
      }
    } catch (error) {
      console.error('Error fetching class details:', error);
      setSelectedClass({
        _id: 'mock-class-1',
        name: 'Mock Class 1',
        subjects: [
          { _id: 'mock-subject-1', name: 'Mock Subject 1' },
          { _id: 'mock-subject-2', name: 'Mock Subject 2' },
        ],
        topics: [
          { _id: 'mock-topic-1', name: 'Mock Topic 1', subjectId: 'mock-subject-1' },
          { _id: 'mock-topic-2', name: 'Mock Topic 2', subjectId: 'mock-subject-1' },
        ],
      });
      setClassSubjects([
        { _id: 'mock-subject-1', name: 'Mock Subject 1' },
        { _id: 'mock-subject-2', name: 'Mock Subject 2' },
      ]);
      setClassTopics([
        { _id: 'mock-topic-1', name: 'Mock Topic 1', subjectId: 'mock-subject-1' },
        { _id: 'mock-topic-2', name: 'Mock Topic 2', subjectId: 'mock-subject-1' },
      ]);
      showAlert('error', 'Failed to load class details');
    } finally {
      setClassLoading(false);
    }
  };

  const handleAddSubject = (classId) => {
    // Navigate to subject creation page or show modal
    // Implement subject creation logic
    showAlert('info', 'Subject creation will be implemented soon');
  };

  const handleEditSubject = (subject) => {
    // Implement subject editing logic
    showAlert('info', 'Subject editing will be implemented soon');
  };

  const handleDeleteSubject = (subject) => {
    // Implement subject deletion logic
    showAlert('info', 'Subject deletion will be implemented soon');
  };

  const handleAddTopic = (subjectId) => {
    // Navigate to topic creation page or show modal
    // Implement topic creation logic
    showAlert('info', 'Topic creation will be implemented soon');
  };

  const handleEditTopic = (topic) => {
    // Implement topic editing logic
    showAlert('info', 'Topic editing will be implemented soon');
  };

  const handleDeleteTopic = (topic) => {
    // Implement topic deletion logic
    showAlert('info', 'Topic deletion will be implemented soon');
  };

  const handleViewTopics = (subject) => {
    setSelectedSubject(subject);
    setActiveSection('topics');
  };

  const showAlert = (severity, message) => {
    setAlertInfo({
      open: true,
      message,
      severity
    });
  };

  const handleAlertClose = () => {
    setAlertInfo({
      ...alertInfo,
      open: false
    });
  };

  // Render the active section content
  const renderSection = () => {
    switch (activeSection) {
      case 'dashboard':
        return (
          <DashboardHome 
            institutionData={institutionData} 
            loading={loading}
          />
        );
      case 'classes':
        return selectedClass ? (
          <ClassDetail
            classItem={selectedClass}
            subjects={classSubjects}
            topics={classTopics}
            loading={classLoading}
            onBackToClasses={() => setSelectedClass(null)}
            onEditClass={(classItem) => {
              // Implement class editing logic or delegate to ClassManagement
              showAlert('info', 'Class editing will be implemented soon');
            }}
            onDeleteClass={(classItem) => {
              // Implement class deletion logic or delegate to ClassManagement
              showAlert('info', 'Class deletion will be implemented soon');
            }}
            onAddSubject={handleAddSubject}
            onEditSubject={handleEditSubject}
            onDeleteSubject={handleDeleteSubject}
            onAddTopic={handleAddTopic}
            onEditTopic={handleEditTopic}
            onDeleteTopic={handleDeleteTopic}
            onViewTopics={handleViewTopics}
          />
        ) : (
          <ClassManagement 
            institutionId={institutionData?._id} 
            token={getToken()} 
            showNotification={showNotification}
            onClassSelect={(classItem) => {
              console.log('Class selected in Dashboard:', classItem);
              // Since we're using mock data, just set the class directly
              setSelectedClass(classItem);
              setClassSubjects([
                { _id: 'mock-subject-1', name: 'Physics', subject: 'Physics' },
                { _id: 'mock-subject-2', name: 'Chemistry', subject: 'Chemistry' },
                { _id: 'mock-subject-3', name: 'Biology', subject: 'Biology' },
                { _id: 'mock-subject-4', name: 'Mathematics', subject: 'Mathematics' },
              ]);
              setClassTopics([
                { _id: 'mock-topic-1', name: 'Mechanics', description: 'Study of motion and forces', subject: 'mock-subject-1' },
                { _id: 'mock-topic-2', name: 'Thermodynamics', description: 'Study of heat and energy', subject: 'mock-subject-1' },
                { _id: 'mock-topic-3', name: 'Organic Chemistry', description: 'Study of carbon compounds', subject: 'mock-subject-2' },
                { _id: 'mock-topic-4', name: 'Inorganic Chemistry', description: 'Study of non-carbon elements', subject: 'mock-subject-2' },
                { _id: 'mock-topic-5', name: 'Cell Biology', description: 'Study of cells and their functions', subject: 'mock-subject-3' },
                { _id: 'mock-topic-6', name: 'Anatomy', description: 'Study of body structure', subject: 'mock-subject-3' },
                { _id: 'mock-topic-7', name: 'Algebra', description: 'Study of mathematical symbols and rules', subject: 'mock-subject-4' },
                { _id: 'mock-topic-8', name: 'Calculus', description: 'Study of rates of change', subject: 'mock-subject-4' },
              ]);
              // Show loading initially but quickly hide it
              setClassLoading(true);
              setTimeout(() => setClassLoading(false), 500);
            }}
          />
        );
      case 'mcqs':
        return (
          <MCQManagement 
            mcqs={mcqs}
            totalMcqs={totalMcqs}
            subjects={subjects}
            topics={topics}
            page={mcqPage}
            rowsPerPage={mcqsPerPage}
            onPageChange={(event, newPage) => setMcqPage(newPage)}
            onRowsPerPageChange={(event) => {
              setMcqsPerPage(parseInt(event.target.value, 10));
              setMcqPage(0);
            }}
            onAddMcq={() => {/* Implement add mcq */}}
            onEditMcq={() => {/* Implement edit mcq */}}
            onDeleteMcq={() => {/* Implement delete mcq */}}
          />
        );
      case 'tests':
        return (
          <TestManagement
            institutionId={institutionData?._id}
            token={getToken()}
            showNotification={showNotification}
          />
        );
      case 'users':
        return (
          <UserManagement
            getToken={getToken}
            showNotification={showNotification}
          />
        );
      case 'students':
        return <Typography>Student Management (Coming soon)</Typography>;
      case 'settings':
        return <Typography>Settings (Coming soon)</Typography>;
      default:
        return <Typography>Select a section from the sidebar</Typography>;
    }
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBarStyled position="fixed" open={open} color="primary">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
            Teacher Dashboard {institutionData && `- ${institutionData.name}`}
          </Typography>
          <IconButton color="inherit">
            <NotificationsIcon />
          </IconButton>
          <IconButton
            color="inherit"
            onClick={handleMenuOpen}
            aria-controls="profile-menu"
            aria-haspopup="true"
          >
            <AccountCircleIcon />
          </IconButton>
          <Menu
            id="profile-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
            <MenuItem onClick={handleMenuClose}>Settings</MenuItem>
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          </Menu>
        </Toolbar>
      </AppBarStyled>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant={isMobile ? "temporary" : "persistent"}
        anchor="left"
        open={open}
        onClose={handleDrawerClose}
      >
        <DrawerHeader>
          <Box sx={{ display: 'flex', alignItems: 'center', p: 1, width: '100%' }}>
            <Avatar sx={{ mr: 1, bgcolor: theme.palette.primary.main }}>
              <SchoolIcon />
            </Avatar>
            <Typography variant="h6" noWrap>
              Neetly Teacher
            </Typography>
          </Box>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          <ListItem 
            button 
            onClick={() => handleSectionChange('dashboard')}
            selected={activeSection === 'dashboard'}
          >
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItem>
          
          <ListItem 
            button 
            onClick={() => handleSectionChange('classes')}
            selected={activeSection === 'classes'}
          >
            <ListItemIcon>
              <SchoolIcon />
            </ListItemIcon>
            <ListItemText primary="Classes" />
          </ListItem>
          
          <ListItem 
            button 
            onClick={() => handleSectionChange('mcqs')}
            selected={activeSection === 'mcqs'}
          >
            <ListItemIcon>
              <QuestionAnswerIcon />
            </ListItemIcon>
            <ListItemText primary="MCQs" />
          </ListItem>
          
          <ListItem 
            button 
            onClick={() => handleSectionChange('tests')}
            selected={activeSection === 'tests'}
          >
            <ListItemIcon>
              <AssignmentIcon />
            </ListItemIcon>
            <ListItemText primary="Tests" />
          </ListItem>
          
          <ListItem 
            button 
            onClick={() => handleSectionChange('users')}
            selected={activeSection === 'users'}
          >
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>
            <ListItemText primary="Users" />
          </ListItem>
          
          <Divider sx={{ my: 1 }} />
          
          <ListItem 
            button 
            onClick={() => handleSectionChange('settings')}
            selected={activeSection === 'settings'}
          >
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary="Settings" />
          </ListItem>
          
          <ListItem button onClick={handleLogout}>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
        </List>
      </Drawer>
      <Main open={open}>
        <DrawerHeader />
        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
            <CircularProgress />
          </Box>
        ) : (
          <Container maxWidth="xl">
            {renderSection()}
          </Container>
        )}
        <Snackbar 
          open={alertInfo.open} 
          autoHideDuration={6000} 
          onClose={handleAlertClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert onClose={handleAlertClose} severity={alertInfo.severity} sx={{ width: '100%' }}>
            {alertInfo.message}
          </Alert>
        </Snackbar>
      </Main>
    </Box>
  );
};

export default TeacherDashboard;
