import React, { useState, useEffect } from 'react';
import { 
  Box, Typography, Button, Breadcrumbs, Link,
  Card, CardContent, IconButton, Divider,
  Accordion, AccordionSummary, AccordionDetails,
  List, ListItem, ListItemText, ListItemSecondaryAction,
  Chip, CircularProgress, Paper, Grid, Tab, Tabs,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  LinearProgress, Avatar, Tooltip
} from '@mui/material';
import { 
  Edit, Delete, Add, ExpandMore, Person, 
  MenuBook, School, Assessment, ArrowBack,
  Assignment, QuestionAnswer, AssignmentTurnedIn,
  PieChart, BarChart, Timeline, Group, CheckCircle,
  Info, Star, StarBorder, StarHalf
} from '@mui/icons-material';

const ClassDetail = ({ 
  classItem, 
  subjects = [],
  topics = [],
  loading = false,
  onBackToClasses,
  onEditClass,
  onDeleteClass,
  onAddSubject,
  onEditSubject,
  onDeleteSubject,
  onAddTopic,
  onEditTopic,
  onDeleteTopic,
  onViewTopics
}) => {
  const [expandedSubject, setExpandedSubject] = useState(null);
  
  // Generate some mock data for demonstration
  const mockTeachers = [
    { id: 't1', name: 'Dr. Rajesh Kumar', avatar: 'R', subject: 'Physics' },
    { id: 't2', name: 'Dr. Priya Sharma', avatar: 'P', subject: 'Chemistry' },
    { id: 't3', name: 'Dr. Anand Verma', avatar: 'A', subject: 'Biology' },
    { id: 't4', name: 'Prof. Meera Patel', avatar: 'M', subject: 'Mathematics' }
  ];
  
  // Mock subject data with enhanced details
  const enrichedSubjects = subjects.length > 0 ? subjects.map((subject, index) => {
    const topicsCount = topics.filter(topic => topic.subject === subject._id).length;
    const mockTeacher = mockTeachers[index % mockTeachers.length];
    const mockMCQsCount = Math.floor(Math.random() * 50) + 10;
    const mockTestsCount = Math.floor(Math.random() * 8) + 2;
    const mockStudentsCount = classItem?.studentsCount || 45;
    
    return {
      ...subject,
      topicsCount,
      mcqsCount: mockMCQsCount,
      testsCount: mockTestsCount,
      studentsCount: mockStudentsCount,
      teacher: mockTeacher,
      lastUpdated: new Date(Date.now() - Math.floor(Math.random() * 604800000)).toLocaleDateString()
    };
  }) : [
    {
      _id: 'mock-subject-1',
      name: 'Physics',
      topicsCount: 12,
      mcqsCount: 245,
      testsCount: 5,
      studentsCount: classItem?.studentsCount || 45,
      teacher: mockTeachers[0],
      lastUpdated: new Date(Date.now() - 86400000 * 2).toLocaleDateString()
    },
    {
      _id: 'mock-subject-2',
      name: 'Chemistry',
      topicsCount: 15,
      mcqsCount: 320,
      testsCount: 6,
      studentsCount: classItem?.studentsCount || 45,
      teacher: mockTeachers[1],
      lastUpdated: new Date(Date.now() - 86400000 * 5).toLocaleDateString()
    },
    {
      _id: 'mock-subject-3',
      name: 'Biology',
      topicsCount: 18,
      mcqsCount: 410,
      testsCount: 7,
      studentsCount: classItem?.studentsCount || 45,
      teacher: mockTeachers[2],
      lastUpdated: new Date(Date.now() - 86400000 * 1).toLocaleDateString()
    },
    {
      _id: 'mock-subject-4',
      name: 'Mathematics',
      topicsCount: 10,
      mcqsCount: 180,
      testsCount: 4,
      studentsCount: classItem?.studentsCount || 45,
      teacher: mockTeachers[3],
      lastUpdated: new Date(Date.now() - 86400000 * 3).toLocaleDateString()
    }
  ];

  const handleSubjectToggle = (subjectId) => {
    setExpandedSubject(expandedSubject === subjectId ? null : subjectId);
  };

  // Filter topics by subject
  const getTopicsForSubject = (subjectId) => {
    return topics.filter(topic => topic.subject === subjectId);
  };

  return (
    <Box>
      {/* Breadcrumb navigation */}
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
        <IconButton onClick={onBackToClasses} sx={{ mr: 1 }}>
          <ArrowBack />
        </IconButton>
        <Breadcrumbs>
          <Link 
            component="button"
            underline="hover" 
            color="inherit" 
            onClick={onBackToClasses}
            sx={{ cursor: 'pointer' }}
          >
            Classes
          </Link>
          <Typography color="text.primary">
            {classItem?.displayName || classItem?.name}
          </Typography>
        </Breadcrumbs>
      </Box>
      
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          {/* Class header with stats */}
          <Card sx={{ mb: 4 }}>
            <CardContent>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <School sx={{ mr: 1, fontSize: 28, color: 'primary.main' }} />
                  <Typography variant="h5">
                    {classItem?.displayName || classItem?.name}
                  </Typography>
                </Box>
                <Box>
                  <Button 
                    variant="outlined" 
                    startIcon={<Add />}
                    onClick={() => onAddSubject(classItem?._id)}
                    sx={{ mr: 1 }}
                  >
                    Add Subject
                  </Button>
                  <IconButton onClick={() => onEditClass(classItem)} title="Edit class">
                    <Edit />
                  </IconButton>
                  <IconButton onClick={() => onDeleteClass(classItem)} title="Delete class">
                    <Delete />
                  </IconButton>
                </Box>
              </Box>
              
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <Person sx={{ mr: 1, color: 'primary.main' }} />
                <Typography variant="body1">
                  <strong>Class Teacher:</strong> {classItem?.teacher?.name || 'Not assigned'}
                </Typography>
              </Box>
              
              <Grid container spacing={3} sx={{ mt: 1 }}>
                <Grid item xs={12} sm={6} md={3}>
                  <Card variant="outlined" sx={{ bgcolor: 'primary.50' }}>
                    <CardContent>
                      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                        <Group sx={{ mr: 1, color: 'primary.main' }} />
                        <Typography variant="subtitle2" color="primary.main">Students</Typography>
                      </Box>
                      <Typography variant="h4" component="div" sx={{ fontWeight: 'bold' }}>
                        {classItem?.studentsCount || 45}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                
                <Grid item xs={12} sm={6} md={3}>
                  <Card variant="outlined" sx={{ bgcolor: 'secondary.50' }}>
                    <CardContent>
                      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                        <MenuBook sx={{ mr: 1, color: 'secondary.main' }} />
                        <Typography variant="subtitle2" color="secondary.main">Subjects</Typography>
                      </Box>
                      <Typography variant="h4" component="div" sx={{ fontWeight: 'bold' }}>
                        {enrichedSubjects.length}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                
                <Grid item xs={12} sm={6} md={3}>
                  <Card variant="outlined" sx={{ bgcolor: 'info.50' }}>
                    <CardContent>
                      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                        <QuestionAnswer sx={{ mr: 1, color: 'info.main' }} />
                        <Typography variant="subtitle2" color="info.main">Total MCQs</Typography>
                      </Box>
                      <Typography variant="h4" component="div" sx={{ fontWeight: 'bold' }}>
                        {enrichedSubjects.reduce((total, subject) => total + subject.mcqsCount, 0)}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                
                <Grid item xs={12} sm={6} md={3}>
                  <Card variant="outlined" sx={{ bgcolor: 'success.50' }}>
                    <CardContent>
                      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                        <Assessment sx={{ mr: 1, color: 'success.main' }} />
                        <Typography variant="subtitle2" color="success.main">Tests</Typography>
                      </Box>
                      <Typography variant="h4" component="div" sx={{ fontWeight: 'bold' }}>
                        {enrichedSubjects.reduce((total, subject) => total + subject.testsCount, 0)}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          
          {/* Subjects Table */}
          <Box sx={{ mt: 4 }}>
            <Typography variant="h6" sx={{ mb: 2 }}>Subjects</Typography>
            
            <TableContainer component={Paper}>
              <Table aria-label="subjects table">
                <TableHead sx={{ bgcolor: 'primary.light' }}>
                  <TableRow>
                    <TableCell>Subject</TableCell>
                    <TableCell>Teacher</TableCell>
                    <TableCell align="center">Topics</TableCell>
                    <TableCell align="center">MCQs</TableCell>
                    <TableCell align="center">Tests</TableCell>
                    <TableCell align="center">Students</TableCell>
                    <TableCell align="right">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {enrichedSubjects.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={7} align="center">
                        <Box sx={{ py: 3 }}>
                          <Typography variant="body1" sx={{ mb: 2 }}>
                            No subjects available for this class
                          </Typography>
                          <Button 
                            variant="contained" 
                            startIcon={<Add />}
                            onClick={() => onAddSubject(classItem?._id)}
                          >
                            Add Subject
                          </Button>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ) : (
                    enrichedSubjects.map((subject) => (
                      <TableRow 
                        key={subject._id}
                        sx={{ 
                          '&:last-child td, &:last-child th': { border: 0 },
                          bgcolor: expandedSubject === subject._id ? 'action.hover' : 'inherit'
                        }}
                        hover
                      >
                        <TableCell component="th" scope="row">
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <MenuBook sx={{ mr: 1, color: 'primary.main' }} />
                            <Typography variant="subtitle2">{subject.name}</Typography>
                          </Box>
                        </TableCell>
                        <TableCell>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Avatar sx={{ width: 24, height: 24, mr: 1, fontSize: '0.875rem' }}>
                              {subject.teacher.avatar}
                            </Avatar>
                            <Typography variant="body2">{subject.teacher.name}</Typography>
                          </Box>
                        </TableCell>
                        <TableCell align="center">
                          <Chip 
                            label={subject.topicsCount} 
                            size="small" 
                            color="primary" 
                            variant="outlined"
                            onClick={() => handleSubjectToggle(subject._id)}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <Chip 
                            label={subject.mcqsCount} 
                            size="small" 
                            color="secondary" 
                            variant="outlined"
                          />
                        </TableCell>
                        <TableCell align="center">
                          <Chip 
                            label={subject.testsCount} 
                            size="small" 
                            color="success" 
                            variant="outlined"
                          />
                        </TableCell>
                        <TableCell align="center">
                          <Chip 
                            label={subject.studentsCount} 
                            size="small" 
                            color="info" 
                            variant="outlined"
                          />
                        </TableCell>
                        <TableCell align="right">
                          <Box>
                            <Tooltip title="View topics">
                              <IconButton 
                                size="small" 
                                color="primary"
                                onClick={() => handleSubjectToggle(subject._id)}
                              >
                                <Info fontSize="small" />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Edit subject">
                              <IconButton 
                                size="small"
                                onClick={() => onEditSubject(subject)}
                              >
                                <Edit fontSize="small" />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Delete subject">
                              <IconButton 
                                size="small" 
                                color="error"
                                onClick={() => onDeleteSubject(subject)}
                              >
                                <Delete fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          
          {/* Topics List for Selected Subject */}
          {expandedSubject && (
            <Card sx={{ mt: 3, mb: 3 }}>
              <CardContent>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Typography variant="h6">
                    {enrichedSubjects.find(s => s._id === expandedSubject)?.name} Topics
                  </Typography>
                  <Button 
                    size="small" 
                    variant="contained"
                    startIcon={<Add />}
                    onClick={() => onAddTopic(expandedSubject)}
                  >
                    Add Topic
                  </Button>
                </Box>
                
                <Divider sx={{ my: 2 }} />
                
                <List sx={{ width: '100%' }}>
                  {getTopicsForSubject(expandedSubject).length > 0 ? (
                    getTopicsForSubject(expandedSubject).map((topic, index) => (
                      <ListItem 
                        key={topic._id} 
                        divider={index < getTopicsForSubject(expandedSubject).length - 1}
                        secondaryAction={
                          <Box>
                            <IconButton edge="end" size="small" onClick={() => onEditTopic(topic)}>
                              <Edit fontSize="small" />
                            </IconButton>
                            <IconButton edge="end" size="small" onClick={() => onDeleteTopic(topic)}>
                              <Delete fontSize="small" />
                            </IconButton>
                          </Box>
                        }
                      >
                        <ListItemText 
                          primary={topic.name} 
                          secondary={topic.description || "No description available"}
                        />
                      </ListItem>
                    ))
                  ) : (
                    <ListItem>
                      <ListItemText 
                        primary="No topics available" 
                        secondary="Click the 'Add Topic' button to create a new topic"
                      />
                    </ListItem>
                  )}
                </List>
              </CardContent>
            </Card>
          )}
        </>
      )}
    </Box>
  );
};

export default ClassDetail;
