// Blue theme configuration for the Institution Admin Dashboard
const blueTheme = {
  primary: {
    main: '#1976d2',
    light: '#42a5f5',
    dark: '#1565c0',
    contrastText: '#fff'
  },
  secondary: {
    main: '#03a9f4',
    light: '#4fc3f7',
    dark: '#0288d1',
    contrastText: '#fff'
  },
  background: {
    default: '#f5f7fa',
    paper: '#ffffff'
  },
  text: {
    primary: '#263238',
    secondary: '#546e7a'
  },
  error: {
    main: '#f44336',
    light: '#e57373'
  },
  success: {
    main: '#4caf50',
    light: '#81c784'
  },
  warning: {
    main: '#ff9800',
    light: '#ffb74d'
  },
  info: {
    main: '#2196f3',
    light: '#64b5f6'
  },
  // Common UI element styling
  borderRadius: {
    small: '4px',
    medium: '8px',
    large: '12px'
  },
  boxShadow: {
    light: '0 2px 10px rgba(0,0,0,0.05)',
    medium: '0 4px 20px rgba(0,0,0,0.08)',
    heavy: '0 8px 30px rgba(0,0,0,0.12)'
  },
  transition: 'all 0.3s ease'
};

export default blueTheme;
