import React from 'react';
import { 
  Box, 
  Paper, 
  Typography, 
  Grid, 
  Card, 
  CardContent, 
  Divider,
  List,
  ListItem,
  ListItemText,
  Chip,
  Button,
  Alert
} from '@mui/material';
import { 
  CalendarToday as CalendarIcon,
  AccessTime as TimeIcon,
  School as ClassIcon, 
  Book as SubjectIcon,
  Topic as TopicIcon,
  Quiz as QuizIcon,
  CheckCircle as CorrectIcon,
  Settings as SettingsIcon
} from '@mui/icons-material';

const TestReview = ({
  testData,
  subjects,
  topics,
  classes,
  onPublish,
  onSaveAsDraft,
  loading
}) => {
  // Helper function to get entity name by ID
  const getEntityName = (id, collection) => {
    if (!id) return 'Not specified';
    const entity = collection.find(item => item._id === id);
    return entity ? entity.name : 'Unknown';
  };
  
  // Format date
  const formatDate = (date) => {
    if (!date) return 'Not specified';
    return new Date(date).toLocaleString();
  };
  
  // Calculate total marks
  const totalMarks = testData.questions.reduce((sum, q) => sum + (parseInt(q.points) || 1), 0);
  
  // Get selected topics names
  const getSelectedTopicsNames = () => {
    if (!testData.topics || testData.topics.length === 0) return 'All topics';
    return testData.topics.map(id => getEntityName(id, topics)).join(', ');
  };
  
  // Check for potential issues
  const checkForIssues = () => {
    const issues = [];
    
    if (!testData.title) {
      issues.push('Test title is missing');
    }
    
    if (!testData.subject) {
      issues.push('No subject selected');
    }
    
    if (!testData.classId) {
      issues.push('No class/grade selected');
    }
    
    if (!testData.questions || testData.questions.length === 0) {
      issues.push('No questions added to the test');
    } else if (testData.questions.length < 5) {
      issues.push('Test has fewer than 5 questions. Consider adding more for a comprehensive assessment.');
    }
    
    if (testData.startDate && testData.endDate && new Date(testData.startDate) >= new Date(testData.endDate)) {
      issues.push('Start date is after or equal to end date');
    }
    
    if (testData.duration <= 0) {
      issues.push('Test duration must be greater than 0 minutes');
    }
    
    return issues;
  };
  
  const issues = checkForIssues();
  
  return (
    <Box sx={{ width: '100%' }}>
      <Paper elevation={0} sx={{ p: 2, mb: 3 }}>
        <Typography variant="h6" gutterBottom>
          Review Test Details
        </Typography>
        <Typography variant="body2" color="text.secondary" paragraph>
          Review all test details before publishing or saving as draft.
        </Typography>
        
        {issues.length > 0 && (
          <Alert severity="warning" sx={{ mb: 3 }}>
            <Typography variant="subtitle2">
              Please address the following issues:
            </Typography>
            <List dense disablePadding>
              {issues.map((issue, index) => (
                <ListItem key={index} dense disablePadding>
                  <ListItemText primary={`• ${issue}`} />
                </ListItem>
              ))}
            </List>
          </Alert>
        )}
        
        <Grid container spacing={3}>
          {/* Basic Information */}
          <Grid item xs={12} md={6}>
            <Card variant="outlined">
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Basic Information
                </Typography>
                
                <List disablePadding>
                  <ListItem disablePadding sx={{ py: 1 }}>
                    <ListItemText
                      primary="Test Title"
                      secondary={testData.title || 'Not specified'}
                      primaryTypographyProps={{ variant: 'subtitle2' }}
                    />
                  </ListItem>
                  
                  <Divider component="li" />
                  
                  <ListItem disablePadding sx={{ py: 1 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', mr: 1 }}>
                      <ClassIcon fontSize="small" color="primary" />
                    </Box>
                    <ListItemText
                      primary="Class/Grade"
                      secondary={getEntityName(testData.classId, classes)}
                      primaryTypographyProps={{ variant: 'subtitle2' }}
                    />
                  </ListItem>
                  
                  <Divider component="li" />
                  
                  <ListItem disablePadding sx={{ py: 1 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', mr: 1 }}>
                      <SubjectIcon fontSize="small" color="primary" />
                    </Box>
                    <ListItemText
                      primary="Subject"
                      secondary={getEntityName(testData.subject, subjects)}
                      primaryTypographyProps={{ variant: 'subtitle2' }}
                    />
                  </ListItem>
                  
                  <Divider component="li" />
                  
                  <ListItem disablePadding sx={{ py: 1 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', mr: 1 }}>
                      <TopicIcon fontSize="small" color="primary" />
                    </Box>
                    <ListItemText
                      primary="Topics"
                      secondary={getSelectedTopicsNames()}
                      primaryTypographyProps={{ variant: 'subtitle2' }}
                    />
                  </ListItem>
                  
                  <Divider component="li" />
                  
                  <ListItem disablePadding sx={{ py: 1 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', mr: 1 }}>
                      <TimeIcon fontSize="small" color="primary" />
                    </Box>
                    <ListItemText
                      primary="Duration"
                      secondary={`${testData.duration} minutes`}
                      primaryTypographyProps={{ variant: 'subtitle2' }}
                    />
                  </ListItem>
                  
                  <Divider component="li" />
                  
                  <ListItem disablePadding sx={{ py: 1 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', mr: 1 }}>
                      <QuizIcon fontSize="small" color="primary" />
                    </Box>
                    <ListItemText
                      primary="Total Questions"
                      secondary={`${testData.questions.length} questions (${totalMarks} total points)`}
                      primaryTypographyProps={{ variant: 'subtitle2' }}
                    />
                  </ListItem>
                  
                  <Divider component="li" />
                  
                  <ListItem disablePadding sx={{ py: 1 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', mr: 1 }}>
                      <CorrectIcon fontSize="small" color="primary" />
                    </Box>
                    <ListItemText
                      primary="Passing Score"
                      secondary={testData.passingScore ? `${testData.passingScore}%` : 'Not specified'}
                      primaryTypographyProps={{ variant: 'subtitle2' }}
                    />
                  </ListItem>
                </List>
              </CardContent>
            </Card>
          </Grid>
          
          {/* Test Schedule & Settings */}
          <Grid item xs={12} md={6}>
            <Card variant="outlined" sx={{ mb: 3 }}>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Test Schedule
                </Typography>
                
                <List disablePadding>
                  <ListItem disablePadding sx={{ py: 1 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', mr: 1 }}>
                      <CalendarIcon fontSize="small" color="primary" />
                    </Box>
                    <ListItemText
                      primary="Start Date & Time"
                      secondary={formatDate(testData.startDate)}
                      primaryTypographyProps={{ variant: 'subtitle2' }}
                    />
                  </ListItem>
                  
                  <Divider component="li" />
                  
                  <ListItem disablePadding sx={{ py: 1 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', mr: 1 }}>
                      <CalendarIcon fontSize="small" color="primary" />
                    </Box>
                    <ListItemText
                      primary="End Date & Time"
                      secondary={formatDate(testData.endDate)}
                      primaryTypographyProps={{ variant: 'subtitle2' }}
                    />
                  </ListItem>
                </List>
              </CardContent>
            </Card>
            
            <Card variant="outlined">
              <CardContent>
                <Typography variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
                  <SettingsIcon fontSize="small" sx={{ mr: 1 }} />
                  Test Settings
                </Typography>
                
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mb: 2 }}>
                  <Chip 
                    label={`Status: ${testData.status.charAt(0).toUpperCase() + testData.status.slice(1)}`}
                    color={testData.status === 'published' ? 'success' : 'default'}
                    variant="outlined"
                  />
                </Box>
                
                <Typography variant="subtitle2" gutterBottom>
                  Difficulty Distribution:
                </Typography>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mb: 2 }}>
                  <Chip 
                    label={`Easy: ${testData.difficultyDistribution.easy}%`}
                    color="info"
                    variant="outlined"
                    size="small"
                  />
                  <Chip 
                    label={`Medium: ${testData.difficultyDistribution.medium}%`}
                    color="info"
                    variant="outlined"
                    size="small"
                  />
                  <Chip 
                    label={`Hard: ${testData.difficultyDistribution.hard}%`}
                    color="info"
                    variant="outlined"
                    size="small"
                  />
                </Box>
                
                <Typography variant="subtitle2" gutterBottom>
                  Behavior Settings:
                </Typography>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                  <Chip 
                    label="Randomize Questions"
                    color={testData.settings.randomizeQuestions ? 'primary' : 'default'}
                    variant={testData.settings.randomizeQuestions ? 'filled' : 'outlined'}
                    size="small"
                  />
                  <Chip 
                    label="Show Answers After"
                    color={testData.settings.showAnswersAfterSubmission ? 'primary' : 'default'}
                    variant={testData.settings.showAnswersAfterSubmission ? 'filled' : 'outlined'}
                    size="small"
                  />
                  <Chip 
                    label="Allow Review"
                    color={testData.settings.allowReview ? 'primary' : 'default'}
                    variant={testData.settings.allowReview ? 'filled' : 'outlined'}
                    size="small"
                  />
                  <Chip 
                    label="Prevent Tab Switching"
                    color={testData.settings.preventTabSwitching ? 'primary' : 'default'}
                    variant={testData.settings.preventTabSwitching ? 'filled' : 'outlined'}
                    size="small"
                  />
                  <Chip 
                    label="Require Fullscreen"
                    color={testData.settings.requireFullscreen ? 'primary' : 'default'}
                    variant={testData.settings.requireFullscreen ? 'filled' : 'outlined'}
                    size="small"
                  />
                </Box>
              </CardContent>
            </Card>
          </Grid>
          
          {/* Instructions & Questions Summary */}
          <Grid item xs={12}>
            <Card variant="outlined">
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Test Contents
                </Typography>
                
                <Typography variant="subtitle2" gutterBottom>
                  Instructions:
                </Typography>
                <List dense>
                  {testData.instructions && testData.instructions.length > 0 && testData.instructions[0] !== '' ? (
                    testData.instructions.map((instruction, index) => (
                      <ListItem key={index} disablePadding sx={{ py: 0.5 }}>
                        <ListItemText primary={`${index + 1}. ${instruction}`} />
                      </ListItem>
                    ))
                  ) : (
                    <ListItem disablePadding>
                      <ListItemText primary="No instructions provided" />
                    </ListItem>
                  )}
                </List>
                
                <Divider sx={{ my: 2 }} />
                
                <Typography variant="subtitle2" gutterBottom>
                  Questions Summary:
                </Typography>
                
                {testData.questions.length > 0 ? (
                  <List dense>
                    {testData.questions.slice(0, 5).map((question, index) => (
                      <ListItem key={index} disablePadding sx={{ py: 0.5 }}>
                        <ListItemText 
                          primary={`${index + 1}. ${question.questionText.length > 100 ? question.questionText.substring(0, 100) + '...' : question.questionText}`}
                          secondary={`Points: ${question.points || 1}`}
                        />
                      </ListItem>
                    ))}
                    {testData.questions.length > 5 && (
                      <ListItem disablePadding sx={{ py: 0.5 }}>
                        <ListItemText 
                          primary={`... and ${testData.questions.length - 5} more questions`}
                          primaryTypographyProps={{ fontStyle: 'italic' }}
                        />
                      </ListItem>
                    )}
                  </List>
                ) : (
                  <Typography color="text.secondary">
                    No questions added to this test yet.
                  </Typography>
                )}
              </CardContent>
            </Card>
          </Grid>
          
          {/* Action Buttons - This will be handled by parent component */}
          {/* <Grid item xs={12}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2, gap: 2 }}>
              <Button 
                variant="outlined" 
                onClick={onSaveAsDraft}
                disabled={loading || issues.length > 0}
              >
                Save as Draft
              </Button>
              <Button 
                variant="contained" 
                color="primary" 
                onClick={onPublish}
                disabled={loading || issues.length > 0}
              >
                Publish Test
              </Button>
            </Box>
          </Grid> */}
        </Grid>
      </Paper>
    </Box>
  );
};

export default TestReview;
