import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  ListItemIcon,
  TextField,
  Button,
  IconButton,
  Card,
  CardContent,
  CardActions,
  LinearProgress,
  Pagination,
  InputAdornment,
  Collapse,
  Alert,
  FormControlLabel,
  Switch,
  useMediaQuery,
  Chip,
  Stack
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import FilterListIcon from '@mui/icons-material/FilterList';
import SchoolIcon from '@mui/icons-material/School';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CloseIcon from '@mui/icons-material/Close';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import RefreshIcon from '@mui/icons-material/Refresh';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Tooltip from '@mui/material/Tooltip';
import ButtonGroup from '@mui/material/ButtonGroup';
import { themeColors } from '../theme';
import mcqBankService from '../../../services/mcqBankService';
import DiagramRenderer from './DiagramRenderer';

// Styled components for MCQ Bank layout
const MCQBankContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  height: 'calc(100vh - 64px)',
  overflow: 'hidden',
  backgroundColor: '#f5f7fa',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    height: 'auto',
    minHeight: 'calc(100vh - 64px)',
  }
}));

const SubjectsPanel = styled(Box)(({ theme }) => ({
  width: '300px',
  borderRight: '1px solid #eaeaea',
  backgroundColor: 'white',
  overflowY: 'auto',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    borderRight: 'none',
    borderBottom: '1px solid #eaeaea',
    maxHeight: '300px',
  }
}));

const TopicsPanel = styled(Box)(({ theme }) => ({
  flex: 1,
  overflowY: 'auto',
  padding: theme.spacing(2),
  backgroundColor: '#f5f7fa',
}));

const TopicCard = styled(Box)(({ theme }) => ({
  backgroundColor: 'white',
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
  borderRadius: '12px', // More curved corners
  boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
  transition: 'all 0.2s ease',
  cursor: 'pointer',
  '&:hover': {
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
    transform: 'translateY(-2px)',
  }
}));

const EmptyState = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(4),
  textAlign: 'center',
  height: '100%',
  color: themeColors.text.secondary,
}));

const SubjectItem = styled(ListItem)(({ theme, selected, expanded }) => ({
  backgroundColor: selected ? 'rgba(33, 150, 243, 0.08)' : 'transparent',
  borderLeft: selected ? `4px solid ${themeColors.primary.main}` : '4px solid transparent',
  padding: expanded ? theme.spacing(1.5, 2) : theme.spacing(1.5, 1),
  transition: 'all 0.2s ease',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
  }
}));

const SubjectIcon = styled(ListItemIcon)(({ expanded }) => ({
  minWidth: expanded ? '40px' : '30px',
  color: themeColors.primary.main,
}));

const SubjectText = styled(ListItemText)(({ expanded }) => ({
  margin: 0,
  opacity: expanded ? 1 : 0,
  transition: 'opacity 0.2s ease',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

const TopicTitle = styled(Typography)(({ theme }) => ({
  fontSize: '0.95rem',
  fontWeight: 500,
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  color: themeColors.text.primary,
}));

const TopicSubtitle = styled(Typography)(({ theme }) => ({
  fontSize: '0.8rem',
  color: themeColors.text.secondary,
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
}));

const MCQContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: 'white',
  borderRadius: '8px',
  marginTop: theme.spacing(2),
  border: '1px solid #eaeaea',
}));

const NavigationButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(1, 0),
  borderRadius: '8px',
  textTransform: 'none',
  fontWeight: 500,
  boxShadow: 'none',
}));

/**
 * MCQ Bank Component
 * Provides a hierarchical selection of subject, topic, and difficulty
 * for attempting multiple-choice questions
 */
const NewMCQBank = ({ classId = '1', onClassChange = () => {} }) => {
  // State for subjects, topics, and MCQs
  const [subjects, setSubjects] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [expandedTopics, setExpandedTopics] = useState({});
  const [activeMCQ, setActiveMCQ] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [isAnswerSubmitted, setIsAnswerSubmitted] = useState(false);
  
  // State for filtering and searching
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  
  // State for API loading and errors
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // State for topics
  const [topics, setTopics] = useState([]);
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [topicLoading, setTopicLoading] = useState(false);
  const [topicError, setTopicError] = useState(null);

  // State for MCQs
  const [mcqs, setMCQs] = useState([]);
  const [mcqLoading, setMCQLoading] = useState(false);
  const [mcqError, setMCQError] = useState(null);
  const [mcqPage, setMCQPage] = useState(1);
  const [mcqTotalPages, setMCQTotalPages] = useState(1);
  
  // State for tracking submitted MCQs
  const [submittedMCQs, setSubmittedMCQs] = useState({});

  // State for attempted MCQs view
  const [viewingAttempted, setViewingAttempted] = useState(false);
  const [attemptedMCQs, setAttemptedMCQs] = useState([]);
  const [attemptedMCQLoading, setAttemptedMCQLoading] = useState(false);
  const [attemptedMCQError, setAttemptedMCQError] = useState(null);
  const [attemptedMCQPage, setAttemptedMCQPage] = useState(1);
  const [attemptedMCQTotalPages, setAttemptedMCQTotalPages] = useState(1);
  
  // Dummy data for classes
  const classes = [
    { id: '1', name: 'Class 6' },
    { id: '2', name: 'Class 7' },
    { id: '3', name: 'Class 8' },
    { id: '4', name: 'Class 9' },
    { id: '5', name: 'Class 10' },
    { id: '6', name: 'Class 11' },
    { id: '7', name: 'Class 12' },
    { id: '8', name: 'NEET' },
  ];

  // Dummy data for topics
  const dummyMCQs = {
    // Atomic Structure MCQs
    6: [
      {
        id: 'mcq_as1',
        questionText: 'Which subatomic particle has a positive charge?',
        category: 'Chemistry',
        options: [
          { id: 'a', text: 'Electron' },
          { id: 'b', text: 'Proton' },
          { id: 'c', text: 'Neutron' },
          { id: 'd', text: 'Positron' },
        ],
        correctOption: 'b',
        explanation: 'Protons have a positive charge (+1), electrons have a negative charge (-1), and neutrons have no charge. Protons and neutrons are located in the nucleus, while electrons orbit around the nucleus.',
        attempted: false
      },
      {
        id: 'mcq_as2',
        questionText: 'What is the mass of an electron relative to a proton?',
        category: 'Chemistry',
        options: [
          { id: 'a', text: 'Same mass' },
          { id: 'b', text: 'About 1/2000 of a proton' },
          { id: 'c', text: 'About 1/1840 of a proton' },
          { id: 'd', text: 'About 1/1000 of a proton' },
        ],
        correctOption: 'c',
        explanation: 'The mass of an electron is approximately 1/1840 of the mass of a proton. This significant difference in mass is why the mass of an atom is primarily determined by its protons and neutrons.',
        attempted: false
      },
      {
        id: 'mcq_as3',
        questionText: 'Which scientist proposed the plum pudding model of the atom?',
        category: 'Chemistry',
        options: [
          { id: 'a', text: 'Niels Bohr' },
          { id: 'b', text: 'Ernest Rutherford' },
          { id: 'c', text: 'J.J. Thomson' },
          { id: 'd', text: 'John Dalton' },
        ],
        correctOption: 'c',
        explanation: 'J.J. Thomson proposed the plum pudding model in 1904. This model suggested that the atom was a sphere of positive charge with electrons embedded throughout, similar to plums in a pudding.',
        attempted: false
      }
    ],
    
    // Chemical Bonding MCQs
    7: [
      {
        id: 'mcq_cb1',
        questionText: 'Which of the following is an example of a covalent bond?',
        category: 'Chemistry',
        options: [
          { id: 'a', text: 'NaCl' },
          { id: 'b', text: 'O₂' },
          { id: 'c', text: 'MgO' },
          { id: 'd', text: 'CaF₂' },
        ],
        correctOption: 'b',
        explanation: 'O₂ (oxygen gas) contains a covalent bond formed by sharing electrons between two oxygen atoms. NaCl, MgO, and CaF₂ are examples of ionic bonds formed by the transfer of electrons between metals and non-metals.',
        attempted: false
      },
      {
        id: 'mcq_cb2',
        questionText: 'What type of bond is formed between hydrogen and oxygen in water (H₂O)?',
        category: 'Chemistry',
        options: [
          { id: 'a', text: 'Ionic bond' },
          { id: 'b', text: 'Metallic bond' },
          { id: 'c', text: 'Polar covalent bond' },
          { id: 'd', text: 'Non-polar covalent bond' },
        ],
        correctOption: 'c',
        explanation: 'Water (H₂O) contains polar covalent bonds between hydrogen and oxygen. The electronegativity difference between oxygen and hydrogen creates a partial negative charge on oxygen and partial positive charges on the hydrogen atoms.',
        attempted: false
      },
      {
        id: 'mcq_cb3',
        questionText: 'Which of the following molecules has a triple bond?',
        category: 'Chemistry',
        options: [
          { id: 'a', text: 'H₂' },
          { id: 'b', text: 'O₂' },
          { id: 'c', text: 'N₂' },
          { id: 'd', text: 'Cl₂' },
        ],
        correctOption: 'c',
        explanation: 'N₂ (nitrogen gas) contains a triple bond between the two nitrogen atoms. This strong bond makes nitrogen gas relatively inert and explains why it requires significant energy to convert atmospheric nitrogen into other nitrogen compounds.',
        attempted: false
      }
    ],
    
    // Periodic Table MCQs
    8: [
      {
        id: 'mcq_pt1',
        questionText: 'Who is credited with creating the modern periodic table?',
        category: 'Chemistry',
        options: [
          { id: 'a', text: 'John Dalton' },
          { id: 'b', text: 'Dmitri Mendeleev' },
          { id: 'c', text: 'Antoine Lavoisier' },
          { id: 'd', text: 'Henry Moseley' },
        ],
        correctOption: 'b',
        explanation: 'Dmitri Mendeleev is credited with creating the first widely recognized periodic table in 1869. His table organized elements by atomic weight and chemical properties, and even left gaps for undiscovered elements.',
        attempted: false
      },
      {
        id: 'mcq_pt2',
        questionText: 'What property is used to arrange elements in the modern periodic table?',
        category: 'Chemistry',
        options: [
          { id: 'a', text: 'Atomic mass' },
          { id: 'b', text: 'Atomic number' },
          { id: 'c', text: 'Electronegativity' },
          { id: 'd', text: 'Ionization energy' },
        ],
        correctOption: 'b',
        explanation: 'The modern periodic table arranges elements in order of increasing atomic number (the number of protons in the nucleus). This arrangement was proposed by Henry Moseley in 1913.',
        attempted: false
      },
      {
        id: 'mcq_pt3',
        questionText: 'Which group in the periodic table contains the noble gases?',
        category: 'Chemistry',
        options: [
          { id: 'a', text: 'Group 1' },
          { id: 'b', text: 'Group 7' },
          { id: 'c', text: 'Group 17' },
          { id: 'd', text: 'Group 18' },
        ],
        correctOption: 'd',
        explanation: 'Group 18 (formerly Group 8A) contains the noble gases: helium, neon, argon, krypton, xenon, and radon. These elements have full valence electron shells, making them generally unreactive.',
        attempted: false
      }
    ],
    
    // Acids, Bases and Salts MCQs
    9: [
      {
        id: 'mcq_abs1',
        questionText: 'According to the Arrhenius theory, an acid is a substance that:',
        category: 'Chemistry',
        options: [
          { id: 'a', text: 'Accepts electrons' },
          { id: 'b', text: 'Donates electrons' },
          { id: 'c', text: 'Releases H⁺ ions in solution' },
          { id: 'd', text: 'Releases OH⁻ ions in solution' },
        ],
        correctOption: 'c',
        explanation: 'According to the Arrhenius theory, an acid is a substance that dissociates in water to produce hydrogen ions (H⁺). A base, on the other hand, dissociates to produce hydroxide ions (OH⁻).',
        attempted: false
      },
      {
        id: 'mcq_abs2',
        questionText: 'Which of the following is a strong acid?',
        category: 'Chemistry',
        options: [
          { id: 'a', text: 'Acetic acid (CH₃COOH)' },
          { id: 'b', text: 'Carbonic acid (H₂CO₃)' },
          { id: 'c', text: 'Hydrochloric acid (HCl)' },
          { id: 'd', text: 'Phosphoric acid (H₃PO₄)' },
        ],
        correctOption: 'c',
        explanation: 'Hydrochloric acid (HCl) is a strong acid that completely dissociates in water. Acetic acid, carbonic acid, and phosphoric acid are weak acids that only partially dissociate in water.',
        attempted: false
      },
      {
        id: 'mcq_abs3',
        questionText: 'What is the pH of a neutral solution at 25°C?',
        category: 'Chemistry',
        options: [
          { id: 'a', text: '0' },
          { id: 'b', text: '7' },
          { id: 'c', text: '10' },
          { id: 'd', text: '14' },
        ],
        correctOption: 'b',
        explanation: 'The pH of a neutral solution at 25°C is 7. Solutions with pH less than 7 are acidic, while solutions with pH greater than 7 are basic or alkaline.',
        attempted: false
      }
    ],
    
    // Cell Injury MCQs
    26: [
      {
        id: 'mcq1',
        questionText: 'Which of the following is a reversible cell injury?',
        category: 'Pathology',
        options: [
          { id: 'a', text: 'Coagulative necrosis' },
          { id: 'b', text: 'Hydropic degeneration' },
          { id: 'c', text: 'Liquefactive necrosis' },
          { id: 'd', text: 'Caseous necrosis' },
        ],
        correctOption: 'b',
        explanation: 'Hydropic degeneration is a type of reversible cell injury characterized by accumulation of water within the cell. It is often seen in cells exposed to toxins or in certain metabolic disorders.',
        attempted: true
      },
      {
        id: 'mcq2',
        questionText: 'Which of the following is NOT a cause of cell injury?',
        category: 'Pathology',
        options: [
          { id: 'a', text: 'Hypoxia' },
          { id: 'b', text: 'Physical agents' },
          { id: 'c', text: 'Chemical agents' },
          { id: 'd', text: 'Normal cell division' },
        ],
        correctOption: 'd',
        explanation: 'Normal cell division is a physiological process and does not cause cell injury. Hypoxia, physical agents (trauma, temperature extremes, radiation), and chemical agents (toxins, drugs) are all causes of cell injury.',
        attempted: false
      },
      {
        id: 'mcq3',
        questionText: 'What is the most common cause of cell injury?',
        category: 'Pathology',
        options: [
          { id: 'a', text: 'Hypoxia' },
          { id: 'b', text: 'Radiation' },
          { id: 'c', text: 'Chemical agents' },
          { id: 'd', text: 'Infectious agents' },
        ],
        correctOption: 'a',
        explanation: 'Hypoxia (oxygen deprivation) is the most common cause of cell injury. It can result from reduced blood flow (ischemia), inadequate oxygenation of the blood, or reduced oxygen-carrying capacity of the blood.',
        attempted: false
      }
    ],
    // Inflammation MCQs
    27: [
      {
        id: 'mcq4',
        questionText: 'Which of the following is a cardinal sign of inflammation?',
        category: 'Pathology',
        options: [
          { id: 'a', text: 'Pallor' },
          { id: 'b', text: 'Rubor (redness)' },
          { id: 'c', text: 'Hypothermia' },
          { id: 'd', text: 'Anesthesia' },
        ],
        correctOption: 'b',
        explanation: 'Rubor (redness) is one of the five cardinal signs of inflammation described by Celsus: rubor (redness), calor (heat), tumor (swelling), dolor (pain), and functio laesa (loss of function).',
        attempted: true
      },
      {
        id: 'mcq5',
        questionText: 'Which cells are primarily involved in acute inflammation?',
        category: 'Pathology',
        options: [
          { id: 'a', text: 'Neutrophils' },
          { id: 'b', text: 'Lymphocytes' },
          { id: 'c', text: 'Plasma cells' },
          { id: 'd', text: 'Fibroblasts' },
        ],
        correctOption: 'a',
        explanation: 'Neutrophils are the primary cells involved in acute inflammation. They are the first leukocytes to migrate to the site of injury and are characteristic of acute inflammatory responses.',
        attempted: false
      }
    ],
    // Neoplasia MCQs
    28: [
      {
        id: 'mcq6',
        questionText: 'Which of the following is a characteristic of malignant neoplasms?',
        category: 'Pathology',
        options: [
          { id: 'a', text: 'Well-differentiated cells' },
          { id: 'b', text: 'Slow growth' },
          { id: 'c', text: 'Metastasis' },
          { id: 'd', text: 'Expansile growth' },
        ],
        correctOption: 'c',
        explanation: 'Metastasis (spread to distant sites) is a characteristic feature of malignant neoplasms. Benign tumors do not metastasize.',
        attempted: false
      }
    ]
  };
  
  // Fetch subjects when classId changes
  useEffect(() => {
    const fetchSubjects = async () => {
      setLoading(true);
      setError(null);
      try {
        const data = await mcqBankService.getSubjectsByClassId(classId, 'default');
        
        // Transform API data to match the component's expected format
        const transformedSubjects = data.subjects.map(subject => ({
          id: subject._id,
          name: subject.name,
          icon: subject.imageUrl || '📚', // Use imageUrl as icon or fallback to a default
          classId: subject.classId,
          topicCount: 0, // This will be updated when topics API is implemented
          description: subject.description || ''
        }));
        
        setSubjects(transformedSubjects);
        
        // Reset selected subject if needed
        if (transformedSubjects.length > 0 && !selectedSubject) {
          setSelectedSubject(transformedSubjects[0]);
        } else if (transformedSubjects.length > 0 && selectedSubject) {
          // Check if current selected subject is in the new list
          const subjectExists = transformedSubjects.some(subject => subject.id === selectedSubject.id);
          if (!subjectExists) {
            setSelectedSubject(transformedSubjects[0]);
          }
        } else {
          setSelectedSubject(null);
        }
      } catch (err) {
        console.error('Error fetching subjects:', err);
        if (err.response && err.response.status === 401) {
          setError('Authentication error. Please sign in again.');
        } else {
          setError('Failed to load subjects. Please try again later.');
        }
      } finally {
        setLoading(false);
      }
    };

    fetchSubjects();
  }, [classId]);

  // Fetch topics when selected subject changes
  useEffect(() => {
    if (!selectedSubject) return;
    
    const fetchTopics = async () => {
      setTopicLoading(true);
      setTopicError(null);
      try {
        console.log('Fetching topics for subject:', selectedSubject);
        const data = await mcqBankService.getTopicsBySubjectId(selectedSubject.id, 'default');
        console.log('Topics data received:', data);
        
        if (data.success && data.topics) {
          setTopics(data.topics);
          console.log('Topics set in state:', data.topics);
          
          // Reset selected topic if needed
          if (data.topics.length > 0 && !selectedTopic) {
            setSelectedTopic(data.topics[0]);
          } else if (data.topics.length > 0 && selectedTopic) {
            // Check if current selected topic is in the new list
            const topicExists = data.topics.some(topic => topic.id === selectedTopic.id);
            if (!topicExists) {
              setSelectedTopic(data.topics[0]);
            }
          } else {
            setSelectedTopic(null);
          }
        } else {
          setTopics([]);
          setSelectedTopic(null);
        }
      } catch (err) {
        console.error('Error fetching topics:', err);
        if (err.response && err.response.status === 401) {
          setTopicError('Authentication error. Please sign in again.');
        } else {
          setTopicError('Failed to load topics. Please try again later.');
        }
        setTopics([]);
        setSelectedTopic(null);
      } finally {
        setTopicLoading(false);
      }
    };
    
    fetchTopics();
  }, [selectedSubject]);

  // Set default subject when component mounts or classId changes
  useEffect(() => {
    const filteredSubjects = subjects.filter(subject => subject.classId === classId);
    if (filteredSubjects.length > 0 && !selectedSubject) {
      setSelectedSubject(filteredSubjects[0]);
    } else if (filteredSubjects.length > 0 && selectedSubject) {
      // Check if current selected subject is in the filtered list
      const subjectExists = filteredSubjects.some(subject => subject.id === selectedSubject.id);
      if (!subjectExists) {
        setSelectedSubject(filteredSubjects[0]);
      }
    } else {
      setSelectedSubject(null);
    }
  }, [classId]);
  
  // Get theme for responsive design
  const theme = useTheme();
  
  // Responsive design
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  
  // Filter subjects based on classId
  const filteredSubjects = subjects.filter(subject => subject.classId === classId);
  
  // Handle subject selection
  const handleSubjectSelect = (subject) => {
    console.log('Subject selected:', subject);
    setSelectedSubject(subject);
    setCurrentPage(1);
  };
  
  // Handle topic expansion toggle
  const handleTopicToggle = (topicId) => {
    console.log('handleTopicToggle called with topicId:', topicId);
    // If this topic is already expanded, close it
    if (expandedTopics[topicId]) {
      console.log('Topic already expanded, closing it');
      setExpandedTopics({});
      setActiveMCQ(null);
      setSelectedOption(null);
      setIsAnswerSubmitted(false);
    } else {
      // Close any other open topics and expand this one
      console.log('Expanding topic and finding topic object');
      const newExpandedTopics = { [topicId]: true };
      setExpandedTopics(newExpandedTopics);
      
      // Find the topic object
      const topic = topics.find(t => t.id === topicId);
      console.log('Found topic object:', topic);
      if (topic) {
        // Immediately start MCQ practice for this topic
        console.log('Calling handleStartPractice with topic:', topic);
        handleStartPractice(topic);
      } else {
        console.error('Topic not found with id:', topicId);
        console.log('Available topics:', topics);
      }
    }
  };
  
  // Handle search input change
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };
  
  // Handle class selection
  const handleClassChange = (newClassId) => {
    onClassChange(newClassId);
    setSelectedSubject(null);
    setSearchQuery('');
    setExpandedTopics({});
    setCurrentPage(1);
  };
  
  // Handle starting practice for a topic
  const handleStartPractice = (topic) => {
    console.log('Starting practice for topic:', topic);
    setSelectedTopic(topic);
    
    // Reset MCQ state
    setSelectedOption(null);
    setIsAnswerSubmitted(false);
    
    // Fetch MCQs from API for this topic
    fetchMCQsByTopicId(topic.id);
  };
  
  // Handle topic selection
  const handleTopicSelect = (topic) => {
    setSelectedTopic(topic);
    // Reset MCQ state when a new topic is selected
    setActiveMCQ(null);
    setSelectedOption(null);
    setIsAnswerSubmitted(false);
    
    // Fetch MCQs for the selected topic
    fetchMCQsByTopicId(topic.id);
  };

  // Fetch MCQs by topic ID
  const fetchMCQsByTopicId = async (topicId) => {
    console.log(`fetchMCQsByTopicId called with topicId: ${topicId}`);
    // Ensure topicId is a string
    const topicIdStr = String(topicId);
    console.log(`Converted topicId to string: ${topicIdStr}`);
    
    setMCQLoading(true);
    setMCQError(null);
    try {
      console.log('About to call mcqBankService.getMCQsByTopicId');
      const response = await mcqBankService.getMCQsByTopicId(topicIdStr, mcqPage, 10);
      console.log('Response from mcqBankService.getMCQsByTopicId:', response);
      
      if (response.success && response.mcqs) {
        console.log('Setting MCQs from API response:', response.mcqs);
        setMCQs(response.mcqs);
        setMCQTotalPages(response.pagination?.pages || 1);
        
        // If there are MCQs, set the first one as active
        if (response.mcqs.length > 0) {
          const firstMCQ = response.mcqs[0];
          console.log('Setting first MCQ as active:', firstMCQ);
          setActiveMCQ({
            ...firstMCQ,
            topicId: topicIdStr,
            currentIndex: 0,
            totalMCQs: response.mcqs.length
          });
        }
      } else {
        console.log('No MCQs returned from API, falling back to dummy data');
        // If no MCQs returned, use the dummy MCQs as fallback
        const dummyTopicMCQs = dummyMCQs[topicIdStr] || [];
        
        if (dummyTopicMCQs.length > 0) {
          console.log('Setting dummy MCQs:', dummyTopicMCQs);
          setMCQs(dummyTopicMCQs);
          
          // Set the first dummy MCQ as active
          setActiveMCQ({
            ...dummyTopicMCQs[0],
            topicId: topicIdStr,
            currentIndex: 0,
            totalMCQs: dummyTopicMCQs.length
          });
        } else {
          console.log('No dummy MCQs found for topic');
          setMCQs([]);
          setActiveMCQ(null);
        }
      }
    } catch (error) {
      console.error('Error fetching MCQs:', error);
      setMCQError('Failed to load MCQs. Please try again later.');
      
      // Fallback to dummy MCQs on error
      const dummyTopicMCQs = dummyMCQs[topicIdStr] || [];
      
      if (dummyTopicMCQs.length > 0) {
        console.log('Error occurred, falling back to dummy MCQs:', dummyTopicMCQs);
        setMCQs(dummyTopicMCQs);
        
        // Set the first dummy MCQ as active
        setActiveMCQ({
          ...dummyTopicMCQs[0],
          topicId: topicIdStr,
          currentIndex: 0,
          totalMCQs: dummyTopicMCQs.length
        });
      }
    } finally {
      setMCQLoading(false);
    }
  };

  // Fetch attempted MCQs for a topic
  const fetchAttemptedMCQsByTopic = async (topicId) => {
    console.log(`fetchAttemptedMCQsByTopic called with topicId: ${topicId}`);
    
    setAttemptedMCQLoading(true);
    setAttemptedMCQError(null);
    try {
      console.log('About to call mcqBankService.getAttemptedMCQsByTopic');
      const response = await mcqBankService.getAttemptedMCQsByTopic(topicId, attemptedMCQPage, 10);
      console.log('Response from mcqBankService.getAttemptedMCQsByTopic:', response);
      
      if (response.success && response.mcqs) {
        console.log('Setting attempted MCQs from API response:', response.mcqs);
        setAttemptedMCQs(response.mcqs);
        setAttemptedMCQTotalPages(response.pagination?.pages || 1);
        
        // If there are MCQs, set the first one as active
        if (response.mcqs.length > 0) {
          const firstMCQ = response.mcqs[0];
          console.log('Setting first attempted MCQ as active:', firstMCQ);
          setActiveMCQ({
            ...firstMCQ,
            topicId: topicId,
            currentIndex: 0,
            totalMCQs: response.mcqs.length
          });
          
          // Since this is an attempted MCQ, set the selected option and mark as submitted
          setSelectedOption(firstMCQ.userSelectedOption);
          setIsAnswerSubmitted(true);
        }
      } else {
        console.log('No attempted MCQs returned from API');
        setAttemptedMCQs([]);
        setActiveMCQ(null);
      }
    } catch (error) {
      console.error('Error fetching attempted MCQs:', error);
      setAttemptedMCQError('Failed to load attempted MCQs. Please try again later.');
    } finally {
      setAttemptedMCQLoading(false);
    }
  };

  // Toggle between viewing attempted and unattempted MCQs
  const toggleViewAttempted = (topicId) => {
    if (viewingAttempted) {
      // Switch to unattempted view
      setViewingAttempted(false);
      fetchMCQsByTopicId(topicId);
    } else {
      // Switch to attempted view
      setViewingAttempted(true);
      fetchAttemptedMCQsByTopic(topicId);
    }
  };

  // Handle MCQ pagination
  const handleMCQPageChange = (event, value) => {
    setMCQPage(value);
    if (selectedTopic) {
      fetchMCQsByTopicId(selectedTopic.id);
    }
  };

  // Get the current class name
  const currentClass = classes.find(c => c.id === classId)?.name || 'Class';
  
  // Filter topics based on selected subject
  const filteredTopics = topics.filter(topic => 
    selectedSubject && topic.subjectId === selectedSubject.id
  );
  
  // Filter topics based on search query
  const searchedTopics = filteredTopics.filter(topic =>
    topic.name.toLowerCase().includes(searchQuery.toLowerCase())
  );
  
  // Pagination
  const topicsPerPage = 5;
  const totalPages = Math.ceil(searchedTopics.length / topicsPerPage);
  const paginatedTopics = searchedTopics.slice(
    (currentPage - 1) * topicsPerPage,
    currentPage * topicsPerPage
  );
  
  // Handle page change
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };
  
  // Handle option selection in MCQ
  const handleOptionSelect = (optionId) => {
    if (isAnswerSubmitted) return;
    setSelectedOption(optionId);
  };
  
  // Handle MCQ submission
  const handleSubmitAnswer = async () => {
    if (!selectedOption || isAnswerSubmitted) return;
    
    try {
      console.log('Submitting answer for MCQ:', activeMCQ.id);
      
      // Mark as submitted in the UI immediately
      setIsAnswerSubmitted(true);
      setSubmittedMCQs(prevSubmittedMCQs => ({ 
        ...prevSubmittedMCQs, 
        [activeMCQ.id]: { 
          selectedOption, 
          isCorrect: selectedOption === activeMCQ.correctOption 
        } 
      }));
      
      // Submit to the backend
      if (selectedSubject && activeMCQ) {
        const response = await mcqBankService.submitMCQAttempt(
          activeMCQ.id,
          activeMCQ.topicId,
          selectedSubject.id,
          selectedOption
        );
        
        console.log('MCQ submission response:', response);
        
        // Update the MCQs array to mark this MCQ as attempted
        const updatedMCQs = mcqs.map(mcq => {
          if (mcq.id === activeMCQ.id) {
            return {
              ...mcq,
              attempted: true,
              userSelectedOption: selectedOption,
              isCorrect: selectedOption === mcq.correctOption
            };
          }
          return mcq;
        });
        
        setMCQs(updatedMCQs);
        
        // Update the topic progress if available in the response
        if (response.topicProgress) {
          // Find the topic in the topics array
          const updatedTopics = topics.map(topic => {
            if (topic.id === activeMCQ.topicId) {
              return {
                ...topic,
                progress: response.topicProgress
              };
            }
            return topic;
          });
          
          // Update the topics state
          setTopics(updatedTopics);
        }
      }
    } catch (error) {
      console.error('Error submitting MCQ attempt:', error);
      // Even if submission fails, keep the UI state as submitted
      // to preserve user experience
    }
  };

  // Navigate to previous MCQ
  const handlePreviousMCQ = () => {
    if (!activeMCQ || activeMCQ.currentIndex <= 0) return;
    
    const prevIndex = activeMCQ.currentIndex - 1;
    const prevMCQ = mcqs[prevIndex];
    
    if (prevMCQ) {
      // Check if this MCQ has been submitted before
      const wasSubmitted = submittedMCQs[prevMCQ.id];
      
      setActiveMCQ({
        ...prevMCQ,
        topicId: activeMCQ.topicId,
        currentIndex: prevIndex,
        totalMCQs: mcqs.length
      });
      
      // If this MCQ was previously submitted, restore its state
      if (wasSubmitted) {
        setSelectedOption(wasSubmitted.selectedOption);
        setIsAnswerSubmitted(true);
      } else {
        setSelectedOption(null);
        setIsAnswerSubmitted(false);
      }
    }
  };
  
  // Navigate to next MCQ
  const handleNextMCQ = () => {
    if (!activeMCQ || activeMCQ.currentIndex >= activeMCQ.totalMCQs - 1) return;
    
    const nextIndex = activeMCQ.currentIndex + 1;
    const nextMCQ = mcqs[nextIndex];
    
    if (nextMCQ) {
      // Check if this MCQ has been submitted before
      const wasSubmitted = submittedMCQs[nextMCQ.id];
      
      setActiveMCQ({
        ...nextMCQ,
        topicId: activeMCQ.topicId,
        currentIndex: nextIndex,
        totalMCQs: mcqs.length
      });
      
      // If this MCQ was previously submitted, restore its state
      if (wasSubmitted) {
        setSelectedOption(wasSubmitted.selectedOption);
        setIsAnswerSubmitted(true);
      } else {
        setSelectedOption(null);
        setIsAnswerSubmitted(false);
      }
    }
  };
  
  // Navigate to next attempted MCQ
  const handleNextAttemptedMCQ = () => {
    if (!activeMCQ || activeMCQ.currentIndex >= activeMCQ.totalMCQs - 1) return;
    
    const nextIndex = activeMCQ.currentIndex + 1;
    const nextMCQ = attemptedMCQs[nextIndex];
    
    if (nextMCQ) {
      setActiveMCQ({
        ...nextMCQ,
        topicId: activeMCQ.topicId, // Preserve the topic ID
        currentIndex: nextIndex,
        totalMCQs: attemptedMCQs.length
      });
      
      // For attempted MCQs, always set the selected option and mark as submitted
      setSelectedOption(nextMCQ.userSelectedOption);
      setIsAnswerSubmitted(true);
    }
  };
  
  // Navigate to previous attempted MCQ
  const handlePreviousAttemptedMCQ = () => {
    if (!activeMCQ || activeMCQ.currentIndex <= 0) return;
    
    const prevIndex = activeMCQ.currentIndex - 1;
    const prevMCQ = attemptedMCQs[prevIndex];
    
    if (prevMCQ) {
      setActiveMCQ({
        ...prevMCQ,
        topicId: activeMCQ.topicId, // Preserve the topic ID
        currentIndex: prevIndex,
        totalMCQs: attemptedMCQs.length
      });
      
      // For attempted MCQs, always set the selected option and mark as submitted
      setSelectedOption(prevMCQ.userSelectedOption);
      setIsAnswerSubmitted(true);
    }
  };
  
  // Close active MCQ
  const handleCloseMCQ = () => {
    setActiveMCQ(null);
    setSelectedOption(null);
    setIsAnswerSubmitted(false);
  };

  // Render topics section
  const renderTopics = () => {
    if (topicLoading) {
      return (
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
          <LinearProgress sx={{ width: '100%' }} />
        </Box>
      );
    }

    if (topicError) {
      return (
        <Box sx={{ p: 3, textAlign: 'center' }}>
          <Typography color="error" variant="body2">{topicError}</Typography>
        </Box>
      );
    }

    // Transform topics data to match the expected format for rendering
    const transformedTopics = topics.map(topic => ({
      id: topic.id, // Use topic.id instead of topic._id
      name: topic.name,
      description: topic.description || '',
      progress: {
        completed: topic.progress?.completed || 0,
        total: topic.progress?.total || 10,
        percentage: topic.progress?.percentage || 0,
        correctAttempts: topic.progress?.correctAttempts || 0,
        incorrectAttempts: topic.progress?.incorrectAttempts || 0,
        allAttempted: topic.progress?.allAttempted || false
      }
    }));

    // Apply filtering and pagination
    const filteredTopics = transformedTopics.filter(topic => {
      // Apply search filter
      if (searchQuery && !topic.name.toLowerCase().includes(searchQuery.toLowerCase())) {
        return false;
      }
      
      return true;
    });

    const totalPages = Math.ceil(filteredTopics.length / 5);
    const startIndex = (currentPage - 1) * 5;
    const paginatedTopics = filteredTopics.slice(startIndex, startIndex + 5);

    return (
      <>
        {paginatedTopics.length > 0 ? (
          paginatedTopics.map((topic) => (
            <TopicCard key={topic.id} onClick={() => {
              // Only toggle topic if not all questions are attempted
              if (!topic.progress.allAttempted) {
                console.log('Topic card clicked:', topic);
                handleTopicToggle(topic.id);
              }
            }} sx={{
              cursor: topic.progress.allAttempted ? 'default' : 'pointer',
              '&:hover': {
                boxShadow: topic.progress.allAttempted ? '0 2px 4px rgba(0,0,0,0.05)' : '0 4px 8px rgba(0,0,0,0.1)',
                transform: topic.progress.allAttempted ? 'none' : 'translateY(-2px)',
              }
            }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: 1 }}>
                <TopicTitle>
                  {topic.name}
                </TopicTitle>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  {/* Only show View Attempted button if there are attempted questions */}
                  {(topic.progress.correctAttempts + topic.progress.incorrectAttempts > 0 || topic.progress.allAttempted) && !expandedTopics[topic.id] && (
                    <Box sx={{ 
                      display: 'flex', 
                      mr: 1,
                      flexDirection: { xs: 'column', sm: 'row' },
                      gap: { xs: '8px', sm: '12px' }
                    }}>
                      <ButtonGroup 
                        variant="outlined"
                        orientation="horizontal"
                        sx={{ 
                          '& .MuiButton-root': {
                            minWidth: { xs: '100%', sm: 'auto' }
                          },
                          flexDirection: { xs: 'column', sm: 'row' }
                        }}
                      >
                        {topic.progress.correctAttempts + topic.progress.incorrectAttempts > 0 && (
                          <Tooltip title="See your previous answers and explanations" arrow>
                            <Button 
                              size="small" 
                              variant="outlined" 
                              color="primary"
                              startIcon={<VisibilityIcon fontSize="small" />}
                              onClick={(e) => {
                                e.stopPropagation();
                                setViewingAttempted(true);
                                handleTopicToggle(topic.id);
                                fetchAttemptedMCQsByTopic(topic.id);
                              }}
                              sx={{ 
                                fontSize: '0.75rem',
                                borderColor: themeColors.primary.light,
                                color: themeColors.primary.main,
                                '&:hover': {
                                  borderColor: themeColors.primary.main,
                                  backgroundColor: 'rgba(33, 150, 243, 0.04)',
                                  transform: 'scale(0.98)',
                                },
                                transition: 'all 0.2s ease'
                              }}
                              aria-label="View attempted questions"
                            >
                              View Attempted
                            </Button>
                          </Tooltip>
                        )}
                      </ButtonGroup>
                      
                      {topic.progress.allAttempted && (
                        <Tooltip title="Try this topic again with new questions" arrow>
                          <Button 
                            size="small" 
                            variant="contained" 
                            color="primary"
                            startIcon={<RefreshIcon fontSize="small" />}
                            onClick={(e) => {
                              e.stopPropagation();
                              setViewingAttempted(false);
                              handleTopicToggle(topic.id);
                              fetchMCQsByTopicId(topic.id);
                            }}
                            sx={{ 
                              fontSize: '0.75rem',
                              backgroundColor: themeColors.primary.main,
                              '&:hover': {
                                backgroundColor: themeColors.primary.dark,
                                transform: 'scale(0.98)',
                              },
                              transition: 'all 0.2s ease'
                            }}
                            aria-label="Reattempt all questions"
                          >
                            Reattempt
                          </Button>
                        </Tooltip>
                      )}
                    </Box>
                  )}
                  
                  {/* Toggle icon only shown when not expanded */}
                  {!expandedTopics[topic.id] && (
                    <IconButton size="small" onClick={(e) => {
                      e.stopPropagation();
                      handleTopicToggle(topic.id);
                    }}>
                      <ExpandMoreIcon />
                    </IconButton>
                  )}
                  
                  {/* Close icon when expanded */}
                  {expandedTopics[topic.id] && (
                    <IconButton size="small" onClick={(e) => {
                      e.stopPropagation();
                      handleTopicToggle(topic.id);
                    }}>
                      <ExpandLessIcon />
                    </IconButton>
                  )}
                </Box>
              </Box>
              
              {/* Progress indicator with redesigned Correct/Incorrect count */}
              <Box sx={{ mb: 1 }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', mb: 0.5 }}>
                  <Box sx={{ 
                    display: 'flex', 
                    flexDirection: { xs: 'column', sm: 'row' }, 
                    alignItems: { xs: 'flex-start', sm: 'center' },
                    mb: { xs: 1, sm: 0.5 }
                  }}>
                    <TopicSubtitle sx={{ mr: 2 }}>
                      Progress: {topic.progress.completed}/{topic.progress.total} ({topic.progress.percentage}%)
                    </TopicSubtitle>
                    
                    <Stack 
                      direction={{ xs: 'column', sm: 'row' }} 
                      spacing={1} 
                      sx={{ mt: { xs: 1, sm: 0 } }}
                    >
                      <Tooltip title={`${topic.progress.correctAttempts || 0} questions answered correctly in ${topic.name}`} arrow>
                        <Chip
                          icon={<CheckCircleOutlineIcon />}
                          label={`Correct: ${topic.progress.correctAttempts || 0}`}
                          size="small"
                          color="success"
                          variant="outlined"
                          sx={{ 
                            fontWeight: 500,
                            '& .MuiChip-icon': { color: 'success.main' }
                          }}
                        />
                      </Tooltip>
                      
                      <Tooltip title={`${topic.progress.incorrectAttempts || 0} questions answered incorrectly in ${topic.name}`} arrow>
                        <Chip
                          icon={<HighlightOffIcon />}
                          label={`Incorrect: ${topic.progress.incorrectAttempts || 0}`}
                          size="small"
                          color="error"
                          variant="outlined"
                          sx={{ 
                            fontWeight: 500,
                            '& .MuiChip-icon': { color: 'error.main' }
                          }}
                        />
                      </Tooltip>
                    </Stack>
                  </Box>
                </Box>
                
                <LinearProgress 
                  variant="determinate" 
                  value={topic.progress.percentage} 
                  sx={{ 
                    height: 6, 
                    borderRadius: 3,
                    backgroundColor: 'rgba(0,0,0,0.05)',
                    '& .MuiLinearProgress-bar': {
                      backgroundColor: topic.progress.percentage >= 80 
                        ? '#4caf50' 
                        : topic.progress.percentage >= 40 
                          ? '#2196f3' 
                          : '#ff9800'
                    }
                  }}
                  aria-label={`Progress: ${topic.progress.percentage}% complete`}
                />
              </Box>
              
              {/* Expanded details - MCQ display */}
              {expandedTopics[topic.id] && activeMCQ && activeMCQ.topicId === topic.id && (
                <MCQContainer onClick={(e) => e.stopPropagation()}>
                  {/* MCQ Loading State */}
                  {mcqLoading || attemptedMCQLoading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
                      <LinearProgress sx={{ width: '100%' }} />
                    </Box>
                  ) : mcqError || attemptedMCQError ? (
                    <Alert severity="error" sx={{ mb: 2 }}>{mcqError || attemptedMCQError}</Alert>
                  ) : (
                    <>
                      {/* View Mode Indicator */}
                      {viewingAttempted && (
                        <Alert severity="info" sx={{ mb: 2 }}>
                          You are viewing your previously attempted questions
                        </Alert>
                      )}
                      
                      {/* MCQ Header */}
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                        <Typography variant="h6" sx={{ fontSize: '1rem', fontWeight: 600 }}>
                          Question {activeMCQ.currentIndex + 1} of {activeMCQ.totalMCQs}
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          {viewingAttempted && activeMCQ.attemptedAt && (
                            <Typography variant="caption" sx={{ mr: 2 }}>
                              Attempted: {new Date(activeMCQ.attemptedAt).toLocaleDateString()}
                            </Typography>
                          )}
                          <IconButton size="small" onClick={handleCloseMCQ}>
                            <CloseIcon fontSize="small" />
                          </IconButton>
                        </Box>
                      </Box>
                      
                      {/* Question Text */}
                      <Typography variant="body1" sx={{ mb: 3, fontWeight: 500 }}>
                        {activeMCQ.questionText}
                      </Typography>
                      
                      {/* MCQ Content - Side by side layout for larger screens */}
                      <Box 
                        sx={{ 
                          display: 'flex', 
                          flexDirection: { xs: 'column', md: 'row' },
                          gap: 3,
                          mb: 3
                        }}
                      >
                        {/* Diagram (if available) */}
                        {activeMCQ.diagram && activeMCQ.diagram.hasDiagram && (
                          <Box 
                            sx={{ 
                              flex: { xs: '1', md: '0 0 45%' },
                              display: 'flex', 
                              justifyContent: 'center',
                              alignItems: 'center',
                              border: '1px solid #eaeaea',
                              borderRadius: '8px',
                              padding: 2,
                              backgroundColor: 'white',
                              minHeight: '200px'
                            }}
                          >
                            <DiagramRenderer
                              mcqId={activeMCQ.id}
                              templateId={activeMCQ.diagram.templateId}
                              parameters={activeMCQ.diagram.parameters}
                              svg={activeMCQ.diagram.svg}
                              width="100%"
                              height="auto"
                              style={{ 
                                maxHeight: '300px',
                                minHeight: '200px'
                              }}
                            />
                          </Box>
                        )}
                        
                        {/* Options */}
                        <Box sx={{ 
                          flex: { xs: '1', md: activeMCQ.diagram && activeMCQ.diagram.hasDiagram ? '0 0 50%' : '1' }
                        }}>
                          {activeMCQ.options.map((option) => (
                            <Box
                              key={option.id}
                              onClick={() => !viewingAttempted && !isAnswerSubmitted && handleOptionSelect(option.id)}
                              sx={{
                                p: 2,
                                mb: 1.5,
                                border: '1px solid',
                                borderColor: selectedOption === option.id 
                                  ? themeColors.primary.main 
                                  : '#e0e0e0',
                                borderRadius: '8px',
                                backgroundColor: selectedOption === option.id 
                                  ? 'rgba(33, 150, 243, 0.08)' 
                                  : 'white',
                                cursor: viewingAttempted || isAnswerSubmitted ? 'default' : 'pointer',
                                display: 'flex',
                                alignItems: 'center',
                                '&:hover': {
                                  backgroundColor: viewingAttempted || isAnswerSubmitted 
                                    ? selectedOption === option.id ? 'rgba(33, 150, 243, 0.08)' : 'white'
                                    : 'rgba(0, 0, 0, 0.04)',
                                },
                                // Highlight correct/incorrect answers after submission
                                ...(isAnswerSubmitted && {
                                  borderColor: option.id === activeMCQ.correctOption 
                                    ? '#4caf50' 
                                    : selectedOption === option.id && option.id !== activeMCQ.correctOption 
                                      ? '#f44336' 
                                      : '#e0e0e0',
                                  backgroundColor: option.id === activeMCQ.correctOption 
                                    ? 'rgba(76, 175, 80, 0.08)' 
                                    : selectedOption === option.id && option.id !== activeMCQ.correctOption 
                                      ? 'rgba(244, 67, 54, 0.08)' 
                                      : 'white',
                                })
                              }}
                            >
                              <Box 
                                sx={{ 
                                  width: 28, 
                                  height: 28, 
                                  borderRadius: '50%', 
                                  border: '1px solid',
                                  borderColor: selectedOption === option.id 
                                    ? themeColors.primary.main 
                                    : '#e0e0e0',
                                  display: 'flex', 
                                  alignItems: 'center', 
                                  justifyContent: 'center',
                                  mr: 2,
                                  backgroundColor: selectedOption === option.id 
                                    ? themeColors.primary.main 
                                    : 'transparent',
                                  color: selectedOption === option.id ? 'white' : 'inherit',
                                  // Highlight correct/incorrect answers after submission
                                  ...(isAnswerSubmitted && {
                                    borderColor: option.id === activeMCQ.correctOption 
                                      ? '#4caf50' 
                                      : selectedOption === option.id && option.id !== activeMCQ.correctOption 
                                        ? '#f44336' 
                                        : '#e0e0e0',
                                    backgroundColor: option.id === activeMCQ.correctOption 
                                      ? '#4caf50' 
                                      : selectedOption === option.id && option.id !== activeMCQ.correctOption 
                                        ? '#f44336' 
                                        : 'transparent',
                                    color: (option.id === activeMCQ.correctOption || 
                                          (selectedOption === option.id && option.id !== activeMCQ.correctOption)) 
                                      ? 'white' 
                                      : 'inherit',
                                  })
                                }}
                              >
                                {option.id}
                              </Box>
                              <Typography variant="body2">{option.text}</Typography>
                            </Box>
                          ))}
                        </Box>
                      </Box>

                      {/* Explanation (shown after submission) */}
                      {isAnswerSubmitted && (
                        <Box sx={{ mb: 3, p: 2, backgroundColor: 'rgba(0, 0, 0, 0.03)', borderRadius: '8px' }}>
                          <Typography variant="subtitle2" sx={{ mb: 1, fontWeight: 600 }}>Explanation:</Typography>
                          <Typography variant="body2">{activeMCQ.explanation}</Typography>
                        </Box>
                      )}
                      
                      {/* Action Buttons */}
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                        <NavigationButton
                          variant="outlined"
                          color="primary"
                          startIcon={<ChevronLeftIcon />}
                          onClick={(e) => {
                            e.stopPropagation();
                            viewingAttempted ? handlePreviousAttemptedMCQ() : handlePreviousMCQ();
                          }}
                          disabled={activeMCQ.currentIndex <= 0}
                        >
                          Previous
                        </NavigationButton>
                        
                        {!viewingAttempted && !isAnswerSubmitted ? (
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleSubmitAnswer();
                            }}
                            disabled={!selectedOption}
                            sx={{ borderRadius: '8px', textTransform: 'none', fontWeight: 500 }}
                          >
                            Submit Answer
                          </Button>
                        ) : (
                          <NavigationButton
                            variant="contained"
                            color="primary"
                            endIcon={<ChevronRightIcon />}
                            onClick={(e) => {
                              e.stopPropagation();
                              viewingAttempted ? handleNextAttemptedMCQ() : handleNextMCQ();
                            }}
                            disabled={activeMCQ.currentIndex >= activeMCQ.totalMCQs - 1}
                          >
                            Next
                          </NavigationButton>
                        )}
                      </Box>
                    </>
                  )}
                </MCQContainer>
              )}
            </TopicCard>
          ))
        ) : (
          <EmptyState>
            <Box sx={{ mb: 2 }}>
              <SearchOffIcon sx={{ fontSize: 60, color: 'text.disabled' }} />
            </Box>
            <Typography variant="h6" color="text.secondary" gutterBottom>
              No topics found
            </Typography>
            <Typography variant="body2" color="text.secondary">
              No topics match your search criteria. Try a different search term.
            </Typography>
          </EmptyState>
        )}
        
        {/* Pagination */}
        {totalPages > 1 && (
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
            <Pagination 
              count={totalPages} 
              page={currentPage}
              onChange={handlePageChange}
              color="primary"
              size="small"
            />
          </Box>
        )}
      </>
    );
  };

  return (
    <MCQBankContainer>
      {/* Subjects Panel */}
      <SubjectsPanel>
        <Box sx={{ p: 2, borderBottom: '1px solid #eaeaea' }}>
          {/* Class Selector */}
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="body2" sx={{ mr: 1 }}>Class:</Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
              {classes.map((classItem) => (
                <Chip
                  key={classItem.id}
                  label={classItem.name}
                  onClick={() => handleClassChange(classItem.id)}
                  color={classId === classItem.id ? "primary" : "default"}
                  variant={classId === classItem.id ? "filled" : "outlined"}
                  size="small"
                  sx={{
                    fontWeight: classId === classItem.id ? 600 : 400,
                    cursor: 'pointer'
                  }}
                />
              ))}
            </Box>
          </Box>
        </Box>
        
        {/* Loading indicator */}
        {loading && (
          <Box sx={{ p: 2 }}>
            <LinearProgress />
          </Box>
        )}
        
        {/* Error message */}
        {error && (
          <Box sx={{ p: 2 }}>
            <Typography color="error" variant="body2">{error}</Typography>
          </Box>
        )}
        
        <List disablePadding>
          {filteredSubjects.map((subject) => (
            <SubjectItem
              key={subject.id}
              selected={selectedSubject && selectedSubject.id === subject.id}
              button
              onClick={() => handleSubjectSelect(subject)}
            >
              <ListItemIcon>
                <Typography>{subject.icon}</Typography>
              </ListItemIcon>
              <ListItemText primary={subject.name} secondary={`${subject.topicCount} topics`} />
            </SubjectItem>
          ))}
        </List>
      </SubjectsPanel>
      
      {/* Topics Panel */}
      <TopicsPanel>
        {/* Search */}
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <TextField
            placeholder="Search topics..."
            size="small"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon fontSize="small" />
                </InputAdornment>
              ),
              sx: { borderRadius: '8px' }
            }}
            sx={{ flex: 1, mr: 2 }}
          />
        </Box>
        
        {/* Topic list */}
        {selectedSubject ? (
          renderTopics()
        ) : (
          <EmptyState>
            <Box sx={{ mb: 2 }}>
              <MenuBookIcon sx={{ fontSize: 60, color: 'text.disabled' }} />
            </Box>
            <Typography variant="h6" color="text.secondary" gutterBottom>
              {filteredSubjects.length > 0 
                ? "Select a subject to view topics" 
                : "No subjects found for this class"}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {filteredSubjects.length > 0 
                ? "Choose a subject from the left panel to see available topics" 
                : "Try selecting a different class or contact your administrator"}
            </Typography>
          </EmptyState>
        )}
      </TopicsPanel>
    </MCQBankContainer>
  );
};

export default NewMCQBank;
