import React, { useState } from 'react';
import TopBar from './TopBar';
import Sidebar from './Sidebar';
import { themeColors } from '../theme';

/**
 * Main Layout component that combines the TopBar, Sidebar, and main content area
 * Handles the sidebar expand/collapse state
 */
const MainLayout = ({ children }) => {
  const [sidebarExpanded, setSidebarExpanded] = useState(false);
  
  // Toggle sidebar expanded/collapsed state
  const toggleSidebar = () => {
    setSidebarExpanded(!sidebarExpanded);
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        backgroundColor: themeColors.background.default,
      }}
    >
      {/* Top navigation bar */}
      <TopBar onMenuToggle={toggleSidebar} />
      
      {/* Main content area with sidebar */}
      <div
        style={{
          display: 'flex',
          flex: 1,
          marginTop: '64px', // Height of the TopBar
        }}
      >
        {/* Sidebar navigation */}
        <Sidebar expanded={sidebarExpanded} onToggle={toggleSidebar} />
        
        {/* Main content */}
        <div
          style={{
            flex: 1,
            marginLeft: sidebarExpanded ? '240px' : '64px', // Width of expanded/collapsed sidebar
            transition: 'margin-left 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
          }}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default MainLayout;
