import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Grid, 
  Paper, 
  Typography, 
  CircularProgress, 
  Card, 
  CardContent,
  Divider,
  Button,
  useTheme,
  useMediaQuery,
  IconButton,
  Tooltip,
  Avatar
} from '@mui/material';
import {
  Add as AddIcon,
  Book as BookIcon,
  Topic as TopicIcon,
  QuestionAnswer as QuestionAnswerIcon,
  Assignment as AssignmentIcon,
  People as PeopleIcon,
  TrendingUp as TrendingUpIcon,
  ArrowUpward as ArrowUpwardIcon,
  ArrowDownward as ArrowDownwardIcon,
  BarChart as BarChartIcon
} from '@mui/icons-material';
import { 
  BarChart, 
  Bar, 
  XAxis, 
  YAxis, 
  CartesianGrid, 
  Tooltip as RechartsTooltip, 
  Legend, 
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell
} from 'recharts';
import API from '../../services/api';

const DashboardHome = ({ getToken, user, showNotification, institutionData }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [loading, setLoading] = useState(true);
  const [stats, setStats] = useState({
    subjects: 0,
    topics: 0,
    mcqs: 0,
    tests: 0,
    students: 0
  });
  const [recentActivity, setRecentActivity] = useState([]);
  const [subjectDistribution, setSubjectDistribution] = useState([]);
  const [testPerformance, setTestPerformance] = useState([]);

  useEffect(() => {
    fetchDashboardData();
  }, []);

  const fetchDashboardData = async () => {
    setLoading(true);
    try {
      // In a real implementation, this would be a single API call
      // For now, we'll simulate the data
      
      // Simulate API call delay
      await new Promise(resolve => setTimeout(resolve, 1000));
      
      // Mock data
      setStats({
        subjects: 12,
        topics: 48,
        mcqs: 240,
        tests: 15,
        students: 120
      });
      
      setRecentActivity([
        { id: 1, type: 'test', action: 'created', name: 'Physics Midterm', date: '2025-03-27T10:30:00' },
        { id: 2, type: 'mcq', action: 'added', name: 'Chemistry Equations', count: 15, date: '2025-03-26T14:45:00' },
        { id: 3, type: 'topic', action: 'updated', name: 'Organic Chemistry', date: '2025-03-25T09:15:00' },
        { id: 4, type: 'test', action: 'assigned', name: 'Biology Quiz', class: 'Class 10', date: '2025-03-24T16:20:00' }
      ]);
      
      setSubjectDistribution([
        { name: 'Physics', value: 35, color: '#1976d2' },
        { name: 'Chemistry', value: 25, color: '#2196f3' },
        { name: 'Biology', value: 20, color: '#4caf50' },
        { name: 'Mathematics', value: 15, color: '#ff9800' },
        { name: 'English', value: 5, color: '#f44336' }
      ]);
      
      setTestPerformance([
        { name: 'Physics', avgScore: 72, totalStudents: 45 },
        { name: 'Chemistry', avgScore: 68, totalStudents: 42 },
        { name: 'Biology', avgScore: 75, totalStudents: 38 },
        { name: 'Mathematics', avgScore: 65, totalStudents: 40 },
        { name: 'English', avgScore: 80, totalStudents: 35 }
      ]);
      
    } catch (error) {
      console.error('Error fetching dashboard data:', error);
      showNotification('error', 'Failed to load dashboard data');
    } finally {
      setLoading(false);
    }
  };

  // Format date for display
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-US', {
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    }).format(date);
  };

  // Get icon for activity type
  const getActivityIcon = (type) => {
    switch (type) {
      case 'test':
        return <AssignmentIcon color="primary" />;
      case 'mcq':
        return <QuestionAnswerIcon style={{ color: theme.palette.success.main }} />;
      case 'topic':
        return <TopicIcon style={{ color: theme.palette.warning.main }} />;
      case 'subject':
        return <BookIcon style={{ color: theme.palette.error.main }} />;
      default:
        return <BarChartIcon color="primary" />;
    }
  };

  // Render activity item
  const renderActivityItem = (activity) => {
    return (
      <Box key={activity.id} sx={{ display: 'flex', alignItems: 'center', mb: 2, p: 1, borderRadius: 1, '&:hover': { bgcolor: 'rgba(0,0,0,0.04)' } }}>
        <Avatar sx={{ bgcolor: 'background.paper', mr: 2 }}>
          {getActivityIcon(activity.type)}
        </Avatar>
        <Box>
          <Typography variant="body2" sx={{ fontWeight: 'medium' }}>
            {activity.action === 'created' && 'Created new'}
            {activity.action === 'added' && 'Added new'}
            {activity.action === 'updated' && 'Updated'}
            {activity.action === 'assigned' && 'Assigned'} {activity.type}
            {activity.count && ` (${activity.count} items)`}
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
            {activity.name}
            {activity.class && ` to ${activity.class}`}
          </Typography>
          <Typography variant="caption" color="text.secondary">
            {formatDate(activity.date)}
          </Typography>
        </Box>
      </Box>
    );
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      <Box sx={{ mb: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap' }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Teacher Dashboard
        </Typography>
        <Box sx={{ display: 'flex', gap: 2, mt: { xs: 2, sm: 0 } }}>
          <Button 
            variant="contained" 
            color="primary" 
            startIcon={<AssignmentIcon />}
            onClick={() => {/* Navigate to test creation */}}
          >
            Create Test
          </Button>
          <Button 
            variant="outlined" 
            color="primary" 
            startIcon={<QuestionAnswerIcon />}
            onClick={() => {/* Navigate to MCQ creation */}}
          >
            Add MCQs
          </Button>
        </Box>
      </Box>

      {/* Stats Cards */}
      <Grid container spacing={3} sx={{ mb: 4 }}>
        <Grid item xs={6} sm={4} md={2.4}>
          <Card sx={{ height: '100%', bgcolor: 'primary.light', color: 'primary.contrastText' }}>
            <CardContent sx={{ p: 2, '&:last-child': { pb: 2 } }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <BookIcon fontSize="large" />
                <Typography variant="h4">{stats.subjects}</Typography>
              </Box>
              <Typography variant="body2" sx={{ mt: 1 }}>Subjects</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6} sm={4} md={2.4}>
          <Card sx={{ height: '100%', bgcolor: 'info.light', color: 'info.contrastText' }}>
            <CardContent sx={{ p: 2, '&:last-child': { pb: 2 } }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <TopicIcon fontSize="large" />
                <Typography variant="h4">{stats.topics}</Typography>
              </Box>
              <Typography variant="body2" sx={{ mt: 1 }}>Topics</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6} sm={4} md={2.4}>
          <Card sx={{ height: '100%', bgcolor: 'success.light', color: 'success.contrastText' }}>
            <CardContent sx={{ p: 2, '&:last-child': { pb: 2 } }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <QuestionAnswerIcon fontSize="large" />
                <Typography variant="h4">{stats.mcqs}</Typography>
              </Box>
              <Typography variant="body2" sx={{ mt: 1 }}>MCQs</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6} sm={4} md={2.4}>
          <Card sx={{ height: '100%', bgcolor: 'warning.light', color: 'warning.contrastText' }}>
            <CardContent sx={{ p: 2, '&:last-child': { pb: 2 } }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <AssignmentIcon fontSize="large" />
                <Typography variant="h4">{stats.tests}</Typography>
              </Box>
              <Typography variant="body2" sx={{ mt: 1 }}>Tests</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6} sm={4} md={2.4}>
          <Card sx={{ height: '100%', bgcolor: 'error.light', color: 'error.contrastText' }}>
            <CardContent sx={{ p: 2, '&:last-child': { pb: 2 } }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <PeopleIcon fontSize="large" />
                <Typography variant="h4">{stats.students}</Typography>
              </Box>
              <Typography variant="body2" sx={{ mt: 1 }}>Students</Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Charts and Activity */}
      <Grid container spacing={3}>
        {/* Subject Distribution */}
        <Grid item xs={12} md={4}>
          <Paper sx={{ p: 2, height: '100%' }}>
            <Typography variant="h6" gutterBottom>
              Content Distribution
            </Typography>
            <Box sx={{ height: 300, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <ResponsiveContainer width="100%" height="100%">
                <PieChart>
                  <Pie
                    data={subjectDistribution}
                    cx="50%"
                    cy="50%"
                    innerRadius={60}
                    outerRadius={80}
                    paddingAngle={5}
                    dataKey="value"
                    label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
                  >
                    {subjectDistribution.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={entry.color} />
                    ))}
                  </Pie>
                  <RechartsTooltip formatter={(value) => [`${value} MCQs`, 'Count']} />
                </PieChart>
              </ResponsiveContainer>
            </Box>
          </Paper>
        </Grid>

        {/* Test Performance */}
        <Grid item xs={12} md={4}>
          <Paper sx={{ p: 2, height: '100%' }}>
            <Typography variant="h6" gutterBottom>
              Test Performance
            </Typography>
            <Box sx={{ height: 300 }}>
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  data={testPerformance}
                  margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis domain={[0, 100]} />
                  <RechartsTooltip formatter={(value) => [`${value}%`, 'Average Score']} />
                  <Bar dataKey="avgScore" fill="#8884d8" name="Average Score (%)" />
                </BarChart>
              </ResponsiveContainer>
            </Box>
          </Paper>
        </Grid>

        {/* Recent Activity */}
        <Grid item xs={12} md={4}>
          <Paper sx={{ p: 2, height: '100%' }}>
            <Typography variant="h6" gutterBottom>
              Recent Activity
            </Typography>
            <Box sx={{ maxHeight: 300, overflow: 'auto' }}>
              {recentActivity.map(activity => renderActivityItem(activity))}
            </Box>
            <Box sx={{ mt: 2, textAlign: 'center' }}>
              <Button color="primary">View All Activity</Button>
            </Box>
          </Paper>
        </Grid>
      </Grid>

      {/* Quick Actions */}
      <Grid container spacing={3} sx={{ mt: 2 }}>
        <Grid item xs={12}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              Quick Actions
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={6} sm={3}>
                <Button 
                  fullWidth 
                  variant="outlined" 
                  startIcon={<BookIcon />}
                  sx={{ justifyContent: 'flex-start', py: 1.5 }}
                >
                  Add Subject
                </Button>
              </Grid>
              <Grid item xs={6} sm={3}>
                <Button 
                  fullWidth 
                  variant="outlined" 
                  startIcon={<TopicIcon />}
                  sx={{ justifyContent: 'flex-start', py: 1.5 }}
                >
                  Create Topic
                </Button>
              </Grid>
              <Grid item xs={6} sm={3}>
                <Button 
                  fullWidth 
                  variant="outlined" 
                  startIcon={<QuestionAnswerIcon />}
                  sx={{ justifyContent: 'flex-start', py: 1.5 }}
                >
                  Bulk Upload MCQs
                </Button>
              </Grid>
              <Grid item xs={6} sm={3}>
                <Button 
                  fullWidth 
                  variant="outlined" 
                  startIcon={<BarChartIcon />}
                  sx={{ justifyContent: 'flex-start', py: 1.5 }}
                >
                  View Analytics
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DashboardHome;
