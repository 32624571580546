import React from 'react';
import MainLayout from '../../components/UIRevamp/layout/MainLayout';
import MCQPracticeLayout from '../../components/UIRevamp/mcq/MCQPracticeLayout';

/**
 * MCQ Practice Page Component
 * Provides access to MCQ practice with the revamped UI
 */
const MCQPage = () => {
  return (
    <MainLayout activeItem="mcq">
      <MCQPracticeLayout />
    </MainLayout>
  );
};

export default MCQPage;
