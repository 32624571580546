import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Paper, 
  Typography, 
  FormControl,
  FormControlLabel, 
  RadioGroup,
  Radio,
  Slider,
  Grid,
  Divider,
  Switch,
  FormGroup,
  FormHelperText,
  Card,
  CardContent
} from '@mui/material';

const TestSettings = ({
  testData,
  onSave,
  loading
}) => {
  // Local state
  const [localData, setLocalData] = useState({
    status: testData.status || 'draft',
    difficultyDistribution: testData.difficultyDistribution || { easy: 30, medium: 50, hard: 20 },
    settings: testData.settings || {
      randomizeQuestions: false,
      showAnswersAfterSubmission: false,
      allowReview: true,
      preventTabSwitching: false,
      requireFullscreen: false
    }
  });
  
  // Update local state when props change
  useEffect(() => {
    setLocalData({
      status: testData.status || 'draft',
      difficultyDistribution: testData.difficultyDistribution || { easy: 30, medium: 50, hard: 20 },
      settings: testData.settings || {
        randomizeQuestions: false,
        showAnswersAfterSubmission: false,
        allowReview: true,
        preventTabSwitching: false,
        requireFullscreen: false
      }
    });
  }, [testData]);
  
  // Handle status change
  const handleStatusChange = (e) => {
    const { value } = e.target;
    const updatedData = { ...localData, status: value };
    setLocalData(updatedData);
    onSave(updatedData);
  };
  
  // Handle difficulty distribution change
  const handleDifficultyChange = (difficulty, value) => {
    // Ensure values sum to 100%
    const currentSum = Object.values(localData.difficultyDistribution).reduce((sum, val) => sum + val, 0);
    const difference = 100 - (currentSum - localData.difficultyDistribution[difficulty] + value);
    
    let updatedDistribution = {
      ...localData.difficultyDistribution,
      [difficulty]: value
    };
    
    // If sum is not 100, adjust other values proportionally
    if (difference !== 100) {
      const otherDifficulties = Object.keys(localData.difficultyDistribution).filter(d => d !== difficulty);
      const otherSum = otherDifficulties.reduce((sum, d) => sum + localData.difficultyDistribution[d], 0);
      
      if (otherSum > 0) {
        otherDifficulties.forEach(d => {
          const ratio = localData.difficultyDistribution[d] / otherSum;
          updatedDistribution[d] = Math.max(0, Math.round(ratio * (100 - value)));
        });
      }
    }
    
    // Final check to ensure sum is 100
    const finalSum = Object.values(updatedDistribution).reduce((sum, val) => sum + val, 0);
    if (finalSum !== 100) {
      const diff = 100 - finalSum;
      const largestKey = Object.keys(updatedDistribution)
        .filter(k => k !== difficulty)
        .sort((a, b) => updatedDistribution[b] - updatedDistribution[a])[0] || 'medium';
      
      updatedDistribution[largestKey] += diff;
    }
    
    const updatedData = { ...localData, difficultyDistribution: updatedDistribution };
    setLocalData(updatedData);
    onSave(updatedData);
  };
  
  // Handle settings toggle
  const handleSettingToggle = (setting) => {
    const updatedSettings = {
      ...localData.settings,
      [setting]: !localData.settings[setting]
    };
    
    const updatedData = { ...localData, settings: updatedSettings };
    setLocalData(updatedData);
    onSave(updatedData);
  };
  
  // Value text for sliders
  const valuetext = (value) => `${value}%`;
  
  return (
    <Box sx={{ width: '100%' }}>
      <Paper elevation={0} sx={{ p: 2, mb: 3 }}>
        <Typography variant="h6" gutterBottom>
          Test Settings
        </Typography>
        <Typography variant="body2" color="text.secondary" paragraph>
          Configure test behavior, difficulty distribution, and security settings.
        </Typography>
        
        <Grid container spacing={4}>
          {/* Status Settings */}
          <Grid item xs={12} md={6}>
            <Card variant="outlined" sx={{ mb: 3 }}>
              <CardContent>
                <Typography variant="subtitle1" gutterBottom>
                  Test Status
                </Typography>
                <FormControl component="fieldset" disabled={loading}>
                  <RadioGroup
                    name="status"
                    value={localData.status}
                    onChange={handleStatusChange}
                  >
                    <FormControlLabel value="draft" control={<Radio />} label="Draft (not visible to students)" />
                    <FormControlLabel value="published" control={<Radio />} label="Published (available to assigned students)" />
                    <FormControlLabel value="archived" control={<Radio />} label="Archived (no longer active)" />
                  </RadioGroup>
                  <FormHelperText>
                    You can change the status later from the test management dashboard.
                  </FormHelperText>
                </FormControl>
              </CardContent>
            </Card>
          </Grid>
          
          {/* Difficulty Distribution */}
          <Grid item xs={12} md={6}>
            <Card variant="outlined" sx={{ mb: 3 }}>
              <CardContent>
                <Typography variant="subtitle1" gutterBottom>
                  Difficulty Distribution
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Adjust the target distribution of question difficulty. The system will try to match this distribution when generating or selecting questions.
                </Typography>
                
                <Box sx={{ mt: 3, mb: 1 }}>
                  <Typography id="easy-difficulty-slider" gutterBottom>
                    Easy: {localData.difficultyDistribution.easy}%
                  </Typography>
                  <Slider
                    aria-labelledby="easy-difficulty-slider"
                    value={localData.difficultyDistribution.easy}
                    onChange={(e, newValue) => handleDifficultyChange('easy', newValue)}
                    getAriaValueText={valuetext}
                    valueLabelDisplay="auto"
                    disabled={loading}
                  />
                </Box>
                
                <Box sx={{ mt: 3, mb: 1 }}>
                  <Typography id="medium-difficulty-slider" gutterBottom>
                    Medium: {localData.difficultyDistribution.medium}%
                  </Typography>
                  <Slider
                    aria-labelledby="medium-difficulty-slider"
                    value={localData.difficultyDistribution.medium}
                    onChange={(e, newValue) => handleDifficultyChange('medium', newValue)}
                    getAriaValueText={valuetext}
                    valueLabelDisplay="auto"
                    disabled={loading}
                  />
                </Box>
                
                <Box sx={{ mt: 3, mb: 1 }}>
                  <Typography id="hard-difficulty-slider" gutterBottom>
                    Hard: {localData.difficultyDistribution.hard}%
                  </Typography>
                  <Slider
                    aria-labelledby="hard-difficulty-slider"
                    value={localData.difficultyDistribution.hard}
                    onChange={(e, newValue) => handleDifficultyChange('hard', newValue)}
                    getAriaValueText={valuetext}
                    valueLabelDisplay="auto"
                    disabled={loading}
                  />
                </Box>
              </CardContent>
            </Card>
          </Grid>
          
          {/* Behavioral Settings */}
          <Grid item xs={12}>
            <Card variant="outlined">
              <CardContent>
                <Typography variant="subtitle1" gutterBottom>
                  Test Behavior
                </Typography>
                <Typography variant="body2" color="text.secondary" paragraph>
                  Configure how the test will behave when students take it.
                </Typography>
                
                <FormGroup>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={localData.settings.randomizeQuestions}
                            onChange={() => handleSettingToggle('randomizeQuestions')}
                            disabled={loading}
                          />
                        }
                        label="Randomize Question Order"
                      />
                      <FormHelperText>
                        Questions will appear in random order for each student
                      </FormHelperText>
                    </Grid>
                    
                    <Grid item xs={12} sm={6}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={localData.settings.showAnswersAfterSubmission}
                            onChange={() => handleSettingToggle('showAnswersAfterSubmission')}
                            disabled={loading}
                          />
                        }
                        label="Show Answers After Submission"
                      />
                      <FormHelperText>
                        Students can see correct answers after completing the test
                      </FormHelperText>
                    </Grid>
                    
                    <Grid item xs={12} sm={6}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={localData.settings.allowReview}
                            onChange={() => handleSettingToggle('allowReview')}
                            disabled={loading}
                          />
                        }
                        label="Allow Question Review"
                      />
                      <FormHelperText>
                        Students can go back and review previous questions
                      </FormHelperText>
                    </Grid>
                    
                    <Grid item xs={12} sm={6}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={localData.settings.preventTabSwitching}
                            onChange={() => handleSettingToggle('preventTabSwitching')}
                            disabled={loading}
                          />
                        }
                        label="Prevent Tab Switching"
                      />
                      <FormHelperText>
                        Warn students if they attempt to switch browser tabs during the test
                      </FormHelperText>
                    </Grid>
                    
                    <Grid item xs={12} sm={6}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={localData.settings.requireFullscreen}
                            onChange={() => handleSettingToggle('requireFullscreen')}
                            disabled={loading}
                          />
                        }
                        label="Require Fullscreen Mode"
                      />
                      <FormHelperText>
                        Force browser fullscreen mode during the test
                      </FormHelperText>
                    </Grid>
                  </Grid>
                </FormGroup>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

export default TestSettings;
