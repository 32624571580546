import axios from 'axios';
import config from '../config';

// API base URL from config
const API_BASE_URL = config.API_URL;

// Helper function to get auth header
const getAuthHeader = () => {
  const token = localStorage.getItem('token');
  if (!token) {
    throw new Error('Authentication token not found');
  }
  return { 'Authorization': `Bearer ${token}` };
};

// Service for MCQ Bank related API calls
const mcqBankService = {
  // Fetch subjects by classId
  getSubjectsByClassId: async (classId, institutionId = 'default') => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/v1/subjects`, {
        params: { classId },
        headers: getAuthHeader()
      });
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.error('Unauthorized: Please sign in again');
        // Redirect to login page or handle auth error
        // window.location.href = '/sign-in';
      }
      console.error('Error fetching subjects:', error);
      throw error;
    }
  },

  // Fetch topics by subjectId
  getTopicsBySubjectId: async (subjectId, institutionId = 'default') => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/v1/topics`, {
        params: { subjectId },
        headers: getAuthHeader()
      });
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.error('Unauthorized: Please sign in again');
        // Redirect to login page or handle auth error
        // window.location.href = '/sign-in';
      }
      console.error('Error fetching topics:', error);
      throw error;
    }
  },

  // Fetch MCQs by topicId
  getMCQsByTopicId: async (topicId, page = 1, limit = 10, institutionId = 'default') => {
    console.log(`DIRECT LOG: Calling getMCQsByTopicId API with topicId: ${topicId}, page: ${page}, limit: ${limit}`);
    try {
      // Ensure topicId is a string
      const topicIdStr = String(topicId);
      
      const apiUrl = `${API_BASE_URL}/api/mcq-bank/mcqs/topic`;
      console.log(`DIRECT LOG: API URL: ${apiUrl}`);
      console.log(`DIRECT LOG: API params:`, { topicId: topicIdStr, page, limit });
      
      const response = await axios.get(apiUrl, {
        params: { 
          topicId: topicIdStr,
          page,
          limit
        },
        headers: getAuthHeader()
      });
      console.log('DIRECT LOG: MCQs API response:', response.data);
      return response.data;
    } catch (error) {
      console.error('DIRECT LOG: Error fetching MCQs:', error);
      if (error.response) {
        console.error('DIRECT LOG: Error response:', error.response.data);
        console.error('DIRECT LOG: Error status:', error.response.status);
      }
      if (error.response && error.response.status === 401) {
        console.error('Unauthorized: Please sign in again');
        // Redirect to login page or handle auth error
        // window.location.href = '/sign-in';
      }
      console.error('Error fetching MCQs:', error);
      throw error;
    }
  },
  
  // Submit MCQ attempt
  submitMCQAttempt: async (mcqId, topicId, subjectId, selectedAnswer) => {
    console.log(`Submitting MCQ attempt: mcqId=${mcqId}, topicId=${topicId}, subjectId=${subjectId}, selectedAnswer=${selectedAnswer}`);
    try {
      const response = await axios.post(`${API_BASE_URL}/api/mcq-bank/mcq/attempt`, {
        mcqId,
        topicId,
        subjectId,
        selectedAnswer
      }, {
        headers: getAuthHeader()
      });
      console.log('MCQ attempt submission response:', response.data);
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.error('Unauthorized: Please sign in again');
        // Redirect to login page or handle auth error
        // window.location.href = '/sign-in';
      }
      console.error('Error submitting MCQ attempt:', error);
      throw error;
    }
  },
  
  // Get attempted MCQs for a topic
  getAttemptedMCQsByTopic: async (topicId, page = 1, limit = 10) => {
    console.log(`Getting attempted MCQs for topic: ${topicId}, page: ${page}, limit: ${limit}`);
    try {
      const response = await axios.get(`${API_BASE_URL}/api/mcq-bank/mcqs/attempted`, {
        params: {
          topicId,
          page,
          limit
        },
        headers: getAuthHeader()
      });
      console.log('Attempted MCQs response:', response.data);
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.error('Unauthorized: Please sign in again');
      }
      console.error('Error getting attempted MCQs:', error);
      throw error;
    }
  }
};

export default mcqBankService;
