import React, { useState, useEffect } from 'react';
import { Box, Grid, styled, IconButton } from '@mui/material';
import MCQPractice from './MCQPractice';
import RecentTopics from './RecentTopics';
import axios from 'axios';
import config from '../../../config';
import { useAuth } from '../../../services/authContext';
import { useNavigate } from 'react-router-dom';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const MCQLayoutContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  height: '100%',
  width: '100%',
  overflow: 'hidden',
  backgroundColor: '#f9f9f9'
}));

const SidebarContainer = styled(Box)(({ theme, isCollapsed }) => ({
  height: '100%',
  backgroundColor: 'white',
  padding: '0',
  borderRight: '1px solid #eaeaea',
  width: isCollapsed ? '50px' : '250px',
  flex: isCollapsed ? '0 0 50px' : '0 0 250px',
  minWidth: isCollapsed ? '50px' : '250px',
  maxWidth: isCollapsed ? '50px' : '250px',
  transition: 'all 0.3s ease',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  boxShadow: '0 0 10px rgba(0,0,0,0.03)'
}));

const ContentContainer = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  height: '100%',
  backgroundColor: 'white',
  borderRadius: '0',
  overflow: 'auto'
}));

const CollapseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  bottom: '20px',
  left: '50%',
  transform: 'translateX(-50%)',
  backgroundColor: 'white',
  boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
  width: '36px',
  height: '36px',
  borderRadius: '50%',
  border: 'none',
  padding: '0',
  zIndex: 10,
  '&:hover': {
    backgroundColor: '#f5f5f5',
  }
}));

const MCQPracticeLayout = () => {
  const [mcqHistory, setMcqHistory] = useState([]);
  const [selectedTopicId, setSelectedTopicId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
  
  const { getToken, logout } = useAuth();
  const navigate = useNavigate();
  
  // Create an axios instance with interceptors to handle 401 errors
  const axiosInstance = axios.create();
  
  // Setup response interceptor for 401 handling
  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && error.response.status === 401) {
        // Handle 401 Unauthorized error - logout and redirect to login
        logout();
        navigate('/auth/signin');
        return Promise.reject(new Error('Session expired, please sign in again.'));
      }
      return Promise.reject(error);
    }
  );
  
  // Fetch MCQ history only on mount - no interval refresh
  useEffect(() => {
    // Initial fetch on page load
    fetchMCQHistory();
    
    // No interval refresh anymore - only fetch on explicit calls
    // after user submits MCQ answers
    
  }, []);
  
  // Function to fetch MCQ history
  const fetchMCQHistory = async () => {
    try {
      setLoading(true);
      const token = await getToken();
      const response = await axios.get(
        `${config.API_URL}/api/mcq/userMcqHistory`,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );
      
      console.log('MCQ History API response:', response.data);
      
      if (response.data && Array.isArray(response.data)) {
        // Sort by createdAt date in descending order (newest first)
        const sortedHistory = [...response.data].sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
        
        console.log('Sorted MCQ history:', sortedHistory);
        setMcqHistory(sortedHistory);
      } else {
        console.error('Invalid MCQ history data structure:', response.data);
        setMcqHistory([]);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching MCQ history:', error);
      setMcqHistory([]);
      setLoading(false);
    }
  };

  // Handle history update from MCQPractice component
  const handleHistoryUpdate = (history) => {
    console.log('Received history update in layout:', history);
    // No transformation needed, just use the data as is
    setMcqHistory(history);
  };

  // Handle topic selection from sidebar
  const handleTopicSelect = async (topicId) => {
    try {
      setLoading(true);
      // Fetch the data first to ensure we have the latest
      await fetchMCQHistory();
      // Then set the selected topic
      setSelectedTopicId(topicId);
      setLoading(false);
    } catch (error) {
      console.error('Error selecting topic:', error);
      setLoading(false);
    }
  };

  // Toggle sidebar collapsed state
  const toggleSidebar = () => {
    setSidebarCollapsed(!sidebarCollapsed);
  };

  return (
    <MCQLayoutContainer>
      <Box sx={{ display: 'flex', height: '100%', width: '100%' }}>
        {/* Sidebar */}
        <SidebarContainer isCollapsed={sidebarCollapsed}>
          {!sidebarCollapsed && (
            <RecentTopics 
              topics={mcqHistory} 
              selectedTopicId={selectedTopicId} 
              onTopicSelect={handleTopicSelect}
              loading={loading}
            />
          )}
          <CollapseButton onClick={toggleSidebar} size="small">
            {sidebarCollapsed ? <ChevronRightIcon fontSize="small" /> : <ChevronLeftIcon fontSize="small" />}
          </CollapseButton>
        </SidebarContainer>
        
        {/* Content */}
        <ContentContainer>
          <MCQPractice 
            topicId={selectedTopicId}
            onHistoryUpdate={handleHistoryUpdate}
            refreshHistory={fetchMCQHistory}
          />
        </ContentContainer>
      </Box>
    </MCQLayoutContainer>
  );
};

export default MCQPracticeLayout;
