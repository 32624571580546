
// // //

import React from "react";
import {
    Box,
    TextField,
    Button,
    Typography,
    Checkbox,
    Radio,
    FormControlLabel,
    Card,
    CardContent,
    Paper,
    IconButton,
    Chip,
    Collapse,
    Avatar,
    Container,
    CircularProgress,
    Drawer,
    AppBar,
    Toolbar,
    Divider,
} from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import HistoryIcon from "@mui/icons-material/History"; // Icon representing history
import { CopyAll, Refresh, ThumbUp, ThumbDown } from '@mui/icons-material';
import FlagIcon from '@mui/icons-material/Flag';
import { Skeleton } from "@mui/material";
import { styled } from "@mui/material/styles";
import SendIcon from "@mui/icons-material/Send";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import SchoolIcon from "@mui/icons-material/School";
import PersonIcon from "@mui/icons-material/Person";
import MenuIcon from "@mui/icons-material/Menu";
import HospitalIcon from "@mui/icons-material/LocalHospital"; // New import
import authService from "../../services/authService";
import { Link } from "react-router-dom"; // New import
import { useAuth } from "../../services/authContext";
import { useState, useEffect } from "react";
import TopicSelectionCards from "./TopicSelectionCards";
import MCQActions from "./MCQActions";
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import jsPDF from 'jspdf';
import MCQUploadDialog from './MCQUploadDialog';
import UploadFileIcon from '@mui/icons-material/UploadFile';
// Add this import
import DownloadIcon from '@mui/icons-material/Download';
import Fab from '@mui/material/Fab';

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import MCQTypeSelectorDialog from "./MCQTypeSelectorDialog"; // Import the new dialog component
import config from "../../config";
import { useNavigate } from "react-router-dom"; // Import useParams or withRouter

// const StyledCard = styled(Card)(({ theme }) => ({
//     marginBottom: theme.spacing(2),
//     borderRadius: "12px",
//     boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
//     overflow: "hidden",
//     width: "100%",
// }));
const StyledCard = styled(Card)(({ theme }) => ({
    marginBottom: theme.spacing(2),
    borderRadius: "8px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    transition: "all 0.3s ease-in-out",
    "&:hover": {
        transform: "scale(1.02)",
        boxShadow: "0 6px 12px rgba(0, 0, 0, 0.2)",
    },
}));


const StyledCardForMCQ = styled(Card)(({ theme }) => ({
    marginBottom: theme.spacing(2),
    borderRadius: "8px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    // transition: "all 0.3s ease-in-out",
    // "&:hover": {
    //     transform: "scale(1.02)",
    //     boxShadow: "0 6px 12px rgba(0, 0, 0, 0.2)",
    // },
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    marginRight: theme.spacing(2),
}));


const MCQSet =(({ set, handleAnswerChange, handleSubmit }) => (
    <StyledCardForMCQ key={set.id} id={`mcq-set-${set.id}`} sx={{ mb: 4, width: "100%" }}>
        <CardContent>
            <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                <Avatar sx={{ bgcolor: "#3498db", mr: 2 }}>
                    <SchoolIcon />
                </Avatar>
                <Typography variant="h6" sx={{ color: "#3498db" }}>
                    MCQs on {set.topic}:
                </Typography>
            </Box>
            {set.questions.map((q) => {
                const isCorrect =
                    JSON.stringify(q.correct.sort()) === JSON.stringify((set.answers[q.id || q._id] || []).sort());
                return (
                    <Box
                        key={q.id || q._id}
                        sx={{
                            mb: 3,
                            p: 2,
                            border: set.submitted ? "1px solid" : "1px solid",
                            borderColor: set.submitted ? (isCorrect ? "lightblue" : "pink") : "lightblue",
                            borderRadius: "4px",
                        }}
                    >
                        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2 }}>
                            <Typography variant="subtitle1" sx={{ color: "#3498db" }}>
                                Question {q.id || q._id}
                            </Typography>
                            <Chip label={q.subject || "General"} color="primary" variant="outlined" />
                        </Box>
                        <Typography variant="body1" gutterBottom sx={{ color: "#34495e", mb: 2 }}>
                            {q.text}
                        </Typography>
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                            {q.options.map((option) => (
                                <FormControlLabel
                                    key={option}
                                    control={
                                        q.type === "multiple" ? (
                                            <Checkbox
                                                checked={(set.answers[q.id || q._id ] || []).includes(option)}
                                                onChange={() => handleAnswerChange(set.id, q.id || q._id, option, true)}
                                                disabled={set.submitted}
                                            />
                                        ) : (
                                            <Radio
                                                checked={(set.answers[q.id || q._id] || []).includes(option)}
                                                onChange={() => handleAnswerChange(set.id, q.id||q._id, option, false)}
                                                disabled={set.submitted}
                                            />
                                        )
                                    }
                                    label={<Typography sx={{ color: "#2c3e50" }}>{option}</Typography>}
                                />
                            ))}
                        </Box>
                        <Collapse in={set.submitted}>
                            <Box sx={{ mt: 2, p: 2, bgcolor: "#f5f5f5", borderRadius: "4px" }}>
                                <Typography variant="body2" color={isCorrect ? "success.main" : "error.main"}>
                                    {isCorrect ? "Correct!" : "Incorrect"}
                                </Typography>
                                <Typography variant="body2" sx={{ mt: 1 }}>
                                    Correct Answer: {q.correct.join(", ")}
                                </Typography>
                                <Typography variant="body2" sx={{ mt: 1 }}>
                                    {q.explanation}
                                </Typography>
                            </Box>
                        </Collapse>
                        <MCQActions 
                            setId={set.id} 
                            mcqId={q.id || q._id} 
                            onLike={(setId, mcqId) => console.log(`Liked MCQ ${mcqId} in Set ${setId}`)}
                            onDislike={(setId, mcqId) => console.log(`Disliked MCQ ${mcqId} in Set ${setId}`)}
                            onReport={(setId, mcqId) => console.log(`Reported MCQ ${mcqId} in Set ${setId}`)}
                        />
                    </Box>
                );
            })}
            {!set.submitted && (
                <Button
                    type="button"
                    variant="contained"
                    onClick={(event) => handleSubmit(event, set.id)}
                    sx={{
                        mt: 2, // Adjust the margin top to align properly.
                        mb: 2, // Padding to make the button more prominent.
                        fontWeight: "bold", // Make the text bold for better visibility.
                        backgroundColor: "#3498db", // Set a nice blue color.
                        color: "white", // Ensure the text color contrasts well with the background.
                        borderRadius: "8px", // Rounded corners for a modern look.
                        "&:hover": {
                            backgroundColor: "#2980b9", // Darker shade of blue for the hover state.
                            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)", // Add a subtle shadow on hover for more focus.
                        },
                    }}
                >
                    Submit Answers
                </Button>
            )}
        </CardContent>
    </StyledCardForMCQ>
));  


const ShimmerSkeleton = styled(Skeleton)(({ theme }) => ({
    background: `linear-gradient(90deg, ${theme.palette.grey[300]} 25%, ${theme.palette.grey[100]} 50%, ${theme.palette.grey[300]} 75%)`,
    backgroundSize: "200% 100%",
    animation: "shimmer 1.5s infinite",
    "@keyframes shimmer": {
        "0%": {
            backgroundPosition: "200% 0",
        },
        "100%": {
            backgroundPosition: "-200% 0",
        },
    },
}));
const RootLayout = () => {
    const { isSignedIn, user, signOut } = useAuth();
    
    return (
        <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
            <Link to="/" style={{ display: "flex", alignItems: "center", textDecoration: "none", color: "inherit" }}>
                <HospitalIcon sx={{ mr: 1 }} />
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                    Neetly AI
                </Typography>
            </Link>
            <Box sx={{ flexGrow: 1 }} />
            <Link
                to="/dashboard"
                style={{
                    textDecoration: "none",
                    display: "inline-block",
                }}
            >
                <Button
                    variant="contained"
                    sx={{
                        background: "linear-gradient(135deg, #ffffff, #f0f0f0)",
                        color: "#333",
                        fontWeight: "bold",
                        borderRadius: "8px",
                        padding: { xs: "6px 12px", sm: "8px 16px" }, // Responsive padding
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
                        "&:hover": {
                            background: "linear-gradient(135deg, #f0f0f0, #e0e0e0)",
                            boxShadow: "0 6px 12px rgba(0, 0, 0, 0.3)",
                        },
                        textTransform: "none",
                    }}
                >
                    Case Study
                </Button>
            </Link>
            <Box sx={{ flexGrow: 1 }} />
            {!isSignedIn ? (
                <Link to="/sign-in">
                    <Button color="primary" variant="contained">
                        Sign In
                    </Button>
                </Link>
            ) : (
                <IconButton 
                    onClick={signOut} 
                    color="primary"
                    sx={{ ml: 1 }}
                >
                    <Avatar sx={{ width: 32, height: 32 }}>
                        {user?.firstName?.charAt(0) || 'U'}
                    </Avatar>
                </IconButton>
            )}
        </Box>
    );
};

const TopBar = ({ isMobile, onToggleDrawer }) => (
    <AppBar position="static">
        <Toolbar>
            {isMobile && (
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={onToggleDrawer}
                    sx={{ mr: 2 }}
                >
                    <MenuIcon />
                </IconButton>
            )}
            <RootLayout />
        </Toolbar>
    </AppBar>
);

// const ChatHistoryPanel = ({ chatHistory, onSelectTopic, isMobile }) => (
//     <Box sx={{ p: 2, height: "100%", overflowY: "auto" }}>
//         <Typography variant="h6" gutterBottom>
//             Chat History
//         </Typography>
//         {chatHistory.map((chat) => (
//           <Button
//           key={chat._id}
//           onClick={() => onSelectTopic(chat._id)}
//           fullWidth
//           sx={{ justifyContent: 'flex-start', mb: 1 }}
//         >
//           {chat.title} - Created At: {new Date(chat.createdAt).toLocaleString()}
//         </Button>
//         ))}
//     </Box>
// );
// const ChatHistoryPanel = ({ chatHistory, onSelectTopic, isMobile, isLoadingHistory, isAddingNewEntry }) => (
//     <Box sx={{ p: 2, height: "100%", overflowY: "auto" }}>
//         <Typography variant="h6" gutterBottom>
//             Chat History
//         </Typography>
//         {isLoadingHistory ? (
//             // Show skeleton loader during initial loading
//             Array.from({ length: 4 }).map((_, index) => (
//                 <Box key={index} sx={{ mb: 2 }}>
//                     <Skeleton variant="rectangular" height={40} />
//                 </Box>
//             ))
//         ) : (
//             <>
//                 {isAddingNewEntry && (
//                     // Show skeleton loader at the top while adding a new entry
//                     <Box sx={{ mb: 2 }}>
//                         <Skeleton variant="rectangular" height={40} />
//                     </Box>
//                 )}
//                 {chatHistory.map((chat) => (
//                     <Button
//                         key={chat._id}
//                         onClick={() => onSelectTopic(chat._id)}
//                         fullWidth
//                         sx={{ justifyContent: 'flex-start', mb: 1 }}
//                     >
//                         {chat.title} - Created At: {new Date(chat.createdAt).toLocaleString()}
//                     </Button>
//                 ))}
//             </>
//         )}
//     </Box>
// );

const ChatHistoryPanel = ({ chatHistory, onSelectTopic, isMobile, isLoadingHistory, isAddingNewEntry }) => (
    <Box sx={{ p: 2, height: "100%", overflowY: "auto" }}>
        <Typography variant="h5" gutterBottom sx={{ fontWeight: "bold", textAlign: "center" }}>
            Chat History
        </Typography>

        {isLoadingHistory ? (
            Array.from({ length: 4 }).map((_, index) => (
                <Box key={index} sx={{ mb: 2 }}>
                    <Skeleton variant="rectangular" height={60} />
                </Box>
            ))
        ) : (
            <>
                {isAddingNewEntry && (
                    <Box sx={{ mb: 2 }}>
                        <Skeleton variant="rectangular" height={60} />
                    </Box>
                )}
                {chatHistory.map((chat) => (
                    <StyledCard
                        key={chat._id}
                        onClick={() => onSelectTopic(chat._id, false)}
                        sx={{
                            cursor: "pointer",
                            // backgroundColor: index === chat._id ? "#e3f2fd" : "#fff",
                            // boxShadow:  === chat._id ? "0px 4px 10px rgba(0, 0, 0, 0.15)" : "0px 2px 5px rgba(0, 0, 0, 0.1)"
                        }}
                    >
                        <CardContent sx={{ display: "flex", alignItems: "center" }}>
                            <Box sx={{ flexGrow: 1 }}>
                                <Typography variant="subtitle1" sx={{ fontWeight: "bold",fontSize:12 }}>
                                    {chat.title.toUpperCase()}
                                </Typography>
                                <Box sx={{ display: "flex", alignItems: "center" }}>
                                    <AccessTimeIcon fontSize="small" sx={{ mr: 1, color: "gray" }} />
                                    <Typography variant="caption" color="text.secondary">
                                        {new Date(chat.createdAt).toLocaleString()}
                                    </Typography>
                                </Box>
                            </Box>
                        </CardContent>
                        <Divider />
                    </StyledCard>
                ))}
            </>
        )}
    </Box>
);

const LoaderWithMessage = ({ message }) => (
    <Box
        sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            flexDirection: "column",
        }}
    >
        <CircularProgress sx={{ mb: 2 }} />
        <Typography variant="body2" sx={{ mt: 2 }}>
            {message}
        </Typography>
    </Box>
);

const Loader = () => {
    return (
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
            <CircularProgress />
        </Box>
    );
};

class ChatWindow extends React.Component {
    constructor(props) {
        super(props);
        this.handleResize = this.handleResize.bind(this);
        this.fetchMCQHistory = this.fetchMCQHistory.bind(this);
        this.mcqContainerRef = React.createRef();
        this.state = {
            input: "",
            isUploadDialogOpen: false,
            mcqSets: [],
            mcqLoader: false,
            loading: false,
            chatHistory: [],
            isReattemptSubmit:false,
            isMobile: false,
            isChatOpen: false,
            isSaving: false,
            chatHistoryLoader: false,
            initialTopic: {},
            isLoadingHistory: true, // For loading initial chat history
    isAddingNewEntry: false,
    mcqId: null,
    reportDialogOpen:false,
    showTypeOptions:false,
    topicForMCQ:'',
    textIdForMCQ:'',
            

        };
    }

    handleFileUpload = async (formData) => {
        this.setState({ mcqLoader: true });
        
        try {
            const token = await this.props.getToken();
            
            // Create a proper FormData object for backend
            const file = formData.get('file');
            const requestData = new FormData();
            requestData.append('file', file);
            requestData.append('prompt', formData.get('prompt'));
            requestData.append('numQuestions', formData.get('numQuestions'));
            requestData.append('difficulty', formData.get('difficulty'));
            requestData.append('topics', formData.get('topics'));
    
            // For development logging
            console.log('Sending to backend:', {
                fileName: file.name,
                fileType: file.type,
                fileSize: file.size,
                prompt: formData.get('prompt'),
                numQuestions: formData.get('numQuestions'),
                difficulty: formData.get('difficulty'),
                topics: formData.get('topics')
            });
    
            // Backend API call (commented out for now)
             const response = await fetch(`${config.API_URL}/api/mcq/upload`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`
                },
                body: requestData
            });
    
            const result = await response.json(); 
    
            // Temporary simulation
            // setTimeout(() => {
            //     const mockResponse = {
            //         topic: `MCQs from ${file.name}`,
            //         questions: [
            //             {
            //                 id: "1",
            //                 text: "Sample question from uploaded content?",
            //                 type: "single",
            //                 options: ["Option A", "Option B", "Option C", "Option D"],
            //                 correct: ["Option A"],
            //                 explanation: "This is a sample explanation"
            //             }
            //         ]
            //     };
    
                const newSet = {
                    id: Date.now().toString(),
                    topic: result.topic,
                    questions: result.questions,
                    answers: {},
                    submitted: false,
                };
    
                this.setState((prevState) => ({
                    mcqSets: [...prevState.mcqSets, newSet],
                    mcqLoader: false,
                    isUploadDialogOpen: false
                }));
    
                this.scrollToMcqSetForNewPromt(newSet.id, true);
    
        } catch (error) {
            console.error('Error processing file upload:', error);
            this.setState({ mcqLoader: false, isUploadDialogOpen: false });
        }
    };

    downloadMCQAsPDF = () => {
        const doc = new jsPDF();
      
        // If your environment doesn't have a global jsPDF,
        // ensure it's imported: import jsPDF from 'jspdf';
      
        const { mcqSets } = this.state;
      
        // Page dimensions and margins
        const pageWidth = doc.internal.pageSize.getWidth();
        const pageHeight = doc.internal.pageSize.getHeight();
        const margin = 35;
        const contentWidth = pageWidth - margin * 2;
      
        // Track vertical offset from the top
        let yOffset = margin;
      
        // Define spacing values (in points)
        const spacing = {
          afterHeader: 10,              // Space after header
          afterSetTitle: 10,            // Space after set title
          lineHeight: 6,                // Base line height
          questionSpacing: 10,          // Space after question
          optionSpacing: 5,             // Space between options
          answerSpacing: 10,            // Space after answer
          explanationSpacing: 10,       // Space after explanation
          dividerSpacing: 10,           // Space after the divider line
          bottomSafeMargin: 30,         // Ensure we don’t print into the bottom margin
        };
      
        // Font configurations
        const fonts = {
          header:     { size: 10, style: 'normal' },
          setTitle:   { size: 14, style: 'bold' },
          question:   { size: 12, style: 'bold' },
          option:     { size: 11, style: 'normal' },
          answer:     { size: 11, style: 'bold' },
          explanation:{ size: 11, style: 'normal' },
        };
      
        // ---------------------------------------------------------------------------
        // Helper functions
        // ---------------------------------------------------------------------------
      
        // Draw the header on each page
        const drawHeader = (pageNum) => {
          doc.setFont('helvetica', fonts.header.style);
          doc.setFontSize(fonts.header.size);
      
          const dateStr = `Generated on: ${new Date().toLocaleDateString()}`;
          const pageStr = `Page ${pageNum}`;
          // Print date on the left
          doc.text(dateStr, margin, 20);
          // Print page number on the right
          doc.text(pageStr, pageWidth - margin - doc.getTextWidth(pageStr), 20);
      
          // Draw a line under the header
          doc.setDrawColor(200, 200, 200);
          doc.line(margin, 25, pageWidth - margin, 25);
        };
      
        // Check if there's enough space left on the page.
        // If not, add a new page and re-draw the header.
        const checkPageSpace = (requiredSpace) => {
          if (yOffset + requiredSpace > pageHeight - spacing.bottomSafeMargin) {
            doc.addPage();
            const pageNum = doc.internal.getNumberOfPages();
            drawHeader(pageNum);
            yOffset = margin; // Reset yOffset after drawing the header
          }
        };
      
        // Add the initial header
        drawHeader(1);
        yOffset = 30 + spacing.afterHeader;
      
        // ---------------------------------------------------------------------------
        // Main PDF generation logic
        // ---------------------------------------------------------------------------
      
        mcqSets.forEach((set, setIndex) => {
          // -------------------------------------------------------------------------
          // Section Title
          // -------------------------------------------------------------------------
          checkPageSpace(20); // Check if we have enough room for a title
          doc.setFont('helvetica', fonts.setTitle.style);
          doc.setFontSize(fonts.setTitle.size);
      
          const setTitle = `MCQ Set ${setIndex + 1}: ${set.topic}`;
          doc.text(setTitle, margin, yOffset);
          yOffset += spacing.afterSetTitle;
      
          // -------------------------------------------------------------------------
          // Questions Loop
          // -------------------------------------------------------------------------
          set.questions.forEach((q, qIndex) => {
            // ---------------------------
            // Question Text
            // ---------------------------
            doc.setFont('helvetica', fonts.question.style);
            doc.setFontSize(fonts.question.size);
      
            // Split text to fit within content width
            const questionNumber = `${qIndex + 1}. `;
            const splitQuestion = doc.splitTextToSize(q.text, contentWidth - doc.getTextWidth(questionNumber));
      
            // Check space needed for question text
            checkPageSpace(splitQuestion.length * spacing.lineHeight + spacing.questionSpacing);
      
            // Print the question number and the wrapped question text
            doc.text(questionNumber, margin, yOffset);
            doc.text(splitQuestion, margin + doc.getTextWidth(questionNumber), yOffset);
      
            // Advance yOffset
            yOffset += splitQuestion.length * spacing.lineHeight + spacing.questionSpacing;
      
            // ---------------------------
            // Options
            // ---------------------------
            doc.setFont('helvetica', fonts.option.style);
            doc.setFontSize(fonts.option.size);
      
            q.options.forEach((option, optIndex) => {
              // Build the option label, e.g. "A. Option text..."
              const optionLabel = `${String.fromCharCode(65 + optIndex)}. `;
              const splitOptionText = doc.splitTextToSize(option, contentWidth - doc.getTextWidth(optionLabel) - 10);
      
              // Check space for each option
              checkPageSpace(splitOptionText.length * spacing.lineHeight + spacing.optionSpacing);
      
              // Print option label and text
              doc.text(optionLabel, margin + 5, yOffset); 
              doc.text(splitOptionText, margin + 5 + doc.getTextWidth(optionLabel), yOffset);
      
              // Advance yOffset
              yOffset += splitOptionText.length * spacing.lineHeight + spacing.optionSpacing;
            });
      
            // ---------------------------
            // Answer
            // ---------------------------
            doc.setFont('helvetica', fonts.answer.style);
            doc.setFontSize(fonts.answer.size);
      
            // Collect correct answer letters (A, B, C, ...)
            const correctAnswers = q.correct
              .map(correctOpt => {
                const optIndex = q.options.indexOf(correctOpt);
                return String.fromCharCode(65 + optIndex);
              })
              .join(', ');
      
            // Check space for answer text
            checkPageSpace(spacing.lineHeight + spacing.answerSpacing);
      
            doc.text('Correct Answer:', margin, yOffset);
            doc.setFont('helvetica', 'normal');
            doc.text(correctAnswers, margin + doc.getTextWidth('Correct Answer: ') + 5, yOffset);
      
            // Advance yOffset
            yOffset += spacing.lineHeight + spacing.answerSpacing;
      
            // ---------------------------
            // Explanation
            // ---------------------------
            doc.setFont('helvetica', fonts.explanation.style);
            doc.setFontSize(fonts.explanation.size);
      
            const explanationLabel = 'Explanation: ';
            const explanationText = q.explanation || 'No explanation available.';
            const splitExplanation = doc.splitTextToSize(explanationText, contentWidth - doc.getTextWidth(explanationLabel));
      
            // Check space for explanation text
            checkPageSpace(splitExplanation.length * spacing.lineHeight + spacing.explanationSpacing);
      
            doc.text(explanationLabel, margin, yOffset);
            doc.setFont('helvetica', 'normal');
            doc.text(splitExplanation, margin + doc.getTextWidth(explanationLabel), yOffset);
      
            // Advance yOffset
            yOffset += splitExplanation.length * spacing.lineHeight + spacing.explanationSpacing;
      
            // ---------------------------
            // Divider between questions
            // ---------------------------
            if (qIndex < set.questions.length - 1) {
              // Check space for the divider
              checkPageSpace(spacing.dividerSpacing);
      
              doc.setDrawColor(220, 220, 220);
              doc.line(margin, yOffset, pageWidth - margin, yOffset);
      
              // Advance yOffset
              yOffset += spacing.dividerSpacing;
            }
          });
      
          // Optionally, add some extra space after each set
          yOffset += 10;
        });
      
        // Finally, save the PDF
        doc.save('mcqs.pdf');
    }      

      

    componentDidMount() {
        const { mcqId,textId } = this.props;

        this.handleResize();
        this.fetchMCQHistory();
        if (mcqId) {
            this.scrollToMcqSet(mcqId,true,textId);
            this.setState( {mcqId:mcqId});
        }
        if (this.props.textId) {
            this.handleTopicSelect(null,textId);
        }

        window.addEventListener("resize", this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    }
    componentDidUpdate(prevProps) {
        const {mcqId} =this.props;
        if (mcqId && mcqId !== prevProps.mcqId) {
            this.scrollToMcqSet(mcqId,true);
            this.setState( {mcqId:null});

          }
        // Check if the initialTopic prop has changed
        if (this.props.initialTopic && this.props.initialTopic !== prevProps.initialTopic) {
            this.handleTopicSelect(this.props.initialTopic);
           this.setState( {mcqId:null});
        }
    }
    // renderSkeletonLoader = () => (
    //     <StyledCard sx={{ mb: 4, width: "100%" }}>
    //         <CardContent>
    //             <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
    //                 <Skeleton variant="circular" width={40} height={40} sx={{ mr: 2 }} />
    //                 <Skeleton variant="text" width="40%" height={30} />
    //             </Box>
    //             <Skeleton variant="text" width="80%" sx={{ mb: 2 }} height={20} />
    //             <Skeleton variant="text" width="100%" height={15} sx={{ mb: 1 }} />
    //             <Skeleton variant="text" width="100%" height={15} sx={{ mb: 1 }} />
    //             <Skeleton variant="text" width="100%" height={15} sx={{ mb: 1 }} />
    //             <Skeleton variant="text" width="100%" height={15} sx={{ mb: 2 }} />
    //             <Skeleton variant="rectangular" width={100} height={30} />
    //         </CardContent>
    //     </StyledCard>
    // );
    renderSkeletonLoader = () => {
        // Determine a fixed number of skeletons (4 to 5 questions)
        const numberOfSkeletons = Math.floor(Math.random() * 2) + 4; // Random value between 4 and 5

        return (
            <StyledCard sx={{ mb: 4, width: "100%" }}>
                <CardContent>
                    {Array.from({ length: numberOfSkeletons }).map((_, index) => (
                        <Box key={index} sx={{ mb: 3 }}>
                            {/* Question Title Skeleton */}
                            <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                                <ShimmerSkeleton variant="text" width="60%" height={20} />
                            </Box>
                            {/* Question Text Skeleton */}
                            <ShimmerSkeleton variant="text" width="80%" sx={{ mb: 2 }} height={20} />
                            {/* Answer Options Skeleton */}
                            <ShimmerSkeleton variant="text" width="100%" height={15} sx={{ mb: 1 }} />
                            <ShimmerSkeleton variant="text" width="100%" height={15} sx={{ mb: 1 }} />
                            <ShimmerSkeleton variant="text" width="100%" height={15} sx={{ mb: 1 }} />
                            <ShimmerSkeleton variant="text" width="100%" height={15} />
                        </Box>
                    ))}
                </CardContent>
            </StyledCard>
        );
    };

    // componentDidMount() {
    //     // this.handleResize();
    //     // this.fetchMCQHistory();
    //     // // this.fetchInitialMCQSets();
    //     // window.addEventListener("resize", this.handleResize);
    //     useEffect(() => {
    //         // ComponentDidMount: Add event listener
    //         // window.addEventListener("resize", handleResize);
    //         this.handleResize();
    //         // // Fetch MCQ history when the component mounts
    //          fetchMCQHistory();

    //         // ComponentWillUnmount: Remove event listener
    //         return () => {
    //             window.addEventListener("resize", handleResize);            };
    //       }, []);
    // }

    // componentWillUnmount() {
    //     useEffect(() => {
    //         // ComponentDidMount: Add event listener
    //         // window.addEventListener("resize", handleResize);

    //         // // Fetch MCQ history when the component mounts
    //         // fetchMCQHistory();

    //         // ComponentWillUnmount: Remove event listener
    //         return () => {
    //           window.removeEventListener("resize", handleResize);
    //         };
    //       }, []);
    //    // window.removeEventListener("resize", this.handleResize);
    // }

    //   fetchInitialMCQSets = async () => {
    //     this.setState({ loading: true });
    //     try {
    //      const initialSets = await authService.getQuestions("cc");
    //       const chatHistoryEntry = {
    //         id: 1234,
    //         topic: initialSets.topic,
    //         score:2,
    //         totalQuestions: initialSets.questions.length,
    //         questions: initialSets.questions
    //       };
    //       this.setState({
    //         chatsHistory: {...chatHistoryEntry,submitted : true,score:0},
    //         loading: false
    //       });
    //     } catch (error) {
    //       console.error('Error fetching initial MCQ sets:', error);
    //       this.setState({ loading: false });
    //     }
    //   }


async saveMCQAnswers(mcqSet) {
    this.setState({  isAddingNewEntry: true });

console.trace();
    if (this.state.isSaving) {
        return; // Exit if saveMCQAnswers is already in progress
      }
      this.setState({ isSaving: true });
    const token = await this.props.getToken(); // Use the getToken prop

    try {
      const payload = {
        topic: mcqSet.topic,
        totalQuestions: mcqSet.questions.length,
        questionData: mcqSet.questions.map(q => ({
          text: q.text,
          type: q.type,
          options: q.options,
          correct: q.correct,
          explanation: q.explanation,
          subject: q.subject,
          userAnswer: mcqSet.answers[q.id] || [],
        })),
        marks: mcqSet.score,
      };
      let response={};
      const { mcqId } = this.props || null;
      console.log(this.state.isResubmission);
     if(mcqId){
        response = await fetch(`${config.API_URL}/api/mcq/questionHistory/${mcqId}`, {
            method: 'POST',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(payload),
          });
     }
     else{
      response = await fetch(`${config.API_URL}/api/mcq/mcqHistory`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });
    }
  
      if (!response.ok) {
        // Check for 401 Unauthorized errors
        if (response.status === 401) {
          console.log("Authentication expired. Logging out...");
          // Get signOut from props
          const { signOut } = this.props;
          if (signOut) {
            await signOut();
            return; // Exit early after logout
          }
        }
        throw new Error('Failed to save MCQ answers');
      }

      if (response && response.id) {
        
        const chatHistoryEntry = {
            _id: response.id,
            title: mcqSet.topic,
            createdAt: new Date().toISOString(), // Assuming current timestamp for new entry
        };

        this.setState((prevState) => ({
            chatHistory: [chatHistoryEntry,...prevState.chatHistory],
            loading: false,
            isResubmission:false,
            isAddingNewEntry: false,mcqId:false, // Finished adding the new entry
        }));
    }
      
  
      // Fetch the updated history after saving
    //  await this.fetchMCQHistory();
    } catch (error) {
      console.error('Error saving MCQ answers:', error);
      throw new Error('Failed to save MCQ answers');
    // Add this function inside the ChatWindow class
        } finally {
            this.setState({ isAddingNewEntry: false });
            this.setState({ isSaving: false });
        }
    }
    // Add this function inside the ChatWindow class

    async fetchMCQHistory() {
        this.setState({ isLoadingHistory: true });

        this.setState({ loading: true });

        const token = await this.props.getToken(); // Use the getToken prop
        try {
            const response = await fetch(`${config.API_URL}/api/mcq/userMcqHistory`, {
                method: "GET",
                credentials: "include",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                // Check for 401 Unauthorized errors
                if (response.status === 401) {
                    console.log("Authentication expired. Logging out...");
                    // Get signOut from props
                    const { signOut } = this.props;
                    if (signOut) {
                        await signOut();
                        return; // Exit early after logout
                    }
                }
                throw new Error("Failed to fetch MCQ history");
            }

            const history = await response.json();
            history.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
            this.setState({ isLoadingHistory: false });

            this.setState({ chatHistory: history, loading: false });
        } catch (error) {
            this.setState({ isLoadingHistory: false });
            console.error("Error fetching MCQ history:", error);
            this.setState({ loading: false });
        }
    }

    // Add this function inside the ChatWindow class

    async fetchMCQDetailsById(mcqId) {
        try {
            this.setState({ chatHistoryLoader: true });

            const token = await this.props.getToken(); // Use the getToken prop
            //   const token = await this.props.getToken(); // Assuming getToken is passed as a prop
            const response = await fetch(`${config.API_URL}/api/mcq/questHistory/${mcqId}`, {
                method: "GET",
                credentials: "include",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            this.setState({ chatHistoryLoader: false });
            if (!response.ok) {
                this.setState({ chatHistoryLoader: false });
                // Check for 401 Unauthorized errors
                if (response.status === 401) {
                    console.log("Authentication expired. Logging out...");
                    // Get signOut from props
                    const { signOut } = this.props;
                    if (signOut) {
                        await signOut();
                        return null; // Exit early after logout
                    }
                }
                throw new Error("Failed to fetch MCQ details");
            }

            const mcqDetails = await response.json();
            return mcqDetails;
            // Handle the fetched MCQ details (e.g., display them in the UI)
        } catch (error) {
            this.setState({ chatHistoryLoader: false });

            console.error("Error fetching MCQ details:", error);
            return null;
        }
    }

    handleResize = () => {
        const mobile = window.innerWidth < 600;
        this.setState({
            isMobile: mobile,
            isChatOpen: !mobile,
        });
    };

    toggleChat = () => {
        this.setState((prevState) => ({ isChatOpen: !prevState.isChatOpen }));
    };
    scrollToHalfway = () => {
        // Get the container of the MCQs using the ref
        if (this.mcqContainerRef.current) {
            const container = this.mcqContainerRef.current;
            const halfwayOffset = container.scrollHeight / 2;

            container.scrollTo({
                top: halfwayOffset,
                behavior: "smooth",
            });
        }
    };

    // handleTopicSelect = async (topic,textId=null) => {
    //     this.setState({ mcqLoader: true });
    //     this.setState({ loading: true });
    //     this.scrollToHalfway(); // Scroll halfway when new questions are being fetched
    //     try {
    //         const token = await this.props.getToken();
    //         const questions = await authService.getQuestions(topic,textId,token);
    //         const newSet = {
    //             id: Date.now().toString(),
    //             topic,
    //             questions,
    //             answers: {},
    //             submitted: false,
    //         };

    //         this.setState((prevState) => ({
    //             mcqSets: [...prevState.mcqSets, newSet],
    //             input: "",
    //             loading: false,
    //             mcqLoader:false,
    //             mcqId:null,
    //         }));
    //         this.scrollToMcqSetForNewPromt(newSet.id, true);
    //     } catch (error) {
    //         console.error("Error fetching questions:", error);
    //         this.setState({ loading: false });
    //         this.setState({ mcqLoader: false });
    //     }
    // };
    handleTopicSelect = async (topic, textId = null) => {
        if(textId==null&&!topic) return;
        this.setState({ showTypeOptions: true, topicForMCQ: topic, textIdForMCQ: textId });
     };
    
    handleMCQTypeSelect = async (mcqType) => {
        this.setState({ mcqLoader: true, loading: true, showTypeOptions: false });
    
        this.scrollToHalfway(); 
        try {
            const { topicForMCQ, textIdForMCQ } = this.state; 
            const token = await this.props.getToken();
            
            const questions = await authService.getQuestions(`${topicForMCQ}`, textIdForMCQ, token, mcqType);
            
            const newSet = {
                id: Date.now().toString(),
                topic: topicForMCQ,
                questions,
                answers: {},
                submitted: false,
            };
    
            this.setState((prevState) => ({
                mcqSets: [...prevState.mcqSets, newSet],
                input: "",
                loading: false,
                mcqLoader: false,
            }));
            
            this.scrollToMcqSetForNewPromt(newSet.id, true);
        } catch (error) {
            console.error("Error fetching questions:", error);
            // Check if this is an authentication error (401)
            if (error && error.status === 401) {
                console.log("Authentication expired. Logging out...");
                // Get signOut from props
                const { signOut } = this.props;
                if (signOut) {
                    await signOut();
                    return; // Exit early after logout
                }
            }
            this.setState({ loading: false, mcqLoader: false });
        }
    };
    

    handleAnswerChange = (setId, questionId, option, isMultiSelect) => {
        this.setState((prevState) => {
            const setIndex = prevState.mcqSets.findIndex((s) => s.id === setId);
            if (setIndex === -1) {
                console.error(`MCQ set with id ${setId} not found`);
                return prevState;
            }

            const newMcqSets = [...prevState.mcqSets];
            const set = newMcqSets[setIndex];

            if (isMultiSelect) {
                // Multi-select behavior
                if (!set.answers[questionId]) {
                    set.answers[questionId] = [];
                }
                const answerIndex = set.answers[questionId].indexOf(option);
                if (answerIndex > -1) {
                    // Remove the option if it's already selected
                    set.answers[questionId] = set.answers[questionId].filter((a) => a !== option);
                } else {
                    // Add the option if it's not already selected
                    set.answers[questionId] = [...set.answers[questionId], option];
                }
            } else {
                // Single-select behavior
                set.answers[questionId] = [option];
            }

            // Log the current state of answers for debugging
            console.log(`Set ID: ${setId}, Question ID: ${questionId}, Current Answers:`, set.answers[questionId]);

            // Ensure the component re-renders
            //this.forceUpdate();

            return { mcqSets: newMcqSets };
        });
    };

    handleSubmit = (event, setId) => {
        this.setState({ loading:true  });
        const {clearMcqId} = this.props || false;
        event.preventDefault();
        event.stopPropagation();
        console.trace();
        if(clearMcqId){
        clearMcqId();
        }
        this.setState((prevState) => {
            const setIndex = prevState.mcqSets.findIndex((s) => s.id === setId);
            if (setIndex === -1) return prevState;

            const set = prevState.mcqSets[setIndex];
            let score = 0;
            set.questions.forEach((q) => {
                const userAnswers = set.answers[q.id] || [];
                const correctAnswers = q.correct;

                if (q.type === "multiple") {
                    // For multi-select questions
                    if (
                        userAnswers.length === correctAnswers.length &&
                        userAnswers.every((a) => correctAnswers.includes(a)) &&
                        correctAnswers.every((a) => userAnswers.includes(a))
                    ) {
                        score++;
                    }
                } else {
                    // For single-select questions
                    if (userAnswers.length === 1 && userAnswers[0] === correctAnswers[0]) {
                        score++;
                    }
                }
            });

            const updatedSet = { ...set, submitted: true, score };
            const updatedMcqSets = [...prevState.mcqSets];
            updatedMcqSets[setIndex] = updatedSet;
            // const chatHistoryEntry = {
            //     id: setId,
            //     topic: set.topic,
            //     score,
            //     totalQuestions: set.questions.length,
            //     questions: set.questions,
            // };
             this.saveMCQAnswers(updatedSet);
            //   useEffect(() => {
            //     if (!isSaving) {
            //       setIsSaving(true);
            //       // Call your save function here
            //      this.saveMCQAnswers(mcqSet).finally(() => {
            //         setIsSaving(false);
            //       });
            //     }
            //   }, [mcqSet]);
            return {
                mcqSets: updatedMcqSets,
               mcqId: null,
               // chatHistory: [...prevState.chatHistory, chatHistoryEntry],
            };
        });
      //  setPathVariable(null); // Clear the path variable after submission
        this.setState({ loading:false  });
    };

    scrollToMcqSet = async (topicId, isReattempt) => {
        const questions = await this.fetchMCQDetailsById(topicId);
        const updatedSet = {
            id: topicId,
            topic: questions.history[0].topic,
            questions: questions.history[0].questionData,
            answers: {}, // Initialize answers as an empty object
            submitted: !isReattempt, // Set to true if you want to mark it as submitted
            score: questions.marks, // Include the score if available
        };
        this.setState((prevState) => ({
            mcqSets:[updatedSet],
        }));
        setTimeout(() => {
            const element = document.getElementById(`mcq-set-${topicId}`);
            if (element) {
                element.scrollIntoView({ behavior: "smooth", block: "start" });
            }
            if (this.state.isMobile) {
                this.toggleChat();
            }
        }, 100);
    };

    scrollToMcqSetForNewPromt = (setId, isNotPromptEvent) => {
        setTimeout(() => {
            const element = document.getElementById(`mcq-set-${setId}`);
            if (element) {
                element.scrollIntoView({ behavior: "smooth", block: "start" });
            }
            this.setState({ mcqId: null });  
        }, 100); // Short delay to ensure the new set is rendered
    };

    renderMCQSet = (set) => (
        <StyledCardForMCQ key={set.id} id={`mcq-set-${set.id}`} sx={{ mb: 4, width: "100%" }}>
            <CardContent>
                <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                    <Avatar sx={{ bgcolor: "#3498db", mr: 2 }}>
                        <SchoolIcon />
                    </Avatar>
                    <Typography variant="h6" sx={{ color: "#3498db" }}>
                        MCQs on {set.topic}:
                    </Typography>
                </Box>
                {set.questions.map((q) => {
                    const isCorrect =
                        JSON.stringify(q.correct.sort()) === JSON.stringify((set.answers[q.id] || []).sort());
                    return (
                        <Box
                            key={q.id}
                            sx={{
                                mb: 3,
                                p: 2,
                                border: set.submitted ? "1px solid" : "none",
                                borderColor: set.submitted ? (isCorrect ? "lightblue" : "pink") : "transparent",
                                borderRadius: "4px",
                            }}
                        >
                            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2 }}>
                                <Typography variant="subtitle1" sx={{ color: "#3498db" }}>
                                  {/* //  Question {q.id} */}
                                </Typography>
                                <Chip label={q.subject || "General"} color="primary" variant="outlined" />
                            </Box>
                            <Typography variant="body1" gutterBottom sx={{ color: "#34495e", mb: 2 }}>
                                {q.text}
                            </Typography>
                            <Box sx={{ display: "flex", flexDirection: "column" }}>
                                {q.options.map((option) => (
                                    <FormControlLabel
                                        key={option}
                                        control={
                                            q.type === "multiple" ? (
                                                <Checkbox
                                                    checked={(set.answers[q.id] || []).includes(option)}
                                                    onChange={() => this.handleAnswerChange(set.id, q.id, option, true)}
                                                    disabled={set.submitted}
                                                />
                                            ) : (
                                                <Radio
                                                    checked={(set.answers[q.id] || []).includes(option)}
                                                    onChange={() =>
                                                        this.handleAnswerChange(set.id, q.id, option, false)
                                                    }
                                                    disabled={set.submitted}
                                                />
                                            )
                                        }
                                        label={<Typography sx={{ color: "#2c3e50" }}>{option}</Typography>}
                                    />
                                ))}
                            </Box>
                            <Collapse in={set.submitted}>
                                <Box sx={{ mt: 2, p: 2, bgcolor: "#f5f5f5", borderRadius: "4px" }}>
                                    <Typography variant="body2" color={isCorrect ? "success.main" : "error.main"}>
                                        {isCorrect ? "Correct!" : "Incorrect"}
                                    </Typography>
                                    <Typography variant="body2" sx={{ mt: 1 }}>
                                        Correct Answer: {q.correct.join(", ")}
                                    </Typography>
                                    <Typography variant="body2" sx={{ mt: 1 }}>
                                        {q.explanation}
                                    </Typography>
                                </Box>
                            </Collapse>
                        </Box>
                    );
                })}
                {!set.submitted && (
                    <Button
                        type="button"
                        variant="contained"
                        onClick={(event) => this.handleSubmit(event, set.id)}
                        disabled={this.state.loading}
                        sx={{ mt: -6, bgcolor: "#3498db", "&:hover": { bgcolor: "#2980b9" } }}
                    >
                        Submit Answers
                    </Button>
                )}
            </CardContent>
        </StyledCardForMCQ>
    );

    render() {
        const { mcqLoader } = this.state;
        const { chatHistoryLoader } = this.state;

        // If the application is loading, render the Loader component

        const { isMobile, isChatOpen, mcqSets, input, chatHistory } = this.state;
        return (
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100vh",
                    backgroundColor: "#ffffff",
                    overflow: "hidden", // Explicitly set to white
                }}
            >
                {/* <TopBar 
            isMobile={isMobile}
            onToggleDrawer={this.toggleChat}
          /> */}
                <Box sx={{ position: "fixed", top: 0, left: 0, right: 0, zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                    <TopBar isMobile={isMobile} onToggleDrawer={this.toggleChat} />
                </Box>
                <Box sx={{ height: 64 }} />
                <Box sx={{ display: "flex", flexGrow: 1, overflow: "hidden" }}>
                    {isMobile ? (
                        <Drawer
                            anchor="left"
                            open={isChatOpen}
                            onClose={this.toggleChat}
                            sx={{ "& .MuiDrawer-paper": { width: 250 } }}
                        >
                            <ChatHistoryPanel
                                chatHistory={chatHistory}
                                onSelectTopic={this.scrollToMcqSet}
                                isMobile={isMobile}
                                isLoadingHistory={this.state.isLoadingHistory}
                                isAddingNewEntry={this.state.isAddingNewEntry}
                            />
                        </Drawer>
                    ) : (
                        <Box sx={{ width: 250, flexShrink: 0, borderRight: 1, borderColor: "divider" }}>
                            <ChatHistoryPanel
                                chatHistory={chatHistory}
                                onSelectTopic={this.scrollToMcqSet}
                                isMobile={isMobile}
                                isLoadingHistory={this.state.isLoadingHistory}
                                isAddingNewEntry={this.state.isAddingNewEntry}
                            />
                        </Box>
                    )}
                    <Box ref={this.mcqContainerRef} sx={{ flexGrow: 1, p: 3, overflowY: "auto" }}>
                        <Typography
                            variant="h5"
                            gutterBottom
                            sx={{ fontWeight: "bold", color: "#2c3e50", width: "100%", textAlign: "center" }}
                        >
                        </Typography>
                        {mcqLoader && this.renderSkeletonLoader()}
                        {chatHistoryLoader && this.renderSkeletonLoader()}

                        {/* {!mcqLoader && !chatHistoryLoader && mcqSets.length === 0 && (
                            <TopicSelectionCards handleTopicSelect={this.handleTopicSelect} />
                        )} */}
                           {mcqSets && mcqSets.length > 0 && (
                    <Fab
                        color="primary"
                        aria-label="download"
                        onClick={this.downloadMCQAsPDF}
                        sx={{
                            position: 'fixed',
                            bottom: 100,
                            right: 20,
                            backgroundColor: '#3498db',
                            '&:hover': {
                                backgroundColor: '#2980b9'
                            },
                            zIndex: 1000
                        }}
                    >
                        <DownloadIcon />
                    </Fab>
                )}           
                        {/* Render MCQ sets */}
                        {!mcqLoader &&
                            !chatHistoryLoader &&
                            mcqSets.map((set) => (
                                <MCQSet
                                    key={`${set.id}-${set.topic}`}
                                    set={set}
                                    handleAnswerChange={this.handleAnswerChange}
                                    handleSubmit={this.handleSubmit}
                                />
                            ))}
                        <Box
                            sx={{
                                position: "fixed",
                                bottom: 0,
                                left: isMobile ? 0 : 250,
                                right: 0,
                                padding: 2,
                                backgroundColor: "background.paper",
                                borderColor: "divider",
                            }}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    bgcolor: "white",
                                    borderRadius: "24px",
                                    p: 1,
                                    border: "1px solid #e0e0e0",
                                    width: "100%",
                                }}
                            >
                                <IconButton
        onClick={() => this.setState({ isUploadDialogOpen: true })}
        sx={{ color: "#3498db", mr: 1 }}
    >
        <UploadFileIcon />
    </IconButton>
                                <TextField
                                    fullWidth
                                    value={input}
                                    onChange={(e) => this.setState({ input: e.target.value })}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                          e.preventDefault(); // Prevents default Enter behavior
                                          this.handleTopicSelect(input); // Trigger send action
                                        }
                                      }}
                                    placeholder="Ask for MCQs on any topic..."
                                    variant="standard"
                                    sx={{
                                        ml: 1,
                                        flex: 1,
                                        "& .MuiInput-underline:before": { borderBottom: "none" },
                                        "& .MuiInput-underline:after": { borderBottom: "none" },
                                    }}
                                    InputProps={{
                                        disableUnderline: true,
                                    }}
                                />
                                <IconButton
                                    onClick={() => this.handleTopicSelect(input)}
                                    disabled={this.state.loading}
                                    sx={{ color: "#3498db" }}
                                >
                                    {this.state.loading ? <CircularProgress size={24} /> : <SendIcon />}
                                </IconButton>
                                {/* <Dialog
        open={this.state.showTypeOptions}
        onClose={() => this.setState({ showTypeOptions: false })}
    >
        <DialogTitle>Select MCQ Type on {input}</DialogTitle>
        <DialogContent>
            <List>
                {["Case-based", "Diagnosis-based", "Management-based", "Pathophysiology"].map((type) => (
                    <ListItem
                        Button
                        key={type}
                        onClick={() => this.handleMCQTypeSelect(type)}
                    >
                        <ListItemText primary={type} />
                    </ListItem>
                ))}
            </List>
        </DialogContent>
    </Dialog> */}
    <MCQTypeSelectorDialog
                open={this.state.showTypeOptions}
                onClose={() => this.setState({ showTypeOptions: false })}
                onSelect={this.handleMCQTypeSelect}
                topic={this.topicForMCQ}
            />
            <MCQUploadDialog
                    open={this.state.isUploadDialogOpen}
                    onClose={() => this.setState({ isUploadDialogOpen: false })}
                    onSubmit={this.handleFileUpload}
                />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        );
    }
}

export default ChatWindow;
                                    