import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useAuth } from '../../services/authContext';
import config from '../../config';
import {
  Box,
  Container,
  Typography,
  Paper,
  Button,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  AppBar,
  Toolbar,
  CircularProgress,
  Alert,
  Snackbar,
  Grid,
  Card,
  CardContent,
  Divider,
  Switch,
  FormControlLabel,
  Chip,
  useTheme,
  Tooltip
} from '@mui/material';
import {
  Menu as MenuIcon,
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Search as SearchIcon,
  School as SchoolIcon,
  Dashboard as DashboardIcon,
  Settings as SettingsIcon,
  Close as CloseIcon
} from '@mui/icons-material';

const MaterialSuperAdminDashboard = () => {
  const theme = useTheme();
  const { user, getToken, hasRole } = useAuth();
  const [institutions, setInstitutions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showAddForm, setShowAddForm] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const [selectedInstitution, setSelectedInstitution] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const searchTimeoutRef = useRef(null);

  const [formData, setFormData] = useState({
    name: '',
    code: '',
    email: '',
    address: '',
    phone: '',
    website: '',
    adminDetails: {
      firstName: '',
      lastName: '',
      email: '',
      password: ''
    },
    subscription: {
      plan: 'free'
    },
    branding: {
      logoUrl: '',
      primaryColor: '#3f51b5',
      secondaryColor: '#f50057'
    },
    domain: {
      useCustomDomain: false,
      customDomain: ''
    }
  });

  useEffect(() => {
    const fetchInstitutions = async () => {
      try {
        setLoading(true);
        const authToken = await getToken();
        const response = await axios.get(`${config.API_URL}/api/institutions`, {
          headers: {
            Authorization: `Bearer ${authToken}`
          },
          params: {
            page: page + 1,
            pageSize: rowsPerPage,
            search: searchQuery
          }
        });
        
        setInstitutions(response.data.institutions || []);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching institutions:', err);
        setError('Failed to load institutions. Please try again later.');
        setLoading(false);
      }
    };

    if (user && hasRole('super_admin')) {
      fetchInstitutions();
    } else {
      setError('You do not have permission to access this page.');
      setLoading(false);
    }
  }, [user, getToken, hasRole, page, rowsPerPage, searchQuery]);

  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    
    if (searchTimeoutRef.current) {
      clearTimeout(searchTimeoutRef.current);
    }
    
    searchTimeoutRef.current = setTimeout(() => {
      setPage(0);
    }, 500);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name.includes('.')) {
      const [parent, child] = name.split('.');
      setFormData({
        ...formData,
        [parent]: {
          ...formData[parent],
          [child]: value
        }
      });
    } else {
      setFormData({
        ...formData,
        [name]: value
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const authToken = await getToken();
      
      await axios.post(`${config.API_URL}/api/institutions`, formData, {
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      });
      
      setShowAddForm(false);
      setSnackbar({ open: true, message: 'Institution created successfully', severity: 'success' });
      
      // Reset form and refresh list
      setFormData({
        name: '',
        code: '',
        email: '',
        address: '',
        phone: '',
        website: '',
        adminDetails: {
          firstName: '',
          lastName: '',
          email: '',
          password: ''
        },
        subscription: {
          plan: 'free'
        },
        branding: {
          logoUrl: '',
          primaryColor: '#3f51b5',
          secondaryColor: '#f50057'
        },
        domain: {
          useCustomDomain: false,
          customDomain: ''
        }
      });
      
      // Refresh the list
      const response = await axios.get(`${config.API_URL}/api/institutions`, {
        headers: {
          Authorization: `Bearer ${authToken}`
        },
        params: {
          page: page + 1,
          pageSize: rowsPerPage,
          search: searchQuery
        }
      });
      
      setInstitutions(response.data.institutions || []);
      setLoading(false);
    } catch (err) {
      console.error('Error creating institution:', err);
      setSnackbar({
        open: true,
        message: err.response?.data?.message || 'Failed to create institution',
        severity: 'error'
      });
      setLoading(false);
    }
  };

  const handleDeleteInstitution = async (institutionId) => {
    try {
      const authToken = await getToken();
      await axios.delete(`${config.API_URL}/api/institutions/${institutionId}`, {
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      });

      setSnackbar({ open: true, message: 'Institution deleted successfully', severity: 'success' });
      setInstitutions(institutions.filter(inst => inst._id !== institutionId));
    } catch (err) {
      console.error('Error deleting institution:', err);
      setSnackbar({
        open: true,
        message: err.response?.data?.message || 'Failed to delete institution',
        severity: 'error'
      });
    }
  };

  const drawer = (
    <Box sx={{ width: 240 }}>
      <Box sx={{ p: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography variant="h6" noWrap>
          Super Admin
        </Typography>
        <IconButton onClick={() => setDrawerOpen(false)}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Divider />
      <List>
        <ListItem button selected>
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItem>
        <ListItem button>
          <ListItemIcon>
            <SchoolIcon />
          </ListItemIcon>
          <ListItemText primary="Institutions" />
        </ListItem>
        <ListItem button>
          <ListItemIcon>
            <SettingsIcon />
          </ListItemIcon>
          <ListItemText primary="Settings" />
        </ListItem>
      </List>
    </Box>
  );

  if (error) {
    return (
      <Box sx={{ p: 3 }}>
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar position="fixed" sx={{ zIndex: theme.zIndex.drawer + 1 }}>
        <Toolbar>
          <IconButton
            color="inherit"
            edge="start"
            onClick={() => setDrawerOpen(true)}
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
            Institution Management
          </Typography>
          <Button color="inherit" startIcon={<AddIcon />} onClick={() => setShowAddForm(true)}>
            Add Institution
          </Button>
        </Toolbar>
      </AppBar>

      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
      >
        {drawer}
      </Drawer>

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${240}px)` },
          mt: 8
        }}
      >
        <Paper sx={{ p: 2, mb: 2 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                variant="outlined"
                placeholder="Search institutions..."
                value={searchQuery}
                onChange={handleSearch}
                InputProps={{
                  startAdornment: <SearchIcon sx={{ mr: 1, color: 'action.active' }} />
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} sx={{ textAlign: 'right' }}>
              <Typography variant="body2" color="textSecondary">
                Total Institutions: {institutions.length}
              </Typography>
            </Grid>
          </Grid>
        </Paper>

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Code</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Subscription</TableCell>
                <TableCell>Status</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              ) : institutions.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    No institutions found
                  </TableCell>
                </TableRow>
              ) : (
                institutions.map((institution) => (
                  <TableRow key={institution._id}>
                    <TableCell>{institution.name}</TableCell>
                    <TableCell>{institution.code}</TableCell>
                    <TableCell>{institution.email}</TableCell>
                    <TableCell>
                      <Chip
                        label={institution.subscription?.plan || 'free'}
                        color={institution.subscription?.plan === 'premium' ? 'primary' : 'default'}
                        size="small"
                      />
                    </TableCell>
                    <TableCell>
                      <Chip
                        label={institution.status || 'active'}
                        color={institution.status === 'active' ? 'success' : 'error'}
                        size="small"
                      />
                    </TableCell>
                    <TableCell align="right">
                      <Tooltip title="Edit">
                        <IconButton
                          size="small"
                          onClick={() => {
                            setSelectedInstitution(institution);
                            setShowEditForm(true);
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Delete">
                        <IconButton
                          size="small"
                          color="error"
                          onClick={() => handleDeleteInstitution(institution._id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={institutions.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>

        <Dialog open={showAddForm} onClose={() => setShowAddForm(false)} maxWidth="md" fullWidth>
          <DialogTitle>Add New Institution</DialogTitle>
          <form onSubmit={handleSubmit}>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Institution Name"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Institution Code"
                    name="code"
                    value={formData.code}
                    onChange={handleInputChange}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Email"
                    name="email"
                    type="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Website"
                    name="website"
                    value={formData.website}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Address"
                    name="address"
                    value={formData.address}
                    onChange={handleInputChange}
                    multiline
                    rows={2}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
                    Admin Details
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="First Name"
                    name="adminDetails.firstName"
                    value={formData.adminDetails.firstName}
                    onChange={handleInputChange}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Last Name"
                    name="adminDetails.lastName"
                    value={formData.adminDetails.lastName}
                    onChange={handleInputChange}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Admin Email"
                    name="adminDetails.email"
                    type="email"
                    value={formData.adminDetails.email}
                    onChange={handleInputChange}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Admin Password"
                    name="adminDetails.password"
                    type="password"
                    value={formData.adminDetails.password}
                    onChange={handleInputChange}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
                    Subscription & Branding
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    select
                    fullWidth
                    label="Subscription Plan"
                    name="subscription.plan"
                    value={formData.subscription.plan}
                    onChange={handleInputChange}
                  >
                    <option value="free">Free</option>
                    <option value="premium">Premium</option>
                    <option value="enterprise">Enterprise</option>
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Logo URL"
                    name="branding.logoUrl"
                    value={formData.branding.logoUrl}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Primary Color"
                    name="branding.primaryColor"
                    type="color"
                    value={formData.branding.primaryColor}
                    onChange={handleInputChange}
                    sx={{ '& input': { p: 1, height: 40 } }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Secondary Color"
                    name="branding.secondaryColor"
                    type="color"
                    value={formData.branding.secondaryColor}
                    onChange={handleInputChange}
                    sx={{ '& input': { p: 1, height: 40 } }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
                    Domain Settings
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={formData.domain.useCustomDomain}
                        onChange={(e) =>
                          handleInputChange({
                            target: {
                              name: 'domain.useCustomDomain',
                              value: e.target.checked
                            }
                          })
                        }
                      />
                    }
                    label="Enable Custom Domain"
                  />
                </Grid>
                {formData.domain.useCustomDomain && (
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Custom Domain"
                      name="domain.customDomain"
                      value={formData.domain.customDomain}
                      onChange={handleInputChange}
                      placeholder="example.com"
                      helperText="Enter domain without http:// or https://"
                    />
                  </Grid>
                )}
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setShowAddForm(false)}>Cancel</Button>
              <Button type="submit" variant="contained" color="primary">
                Create Institution
              </Button>
            </DialogActions>
          </form>
        </Dialog>

        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
        >
          <Alert
            onClose={() => setSnackbar({ ...snackbar, open: false })}
            severity={snackbar.severity}
            sx={{ width: '100%' }}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Box>
    </Box>
  );
};

export default MaterialSuperAdminDashboard;