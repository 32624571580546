import React from "react";
import "./App.css";
import { BrowserRouter as Router, useRoutes, Navigate } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { AuthProvider, useAuth } from "./services/authContext";
import HomePage from "./containers/HomePage";
import CustomSignInPage from "./routes/signInPage/CustomSignInPage.js";
import ChatPage from "./containers/ChatPage";
import CustomSignUpPage from "./routes/signUpPage/CustomSignUpPage.js";
import DashboardPage from "./containers/DashboardPage/index.js";
import Explore from "./containers/Explore/index.js";
import SubjectAnalytics from "./components/subjectAnalytics/index.js";
import SubjectWiseList from "./components/chatWindow/SubjectWiseList.js";
import TestComponent from "./components/chatWindow/TestComponent.js";
import ResultComponent from "./components/chatWindow/ResultComponent.js";
import TestHandler from "./components/chatWindow/TestHandler.js";
import ViewTest from "./components/chatWindow/viewTest.js";
import SuperAdminDashboard from "./containers/SuperAdminDashboard";
import InstitutionAdminDashboard from "./containers/InstitutionAdminDashboard";
import InstitutionUsersManagement from "./containers/InstitutionUsersManagement";
import UIRevampPage from "./pages/UIRevamp/UIRevampPage"; // Import the new UI Revamp page
import CaseStudyPage from "./pages/UIRevamp/CaseStudyPage"; // Import the Case Study page
import AnalyticsPage from "./pages/UIRevamp/AnalyticsPage"; // Import the Analytics page
import PersonalizationPage from "./pages/UIRevamp/PersonalizationPage"; // Import the Personalization page
import MCQBankPage from "./pages/UIRevamp/MCQBankPage"; // Import the MCQ Bank page
import MCQPage from "./pages/UIRevamp/MCQPage"; // Import the MCQ Practice page
import TestsPage from "./pages/UIRevamp/TestsPage"; // Import the Tests page
import TestEnvironmentPage from "./pages/UIRevamp/TestEnvironmentPage"; // Import the Test Environment page
import TestResultsPage from "./components/UIRevamp/tests/TestResultsPage"; // Import the Test Results page
import TeacherDashboard from "./components/TeacherDashboard/Dashboard"; // Import the Teacher Dashboard

const queryClient = new QueryClient();

// Protected route wrapper
const ProtectedRoute = ({ children }) => {
    const { isSignedIn, isLoaded } = useAuth();

    if (!isLoaded) {
        return <div>Loading...</div>;
    }

    if (!isSignedIn) {
        return <Navigate to="/sign-in" replace />;
    }

    return children;
};

// Role-based protected route wrapper
const RoleProtectedRoute = ({ children, allowedRoles }) => {
    const { user, isSignedIn, isLoaded } = useAuth();

    console.log('RoleProtectedRoute - Auth State:', { isLoaded, isSignedIn, user, allowedRoles });

    if (!isLoaded) {
        console.log('RoleProtectedRoute - Still loading auth state');
        return <div>Loading...</div>;
    }

    if (!isSignedIn) {
        console.log('RoleProtectedRoute - User not signed in, redirecting to sign-in');
        return <Navigate to="/sign-in" replace />;
    }

    if (!user || !allowedRoles.includes(user.role)) {
        console.log('RoleProtectedRoute - User role not authorized:', user?.role);
        return <Navigate to="/sign-in" replace />;
    }

    console.log('RoleProtectedRoute - Access granted for role:', user.role);
    return children;
};

function AppRoutes() {
    let routes = useRoutes([
        { path: "/", element: <HomePage /> },
        { path: "/sign-in/*", element: <CustomSignInPage /> },
        { path: "/sign-up/*", element: <CustomSignUpPage /> },
        {
            path: "/dashboard",
            element: (
                <ProtectedRoute>
                    <DashboardPage />
                </ProtectedRoute>
            ),
        },
        {
            path: "/dashboard/chats/:id",
            element: (
                <ProtectedRoute>
                    <ChatPage />
                </ProtectedRoute>
            ),
        },
        {
            path: "/explore",
            element: (
                <ProtectedRoute>
                    <Explore />
                </ProtectedRoute>
            ),
        },
        {
            path: "/explore/:mcqId",
            element: (
                <ProtectedRoute>
                    <Explore />
                </ProtectedRoute>
            ),
        },
        {
            path: "/test/subject",
            element: (
                <ProtectedRoute>
                    <TestHandler />
                </ProtectedRoute>
            ),
        },
        {
            path: "/view-test/:id",
            element: (
                <ProtectedRoute>
                    <ViewTest />
                </ProtectedRoute>
            ),
        },
        {
            path: "/results",
            element: (
                <ProtectedRoute>
                    <ResultComponent />
                </ProtectedRoute>
            ),
        },
        {
            path: "/explore/personlisation",
            element: (
                <ProtectedRoute>
                    <SubjectAnalytics />
                </ProtectedRoute>
            ),
        },
        {
            path: "/subjects/mcqs",
            element: (
                <ProtectedRoute>
                    <SubjectWiseList />
                </ProtectedRoute>
            ),
        },
        {
            path: "/ui-revamp",
            element: (
                <ProtectedRoute>
                    <UIRevampPage />
                </ProtectedRoute>
            ),
        },
        {
            path: "/ui-revamp/mcq",
            element: (
                <ProtectedRoute>
                    <MCQPage />
                </ProtectedRoute>
            ),
        },
        {
            path: "/ui-revamp/case-study",
            element: (
                <ProtectedRoute>
                    <CaseStudyPage />
                </ProtectedRoute>
            ),
        },
        {
            path: "/ui-revamp/analytics",
            element: (
                <ProtectedRoute>
                    <AnalyticsPage />
                </ProtectedRoute>
            ),
        },
        {
            path: "/ui-revamp/analytics/personalization",
            element: (
                <ProtectedRoute>
                    <PersonalizationPage />
                </ProtectedRoute>
            ),
        },
        {
            path: "/ui-revamp/mcq-bank",
            element: (
                <ProtectedRoute>
                    <MCQBankPage />
                </ProtectedRoute>
            ),
        },
        {
            path: "/ui-revamp/tests",
            element: (
                <ProtectedRoute>
                    <TestsPage />
                </ProtectedRoute>
            ),
        },
        {
            path: "/ui-revamp/tests/:testId",
            element: (
                <ProtectedRoute>
                    <TestEnvironmentPage />
                </ProtectedRoute>
            ),
        },
        {
            path: "/ui-revamp/tests/:testId/results",
            element: (
                <ProtectedRoute>
                    <TestResultsPage />
                </ProtectedRoute>
            ),
        },
        {
            path: "/tests/:testId",
            element: <Navigate to={location => `/ui-revamp/tests/${location.pathname.split('/')[2]}`} replace />
        },
        {
            path: "/tests",
            element: <Navigate to="/ui-revamp/tests" replace />
        },
        {
            path: "/admin/super",
            element: (
                <RoleProtectedRoute allowedRoles={['super_admin']}>
                    <SuperAdminDashboard />
                </RoleProtectedRoute>
            ),
        },
        {
            path: "/admin/institution",
            element: (
                <RoleProtectedRoute allowedRoles={['institution_admin', 'super_admin']}>
                    <InstitutionAdminDashboard />
                </RoleProtectedRoute>
            ),
        },
        {
            path: "/admin/institution/:institutionId/users",
            element: (
                <RoleProtectedRoute allowedRoles={['super_admin', 'institution_admin']}>
                    <InstitutionUsersManagement />
                </RoleProtectedRoute>
            ),
        },
        {
            path: "/admin/teacher-dashboard",
            element: (
                <RoleProtectedRoute allowedRoles={['institution_admin', 'super_admin', 'teacher']}>
                    <TeacherDashboard 
                        getToken={() => localStorage.getItem('token')}
                        user={JSON.parse(localStorage.getItem('user') || '{}')}
                        showNotification={(type, message) => console.log(type, message)}
                    />
                </RoleProtectedRoute>
            ),
        },
    ]);
    return routes;
}

function App() {
    return (
        <AuthProvider>
            <QueryClientProvider client={queryClient}>
                <div className="App">
                    <Router>
                        <AppRoutes />
                    </Router>
                </div>
            </QueryClientProvider>
        </AuthProvider>
    );
}

export default App;