import React, { useState, useEffect, useContext } from 'react';
import { Box, Typography, Tabs, Tab, Paper, Button, TextField, Grid, Card, CardContent, CardActions, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Snackbar, Alert, CircularProgress, Chip, Avatar, FormControlLabel, Checkbox, InputAdornment, IconButton, MenuItem, Drawer, List, ListItem, ListItemIcon, ListItemText, Divider, useTheme, useMediaQuery, FormControl, InputLabel, Select } from '@mui/material';
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon, Search as SearchIcon, Menu as MenuIcon, Dashboard as DashboardIcon, School as SchoolIcon, Book as BookIcon, QuestionAnswer as QuestionAnswerIcon, Person as PersonIcon, Close as CloseIcon, Assessment as AssessmentIcon, PlayArrow as PlayArrowIcon, CloudUpload as CloudUploadIcon } from '@mui/icons-material';
import axios from 'axios';
import { useAuth } from '../../services/authContext';
import config from '../../config';
import API from '../../services/api';

// Import our new components
import AnalyticsDashboard from '../../components/InstitutionAdmin/AnalyticsDashboard';
import SubjectManagement from '../../components/InstitutionAdmin/SubjectManagement';
import TopicManagement from '../../components/InstitutionAdmin/TopicManagement';
import MCQManagement from '../../components/InstitutionAdmin/MCQManagement';

// Define custom color palette for blue theme
const blueTheme = {
  primary: {
    main: '#1976d2',
    light: '#42a5f5',
    dark: '#1565c0',
    contrastText: '#fff'
  },
  secondary: {
    main: '#03a9f4',
    light: '#4fc3f7',
    dark: '#0288d1',
    contrastText: '#fff'
  },
  background: {
    default: '#f5f7fa',
    paper: '#ffffff'
  }
};

const InstitutionAdminDashboard = () => {
  const { getToken, user } = useAuth();
  const [activeTab, setActiveTab] = useState(1);
  const [playgroundTab, setPlaygroundTab] = useState(0);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  
  // Students state
  const [students, setStudents] = useState([]);
  const [studentSearchTerm, setStudentSearchTerm] = useState('');
  const [classFilter, setClassFilter] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [filteredStudents, setFilteredStudents] = useState([]);
  const [totalStudents, setTotalStudents] = useState(0);
  const [studentDialog, setStudentDialog] = useState({ open: false, mode: 'add', data: null });
  const [tabValue, setTabValue] = useState(0);
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadResults, setUploadResults] = useState(null);
  
  // Teachers state
  const [teachers, setTeachers] = useState([]);
  const [filteredTeachers, setFilteredTeachers] = useState([]);
  const [totalTeachers, setTotalTeachers] = useState(0);
  const [teacherDialog, setTeacherDialog] = useState({ open: false, mode: 'add', data: null });
  const [teacherSearchTerm, setTeacherSearchTerm] = useState('');
  const [teacherStatusFilter, setTeacherStatusFilter] = useState('');
  
  // Subjects state
  const [subjects, setSubjects] = useState([]);
  const [totalSubjects, setTotalSubjects] = useState(0);
  const [subjectDialog, setSubjectDialog] = useState({ open: false, mode: 'add', data: null });
  const [subjectFilter, setSubjectFilter] = useState('');
  const [subjectSearchTerm, setSubjectSearchTerm] = useState('');
  const [filteredSubjects, setFilteredSubjects] = useState([]);
  
  // Topics state
  const [topics, setTopics] = useState({});
  const [topicDialog, setTopicDialog] = useState({ open: false, mode: 'add', data: null, subjectId: null });
  const [topicFilter, setTopicFilter] = useState('');
  const [topicSearchTerm, setTopicSearchTerm] = useState('');
  const [filteredTopics, setFilteredTopics] = useState({});
  
  // Classes state
  const [classes, setClasses] = useState([]);
  const [filteredClasses, setFilteredClasses] = useState([]);
  
  // MCQs state
  const [mcqs, setMcqs] = useState([]);
  const [totalMcqs, setTotalMcqs] = useState(0);
  const [mcqDialog, setMcqDialog] = useState({ open: false, mode: 'add', data: null, subjectId: null, topicId: null });
  const [mcqSearchTerm, setMcqSearchTerm] = useState('');
  const [mcqSubjectFilter, setMcqSubjectFilter] = useState('');
  const [mcqTopicFilter, setMcqTopicFilter] = useState('');
  const [mcqDifficultyFilter, setMcqDifficultyFilter] = useState('');
  const [filteredMcqs, setFilteredMcqs] = useState([]);
  const [mcqUploadFile, setMcqUploadFile] = useState(null);
  const [showMCQManager, setShowMCQManager] = useState(false);
  const [mcqManagerMode, setMcqManagerMode] = useState('all'); // 'all', 'subject', 'topic'
  const [mcqsBySubjectId, setMcqsBySubjectId] = useState(null);
  const [mcqsByTopicId, setMcqsByTopicId] = useState(null);
  
  // Tests state
  const [tests, setTests] = useState([]);
  const [totalTests, setTotalTests] = useState(0);
  
  // Pagination state
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  
  // Delete dialog state
  const [deleteDialog, setDeleteDialog] = useState({ open: false, type: '', id: null });
  
  // Notification state
  const [notification, setNotification] = useState({
    open: false,
    message: '',
    severity: 'info'
  });
  
  // State for drawer
  const [drawerOpen, setDrawerOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [selectedSubject, setSelectedSubject] = useState('');

  useEffect(() => {
    if (user && user.token) {
      fetchStudents();
      fetchTeachers();
      fetchSubjects();
      fetchClasses();
    }
  }, [page, rowsPerPage, user]);

  useEffect(() => {
    if (students.length > 0) {
      filterStudents();
    }
  }, [studentSearchTerm, classFilter, statusFilter, students]);

  useEffect(() => {
    if (activeTab === 1) {
      fetchStudents();
    } else if (activeTab === 2) {
      fetchTeachers();
    } else if (activeTab === 3) {
      fetchSubjects();
    } else if (activeTab === 4) {
      if (playgroundTab === 0) {
        fetchTests();
      } else if (playgroundTab === 2) {
        fetchMCQs();
      }
    }
  }, [activeTab, playgroundTab, page, rowsPerPage]);
  
  useEffect(() => {
    if (activeTab === 1) {
      filterStudents();
    } else if (activeTab === 2) {
      filterTeachers();
    } else if (activeTab === 3) {
      filterSubjects();
    } else if (activeTab === 4 && playgroundTab === 2) {
      filterMcqs();
    }
  }, [searchTerm, students, teacherSearchTerm, teachers, subjectSearchTerm, subjects, mcqSearchTerm, mcqs, mcqSubjectFilter, mcqDifficultyFilter]);
  
  const fetchData = async () => {
    try {
      setLoading(true);
      await Promise.all([
        fetchStudents(),
        fetchTeachers(),
        fetchSubjects(),
        fetchClasses()
      ]);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };
  
  // Function to fetch students
  const fetchStudents = async () => {
    try {
      setLoading(true);
      const authToken = await getToken();
      
      if (!authToken) {
        showNotification('Authentication error. Please sign in again.', 'error');
        return;
      }
      
      const response = await axios.get(
        `${config.API_URL}/api/institutions/students`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`
          },
          params: {
            institutionId: user.institution,
            page: page + 1,
            limit: rowsPerPage
          }
        }
      );
      
      const studentsData = response.data.students || [];
      setStudents(studentsData);
      setFilteredStudents(studentsData);
      setTotalStudents(response.data.totalCount || studentsData.length);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching students:', error);
      showNotification('Failed to fetch students. Please try again.', 'error');
      setLoading(false);
    }
  };

  // Function to fetch classes
  const fetchClasses = async () => {
    try {
      const authToken = await getToken();
      
      if (!authToken) {
        showNotification('Authentication error. Please sign in again.', 'error');
        return;
      }
      
      // First try to get classes from the institution config endpoint
      try {
        const response = await API.institution.config.getClasses(authToken);
        if (response.data.classes && response.data.classes.length > 0) {
          setClasses(response.data.classes);
          return;
        }
      } catch (configError) {
        console.error('Error fetching classes from institution config:', configError);
      }
      
      // Fallback to the global config endpoint
      try {
        const response = await API.config.getClasses(authToken);
        setClasses(response.data.classes || []);
      } catch (globalError) {
        console.error('Error fetching classes from global config:', globalError);
        
        // Fallback default classes if both API calls fail
        setClasses([
          { _id: 'class-8', name: 'Class 8' },
          { _id: 'class-9', name: 'Class 9' },
          { _id: 'class-10', name: 'Class 10' },
          { _id: 'class-11', name: 'Class 11' },
          { _id: 'class-12', name: 'Class 12' }
        ]);
      }
    } catch (error) {
      console.error('Error in fetchClasses:', error);
      
      // Final fallback
      setClasses([
        { _id: 'class-8', name: 'Class 8' },
        { _id: 'class-9', name: 'Class 9' },
        { _id: 'class-10', name: 'Class 10' },
        { _id: 'class-11', name: 'Class 11' },
        { _id: 'class-12', name: 'Class 12' }
      ]);
    }
  };
  
  const fetchTeachers = async () => {
    try {
      setLoading(true);
      const authToken = await getToken();
      
      if (!authToken) {
        showNotification('Authentication error. Please sign in again.', 'error');
        return;
      }
      
      const response = await axios.get(
        `${config.API_URL}/api/institutions/teachers`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`
          },
          params: {
            institutionId: user.institution,
            page: page + 1,
            limit: rowsPerPage
          }
        }
      );
      
      setTeachers(response.data.teachers || []);
      setTotalTeachers(response.data.totalCount || 0);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching teachers:', error);
      showNotification('Failed to fetch teachers. Please try again.', 'error');
      setLoading(false);
    }
  };
  
  const fetchSubjects = async () => {
    setLoading(true);
    
    try {
      const authToken = await getToken();
      
      if (!authToken) {
        showNotification('Authentication error. Please sign in again.', 'error');
        setLoading(false);
        return;
      }
      
      const response = await API.institution.subjects.getAll(
        authToken, 
        {
          institutionId: user.institution,
          page: page + 1,
          limit: rowsPerPage
        }
      );
      
      setSubjects(response.data.subjects || []);
      setTotalSubjects(response.data.totalCount || 0);
      
      // Fetch topics for each subject
      const topicsData = {};
      for (const subject of response.data.subjects || []) {
        try {
          const topicsResponse = await API.institution.subjects.topics.getAll(
            authToken, 
            subject._id
          );
          
          topicsData[subject._id] = topicsResponse.data.topics || [];
        } catch (error) {
          console.error(`Error fetching topics for subject ${subject._id}:`, error);
        }
      }
      
      setTopics(topicsData);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching subjects:', error);
      showNotification('Failed to fetch subjects. Please try again.', 'error');
      setLoading(false);
    }
  };
  
  const fetchMCQs = async (subjectId = null, topicId = null) => {
    setLoading(true);
    
    try {
      const authToken = await getToken();
      
      if (!authToken) {
        showNotification('Authentication error. Please sign in again.', 'error');
        setLoading(false);
        return;
      }
      
      const params = {
        institutionId: user.institution,
        page: page + 1,
        limit: rowsPerPage,
        search: mcqSearchTerm || ''
      };
      
      if (subjectId) {
        params.subjectId = subjectId;
      }
      
      if (topicId) {
        params.topicId = topicId;
      }
      
      if (mcqDifficultyFilter) {
        params.difficulty = mcqDifficultyFilter;
      }
      
      const response = await API.institution.mcqs.getAll(authToken, params);
      
      setMcqs(response.data.mcqs || []);
      setFilteredMcqs(response.data.mcqs || []);
      setTotalMcqs(response.data.totalCount || 0);
    } catch (error) {
      console.error('Error fetching MCQs:', error);
      showNotification('Failed to load MCQs. Please try again later.', 'error');
    } finally {
      setLoading(false);
    }
  };
  
  const fetchTests = async () => {
    try {
      setLoading(true);
      const authToken = await getToken();
      
      if (!authToken) {
        showNotification('Authentication error. Please sign in again.', 'error');
        return;
      }
      
      const response = await axios.get(
        `${config.API_URL}/api/institutions/tests`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`
          },
          params: {
            institutionId: user.institution,
            page: page + 1,
            limit: rowsPerPage
          }
        }
      );
      
      setTests(response.data.tests || []);
      setTotalTests(response.data.totalCount || 0);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching tests:', error);
      showNotification('Failed to fetch tests. Please try again.', 'error');
      setLoading(false);
    }
  };
  
  const filterStudents = () => {
    if (!studentSearchTerm.trim() && !classFilter && !statusFilter) {
      setFilteredStudents(students);
      return;
    }
    
    const filtered = students.filter(student => {
      const fullName = `${student.firstName} ${student.lastName}`.toLowerCase();
      const searchTermMatch = studentSearchTerm ? 
        fullName.includes(studentSearchTerm.toLowerCase()) ||
        student.email.toLowerCase().includes(studentSearchTerm.toLowerCase()) : true;
      
      const classMatch = classFilter ? student.classId === classFilter : true;
      
      const statusMatch = statusFilter ? student.status === statusFilter : true;
      
      return searchTermMatch && classMatch && statusMatch;
    });
    
    setFilteredStudents(filtered);
  };
  
  const filterTeachers = () => {
    if (!teacherSearchTerm.trim() && !teacherStatusFilter) {
      setFilteredTeachers(teachers);
      return;
    }
    
    const filtered = teachers.filter(teacher => {
      const fullName = `${teacher.firstName} ${teacher.lastName}`.toLowerCase();
      return (
        fullName.includes(teacherSearchTerm.toLowerCase()) ||
        teacher.email.toLowerCase().includes(teacherSearchTerm.toLowerCase()) ||
        (teacherStatusFilter === 'active' && teacher.isActive) ||
        (teacherStatusFilter === 'inactive' && !teacher.isActive)
      );
    });
    
    setFilteredTeachers(filtered);
  };
  
  const filterSubjects = () => {
    if (!subjectSearchTerm.trim()) {
      setFilteredSubjects(subjects);
      return;
    }
    
    const filtered = subjects.filter(subject => {
      return (
        subject.name.toLowerCase().includes(subjectSearchTerm.toLowerCase()) ||
        subject.description.toLowerCase().includes(subjectSearchTerm.toLowerCase())
      );
    });
    
    setFilteredSubjects(filtered);
  };
  
  const filterMcqs = () => {
    if (!mcqs.length) {
      setFilteredMcqs([]);
      return;
    }
    
    let filtered = [...mcqs];
    
    // Apply subject filter
    if (mcqSubjectFilter) {
      filtered = filtered.filter(mcq => mcq.subject?._id === mcqSubjectFilter);
    }
    
    // Apply topic filter
    if (mcqTopicFilter) {
      filtered = filtered.filter(mcq => mcq.topic?._id === mcqTopicFilter);
    }
    
    // Apply difficulty filter
    if (mcqDifficultyFilter) {
      filtered = filtered.filter(mcq => mcq.difficulty === mcqDifficultyFilter);
    }
    
    // Apply search term
    if (mcqSearchTerm.trim()) {
      const searchLower = mcqSearchTerm.toLowerCase();
      filtered = filtered.filter(
        mcq => 
          mcq.question.toLowerCase().includes(searchLower) || 
          mcq.options.some(opt => opt.text.toLowerCase().includes(searchLower)) ||
          (mcq.explanation && mcq.explanation.toLowerCase().includes(searchLower))
      );
    }
    
    setFilteredMcqs(filtered);
  };
  
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    setPage(0);
    setSearchTerm('');
    setSubjectSearchTerm('');
  };
  
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };
  
  const handleTeacherSearchChange = (event) => {
    setTeacherSearchTerm(event.target.value);
  };
  
  const handleSubjectSearchChange = (event) => {
    setSubjectSearchTerm(event.target.value);
  };
  
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  
  const handleAddStudent = () => {
    setStudentDialog({ open: true, mode: 'add', data: null });
    setTabValue(0); // Reset to individual student tab
    setSelectedFile(null); // Clear any uploaded file
    setUploadResults(null); // Clear any upload results
  };

  const handleEditStudent = (student) => {
    setStudentDialog({ open: true, mode: 'edit', data: student });
    setTabValue(0); // Force to individual student tab for editing
    setSelectedFile(null); // Clear any uploaded file
    setUploadResults(null); // Clear any upload results
  };

  const handleStudentDialogClose = () => {
    setStudentDialog({
      ...studentDialog,
      open: false
    });
  };

  const handleStudentTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleFileUpload = (event) => {
    setSelectedFile(event.target.files[0]);
    setUploadResults(null); // Clear previous results
  };

  const handleFileClear = () => {
    setSelectedFile(null);
    setUploadResults(null);
  };

  const handleBulkUpload = async () => {
    if (!selectedFile) {
      showNotification('Please select a file first.', 'error');
      return;
    }
    
    setLoading(true);
    
    const formData = new FormData();
    formData.append('file', selectedFile);
    formData.append('institutionId', user.institution);
    
    try {
      const authToken = await getToken();
      
      if (!authToken) {
        showNotification('Authentication error. Please sign in again.', 'error');
        setLoading(false);
        return;
      }
      
      const response = await API.institution.students.bulkUpload(authToken, formData);
      
      showNotification(`Successfully uploaded ${response.data.successful} students.`, 'success');
      setSelectedFile(null);
      setStudentDialog({ open: false, mode: 'add', data: null });
      
      // Refresh the students list
      fetchStudents();
    } catch (error) {
      console.error('Error uploading students:', error);
      showNotification('Failed to upload students. Please check your file format and try again.', 'error');
    }
    
    setLoading(false);
  };

  const handleStudentFormSubmit = async () => {
    try {
      const form = document.getElementById('studentForm');
      const formData = new FormData(form);
      
      const data = {
        firstName: formData.get('firstName'),
        lastName: formData.get('lastName'),
        email: formData.get('email'),
        class: formData.get('classId'), // Change classId to class to match backend expectation
        phone: formData.get('phone'),
        status: formData.get('status'),
        address: formData.get('address'),
        parentName: formData.get('parentName'),
        parentPhone: formData.get('parentPhone'),
        institutionId: user.institution
      };
      
      // Validate required fields
      if (!data.firstName || !data.lastName || !data.email || !data.class) {
        showNotification('Please fill all required fields.', 'error');
        return;
      }
      
      const authToken = await getToken();
      
      if (!authToken) {
        showNotification('Authentication error. Please sign in again.', 'error');
        return;
      }
      
      if (studentDialog.mode === 'add') {
        // Add new student
        await axios.post(
          `${config.API_URL}/api/institutions/students`,
          data,
          {
            headers: {
              Authorization: `Bearer ${authToken}`
            }
          }
        );
        
        showNotification('Student added successfully! A welcome email has been sent with login details.', 'success');
      } else {
        // Update existing student
        await axios.put(
          `${config.API_URL}/api/institutions/students/${studentDialog.data._id}`,
          data,
          {
            headers: {
              Authorization: `Bearer ${authToken}`
            }
          }
        );
        
        showNotification('Student updated successfully!', 'success');
      }
      
      // Close dialog and refresh data
      handleStudentDialogClose();
      fetchStudents();
    } catch (error) {
      console.error('Error saving student:', error);
      showNotification(error.response?.data?.message || 'Failed to save student. Please try again.', 'error');
    }
  };

  const handleDeleteStudent = (id) => {
    setDeleteDialog({ open: true, type: 'student', id });
  };
  
  const handleDeleteDialogClose = () => {
    setDeleteDialog({ ...deleteDialog, open: false });
  };

  const handleConfirmDelete = async () => {
    try {
      const authToken = await getToken();
      
      if (!authToken) {
        showNotification('Authentication error. Please sign in again.', 'error');
        return;
      }
      
      if (deleteDialog.type === 'student') {
        await API.institution.students.delete(authToken, deleteDialog.id);
        showNotification('Student deleted successfully!', 'success');
        fetchStudents();
      } else if (deleteDialog.type === 'teacher') {
        await API.institution.teachers.delete(authToken, deleteDialog.id);
        showNotification('Teacher deleted successfully!', 'success');
        fetchTeachers();
      } else if (deleteDialog.type === 'subject') {
        await API.institution.subjects.delete(authToken, deleteDialog.id);
        showNotification('Subject deleted successfully!', 'success');
        fetchSubjects();
      } else if (deleteDialog.type === 'topic') {
        // We need to know which subject this topic belongs to
        let subjectId = '';
        // Loop through all subjects and find which one contains this topic
        for (const [subjId, topicsList] of Object.entries(topics)) {
          const foundTopic = topicsList.find(t => t._id === deleteDialog.id);
          if (foundTopic) {
            subjectId = subjId;
            break;
          }
        }
        
        if (!subjectId) {
          showNotification('Could not determine which subject this topic belongs to', 'error');
          return;
        }
        
        await API.institution.subjects.topics.delete(authToken, subjectId, deleteDialog.id);
        showNotification('Topic deleted successfully!', 'success');
        fetchSubjects();
      } else if (deleteDialog.type === 'mcq') {
        await API.institution.mcqs.delete(authToken, deleteDialog.id);
        showNotification('MCQ deleted successfully!', 'success');
        fetchMCQs(mcqsBySubjectId, mcqsByTopicId);
      }
      
      // Close the delete dialog
      setDeleteDialog({ open: false, type: '', id: null });
    } catch (error) {
      console.error(`Error deleting ${deleteDialog.type}:`, error);
      showNotification(error.response?.data?.message || `Failed to delete ${deleteDialog.type}. Please try again.`, 'error');
    }
  };
  
  const showNotification = (message, severity = 'info') => {
    setNotification({
      open: true,
      message,
      severity
    });
  };
  
  const handleCloseNotification = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    
    setNotification({ ...notification, open: false });
  };
  
  const handlePlaygroundTabChange = (event, newValue) => {
    setPlaygroundTab(newValue);
    setPage(0);
    
    if (activeTab === 3) {
      if (newValue === 0) {
        // Fetch subjects if switching to Subjects tab
        fetchSubjects();
      } else if (newValue === 1) {
        // Handle Topic tab switch if needed
      }
    } else if (activeTab === 4) {
      if (newValue === 2) {
        // Fetch MCQs if switching to MCQs tab
        fetchMCQs();
      }
    }
  };
  
  // Handle drawer toggle
  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  // Function to handle MCQ manager
  const handleMCQManager = (subjectId = null, topicId = null) => {
    if (subjectId && topicId) {
      setMcqManagerMode('topic');
      setMcqSubjectFilter(subjectId);
      setMcqTopicFilter(topicId);
      setMcqsBySubjectId(subjectId);
      setMcqsByTopicId(topicId);
    } else if (subjectId) {
      setMcqManagerMode('subject');
      setMcqSubjectFilter(subjectId);
      setMcqsBySubjectId(subjectId);
      setMcqsByTopicId(null);
    } else {
      setMcqManagerMode('all');
      setMcqsBySubjectId(null);
      setMcqsByTopicId(null);
    }
    
    setShowMCQManager(true);
    
    // Load MCQs for the selected context
    fetchMCQs(subjectId, topicId);
  };
  
  // Function to add a new MCQ
  const handleAddMCQ = () => {
    setMcqDialog({
      open: true,
      mode: 'add',
      data: null,
      subjectId: mcqsBySubjectId,
      topicId: mcqsByTopicId
    });
  };
  
  // Function to edit an MCQ
  const handleEditMCQ = (mcq) => {
    setMcqDialog({
      open: true,
      mode: 'edit',
      data: mcq,
      subjectId: mcq.subjectId,
      topicId: mcq.topicId
    });
  };
  
  // Function to delete an MCQ
  const handleDeleteMCQ = (mcqId) => {
    setDeleteDialog({
      open: true,
      type: 'mcq',
      id: mcqId
    });
  };
  
  // Function to handle MCQ form submission
  const handleMCQSubmit = async (event) => {
    event.preventDefault();
    
    const formData = new FormData(event.target);
    const mcqData = {
      question: formData.get('question'),
      options: [
        formData.get('option1'),
        formData.get('option2'),
        formData.get('option3'),
        formData.get('option4')
      ],
      correctAnswer: formData.get('correctAnswer'),
      subjectId: formData.get('subjectId'),
      topicId: formData.get('topicId') || undefined,
      difficulty: formData.get('difficulty'),
      institutionId: user.institution
    };
    
    try {
      setLoading(true);
      const authToken = await getToken();
      
      if (!authToken) {
        showNotification('Authentication error. Please sign in again.', 'error');
        setLoading(false);
        return;
      }
      
      // Create or update the MCQ
      if (mcqDialog.mode === 'add') {
        const response = await API.institution.mcqs.create(authToken, mcqData);
        showNotification('MCQ created successfully', 'success');
      } else {
        const response = await API.institution.mcqs.update(authToken, mcqDialog.data._id, mcqData);
        showNotification('MCQ updated successfully', 'success');
      }
      
      // Refresh the MCQs list
      fetchMCQs(mcqsBySubjectId, mcqsByTopicId);
      
      // Close the dialog
      setMcqDialog({ open: false, mode: 'add', data: null, subjectId: null, topicId: null });
      setLoading(false);
    } catch (error) {
      console.error('Error saving MCQ:', error);
      showNotification(`Failed to ${mcqDialog.mode === 'add' ? 'create' : 'update'} MCQ. Please try again.`, 'error');
      setLoading(false);
    }
  };

  const handleMCQBulkUpload = async (formData) => {
    try {
      setLoading(true);
      const authToken = await getToken();
      
      if (!authToken) {
        showNotification('Authentication error. Please sign in again.', 'error');
        setLoading(false);
        return;
      }
      
      // Add institution ID if not already in formData
      if (!formData.has('institutionId')) {
        formData.append('institutionId', user.institution);
      }
      
      // Add subject and topic IDs if we're in a filtered context
      if (mcqsBySubjectId && !formData.has('subjectId')) {
        formData.append('subjectId', mcqsBySubjectId);
      }
      
      if (mcqsByTopicId && !formData.has('topicId')) {
        formData.append('topicId', mcqsByTopicId);
      }
      
      // Upload the file
      const response = await API.institution.mcqs.bulkUpload(authToken, formData);
      
      // Refresh the MCQs list
      fetchMCQs(mcqsBySubjectId, mcqsByTopicId);
      
      setLoading(false);
      showNotification(`Successfully uploaded ${response.data.successful} MCQs`, 'success');
      
      return response.data;
    } catch (error) {
      console.error('Error uploading MCQs:', error);
      showNotification('Failed to upload MCQs. Please try again.', 'error');
      setLoading(false);
      throw error;
    }
  };

  const handleSubjectFormSubmit = async () => {
    try {
      setLoading(true);
      
      const form = document.getElementById('subjectForm');
      const formData = new FormData(form);
      
      const data = {
        name: formData.get('name'),
        description: formData.get('description'),
        classId: formData.get('classId'),
        institutionId: user.institution
      };
      
      // Validate required fields
      if (!data.name || !data.classId) {
        showNotification('Please fill all required fields', 'error');
        setLoading(false);
        return;
      }
      
      const authToken = await getToken();
      
      if (!authToken) {
        showNotification('Authentication error. Please sign in again.', 'error');
        setLoading(false);
        return;
      }
      
      if (subjectDialog.mode === 'add') {
        // Create a new subject
        const response = await API.institution.subjects.create(authToken, data);
        showNotification('Subject created successfully!', 'success');
      } else {
        // Update existing subject
        const response = await API.institution.subjects.update(
          authToken, 
          subjectDialog.data._id, 
          data
        );
        showNotification('Subject updated successfully!', 'success');
      }
      
      // Close dialog and refresh subjects
      setSubjectDialog({ open: false, mode: 'add', data: null });
      fetchSubjects();
    } catch (error) {
      console.error('Error saving subject:', error);
      showNotification('Failed to save subject. Please try again.', 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleTopicFormSubmit = async () => {
    try {
      setLoading(true);
      
      const form = document.getElementById('topicForm');
      const formData = new FormData(form);
      
      // Get subjectId either from the form or from the dialog state
      let subjectId = formData.get('subjectId');
      if (!subjectId && topicDialog.subjectId) {
        subjectId = topicDialog.subjectId;
      }
      
      const data = {
        name: formData.get('name'),
        description: formData.get('description'),
        institutionId: user.institution
      };
      
      // Validate required fields
      if (!data.name || !subjectId) {
        showNotification('Please fill all required fields', 'error');
        setLoading(false);
        return;
      }
      
      const authToken = await getToken();
      
      if (!authToken) {
        showNotification('Authentication error. Please sign in again.', 'error');
        setLoading(false);
        return;
      }
      
      if (topicDialog.mode === 'add') {
        // Create a new topic
        const response = await API.institution.subjects.topics.create(
          authToken, 
          subjectId, 
          data
        );
        showNotification('Topic created successfully!', 'success');
      } else {
        // Update existing topic
        const response = await API.institution.subjects.topics.update(
          authToken, 
          subjectId,
          topicDialog.data._id, 
          data
        );
        showNotification('Topic updated successfully!', 'success');
      }
      
      // Close dialog and refresh subjects (to get updated topics)
      setTopicDialog({ open: false, mode: 'add', data: null, subjectId: null });
      fetchSubjects();
    } catch (error) {
      console.error('Error saving topic:', error);
      showNotification('Failed to save topic. Please try again.', 'error');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ display: 'flex', bgcolor: blueTheme.background.default, minHeight: '100vh' }}>
      {/* App Bar for mobile */}
      {isMobile && (
        <Box sx={{ position: 'fixed', top: 0, left: 0, right: 0, zIndex: 1100, bgcolor: blueTheme.primary.main, color: 'white', p: 1, display: 'flex', alignItems: 'center' }}>
          <IconButton color="inherit" edge="start" onClick={toggleDrawer}>
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" sx={{ ml: 2, flexGrow: 1 }}>
            Institution Dashboard
          </Typography>
        </Box>
      )}
      
      {/* Sidebar */}
      <Drawer
        variant={isMobile ? "temporary" : "permanent"}
        open={isMobile ? drawerOpen : true}
        onClose={toggleDrawer}
        sx={{
          width: 240,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: 240,
            boxSizing: 'border-box',
            bgcolor: blueTheme.primary.main,
            color: 'white'
          },
        }}
      >
        <Box sx={{ 
          display: 'flex', 
          flexDirection: 'column', 
          height: '100%' 
        }}>
          <Box sx={{ 
            p: 2, 
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: isMobile ? 'space-between' : 'center' 
          }}>
            <Typography variant="h6" component="div" sx={{ fontWeight: 'bold' }}>
              NeetlyAI
            </Typography>
            {isMobile && (
              <IconButton onClick={toggleDrawer} sx={{ color: 'white' }}>
                <CloseIcon />
              </IconButton>
            )}
          </Box>
          <Divider sx={{ bgcolor: 'rgba(255,255,255,0.1)' }} />
          
          <List sx={{ mt: 2, flexGrow: 1 }}>
            <ListItem 
              button 
              selected={activeTab === 1}
              onClick={() => handleTabChange(null, 1)}
              sx={{ 
                mb: 1, 
                borderRadius: 1, 
                mx: 1,
                '&.Mui-selected': { 
                  bgcolor: 'rgba(255,255,255,0.2)',
                  '&:hover': { bgcolor: 'rgba(255,255,255,0.3)' }
                },
                '&:hover': { bgcolor: 'rgba(255,255,255,0.1)' }
              }}
            >
              <ListItemIcon sx={{ color: 'white', minWidth: 40 }}>
                <SchoolIcon />
              </ListItemIcon>
              <ListItemText primary="Students" />
            </ListItem>
            
            <ListItem 
              button 
              selected={activeTab === 2}
              onClick={() => handleTabChange(null, 2)}
              sx={{ 
                mb: 1, 
                borderRadius: 1, 
                mx: 1,
                '&.Mui-selected': { 
                  bgcolor: 'rgba(255,255,255,0.2)',
                  '&:hover': { bgcolor: 'rgba(255,255,255,0.3)' }
                },
                '&:hover': { bgcolor: 'rgba(255,255,255,0.1)' }
              }}
            >
              <ListItemIcon sx={{ color: 'white', minWidth: 40 }}>
                <PersonIcon />
              </ListItemIcon>
              <ListItemText primary="Teachers" />
            </ListItem>
            
            <ListItem 
              button 
              selected={activeTab === 3}
              onClick={() => handleTabChange(null, 3)}
              sx={{ 
                mb: 1, 
                borderRadius: 1, 
                mx: 1,
                '&.Mui-selected': { 
                  bgcolor: 'rgba(255,255,255,0.2)',
                  '&:hover': { bgcolor: 'rgba(255,255,255,0.3)' }
                },
                '&:hover': { bgcolor: 'rgba(255,255,255,0.1)' }
              }}
            >
              <ListItemIcon sx={{ color: 'white', minWidth: 40 }}>
                <BookIcon />
              </ListItemIcon>
              <ListItemText primary="Subjects & Topics" />
            </ListItem>
            
            <ListItem 
              button 
              selected={activeTab === 4}
              onClick={() => handleTabChange(null, 4)}
              sx={{ 
                mb: 1, 
                borderRadius: 1, 
                mx: 1,
                '&.Mui-selected': { 
                  bgcolor: 'rgba(255,255,255,0.2)',
                  '&:hover': { bgcolor: 'rgba(255,255,255,0.3)' }
                },
                '&:hover': { bgcolor: 'rgba(255,255,255,0.1)' }
              }}
            >
              <ListItemIcon sx={{ color: 'white', minWidth: 40 }}>
                <PlayArrowIcon />
              </ListItemIcon>
              <ListItemText primary="Playground" />
            </ListItem>
            
            <ListItem 
              button 
              selected={activeTab === 5}
              onClick={() => handleTabChange(null, 5)}
              sx={{ 
                mb: 1, 
                borderRadius: 1, 
                mx: 1,
                '&.Mui-selected': { 
                  bgcolor: 'rgba(255,255,255,0.2)',
                  '&:hover': { bgcolor: 'rgba(255,255,255,0.3)' }
                },
                '&:hover': { bgcolor: 'rgba(255,255,255,0.1)' }
              }}
            >
              <ListItemIcon sx={{ color: 'white', minWidth: 40 }}>
                <AssessmentIcon />
              </ListItemIcon>
              <ListItemText primary="Analytics" />
            </ListItem>
          </List>
          
          <Box sx={{ p: 2 }}>
            <Box sx={{ 
              p: 1.5, 
              bgcolor: 'rgba(255,255,255,0.1)', 
              borderRadius: 1,
              display: 'flex',
              alignItems: 'center'
            }}>
              <Avatar 
                sx={{ 
                  width: 40, 
                  height: 40, 
                  bgcolor: blueTheme.secondary.main,
                  color: 'white'
                }}
              >
                {user?.firstName?.charAt(0) || 'U'}
              </Avatar>
              <Box sx={{ ml: 1 }}>
                <Typography variant="body2" sx={{ fontWeight: 'medium' }}>
                  {user?.firstName} {user?.lastName}
                </Typography>
                <Typography variant="caption" sx={{ opacity: 0.8 }}>
                  Institution Admin
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Drawer>
      
      {/* Main content */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          mt: isMobile ? 7 : 0,
          ml: isMobile ? 0 : '240px',
          transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
        }}
      >
        {/* Dashboard Tab */}
        {activeTab === 0 && (
          <Box>
            <Typography variant="h4" gutterBottom sx={{ fontWeight: 'medium', color: blueTheme.primary.dark }}>
              Dashboard Overview
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={3}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 140,
                    bgcolor: blueTheme.primary.light,
                    color: 'white'
                  }}
                >
                  <Typography variant="h6" gutterBottom>
                    Students
                  </Typography>
                  <Typography variant="h3" component="div">
                    {totalStudents}
                  </Typography>
                  <Typography variant="body2">
                    Total enrolled students
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 140,
                    bgcolor: blueTheme.secondary.light,
                    color: 'white'
                  }}
                >
                  <Typography variant="h6" gutterBottom>
                    Teachers
                  </Typography>
                  <Typography variant="h3" component="div">
                    {totalTeachers}
                  </Typography>
                  <Typography variant="body2">
                    Total active teachers
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 140,
                    bgcolor: blueTheme.primary.main,
                    color: 'white'
                  }}
                >
                  <Typography variant="h6" gutterBottom>
                    Subjects
                  </Typography>
                  <Typography variant="h3" component="div">
                    {totalSubjects}
                  </Typography>
                  <Typography variant="body2">
                    Total active subjects
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 140,
                    bgcolor: blueTheme.secondary.main,
                    color: 'white'
                  }}
                >
                  <Typography variant="h6" gutterBottom>
                    Tests
                  </Typography>
                  <Typography variant="h3" component="div">
                    {totalTests}
                  </Typography>
                  <Typography variant="body2">
                    Total tests created
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
            
            <Box sx={{ mt: 4 }}>
              <Typography variant="h5" gutterBottom sx={{ fontWeight: 'medium', color: blueTheme.primary.dark }}>
                Recent Activity
              </Typography>
              <Paper sx={{ p: 2 }}>
                <Typography variant="body1">
                  Recent activity will be displayed here.
                </Typography>
              </Paper>
            </Box>
          </Box>
        )}
        
        {/* Students Tab */}
        {activeTab === 1 && (
          <Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
              <Typography variant="h6" component="h2">
                Students
              </Typography>
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                onClick={handleAddStudent}
                sx={{ 
                  borderRadius: 2,
                  bgcolor: blueTheme.primary.main,
                  '&:hover': {
                    bgcolor: blueTheme.primary.dark,
                  }
                }}
              >
                Add Student
              </Button>
            </Box>
            
            {/* Search and Filter Bar */}
            <Paper
              elevation={0}
              sx={{
                p: 2,
                mb: 3,
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                alignItems: { xs: 'stretch', md: 'center' },
                gap: 2,
                bgcolor: '#f9fafc',
                borderRadius: 2,
                border: '1px solid #eaecef'
              }}
            >
              <TextField
                placeholder="Search by name or email..."
                variant="outlined"
                fullWidth
                size="small"
                value={studentSearchTerm}
                onChange={(e) => setStudentSearchTerm(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon sx={{ color: blueTheme.primary.main }} />
                    </InputAdornment>
                  ),
                  sx: {
                    borderRadius: 2,
                    bgcolor: 'white',
                    '&:hover': {
                      bgcolor: 'white',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#eaecef',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: blueTheme.primary.light,
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: blueTheme.primary.main,
                    }
                  }
                }}
              />
              
              <FormControl
                variant="outlined"
                size="small"
                sx={{ minWidth: { xs: '100%', md: 200 } }}
              >
                <InputLabel id="student-class-filter-label">Class</InputLabel>
                <Select
                  labelId="student-class-filter-label"
                  id="student-class-filter"
                  value={classFilter}
                  onChange={(e) => setClassFilter(e.target.value)}
                  label="Class"
                  sx={{
                    borderRadius: 2,
                    bgcolor: 'white',
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#eaecef',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: blueTheme.primary.light,
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: blueTheme.primary.main,
                    }
                  }}
                >
                  <MenuItem value="">All Classes</MenuItem>
                  <MenuItem value="class-8">Class 8</MenuItem>
                  <MenuItem value="class-9">Class 9</MenuItem>
                  <MenuItem value="class-10">Class 10</MenuItem>
                  <MenuItem value="class-11">Class 11</MenuItem>
                  <MenuItem value="class-12">Class 12</MenuItem>
                </Select>
              </FormControl>
              
              <FormControl
                variant="outlined"
                size="small"
                sx={{ minWidth: { xs: '100%', md: 150 } }}
              >
                <InputLabel id="student-status-filter-label">Status</InputLabel>
                <Select
                  labelId="student-status-filter-label"
                  id="student-status-filter"
                  value={statusFilter}
                  onChange={(e) => setStatusFilter(e.target.value)}
                  label="Status"
                  sx={{
                    borderRadius: 2,
                    bgcolor: 'white',
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#eaecef',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: blueTheme.primary.light,
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: blueTheme.primary.main,
                    }
                  }}
                >
                  <MenuItem value="">All Statuses</MenuItem>
                  <MenuItem value="active">Active</MenuItem>
                  <MenuItem value="inactive">Inactive</MenuItem>
                  <MenuItem value="pending">Pending</MenuItem>
                </Select>
              </FormControl>
            </Paper>
            
            {/* Students Grid */}
            {loading ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
                <CircularProgress sx={{ color: blueTheme.primary.main }} />
              </Box>
            ) : filteredStudents.length === 0 ? (
              <Paper 
                elevation={0}
                sx={{ 
                  p: 4, 
                  textAlign: 'center',
                  borderRadius: 2,
                  bgcolor: '#f9fafc',
                  border: '1px solid #eaecef'
                }}
              >
                <Typography variant="h6" color="text.secondary" gutterBottom>
                  No students found
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {studentSearchTerm || classFilter || statusFilter ? 
                    'Try adjusting your search or filters' : 
                    'Add your first student to get started'}
                </Typography>
                <Button
                  variant="contained"
                  startIcon={<AddIcon />}
                  onClick={handleAddStudent}
                  sx={{ 
                    mt: 2,
                    borderRadius: 2,
                    bgcolor: blueTheme.primary.main,
                    '&:hover': {
                      bgcolor: blueTheme.primary.dark,
                    }
                  }}
                >
                  Add Student
                </Button>
              </Paper>
            ) : (
              <>
                <Grid container spacing={2}>
                  {filteredStudents.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((student) => (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={student._id}>
                      <Card 
                        sx={{ 
                          height: '100%', 
                          display: 'flex', 
                          flexDirection: 'column',
                          borderRadius: 2,
                          overflow: 'hidden',
                          transition: 'all 0.3s ease',
                          '&:hover': {
                            boxShadow: '0px 6px 12px rgba(100, 116, 139, 0.12)',
                            transform: 'translateY(-4px)'
                          }
                        }}
                      >
                        <Box 
                          sx={{ 
                            height: 10, 
                            width: '100%', 
                            bgcolor: student.status === 'active' ? '#10b981' :
                                             student.status === 'inactive' ? '#f43f5e' : 
                                             '#f59e0b'
                          }} 
                        />
                        <Box sx={{ p: 2, display: 'flex', alignItems: 'center' }}>
                          <Avatar 
                            sx={{ 
                              width: 56, 
                              height: 56,
                              bgcolor: blueTheme.primary.main
                            }}
                          >
                            {student.firstName?.charAt(0)}{student.lastName?.charAt(0)}
                          </Avatar>
                          <Box sx={{ ml: 2, overflow: 'hidden' }}>
                            <Typography variant="h6" noWrap>
                              {student.firstName} {student.lastName}
                            </Typography>
                            <Typography variant="body2" color="text.secondary" noWrap>
                              {student.email}
                            </Typography>
                          </Box>
                        </Box>
                        
                        <CardContent sx={{ pt: 0, flex: 1 }}>
                          <Box 
                            sx={{ 
                              display: 'flex',
                              alignItems: 'center',
                              mb: 1
                            }}
                          >
                            <SchoolIcon fontSize="small" sx={{ color: 'text.secondary', mr: 1 }} />
                            <Typography variant="body2" noWrap>
                              {classes.find(c => c._id === student.classId)?.name || 'Unassigned'}
                            </Typography>
                          </Box>
                          {student.phone && (
                            <Box 
                              sx={{ 
                                display: 'flex',
                                alignItems: 'center',
                                mb: 1
                              }}
                            >
                              <PersonIcon fontSize="small" sx={{ color: 'text.secondary', mr: 1 }} />
                              <Typography variant="body2" noWrap>
                                {student.phone}
                              </Typography>
                            </Box>
                          )}
                          
                          <Chip 
                            label={student.status || 'N/A'} 
                            size="small"
                            sx={{ 
                              mt: 1,
                              textTransform: 'capitalize',
                              bgcolor: student.status === 'active' ? '#ecfdf5' :
                                               student.status === 'inactive' ? '#fef2f2' : 
                                               '#fffbeb',
                              color: student.status === 'active' ? '#10b981' :
                                      student.status === 'inactive' ? '#f43f5e' : 
                                      '#f59e0b',
                              '& .MuiChip-label': {
                                fontWeight: 500
                              }
                            }} 
                          />
                        </CardContent>
                        
                        <CardActions sx={{ borderTop: '1px solid #f1f5f9', p: 1 }}>
                          <Button 
                            size="small" 
                            onClick={() => handleEditStudent(student)}
                            sx={{ 
                              color: blueTheme.primary.main,
                              '&:hover': { bgcolor: `${blueTheme.primary.light}15` }
                            }}
                          >
                            Edit
                          </Button>
                          <Button 
                            size="small" 
                            color="error"
                            onClick={() => handleDeleteStudent(student._id)}
                            sx={{ 
                              '&:hover': { bgcolor: '#fee2e2' }
                            }}
                          >
                            Delete
                          </Button>
                        </CardActions>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
                
                <TablePagination
                  component="div"
                  count={filteredStudents.length}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  sx={{ 
                    mt: 2,
                    '.MuiTablePagination-select': {
                      borderRadius: 1,
                    },
                    '.MuiTablePagination-selectIcon': {
                      color: blueTheme.primary.main,
                    }
                  }}
                />
              </>
            )}
          </Box>
        )}
        
        {/* Teachers Tab */}
        {activeTab === 2 && (
          <Box>
            <Typography variant="h4" gutterBottom sx={{ fontWeight: 'medium', color: blueTheme.primary.dark }}>
              Teacher Management
            </Typography>
            <Paper sx={{ p: 3, mb: 3, borderRadius: 2, boxShadow: '0 4px 20px rgba(0,0,0,0.08)' }}>
              <Box sx={{ mb: 3 }}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} md={5}>
                    <TextField
                      label="Search Teachers"
                      variant="outlined"
                      size="small"
                      value={teacherSearchTerm}
                      onChange={handleTeacherSearchChange}
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                      placeholder="Search by name, email or subject..."
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          borderRadius: 2,
                          transition: 'all 0.3s ease',
                          '&:hover': {
                            borderColor: blueTheme.primary.main,
                          },
                          '&.Mui-focused': {
                            boxShadow: `0 0 0 2px ${blueTheme.primary.light}40`,
                          }
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} md={2}>
                    <TextField
                      select
                      label="Status Filter"
                      variant="outlined"
                      size="small"
                      value={teacherStatusFilter}
                      onChange={(e) => setTeacherStatusFilter(e.target.value)}
                      fullWidth
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          borderRadius: 2,
                        }
                      }}
                    >
                      <MenuItem value="">All Teachers</MenuItem>
                      <MenuItem value="active">Active</MenuItem>
                      <MenuItem value="inactive">Inactive</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={6} md={2}>
                    <TextField
                      select
                      label="Difficulty"
                      variant="outlined"
                      size="small"
                      value={mcqDifficultyFilter}
                      onChange={(e) => setMcqDifficultyFilter(e.target.value)}
                      fullWidth
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          borderRadius: 2,
                        }
                      }}
                    >
                      <MenuItem value="">All Difficulties</MenuItem>
                      <MenuItem value="easy">Easy</MenuItem>
                      <MenuItem value="medium">Medium</MenuItem>
                      <MenuItem value="hard">Hard</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={12} md={3} sx={{ display: 'flex', justifyContent: { xs: 'flex-start', md: 'flex-end' } }}>
                    <Button 
                      variant="contained" 
                      color="primary"
                      startIcon={<AddIcon />}
                      onClick={() => setTeacherDialog({ open: true, mode: 'add', data: null })}
                      sx={{ 
                        borderRadius: 2,
                        bgcolor: blueTheme.primary.main,
                        '&:hover': {
                          bgcolor: blueTheme.primary.dark,
                          transform: 'translateY(-2px)',
                        },
                        transition: 'all 0.3s ease',
                        boxShadow: '0 4px 12px rgba(0,0,0,0.15)'
                      }}
                    >
                      Add Teacher
                    </Button>
                  </Grid>
                </Grid>
              </Box>
              
              {loading && (
                <Box sx={{ display: 'flex', justifyContent: 'center', p: 5 }}>
                  <CircularProgress sx={{ color: blueTheme.primary.main }} />
                </Box>
              )}
              
              {!loading && (
                <>
                  {filteredTeachers.length > 0 ? (
                    <Grid container spacing={2}>
                      {filteredTeachers.map((teacher) => (
                        <Grid item xs={12} sm={6} md={4} key={teacher._id}>
                          <Card sx={{ 
                            height: '100%', 
                            display: 'flex', 
                            flexDirection: 'column',
                            borderRadius: 2,
                            transition: 'all 0.3s ease',
                            '&:hover': {
                              boxShadow: '0 8px 24px rgba(0,0,0,0.12)',
                              transform: 'translateY(-4px)'
                            }
                          }}>
                            <CardContent sx={{ flexGrow: 1, p: 3 }}>
                              <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                  <Avatar 
                                    sx={{ 
                                      bgcolor: blueTheme.primary.main, 
                                      width: 48, 
                                      height: 48,
                                      fontSize: '1.2rem',
                                      fontWeight: 'bold',
                                      mr: 2
                                    }}
                                  >
                                    {teacher.firstName?.charAt(0) || 'T'}
                                  </Avatar>
                                  <Box>
                                    <Typography 
                                      variant="h6" 
                                      component="div" 
                                      sx={{ fontWeight: 'medium', color: blueTheme.primary.dark }}
                                    >
                                      {teacher.firstName} {teacher.lastName}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                      {teacher.email}
                                    </Typography>
                                  </Box>
                                </Box>
                                <Chip 
                                  label={teacher.isActive ? 'Active' : 'Inactive'} 
                                  size="small" 
                                  sx={{ 
                                    bgcolor: teacher.isActive ? 
                                      'rgba(46, 125, 50, 0.1)' : 
                                      'rgba(198, 40, 40, 0.1)',
                                    color: teacher.isActive ? 
                                      '#2e7d32' : 
                                      '#c62828',
                                    fontWeight: 'bold',
                                    borderRadius: 1,
                                    height: 24
                                  }} 
                                />
                              </Box>
                              
                              <Divider sx={{ my: 2 }} />
                              
                              <Box sx={{ mt: 2 }}>
                                <Typography variant="body2" color="text.secondary" sx={{ mb: 0.5 }}>
                                  <strong>Subject Expertise:</strong>
                                </Typography>
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                                  {teacher.subjects && teacher.subjects.length > 0 ? (
                                    teacher.subjects.map((subjectId, index) => {
                                      const subject = subjects.find(s => s._id === subjectId);
                                      return subject ? (
                                        <Chip 
                                          key={index}
                                          label={subject.name} 
                                          size="small" 
                                          sx={{ 
                                            bgcolor: `${blueTheme.primary.light}30`,
                                            color: blueTheme.primary.dark,
                                            fontWeight: 'bold',
                                            borderRadius: 1
                                          }}
                                        />
                                      ) : null;
                                    })
                                  ) : (
                                    <Typography variant="body2" color="text.secondary">
                                      No subjects assigned
                                    </Typography>
                                  )}
                                </Box>
                              </Box>
                              
                              {teacher.bio && (
                                <Box sx={{ mt: 2 }}>
                                  <Typography variant="body2" color="text.secondary" sx={{ mb: 0.5 }}>
                                    <strong>Bio:</strong>
                                  </Typography>
                                  <Typography variant="body2" color="text.secondary">
                                    {teacher.bio.length > 100 ? `${teacher.bio.substring(0, 100)}...` : teacher.bio}
                                  </Typography>
                                </Box>
                              )}
                            </CardContent>
                            
                            <CardActions sx={{ justifyContent: 'flex-end', px: 2, pb: 2 }}>
                              <Button 
                                size="small" 
                                startIcon={<EditIcon />}
                                onClick={() => setTeacherDialog({ open: true, mode: 'edit', data: teacher })}
                                sx={{ 
                                  color: blueTheme.primary.main,
                                  '&:hover': { bgcolor: `${blueTheme.primary.light}15` }
                                }}
                              >
                                Edit
                              </Button>
                              <Button 
                                size="small" 
                                color="error" 
                                startIcon={<DeleteIcon />}
                                onClick={() => setDeleteDialog({ open: true, type: 'teacher', id: teacher._id })}
                                sx={{ '&:hover': { bgcolor: '#ffebee' } }}
                              >
                                Delete
                              </Button>
                            </CardActions>
                          </Card>
                        </Grid>
                      ))}
                    </Grid>
                  ) : (
                    <Box sx={{ 
                      textAlign: 'center', 
                      py: 5, 
                      bgcolor: '#f9f9f9', 
                      borderRadius: 2,
                      border: '1px dashed #ddd' 
                    }}>
                      <Typography variant="h6" gutterBottom color="text.secondary">
                        No teachers found
                      </Typography>
                      <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
                        {teacherSearchTerm || teacherStatusFilter ? 
                          'Try adjusting your search filters to see more results' : 
                          'Get started by adding your first teacher'}
                      </Typography>
                      <Button 
                        variant="contained" 
                        color="primary"
                        startIcon={<AddIcon />}
                        onClick={() => setTeacherDialog({ open: true, mode: 'add', data: null })}
                        sx={{ 
                          borderRadius: 2,
                          bgcolor: blueTheme.primary.main,
                          '&:hover': {
                            bgcolor: blueTheme.primary.dark,
                          }
                        }}
                      >
                        Add Your First Teacher
                      </Button>
                    </Box>
                  )}
                  
                  {filteredTeachers.length > 0 && (
                    <Box sx={{ 
                      display: 'flex', 
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      mt: 3,
                      backgroundColor: '#f9f9f9',
                      borderRadius: 2,
                      p: 1
                    }}>
                      <Box sx={{ pl: 1 }}>
                        <Typography variant="body2" color="text.secondary">
                          {filteredTeachers.length > 0 ? 
                            `Showing ${Math.min(rowsPerPage, filteredTeachers.length)} of ${totalTeachers} teachers` :
                            'No teachers to display'}
                        </Typography>
                      </Box>
                      <TablePagination
                        component="div"
                        count={totalTeachers}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        rowsPerPageOptions={[5, 10, 25, 50]}
                        sx={{ 
                          '.MuiTablePagination-select': {
                            borderRadius: 1,
                          }
                        }}
                      />
                    </Box>
                  )}
                </>
              )}
            </Paper>
          </Box>
        )}
        
        {/* Subjects Tab */}
        {activeTab === 3 && (
          <Box>
            <Typography variant="h4" gutterBottom sx={{ fontWeight: 'medium', color: blueTheme.primary.dark }}>
              Subject Management
            </Typography>
            <Box sx={{ width: '100%', bgcolor: blueTheme.background.paper, mb: 2 }}>
              <Tabs
                value={playgroundTab}
                onChange={handlePlaygroundTabChange}
                variant="scrollable"
                scrollButtons="auto"
              >
                <Tab label="Subjects" />
                <Tab label="Topics" />
              </Tabs>
            </Box>
            
            {/* Subjects List */}
            {playgroundTab === 0 && (
              <SubjectManagement 
                subjects={filteredSubjects} 
                totalSubjects={totalSubjects} 
                page={page} 
                rowsPerPage={rowsPerPage} 
                onPageChange={handleChangePage} 
                onRowsPerPageChange={handleChangeRowsPerPage} 
                onAddSubject={() => setSubjectDialog({ open: true, mode: 'add', data: null })} 
                onEditSubject={(subject) => setSubjectDialog({ open: true, mode: 'edit', data: subject })} 
                onDeleteSubject={(id) => setDeleteDialog({ open: true, type: 'subject', id })}
                getToken={getToken}
                user={user}
                showNotification={showNotification}
              />
            )}
            
            {/* Topics List */}
            {playgroundTab === 1 && (
              <TopicManagement 
                topics={topics} 
                selectedSubject={selectedSubject} 
                setSelectedSubject={setSelectedSubject} 
                onAddTopic={() => setTopicDialog({ open: true, mode: 'add', data: null, subjectId: selectedSubject })} 
                onEditTopic={(topic) => setTopicDialog({ open: true, mode: 'edit', data: topic, subjectId: selectedSubject })} 
                onDeleteTopic={(id) => setDeleteDialog({ open: true, type: 'topic', id })}
                getToken={getToken}
                user={user}
                showNotification={showNotification}
              />
            )}
          </Box>
        )}
        
        {/* MCQs Tab */}
        {activeTab === 4 && (
          <Box>
            <Typography variant="h4" gutterBottom sx={{ fontWeight: 'medium', color: blueTheme.primary.dark }}>
              Playground
            </Typography>
            <Box sx={{ width: '100%', bgcolor: blueTheme.background.paper, mb: 2 }}>
              <Tabs
                value={playgroundTab}
                onChange={handlePlaygroundTabChange}
                variant="scrollable"
                scrollButtons="auto"
              >
                <Tab label="Tests" />
                <Tab label="Quiz" />
                <Tab label="MCQ Database" />
              </Tabs>
            </Box>
            
            {/* Tests Tab */}
            {playgroundTab === 0 && (
              <Typography variant="body1">Tests content here</Typography>
            )}
            
            {/* Quiz Tab */}
            {playgroundTab === 1 && (
              <Typography variant="body1">Quiz content here</Typography>
            )}
            
            {/* MCQ Database Tab */}
            {playgroundTab === 2 && (
              <Box>
                <Typography variant="h4" gutterBottom sx={{ fontWeight: 'medium', color: blueTheme.primary.dark }}>
                  MCQ Management
                </Typography>
                <Paper sx={{ p: 3, mb: 3, borderRadius: 2, boxShadow: '0 4px 20px rgba(0,0,0,0.08)' }}>
                  <Box sx={{ mb: 3 }}>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={12} md={5}>
                        <TextField
                          label="Search MCQs"
                          variant="outlined"
                          size="small"
                          value={mcqSearchTerm}
                          onChange={(e) => setMcqSearchTerm(e.target.value)}
                          fullWidth
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <SearchIcon />
                              </InputAdornment>
                            ),
                          }}
                          placeholder="Search questions, options, or explanations..."
                          sx={{
                            '& .MuiOutlinedInput-root': {
                              borderRadius: 2,
                              transition: 'all 0.3s ease',
                              '&:hover': {
                                borderColor: blueTheme.primary.main,
                              },
                              '&.Mui-focused': {
                                boxShadow: `0 0 0 2px ${blueTheme.primary.light}40`,
                              }
                            }
                          }}
                        />
                      </Grid>
                      <Grid item xs={6} md={2}>
                        <TextField
                          select
                          label="Subject"
                          variant="outlined"
                          size="small"
                          value={mcqSubjectFilter}
                          onChange={(e) => setMcqSubjectFilter(e.target.value)}
                          fullWidth
                          sx={{
                            '& .MuiOutlinedInput-root': {
                              borderRadius: 2,
                            }
                          }}
                        >
                          <MenuItem value="">All Subjects</MenuItem>
                          {subjects.map((subject) => (
                            <MenuItem key={subject._id} value={subject._id}>
                              {subject.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item xs={6} md={2}>
                        <TextField
                          select
                          label="Topic"
                          variant="outlined"
                          size="small"
                          value={mcqTopicFilter}
                          onChange={(e) => setMcqTopicFilter(e.target.value)}
                          fullWidth
                          sx={{
                            '& .MuiOutlinedInput-root': {
                              borderRadius: 2,
                            }
                          }}
                        >
                          <MenuItem value="">All Topics</MenuItem>
                          {Object.keys(topics).includes(mcqSubjectFilter) && 
                            topics[mcqSubjectFilter].map(topic => (
                              <MenuItem key={topic._id} value={topic._id}>
                                {topic.name}
                              </MenuItem>
                            ))}
                        </TextField>
                      </Grid>
                      <Grid item xs={6} md={2}>
                        <TextField
                          select
                          label="Difficulty"
                          variant="outlined"
                          size="small"
                          value={mcqDifficultyFilter}
                          onChange={(e) => setMcqDifficultyFilter(e.target.value)}
                          fullWidth
                          sx={{
                            '& .MuiOutlinedInput-root': {
                              borderRadius: 2,
                            }
                          }}
                        >
                          <MenuItem value="">All Difficulties</MenuItem>
                          <MenuItem value="easy">Easy</MenuItem>
                          <MenuItem value="medium">Medium</MenuItem>
                          <MenuItem value="hard">Hard</MenuItem>
                        </TextField>
                      </Grid>
                      <Grid item xs={12} md={3} sx={{ display: 'flex', justifyContent: { xs: 'flex-start', md: 'flex-end' } }}>
                        <Button 
                          variant="contained" 
                          color="primary"
                          startIcon={<AddIcon />}
                          onClick={() => setMcqDialog({ open: true, mode: 'add', data: null })}
                          sx={{ 
                            borderRadius: 2,
                            bgcolor: blueTheme.primary.main,
                            '&:hover': {
                              bgcolor: blueTheme.primary.dark,
                              transform: 'translateY(-2px)',
                            },
                            transition: 'all 0.3s ease',
                            boxShadow: '0 4px 12px rgba(0,0,0,0.15)'
                          }}
                        >
                          Add MCQ
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                  
                  {loading && (
                    <Box sx={{ display: 'flex', justifyContent: 'center', p: 5 }}>
                      <CircularProgress sx={{ color: blueTheme.primary.main }} />
                    </Box>
                  )}
                  
                  {!loading && (
                    <>
                      {filteredMcqs.length > 0 ? (
                        <Grid container spacing={2}>
                          {filteredMcqs.map((mcq) => (
                            <Grid item xs={12} md={6} lg={4} key={mcq._id}>
                              <Card sx={{ 
                                height: '100%', 
                                display: 'flex', 
                                flexDirection: 'column',
                                borderRadius: 2,
                                transition: 'all 0.3s ease',
                                '&:hover': {
                                  boxShadow: '0 8px 24px rgba(0,0,0,0.12)',
                                  transform: 'translateY(-4px)'
                                }
                              }}>
                                <CardContent sx={{ flexGrow: 1 }}>
                                  <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2, alignItems: 'flex-start' }}>
                                    <Chip 
                                      label={mcq.subject?.name || 'Uncategorized'} 
                                      size="small" 
                                      sx={{ 
                                        bgcolor: `${blueTheme.primary.light}30`,
                                        color: blueTheme.primary.dark,
                                        fontWeight: 'bold',
                                        borderRadius: 1
                                      }} 
                                    />
                                    <Chip 
                                      label={mcq.difficulty} 
                                      size="small" 
                                      sx={{ 
                                        bgcolor: mcq.difficulty === 'easy' 
                                          ? '#e8f5e9' 
                                          : mcq.difficulty === 'medium' 
                                            ? '#fff3e0' 
                                            : '#ffebee',
                                        color: mcq.difficulty === 'easy' 
                                          ? '#2e7d32' 
                                          : mcq.difficulty === 'medium' 
                                            ? '#ef6c00' 
                                            : '#c62828',
                                        fontWeight: 'bold',
                                        borderRadius: 1
                                      }} 
                                    />
                                  </Box>
                                  
                                  <Typography 
                                    variant="subtitle1" 
                                    component="div" 
                                    gutterBottom
                                    sx={{ fontWeight: 'medium', color: blueTheme.primary.dark }}
                                  >
                                    {mcq.question}
                                  </Typography>
                                  
                                  <Box sx={{ mt: 2 }}>
                                    {mcq.options?.map((option, index) => (
                                      <Box 
                                        key={index} 
                                        sx={{ 
                                          p: 1.5, 
                                          mb: 1, 
                                          borderRadius: 1.5, 
                                          bgcolor: option.isCorrect ? `${blueTheme.primary.light}20` : 'background.paper',
                                          border: 1,
                                          borderColor: option.isCorrect ? blueTheme.primary.main : 'divider',
                                          display: 'flex',
                                          alignItems: 'center',
                                          transition: 'all 0.2s ease',
                                          '&:hover': {
                                            bgcolor: option.isCorrect ? `${blueTheme.primary.light}30` : '#f5f5f5'
                                          }
                                        }}
                                      >
                                        <Box 
                                          sx={{ 
                                            width: 24, 
                                            height: 24, 
                                            borderRadius: '50%', 
                                            bgcolor: option.isCorrect ? blueTheme.primary.main : '#f1f1f1',
                                            color: option.isCorrect ? 'white' : 'text.secondary',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            mr: 1.5,
                                            fontSize: '0.8rem',
                                            fontWeight: 'bold'
                                          }}
                                        >
                                          {String.fromCharCode(65 + index)}
                                        </Box>
                                        <Typography variant="body2" sx={{ flex: 1 }}>
                                          {option.text}
                                        </Typography>
                                      </Box>
                                    ))}
                                  </Box>
                                  
                                  {mcq.explanation && (
                                    <Box sx={{ mt: 2, p: 1.5, bgcolor: '#f5f7fa', borderRadius: 1.5, border: '1px dashed #ddd' }}>
                                      <Typography variant="caption" color="text.secondary" sx={{ display: 'block', mb: 0.5 }}>
                                        Explanation:
                                      </Typography>
                                      <Typography variant="body2" color="text.secondary">
                                        {mcq.explanation}
                                      </Typography>
                                    </Box>
                                  )}
                                </CardContent>
                                
                                <CardActions sx={{ justifyContent: 'flex-end', px: 2, pb: 2 }}>
                                  <Button 
                                    size="small" 
                                    startIcon={<EditIcon />}
                                    onClick={() => setMcqDialog({
                                      open: true,
                                      mode: 'edit',
                                      data: mcq,
                                      subjectId: mcq.subjectId,
                                      topicId: mcq.topicId
                                    })}
                                    sx={{ 
                                      color: blueTheme.primary.main,
                                      '&:hover': { bgcolor: `${blueTheme.primary.light}15` }
                                    }}
                                  >
                                    Edit
                                  </Button>
                                  <Button 
                                    size="small" 
                                    color="error" 
                                    startIcon={<DeleteIcon />}
                                    onClick={() => setDeleteDialog({
                                      open: true,
                                      type: 'mcq',
                                      id: mcq._id
                                    })}
                                    sx={{ '&:hover': { bgcolor: '#ffebee' } }}
                                  >
                                    Delete
                                  </Button>
                                </CardActions>
                              </Card>
                            </Grid>
                          ))}
                        </Grid>
                      ) : (
                        <Box sx={{ 
                          textAlign: 'center', 
                          py: 5, 
                          bgcolor: '#f9f9f9', 
                          borderRadius: 2,
                          border: '1px dashed #ddd' 
                        }}>
                          <Typography variant="h6" gutterBottom color="text.secondary">
                            No MCQs found
                          </Typography>
                          <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
                            {mcqSearchTerm || mcqSubjectFilter || mcqDifficultyFilter ? 
                              'Try adjusting your search filters to see more results' : 
                              'Get started by adding your first MCQ to the database'}
                          </Typography>
                          <Button 
                            variant="contained" 
                            color="primary"
                            startIcon={<AddIcon />}
                            onClick={() => setMcqDialog({
                              open: true,
                              mode: 'add',
                              data: null,
                              subjectId: mcqsBySubjectId,
                              topicId: mcqsByTopicId
                            })}
                            sx={{ 
                              borderRadius: 2,
                              bgcolor: blueTheme.primary.main,
                              '&:hover': {
                                bgcolor: blueTheme.primary.dark,
                              }
                            }}
                          >
                            Create Your First MCQ
                          </Button>
                        </Box>
                      )}
                      
                      <Box sx={{ 
                        display: 'flex', 
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        mt: 3,
                        backgroundColor: '#f9f9f9',
                        borderRadius: 2,
                        p: 1
                      }}>
                        <Box sx={{ pl: 1 }}>
                          <Typography variant="body2" color="text.secondary">
                            {filteredMcqs.length > 0 ? 
                              `Showing ${Math.min(rowsPerPage, filteredMcqs.length)} of ${totalMcqs} MCQs` :
                              'No MCQs to display'}
                          </Typography>
                        </Box>
                        <TablePagination
                          component="div"
                          count={totalMcqs}
                          page={page}
                          onPageChange={handleChangePage}
                          rowsPerPage={rowsPerPage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                          rowsPerPageOptions={[5, 10, 25, 50]}
                          sx={{ 
                            '.MuiTablePagination-select': {
                              borderRadius: 1,
                            }
                          }}
                        />
                      </Box>
                    </>
                  )}
                </Paper>
              </Box>
            )}
          </Box>
        )}
        
        {/* Analytics Tab */}
        {activeTab === 5 && (
          <AnalyticsDashboard 
            getToken={getToken}
            user={user}
            showNotification={showNotification}
          />
        )}
        
        {/* Student Dialog */}
        <Dialog open={studentDialog.open} onClose={handleStudentDialogClose} maxWidth="md" fullWidth>
          <DialogTitle sx={{ bgcolor: blueTheme.primary.main, color: 'white' }}>
            {studentDialog.mode === 'add' ? 'Add New Student' : 'Edit Student'}
            <IconButton
              aria-label="close"
              onClick={handleStudentDialogClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: 'white',
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent sx={{ mt: 2 }}>
            <Tabs 
              value={tabValue} 
              onChange={handleStudentTabChange} 
              sx={{ 
                mb: 2,
                '& .MuiTab-root': { 
                  color: blueTheme.primary.light,
                  '&.Mui-selected': { color: blueTheme.primary.main }
                },
                '& .MuiTabs-indicator': { backgroundColor: blueTheme.primary.main }
              }}
            >
              <Tab label="Individual Student" />
              <Tab label="Bulk Upload" />
            </Tabs>
            
            {/* Individual Student Form */}
            {tabValue === 0 && (
              <Box component="form" id="studentForm" noValidate sx={{ mt: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="firstName"
                      label="First Name"
                      name="firstName"
                      defaultValue={studentDialog.data?.firstName || ''}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '&.Mui-focused fieldset': {
                            borderColor: blueTheme.primary.main,
                          },
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="lastName"
                      label="Last Name"
                      name="lastName"
                      defaultValue={studentDialog.data?.lastName || ''}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '&.Mui-focused fieldset': {
                            borderColor: blueTheme.primary.main,
                          },
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="email"
                      label="Email Address"
                      name="email"
                      type="email"
                      defaultValue={studentDialog.data?.email || ''}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '&.Mui-focused fieldset': {
                            borderColor: blueTheme.primary.main,
                          },
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl 
                      fullWidth 
                      margin="normal"
                      required
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '&.Mui-focused fieldset': {
                            borderColor: blueTheme.primary.main,
                          },
                        },
                      }}
                    >
                      <InputLabel id="classId-label">Class</InputLabel>
                      <Select
                        labelId="classId-label"
                        id="classId"
                        name="classId"
                        label="Class"
                        defaultValue={studentDialog.data?.classId || ''}
                        required
                      >
                        <MenuItem value="">Select Class</MenuItem>
                        {classes.map((cls) => (
                          <MenuItem key={cls._id} value={cls._id}>
                            {cls.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      margin="normal"
                      fullWidth
                      id="phone"
                      label="Phone Number"
                      name="phone"
                      defaultValue={studentDialog.data?.phone || ''}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '&.Mui-focused fieldset': {
                            borderColor: blueTheme.primary.main,
                          },
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl 
                      fullWidth 
                      margin="normal"
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '&.Mui-focused fieldset': {
                            borderColor: blueTheme.primary.main,
                          },
                        },
                      }}
                    >
                      <InputLabel id="status-label">Status</InputLabel>
                      <Select
                        labelId="status-label"
                        id="status"
                        name="status"
                        label="Status"
                        defaultValue={studentDialog.data?.status || 'active'}
                      >
                        <MenuItem value="active">Active</MenuItem>
                        <MenuItem value="inactive">Inactive</MenuItem>
                        <MenuItem value="pending">Pending</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      margin="normal"
                      fullWidth
                      id="parentName"
                      label="Parent Name"
                      name="parentName"
                      defaultValue={studentDialog.data?.parentName || ''}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '&.Mui-focused fieldset': {
                            borderColor: blueTheme.primary.main,
                          },
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      margin="normal"
                      fullWidth
                      id="parentPhone"
                      label="Parent Phone"
                      name="parentPhone"
                      defaultValue={studentDialog.data?.parentPhone || ''}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '&.Mui-focused fieldset': {
                            borderColor: blueTheme.primary.main,
                          },
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      margin="normal"
                      fullWidth
                      id="address"
                      label="Address"
                      name="address"
                      multiline
                      rows={2}
                      defaultValue={studentDialog.data?.address || ''}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '&.Mui-focused fieldset': {
                            borderColor: blueTheme.primary.main,
                          },
                        },
                      }}
                    />
                  </Grid>
                  {studentDialog.mode === 'add' && (
                    <Grid item xs={12}>
                      <Typography variant="caption" color="text.secondary">
                        Note: A user account will be created automatically with a random password.
                        The login credentials will be emailed to the provided email address.
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </Box>
            )}
            
            {/* Bulk Upload Tab */}
            {tabValue === 1 && (
              <Box sx={{ mt: 1 }}>
                <Box
                  sx={{
                    border: '2px dashed #ccc',
                    borderRadius: 2,
                    p: 3,
                    textAlign: 'center',
                    mb: 3,
                    bgcolor: '#f8f9fa',
                    cursor: 'pointer',
                    transition: 'all 0.3s ease',
                    '&:hover': {
                      borderColor: blueTheme.primary.main,
                      bgcolor: '#f1f4f9',
                    },
                  }}
                  onClick={() => document.getElementById('bulkFileInput').click()}
                >
                  <input
                    type="file"
                    id="bulkFileInput"
                    accept=".xlsx,.csv"
                    style={{ display: 'none' }}
                    onChange={(event) => setSelectedFile(event.target.files[0])}
                  />
                  <CloudUploadIcon sx={{ fontSize: 60, color: blueTheme.primary.main, mb: 1 }} />
                  <Typography variant="h6" gutterBottom>
                    Drop Excel File or Click to Upload
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Supported formats: .xlsx, .csv
                  </Typography>
                  {selectedFile && (
                    <Box sx={{ mt: 2 }}>
                      <Chip
                        label={selectedFile.name}
                        onDelete={() => setSelectedFile(null)}
                        color="primary"
                        variant="outlined"
                      />
                    </Box>
                  )}
                </Box>
                
                <Typography variant="subtitle2" gutterBottom>
                  Template Format:
                </Typography>
                <TableContainer component={Paper} sx={{ mb: 2, maxHeight: 200, overflow: 'auto' }}>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>First Name*</TableCell>
                        <TableCell>Last Name*</TableCell>
                        <TableCell>Email*</TableCell>
                        <TableCell>Class ID*</TableCell>
                        <TableCell>Phone</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Address</TableCell>
                        <TableCell>Parent Name</TableCell>
                        <TableCell>Parent Phone</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>John</TableCell>
                        <TableCell>Doe</TableCell>
                        <TableCell>john.doe@example.com</TableCell>
                        <TableCell>classId1</TableCell>
                        <TableCell>1234567890</TableCell>
                        <TableCell>active</TableCell>
                        <TableCell>123 Main St</TableCell>
                        <TableCell>Jane Doe</TableCell>
                        <TableCell>9876543210</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                
                <Button
                  variant="contained"
                  color="primary"
                  disabled={!selectedFile}
                  onClick={handleBulkUpload}
                  sx={{ 
                    mt: 2,
                    borderRadius: 2,
                    bgcolor: blueTheme.primary.main,
                    '&:hover': {
                      bgcolor: blueTheme.primary.dark,
                    }
                  }}
                >
                  Upload and Process
                </Button>
                
                <Typography variant="caption" color="text.secondary" sx={{ display: 'block', mt: 2 }}>
                  Note: Fields marked with * are required. All students will be associated with this institution automatically.
                  Each student will receive a user account with a random password sent to their email.
                </Typography>
              </Box>
            )}
            
            {/* Upload Results */}
            {uploadResults && (
              <Box sx={{ mt: 3, p: 2, bgcolor: '#f5f7fa', borderRadius: 2 }}>
                <Typography variant="h6" gutterBottom>
                  Upload Results
                </Typography>
                <Typography variant="body2">
                  Total records processed: {uploadResults.total}
                </Typography>
                <Typography variant="body2" color="success.main">
                  Successfully created: {uploadResults.success}
                </Typography>
                <Typography variant="body2" color="error.main">
                  Failed: {uploadResults.failed}
                </Typography>
                
                {uploadResults.errors.length > 0 && (
                  <Box sx={{ mt: 2 }}>
                    <Typography variant="subtitle2" gutterBottom>
                      Errors:
                    </Typography>
                    <TableContainer component={Paper} sx={{ maxHeight: 200, overflow: 'auto' }}>
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell>Row</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Error</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {uploadResults.errors.map((error, index) => (
                            <TableRow key={index}>
                              <TableCell>{error.row || 'N/A'}</TableCell>
                              <TableCell>{error.email || 'N/A'}</TableCell>
                              <TableCell>{error.error}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                )}
              </Box>
            )}
          </DialogContent>
          <DialogActions sx={{ p: 2, bgcolor: '#f9fafc' }}>
            <Button 
              onClick={handleStudentDialogClose}
              sx={{ 
                color: blueTheme.primary.main,
                '&:hover': { bgcolor: `${blueTheme.primary.light}15` }
              }}
            >
              Cancel
            </Button>
            {tabValue === 0 && (
              <Button 
                onClick={handleStudentFormSubmit}
                variant="contained"
                sx={{ 
                  borderRadius: 2,
                  bgcolor: blueTheme.primary.main,
                  '&:hover': {
                    bgcolor: blueTheme.primary.dark,
                  }
                }}
              >
                {studentDialog.mode === 'add' ? 'Add Student' : 'Save Changes'}
              </Button>
            )}
          </DialogActions>
        </Dialog>
        
        {/* Delete Confirmation Dialog */}
        <Dialog
          open={deleteDialog.open}
          onClose={handleDeleteDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Confirm Delete
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete this {deleteDialog.type}? This action cannot be undone.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteDialogClose}>Cancel</Button>
            <Button onClick={handleConfirmDelete} color="error" autoFocus>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
        
        {/* Subject Dialog */}
        <Dialog 
          open={subjectDialog.open} 
          onClose={() => setSubjectDialog({ ...subjectDialog, open: false })}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle>
            {subjectDialog.mode === 'add' ? 'Add New Subject' : 'Edit Subject'}
          </DialogTitle>
          <DialogContent>
            <form id="subjectForm">
              <Grid container spacing={2} sx={{ mt: 1 }}>
                <Grid item xs={12}>
                  <TextField
                    name="name"
                    label="Subject Name"
                    variant="outlined"
                    fullWidth
                    required
                    defaultValue={subjectDialog.data?.name || ''}
                    autoFocus
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="description"
                    label="Description"
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={3}
                    defaultValue={subjectDialog.data?.description || ''}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth required>
                    <InputLabel id="class-select-label">Class</InputLabel>
                    <Select
                      labelId="class-select-label"
                      name="classId"
                      label="Class"
                      defaultValue={subjectDialog.data?.classId || ''}
                    >
                      <MenuItem value="">Select Class</MenuItem>
                      {classes && classes.length > 0 ? (
                        classes.map((cls) => (
                          <MenuItem key={cls._id} value={cls._id}>
                            {cls.name}
                          </MenuItem>
                        ))
                      ) : (
                        [
                          <MenuItem key="class-8" value="class-8">Class 8</MenuItem>,
                          <MenuItem key="class-9" value="class-9">Class 9</MenuItem>,
                          <MenuItem key="class-10" value="class-10">Class 10</MenuItem>,
                          <MenuItem key="class-11" value="class-11">Class 11</MenuItem>,
                          <MenuItem key="class-12" value="class-12">Class 12</MenuItem>
                        ]
                      )}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </form>
          </DialogContent>
          <DialogActions>
            <Button 
              onClick={() => setSubjectDialog({ ...subjectDialog, open: false })}
            >
              Cancel
            </Button>
            <Button 
              variant="contained" 
              onClick={handleSubjectFormSubmit}
              color="primary"
            >
              {subjectDialog.mode === 'add' ? 'Add Subject' : 'Save Changes'}
            </Button>
          </DialogActions>
        </Dialog>
        
        {/* Topic Dialog */}
        <Dialog 
          open={topicDialog.open} 
          onClose={() => setTopicDialog({ ...topicDialog, open: false })}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle>
            {topicDialog.mode === 'add' ? 'Add New Topic' : 'Edit Topic'}
          </DialogTitle>
          <DialogContent>
            <form id="topicForm">
              <Grid container spacing={2} sx={{ mt: 1 }}>
                <Grid item xs={12}>
                  <TextField
                    name="name"
                    label="Topic Name"
                    variant="outlined"
                    fullWidth
                    required
                    defaultValue={topicDialog.data?.name || ''}
                    autoFocus
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="description"
                    label="Description"
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={3}
                    defaultValue={topicDialog.data?.description || ''}
                  />
                </Grid>
                {topicDialog.mode === 'add' && !topicDialog.subjectId && (
                  <Grid item xs={12}>
                    <FormControl fullWidth required>
                      <InputLabel id="subject-select-label">Subject</InputLabel>
                      <Select
                        labelId="subject-select-label"
                        name="subjectId"
                        label="Subject"
                        defaultValue=""
                      >
                        {subjects.map((subject) => (
                          <MenuItem key={subject._id} value={subject._id}>
                            {subject.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                )}
                <input 
                  type="hidden" 
                  name="subjectId" 
                  value={topicDialog.subjectId || ''}
                />
              </Grid>
            </form>
          </DialogContent>
          <DialogActions>
            <Button 
              onClick={() => setTopicDialog({ ...topicDialog, open: false })}
            >
              Cancel
            </Button>
            <Button 
              variant="contained" 
              onClick={handleTopicFormSubmit}
              color="primary"
            >
              {topicDialog.mode === 'add' ? 'Add Topic' : 'Save Changes'}
            </Button>
          </DialogActions>
        </Dialog>
        
        {/* Notification Snackbar */}
        <Snackbar 
          open={notification.open} 
          autoHideDuration={6000} 
          onClose={handleCloseNotification}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
          <Alert onClose={handleCloseNotification} severity={notification.severity} sx={{ width: '100%' }}>
            {notification.message}
          </Alert>
        </Snackbar>
      </Box>
    </Box>
  );
};

export default InstitutionAdminDashboard;