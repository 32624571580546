import React from 'react';
import {
  Box,
  Typography,
  List,
  ListItem,
  styled,
  Paper,
  Divider,
  Fade
} from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

const TopicContainer = styled(Box)(({ theme }) => ({
  backgroundColor: 'white',
  padding: 0,
  marginBottom: 0,
  width: '100%',
  borderRadius: '0px',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
}));

const TopicHeader = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: 0,
  backgroundColor: 'white',
}));

const TopicItem = styled(ListItem)(({ theme }) => ({
  padding: theme.spacing(1.5, 2),
  marginBottom: theme.spacing(0.5),
  borderRadius: '0px',
  flexDirection: 'column',
  alignItems: 'flex-start',
  transition: 'all 0.2s ease',
  color: '#333',
  margin: '0',
  '&:hover': {
    backgroundColor: '#f5f5f5',
    cursor: 'pointer'
  },
  '&.selected': {
    backgroundColor: '#f0f7ff',
    '&:hover': {
      backgroundColor: '#e3f2fd',
    }
  }
}));

const DateText = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  color: '#999',
  fontSize: '0.75rem',
  marginTop: theme.spacing(0.5)
}));

const TopicText = styled(Typography)(({ theme }) => ({
  fontWeight: '400',
  fontSize: '0.9rem',
  color: '#333',
  lineHeight: 1.4
}));

const TopicsList = styled(List)(({ theme }) => ({
  padding: 0,
  overflow: 'auto',
  flexGrow: 1,
  '&::-webkit-scrollbar': {
    width: '4px',
  },
  '&::-webkit-scrollbar-track': {
    background: '#f1f1f1',
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#c1c1c1',
    borderRadius: '4px',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    background: '#a8a8a8',
  }
}));

const RecentTopics = ({ topics = [], selectedTopicId = null, onTopicSelect, loading = false }) => {
  // Debug the topics data
  console.log('RecentTopics received:', topics);
  
  // Ensure the topics are properly sorted by date (newest first)
  const sortedTopics = [...topics].sort((a, b) => {
    return new Date(b.createdAt) - new Date(a.createdAt);
  });
  
  // Limit to last 5 entries
  const limitedTopics = sortedTopics.slice(0, 5);
  
  // Display loading state or empty state if no topics
  if (loading) {
    return (
      <TopicContainer>
        <TopicHeader>
          <Typography variant="subtitle1" fontWeight="bold" sx={{ color: '#333', letterSpacing: '0.5px' }}>
            RECENT TOPICS
          </Typography>
        </TopicHeader>
        <Box sx={{ p: 2, textAlign: 'center', flexGrow: 1 }}>
          <Typography variant="body2" color="text.secondary">
            Loading recent topics...
          </Typography>
        </Box>
      </TopicContainer>
    );
  }
  
  if (!topics || topics.length === 0) {
    return (
      <TopicContainer>
        <TopicHeader>
          <Typography variant="subtitle1" fontWeight="bold" sx={{ color: '#333', letterSpacing: '0.5px' }}>
            RECENT TOPICS
          </Typography>
        </TopicHeader>
        <Box sx={{ p: 2, textAlign: 'center', flexGrow: 1 }}>
          <Typography variant="body2" color="text.secondary">
            No recent topics found
          </Typography>
        </Box>
      </TopicContainer>
    );
  }

  // Format date for display
  const formatDate = (dateString) => {
    try {
      const date = new Date(dateString);
      if (isNaN(date)) {
        console.error('Invalid date:', dateString);
        return 'Invalid date';
      }
      
      // Format as DD/MM/YYYY, HH:MM
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      
      return `${day}/${month}/${year}, ${hours}:${minutes}`;
    } catch (error) {
      console.error('Error formatting date:', error);
      return 'Date error';
    }
  };

  return (
    <TopicContainer>
      <TopicHeader>
        <Typography variant="subtitle1" fontWeight="bold" sx={{ color: '#333', letterSpacing: '0.5px' }}>
          RECENT TOPICS
        </Typography>
      </TopicHeader>
      <Fade in={true} timeout={500}>
        <TopicsList disablePadding>
          {limitedTopics.map((topic, index) => (
            <TopicItem
              key={topic._id}
              className={selectedTopicId === topic._id ? 'selected' : ''}
              onClick={() => onTopicSelect(topic._id)}
              disableGutters
            >
              <TopicText>
                {topic.title || 'Untitled Topic'}
              </TopicText>
              <DateText>
                <AccessTimeIcon fontSize="small" sx={{ mr: 0.5, fontSize: '0.875rem' }} />
                {formatDate(topic.createdAt)}
              </DateText>
            </TopicItem>
          ))}
        </TopicsList>
      </Fade>
    </TopicContainer>
  );
};

export default RecentTopics;
