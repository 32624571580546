import { IKImage } from "imagekitio-react";
import Upload from "../upload/Upload";
import "./style.css";
import React, { useEffect, useRef, useState } from "react";
import { getText } from "../../actions/gemini.action";
import Markdown from "react-markdown";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAuth } from "../../services/authContext";

const API_URL = "https://neetlybackend.vercel.app";

const urlEndpoint = "https://ik.imagekit.io/x54uzdlwy";

const NewPrompt = ({ data }) => {
    const [question, setQuestion] = useState("");
    const [answer, setAnswer] = useState("");
    const [img, setImg] = useState({
        isLoading: false,
        error: "",
        dbData: {},
        aiData: {},
    });
    const { getToken } = useAuth();

    const queryClient = useQueryClient();
    const endRef = useRef(null);
    const formRef = useRef(null);
    const mutation = useMutation({
        mutationFn: async ({ question, answer, img }) => {
            const token = await getToken();
            return fetch(`${API_URL}/api/chats/${data._id}`, {
                method: "PUT",
                credentials: "include",
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    question: question.length ? question : undefined,
                    answer,
                    img,
                }),
            }).then((res) => res.json());
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["chat", data._id] }).then(() => {
                formRef.current.reset();
                setQuestion("");
                setAnswer("");
                setImg({
                    isLoading: false,
                    error: "",
                    dbData: {},
                    aiData: {},
                });
            });
        },
        onError: (err) => {
            console.log(err);
        },
    });

    const add = async (text, isInitial) => {
        if (!isInitial) setQuestion(text);
        try {
            const generateText = await getText(Object.entries(img.aiData).length ? [img.aiData, text] : [text]);
            setAnswer(generateText);
            // console.log(answer); // This is the value you set in setAnswer
            mutation.mutate({
                question: text,
                answer: generateText,
                img: img.dbData?.filePath || undefined,
            });
            // setTimeout(() => {}, 0);
        } catch (err) {
            console.log(err);
        }
    };
    const handleSubmit = async (e) => {
        e.preventDefault();

        const text = e.target.text.value;
        if (!text) return;

        add(text, false);
    };

    const hasRun = useRef(false);

    useEffect(() => {
        if (!hasRun.current) {
            if (data?.history?.length === 1) {
                add(data.history[0].parts[0].text, true);
            }
        }
        hasRun.current = true;
    }, []);

    useEffect(() => {
        endRef.current.scrollIntoView({ behavior: "smooth" });
    }, [data, question, answer, img.dbData]);
    return (
        <>
            {img.isLoading && <div className="">Loading...</div>}
            {img.dbData?.filePath && (
                <IKImage
                    urlEndpoint={urlEndpoint}
                    path={img.dbData?.filePath}
                    width="380"
                    transformation={[{ width: 380 }]}
                />
            )}
            {question && <div className="message user">{question}</div>}
            {answer && (
                <div className="message">
                    <Markdown>{answer}</Markdown>
                </div>
            )}
            <div className="endChat" ref={endRef}></div>
            <form className="newForm" onSubmit={handleSubmit} ref={formRef}>
                <Upload setImg={setImg} />
                <input id="file" type="file" multiple={false} hidden />
                <input type="text" name="text" placeholder="Ask anything..." />
                <button>
                    <img src="/arrow.png" alt="" />
                </button>
            </form>
        </>
    );
};

export default NewPrompt;
