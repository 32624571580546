import axios from 'axios';
import config from '../config';

/**
 * Get all classes for an institution
 * @param {string} institutionId - The ID of the institution
 * @param {string} token - Authentication token
 * @returns {Promise} Promise object representing the API response
 */
const getClasses = async (institutionId, token) => {
  try {
    const formattedToken = token.startsWith('Bearer ') ? token : `Bearer ${token}`;
    const response = await axios.get(
      `${config.API_URL}/api/institutions/${institutionId}/classes`,
      {
        headers: {
          Authorization: formattedToken
        }
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error in getClasses:', error.response || error);
    throw error;
  }
};

/**
 * Create a new class for an institution
 * @param {string} institutionId - The ID of the institution
 * @param {Object} classData - Class data (name, displayName)
 * @param {string} token - Authentication token
 * @returns {Promise} Promise object representing the API response
 */
const createClass = async (institutionId, classData, token) => {
  try {
    const formattedToken = token.startsWith('Bearer ') ? token : `Bearer ${token}`;
    console.log('Creating class with token:', formattedToken);
    const response = await axios.post(
      `${config.API_URL}/api/institutions/${institutionId}/classes`,
      classData,
      {
        headers: {
          Authorization: formattedToken,
          'Content-Type': 'application/json'
        }
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error in createClass:', error.response || error);
    throw error;
  }
};

/**
 * Get a class by ID
 * @param {string} institutionId - The ID of the institution
 * @param {string} classId - The ID of the class
 * @param {string} token - Authentication token
 * @returns {Promise} Promise object representing the API response
 */
const getClassById = async (institutionId, classId, token) => {
  try {
    const formattedToken = token.startsWith('Bearer ') ? token : `Bearer ${token}`;
    const response = await axios.get(
      `${config.API_URL}/api/institutions/${institutionId}/classes/${classId}`,
      {
        headers: {
          Authorization: formattedToken
        }
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error in getClassById:', error.response || error);
    throw error;
  }
};

/**
 * Update a class
 * @param {string} institutionId - The ID of the institution
 * @param {string} classId - The ID of the class
 * @param {Object} classData - Updated class data
 * @param {string} token - Authentication token
 * @returns {Promise} Promise object representing the API response
 */
const updateClass = async (institutionId, classId, classData, token) => {
  try {
    const formattedToken = token.startsWith('Bearer ') ? token : `Bearer ${token}`;
    const response = await axios.put(
      `${config.API_URL}/api/institutions/${institutionId}/classes/${classId}`,
      classData,
      {
        headers: {
          Authorization: formattedToken,
          'Content-Type': 'application/json'
        }
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error in updateClass:', error.response || error);
    throw error;
  }
};

/**
 * Delete a class
 * @param {string} institutionId - The ID of the institution
 * @param {string} classId - The ID of the class
 * @param {string} token - Authentication token
 * @returns {Promise} Promise object representing the API response
 */
const deleteClass = async (institutionId, classId, token) => {
  try {
    const formattedToken = token.startsWith('Bearer ') ? token : `Bearer ${token}`;
    const response = await axios.delete(
      `${config.API_URL}/api/institutions/${institutionId}/classes/${classId}`,
      {
        headers: {
          Authorization: formattedToken
        }
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error in deleteClass:', error.response || error);
    throw error;
  }
};

const classService = {
  getClasses,
  createClass,
  getClassById,
  updateClass,
  deleteClass
};

export default classService;
