import React from 'react';
import { Box, Container } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles'; 
import { blue, teal } from '@mui/material/colors'; 
import MainLayout from '../../components/UIRevamp/layout/MainLayout'; 
import TestList from '../../components/UIRevamp/tests/TestList';

// Define the custom theme for the Test Board
const testBoardTheme = createTheme({
  palette: {
    primary: {
      main: blue[700], // Professional Blue
    },
    secondary: {
      main: teal['A700'], // Teal accent for secondary actions
    },
    // You can customize other aspects like typography, components defaults here if needed
  },
});

const TestsPage = () => {
  return (
    <MainLayout> 
      <ThemeProvider theme={testBoardTheme}> 
        {/* Render TestList directly, relying on MainLayout for spacing */}
        {/* Removed Container and Box wrapper */} 
        <TestList />
      </ThemeProvider>
    </MainLayout>
  );
};

export default TestsPage;
