import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { 
  Box, 
  Typography, 
  CircularProgress, 
  Alert, 
  Container,
  Paper,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider,
  Chip,
  Grid,
  Card,
  CardContent,
  Button,
  Tooltip,
  LinearProgress
} from '@mui/material';
import { 
  CheckCircleOutline, 
  CancelOutlined, 
  HelpOutline, 
  GetApp as DownloadIcon,
  ArrowBack as ArrowBackIcon,
  PieChart as PieChartIcon
} from '@mui/icons-material';
import axios from 'axios';
import config from '../../../config';
import { useAuth } from '../../../services/authContext';
import jsPDF from 'jspdf';
import { PieChart, Pie, Cell, ResponsiveContainer, Legend } from 'recharts';
import { format } from 'date-fns';

const TestResultsPage = () => {
  const { testId } = useParams(); 
  const navigate = useNavigate();
  const { user, token } = useAuth();

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [generatingPdf, setGeneratingPdf] = useState(false);
  
  // Colors for the pie chart
  const COLORS = ['#4caf50', '#f44336'];
  const RADIAN = Math.PI / 180;

  // Format seconds to minutes and seconds
  const formatTime = (seconds) => {
    if (!seconds && seconds !== 0) return 'N/A';
    
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}m ${remainingSeconds}s`;
  };
  
  // Generate PDF report
  const generatePdfReport = () => {
    if (!data || !data.results) return;
    
    setGeneratingPdf(true);
    
    try {
      const { testDetails, attemptDetails, answers, analytics } = data.results;
      
      // Create new PDF document
      const pdf = new jsPDF();
      const pageWidth = pdf.internal.pageSize.getWidth();
      let yPos = 20;
      
      // Add title and header
      pdf.setFontSize(18);
      pdf.setFont('helvetica', 'bold');
      pdf.text('Test Results Report', pageWidth / 2, yPos, { align: 'center' });
      
      yPos += 10;
      pdf.setFontSize(12);
      pdf.setFont('helvetica', 'normal');
      pdf.text(`Generated on: ${format(new Date(), 'PPpp')}`, pageWidth / 2, yPos, { align: 'center' });
      
      // Test details
      yPos += 15;
      pdf.setFont('helvetica', 'bold');
      pdf.text(`Test: ${testDetails?.title || 'N/A'}`, 20, yPos);
      
      yPos += 8;
      pdf.setFont('helvetica', 'normal');
      pdf.text(`Subject: ${testDetails?.subject?.name || 'N/A'} | Duration: ${testDetails?.duration || 'N/A'} mins`, 20, yPos);
      
      // Score summary
      yPos += 15;
      pdf.setFont('helvetica', 'bold');
      pdf.text('Score Summary', 20, yPos);
      
      yPos += 8;
      pdf.setFont('helvetica', 'normal');
      pdf.text(`Score: ${attemptDetails?.score || 0} / ${attemptDetails?.totalMarks || 0}`, 20, yPos);
      
      yPos += 8;
      pdf.text(`Percentage: ${Math.round(attemptDetails?.percentageScore || 0)}%`, 20, yPos);
      
      yPos += 8;
      pdf.text(`Status: ${attemptDetails?.passed ? 'Passed' : 'Failed'}`, 20, yPos);
      
      yPos += 8;
      pdf.text(`Time Spent: ${formatTime(attemptDetails?.timeSpent)}`, 20, yPos);
      
      // Performance summary
      yPos += 15;
      pdf.setFont('helvetica', 'bold');
      pdf.text('Performance Summary', 20, yPos);
      
      yPos += 8;
      pdf.setFont('helvetica', 'normal');
      pdf.text(`Correct Answers: ${analytics?.correctAnswers || 0}`, 20, yPos);
      
      yPos += 8;
      pdf.text(`Incorrect Answers: ${analytics?.incorrectAnswers || 0}`, 20, yPos);
      
      yPos += 8;
      pdf.text(`Class Average: ${analytics?.classAverage || 'N/A'}`, 20, yPos);
      
      // Question breakdown
      yPos += 15;
      pdf.setFont('helvetica', 'bold');
      pdf.text('Question Breakdown', 20, yPos);
      
      // Add each question
      if (Array.isArray(answers) && answers.length > 0) {
        answers.forEach((answer, index) => {
          if (!answer) return;
          
          const questionDetails = answer.questionDetails || {};
          
          // Check if we need a new page
          if (yPos > 250) {
            pdf.addPage();
            yPos = 20;
          }
          
          yPos += 12;
          pdf.setFont('helvetica', 'bold');
          pdf.text(`Question ${index + 1}${questionDetails.difficulty ? ` (${questionDetails.difficulty})` : ''}`, 20, yPos);
          
          yPos += 8;
          pdf.setFont('helvetica', 'normal');
          
          // Question text - handle long text with wrapping
          const questionText = questionDetails.text || 'Question text not available';
          const splitText = pdf.splitTextToSize(questionText, pageWidth - 40);
          pdf.text(splitText, 20, yPos);
          
          yPos += (splitText.length * 7);
          
          // Selected answer and correct answer
          pdf.text(`Your answer: ${answer.selectedOption || 'Not answered'}`, 20, yPos);
          
          yPos += 7;
          pdf.text(`Correct answer: ${questionDetails.correctOption || 'N/A'}`, 20, yPos);
          
          yPos += 7;
          pdf.text(`Result: ${answer.points > 0 ? 'Correct' : 'Incorrect'}`, 20, yPos);
          
          // Explanation if available
          if (questionDetails.explanation) {
            yPos += 7;
            pdf.setFont('helvetica', 'italic');
            pdf.text('Explanation:', 20, yPos);
            
            yPos += 7;
            const splitExplanation = pdf.splitTextToSize(questionDetails.explanation, pageWidth - 40);
            pdf.text(splitExplanation, 20, yPos);
            
            yPos += (splitExplanation.length * 7);
          }
          
          // Add points
          yPos += 7;
          pdf.text(`Points: ${answer.points || 0}`, 20, yPos);
        });
      } else {
        yPos += 10;
        pdf.text('No question data available', 20, yPos);
      }
      
      // Add footer
      pdf.setFontSize(10);
      pdf.text('Generated by Neetly Learning Platform', pageWidth / 2, 285, { align: 'center' });
      
      // Save the PDF
      pdf.save(`Test_Results_${testDetails?.title || testId}_${format(new Date(), 'yyyy-MM-dd')}.pdf`);
    } catch (err) {
      console.error('Error generating PDF:', err);
      alert('Failed to generate PDF report. Please try again.');
    } finally {
      setGeneratingPdf(false);
    }
  };

  useEffect(() => {
    const fetchResults = async () => {
      if (!testId || !user) {
        setError('Missing necessary information (Test ID or User).');
        setLoading(false);
        return;
      }

      try {
        setLoading(true);
        setError(null);
        
        console.log(`Fetching results for test: ${testId} by user: ${user._id}`);
        
        // Make API call to get test results
        const response = await axios.get(
          `${config.API_URL}/api/student/tests/${testId}/results`,
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );
        
        console.log('Test results response:', response.data);
        
        if (response.data.success) {
          setData(response.data);
        } else {
          setError(response.data.message || 'Failed to fetch test results.');
        }
      } catch (err) {
        console.error('Error fetching test results:', err);
        setError(err.response?.data?.message || err.message || 'An unexpected error occurred while fetching results.');
      } finally {
        setLoading(false);
      }
    };

    fetchResults();
  }, [testId, user, token]);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
        <CircularProgress />
        <Typography sx={{ ml: 2 }}>Loading results...</Typography>
      </Box>
    );
  }

  if (error) {
    return (
      <Container maxWidth="md" sx={{ mt: 4 }}>
        <Alert severity="error">{error}</Alert>
        <Button variant="outlined" onClick={() => navigate(-1)} sx={{ mt: 2 }}>
          Go Back
        </Button>
      </Container>
    );
  }

  if (!data || !data.results) {
    return (
      <Container maxWidth="md" sx={{ mt: 4 }}>
        <Alert severity="info">No results data found for this test.</Alert>
        <Button variant="outlined" onClick={() => navigate('/ui-revamp/tests')} sx={{ mt: 2 }}>
          Back to Test List
        </Button>
      </Container>
    );
  }

  const { testDetails, attemptDetails, answers, analytics } = data.results;

  // Helper function to determine option styling
  const getOptionStyle = (optionId, selectedOption, correctOption) => {
    const isSelected = selectedOption === optionId;
    const isCorrect = correctOption === optionId;

    if (isSelected && isCorrect) {
      return { backgroundColor: 'rgba(76, 175, 80, 0.1)', border: '1px solid green', color: 'green', fontWeight: 'bold' }; // Correct & Selected
    } else if (isSelected && !isCorrect) {
      return { backgroundColor: 'rgba(244, 67, 54, 0.1)', border: '1px solid red', color: 'red', fontWeight: 'bold' }; // Incorrect & Selected
    } else if (isCorrect) {
      return { border: '1px solid green', color: 'green' }; // Correct but not selected
    }
    return { border: '1px solid rgba(0, 0, 0, 0.12)' }; // Default
  };

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Button
          startIcon={<ArrowBackIcon />}
          variant="outlined"
          onClick={() => navigate('/ui-revamp/tests')}
        >
          Back to Test List
        </Button>
        
        <Tooltip title="Download a PDF report of your test results">
          <Button
            variant="contained"
            color="primary"
            startIcon={<DownloadIcon />}
            onClick={generatePdfReport}
            disabled={generatingPdf || !data}
          >
            {generatingPdf ? 'Generating PDF...' : 'Download PDF Report'}
          </Button>
        </Tooltip>
      </Box>
      
      <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
        {/* Test Header */}
        <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold' }}>
          {testDetails?.title || 'Test Results'}
        </Typography>
        <Typography variant="subtitle1" sx={{ mb: 3 }}>
          Subject: {testDetails?.subject?.name || 'N/A'} | Duration: {testDetails?.duration || 'N/A'} mins
        </Typography>

        {/* Score Overview */}
        <Grid container spacing={3} sx={{ mb: 4 }}>
          <Grid item xs={12} md={3}>
            <Card variant="outlined">
              <CardContent>
                <Typography variant="h6" gutterBottom>Score</Typography>
                <Typography variant="h4">
                  {attemptDetails?.score !== undefined ? attemptDetails.score : 'N/A'} / 
                  {attemptDetails?.totalMarks !== undefined ? attemptDetails.totalMarks : 'N/A'}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={3}>
            <Card variant="outlined">
              <CardContent>
                <Typography variant="h6" gutterBottom>Percentage</Typography>
                <Typography variant="h4">
                  {attemptDetails?.percentageScore !== undefined ? `${Math.round(attemptDetails.percentageScore)}%` : 'N/A'}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={3}>
            <Card variant="outlined">
              <CardContent>
                <Typography variant="h6" gutterBottom>Status</Typography>
                <Chip 
                  label={attemptDetails?.passed ? "Passed" : "Failed"} 
                  color={attemptDetails?.passed ? "success" : "error"}
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={3}>
            <Card variant="outlined">
              <CardContent>
                <Typography variant="h6" gutterBottom>Time Spent</Typography>
                <Typography variant="h6">
                  {formatTime(attemptDetails?.timeSpent)}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        {/* Analytics Summary with Chart */}
        <Typography variant="h5" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
          <PieChartIcon sx={{ mr: 1 }} /> Performance Summary
        </Typography>
        
        <Grid container spacing={3} sx={{ mb: 4 }}>
          <Grid item xs={12} md={8}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <Card variant="outlined" sx={{ height: '100%' }}>
                  <CardContent>
                    <Typography variant="body1" gutterBottom>Correct Answers</Typography>
                    <Typography variant="h5" color="success.main">{analytics?.correctAnswers || 0}</Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={4}>
                <Card variant="outlined" sx={{ height: '100%' }}>
                  <CardContent>
                    <Typography variant="body1" gutterBottom>Incorrect Answers</Typography>
                    <Typography variant="h5" color="error.main">{analytics?.incorrectAnswers || 0}</Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={4}>
                <Card variant="outlined" sx={{ height: '100%' }}>
                  <CardContent>
                    <Typography variant="body1" gutterBottom>Class Average</Typography>
                    <Typography variant="h5">{analytics?.classAverage || 'N/A'}</Typography>
                  </CardContent>
                </Card>
              </Grid>
              
              {/* Motivational message based on performance */}
              <Grid item xs={12}>
                <Card variant="outlined" sx={{ mt: 2, bgcolor: 'info.light', color: 'info.contrastText' }}>
                  <CardContent>
                    <Typography variant="body1">
                      {attemptDetails?.percentageScore >= 80 ? 
                        '🎉 Excellent work! Keep up the great performance!' :
                        attemptDetails?.percentageScore >= 60 ?
                        '👍 Good job! With a bit more practice, you can excel!' :
                        '💪 Keep practicing to improve your score! You can do it!'}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
          
          {/* Pie Chart */}
          <Grid item xs={12} md={4}>
            <Card variant="outlined" sx={{ height: '100%', minHeight: 250 }}>
              <CardContent>
                <Typography variant="subtitle1" gutterBottom align="center">Correct vs. Incorrect</Typography>
                {analytics && (
                  <ResponsiveContainer width="100%" height={200}>
                    <PieChart>
                      <Pie
                        data={[
                          { name: 'Correct', value: analytics.correctAnswers || 0 },
                          { name: 'Incorrect', value: analytics.incorrectAnswers || 0 }
                        ]}
                        cx="50%"
                        cy="50%"
                        labelLine={false}
                        outerRadius={80}
                        fill="#8884d8"
                        dataKey="value"
                        label={({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
                          const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
                          const x = cx + radius * Math.cos(-midAngle * RADIAN);
                          const y = cy + radius * Math.sin(-midAngle * RADIAN);
                          return (
                            <text 
                              x={x} 
                              y={y} 
                              fill="white" 
                              textAnchor={x > cx ? 'start' : 'end'} 
                              dominantBaseline="central"
                            >
                              {`${(percent * 100).toFixed(0)}%`}
                            </text>
                          );
                        }}
                      >
                        {[
                          { name: 'Correct', value: analytics.correctAnswers || 0 },
                          { name: 'Incorrect', value: analytics.incorrectAnswers || 0 }
                        ].map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                      </Pie>
                      <Legend />
                    </PieChart>
                  </ResponsiveContainer>
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        {/* Question Breakdown */}
        <Typography variant="h5" gutterBottom sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
          <HelpOutline sx={{ mr: 1 }} /> Detailed Breakdown
        </Typography>

        {/* Safely render questions */}
        {(() => {
          if (!Array.isArray(answers) || answers.length === 0) {
            return (
              <Alert severity="info" sx={{ mb: 3 }}>
                No question data available for this test.
              </Alert>
            );
          }
          
          return (
            <Box>
              {answers.map((answer, index) => {
                if (!answer) return null;
                
                const questionDetails = answer.questionDetails || {};
                const options = Array.isArray(questionDetails.options) ? questionDetails.options : [];
                const correctOption = questionDetails.correctOption;
                
                return (
                  <Card key={`question-${index}`} variant="outlined" sx={{ 
                    mb: 3, 
                    borderLeft: answer.points > 0 ? '4px solid #4caf50' : '4px solid #f44336'
                  }}>
                    <CardContent>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                        <Typography variant="h6" component="div" sx={{ fontWeight: 500 }}>
                          Question {index + 1}
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                          {questionDetails.difficulty && (
                            <Chip 
                              label={questionDetails.difficulty} 
                              variant="outlined" 
                              size="small" 
                            />
                          )}
                          <Chip 
                            icon={answer.points > 0 ? <CheckCircleOutline /> : <CancelOutlined />} 
                            label={answer.points > 0 ? "Correct" : "Incorrect"} 
                            color={answer.points > 0 ? "success" : "error"} 
                            size="small" 
                          />
                        </Box>
                      </Box>
                      
                      <Typography variant="body1" sx={{ mb: 3, mt: 2 }}>
                        {questionDetails.text || "Question text not available"}
                      </Typography>
                      
                      {options.length > 0 ? (
                        <List sx={{ mb: 2, bgcolor: 'background.paper', borderRadius: 1 }}>
                          {options.map((option) => {
                            const isSelected = answer.selectedOption === option.id;
                            const isCorrect = correctOption === option.id;
                            
                            let backgroundColor = 'transparent';
                            let borderColor = 'rgba(0, 0, 0, 0.12)';
                            let textColor = 'text.primary';
                            let icon = null;
                            
                            if (isSelected && isCorrect) {
                              backgroundColor = 'rgba(76, 175, 80, 0.1)';
                              borderColor = '#4caf50';
                              icon = <CheckCircleOutline color="success" />;
                            } else if (isSelected && !isCorrect) {
                              backgroundColor = 'rgba(244, 67, 54, 0.1)';
                              borderColor = '#f44336';
                              icon = <CancelOutlined color="error" />;
                            } else if (isCorrect) {
                              borderColor = '#4caf50';
                              textColor = 'success.main';
                            }
                            
                            return (
                              <ListItem 
                                key={option.id} 
                                sx={{ 
                                  mb: 1, 
                                  borderRadius: 1,
                                  border: `1px solid ${borderColor}`,
                                  backgroundColor,
                                }}
                              >
                                <ListItemIcon sx={{ minWidth: 30 }}>
                                  {icon}
                                </ListItemIcon>
                                <ListItemText 
                                  primary={`${option.id}. ${option.text || 'Option text not available'}`} 
                                  sx={{ color: textColor }}
                                />
                              </ListItem>
                            );
                          })}
                        </List>
                      ) : (
                        <Alert severity="info" sx={{ mb: 2 }}>No options available for this question.</Alert>
                      )}

                      {questionDetails.explanation && (
                        <Box sx={{ mt: 2, p: 2, bgcolor: 'background.paper', borderRadius: 1, border: '1px dashed rgba(0, 0, 0, 0.12)' }}>
                          <Typography variant="subtitle2" sx={{ fontWeight: 'bold', mb: 1 }}>Explanation:</Typography>
                          <Typography variant="body2" color="text.secondary">
                            {questionDetails.explanation}
                          </Typography>
                        </Box>
                      )}
                      
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2, pt: 1, borderTop: '1px solid rgba(0, 0, 0, 0.12)' }}>
                        <Typography variant="subtitle2">
                          Points: {answer.points || 0}
                        </Typography>
                        {answer.timeTaken !== undefined && (
                          <Typography variant="subtitle2" color="text.secondary">
                            Time taken: {formatTime(answer.timeTaken)}
                          </Typography>
                        )}
                      </Box>
                    </CardContent>
                  </Card>
                );
              })}
            </Box>
          );
        })()}
      </Paper>
    </Container>
  );
};

export default TestResultsPage;
