// // import React, { useState, useEffect } from "react";
// // import {
// //   Grid,
// //   Typography,
// //   Box,
// //   Button,
// //   CircularProgress,
// //   Collapse,
// //   Card,
// //   CardContent,
// //   Chip,
// //   Avatar,
// //   Skeleton,
// //   AppBar,
// //   Toolbar,
// //   IconButton,
// //   Drawer,
// // } from "@mui/material";
// // import MenuIcon from "@mui/icons-material/Menu";
// // import { styled } from "@mui/system";
// // import { useNavigate } from "react-router-dom";
// // import HospitalIcon from "@mui/icons-material/LocalHospital";
// // import { Link } from "react-router-dom";
// // import { SignedIn, SignedOut, SignInButton, UserButton } from "@clerk/clerk-react";

// // // Styling for the SubjectCard
// // const SubjectCard = styled(Card)(({ theme }) => ({
// //   backgroundColor: "#ffffff",
// //   borderRadius: "10px",
// //   transition: "0.3s",
// //   boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
// //   border: "1px solid #e0e0e0",
// //   "&:hover": {
// //     boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.15)",
// //   },
// // }));

// // // RootLayout used in the TopBar
// // const RootLayout = () => {
// //   return (
// //     <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
// //       <Link to="/" style={{ display: "flex", alignItems: "center", textDecoration: "none", color: "inherit" }}>
// //         <HospitalIcon sx={{ mr: 1 }} />
// //         <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
// //           Neetly AI
// //         </Typography>
// //       </Link>
// //       <Box sx={{ flexGrow: 1 }} />
// //       <Link
// //         to="/dashboard"
// //         style={{
// //           textDecoration: "none",
// //           display: "inline-block",
// //         }}
// //       >
// //         <Button
// //           variant="contained"
// //           sx={{
// //             background: "linear-gradient(135deg, #ffffff, #f0f0f0)",
// //             color: "#333",
// //             fontWeight: "bold",
// //             borderRadius: "8px",
// //             padding: { xs: "6px 12px", sm: "8px 16px" },
// //             boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
// //             "&:hover": {
// //               background: "linear-gradient(135deg, #f0f0f0, #e0e0e0)",
// //               boxShadow: "0 6px 12px rgba(0, 0, 0, 0.3)",
// //             },
// //             textTransform: "none",
// //           }}
// //         >
// //           Ask MCQ's
// //         </Button>
// //       </Link>
// //       <Box sx={{ flexGrow: 1 }} />
// //       <SignedOut>
// //         <SignInButton />
// //       </SignedOut>
// //       <SignedIn>
// //         <UserButton />
// //       </SignedIn>
// //     </Box>
// //   );
// // };

// // // TopBar component
// // const TopBar = ({ isMobile, onToggleDrawer }) => (
// //   <AppBar position="static">
// //     <Toolbar>
// //       {isMobile && (
// //         <IconButton
// //           color="inherit"
// //           aria-label="open drawer"
// //           edge="start"
// //           onClick={onToggleDrawer}
// //           sx={{ mr: 2 }}
// //         >
// //           <MenuIcon />
// //         </IconButton>
// //       )}
// //       <RootLayout />
// //     </Toolbar>
// //   </AppBar>
// // );

// // const Dashboard = () => {
// //   const [expanded, setExpanded] = useState(null); // Track the currently expanded subject
// //   const [activityDrawerOpen, setActivityDrawerOpen] = useState(false);
// //   const [subjects, setSubjects] = useState([]);
// //   const [loading, setLoading] = useState(true);
// //   const [activityLoading, setActivityLoading] = useState(true);
// //   const [activityFeed, setActivityFeed] = useState(null);
// //   const [aiLoading, setAiLoading] = useState(false);
// //   const [aiSummary, setAiSummary] = useState("");
// //   const [currentSubject, setCurrentSubject] = useState(null);
// //   const [currentTopic, setCurrentTopic] = useState(null);
// //   const [currentSet, setCurrentSet] = useState(null);

// //   const navigate = useNavigate();

// //   useEffect(() => {
// //     // Mock API calls to simulate fetching subjects and activity feed
// //     setTimeout(() => {
// //       const mockSubjects = [
// //         {
// //           subject: "Biology",
// //           topics: [
// //             {
// //               name: "Genetics",
// //               mcqSets: [
// //                 { setName: "Set 1", solvedQuestions: 5, totalQuestions: 10, successRate: 50, canReattempt: true },
// //                 { setName: "Set 2", solvedQuestions: 8, totalQuestions: 10, successRate: 80, canReattempt: true },
// //               ],
// //             },
// //           ],
// //         },
// //         {
// //           subject: "Chemistry",
// //           topics: [
// //             {
// //               name: "Organic Chemistry",
// //               mcqSets: [
// //                 { setName: "Set 1", solvedQuestions: 7, totalQuestions: 10, successRate: 70, canReattempt: true },
// //               ],
// //             },
// //           ],
// //         },
// //       ];
// //       setSubjects(mockSubjects);
// //       setExpanded(mockSubjects[0].subject); // Set the first subject as expanded initially
// //       setLoading(false);
// //     }, 1500);

// //     setTimeout(() => {
// //       const mockActivityFeed = {
// //         "2024-10-01": [{ activity: "Completed Genetics Set 1" }],
// //         "2024-10-03": [{ activity: "Completed Organic Chemistry Set 1" }],
// //       };
// //       setActivityFeed(mockActivityFeed);
// //       setActivityLoading(false);
// //     }, 1500);
// //   }, []);

// //   const handleAccordionChange = (subject) => {
// //     setExpanded((prev) => (prev === subject ? null : subject));
// //   };

// //   const handleReattempt = (subject, topic, setName) => {
// //     navigate(`/reattempt/${subject}/${topic}/${setName}`);
// //   };

// //   const handleAiSuggestion = (subject, topic, setName) => {
// //     // Reset previous AI suggestion before fetching a new one
// //     setAiSummary("");
// //     setAiLoading(true);
// //     setCurrentSubject(subject);
// //     setCurrentTopic(topic);
// //     setCurrentSet(setName);

// //     // Mock API call to simulate AI summary generation
// //     setTimeout(() => {
// //       const summary = `AI Summary for ${topic} - ${setName}: This set focuses on understanding core concepts. Aim to improve accuracy.`;
// //       setAiSummary(summary);
// //       setAiLoading(false);
// //     }, 1500);
// //   };

// //   const isMobile = window.innerWidth <= 600;

// //   return (
// //     <>
// //       {/* Adding the TopBar component at the top */}
// //       <Box sx={{ position: "fixed", top: 0, left: 0, right: 0, zIndex: (theme) => theme.zIndex.drawer + 1 }}>
// //         <TopBar isMobile={isMobile} onToggleDrawer={() => setActivityDrawerOpen(true)} />
// //       </Box>
// //       <Box sx={{ height: 80 }} /> {/* Spacing to account for the AppBar height */}

// //       <Grid container spacing={3}>
// //         {/* Left: Subject Wise List */}
// //         <Grid item xs={12} md={8}>
// //           <Typography variant="h5" gutterBottom sx={{ color: "#1976d2", fontWeight: "600" }}>
// //             Subjects & Topics
// //           </Typography>
// //           {loading ? (
// //             Array.from(new Array(2)).map((_, index) => (
// //               <Box key={index} mb={2}>
// //                 <Skeleton variant="rectangular" height={50} />
// //                 <Skeleton variant="rectangular" height={100} sx={{ mt: 1 }} />
// //               </Box>
// //             ))
// //           ) : (
// //             subjects.map((subject, index) => (
// //               <SubjectCard key={subject.subject} sx={{ mb: 2 }}>
// //                 <CardContent onClick={() => handleAccordionChange(subject.subject)} style={{ cursor: "pointer" }}>
// //                   <Box display="flex" alignItems="center" justifyContent="space-between">
// //                     <Typography variant="h6" style={{ fontWeight: "600" }}>
// //                       {subject.subject}
// //                     </Typography>
// //                     <Chip
// //                       label={`${subject.topics.length} topics`}
// //                       color="primary"
// //                       avatar={<Avatar>{subject.topics.length}</Avatar>}
// //                     />
// //                   </Box>
// //                 </CardContent>
// //                 <Collapse in={expanded === subject.subject} timeout="auto" unmountOnExit>
// //                   <Box mt={2}>
// //                     {subject.topics.map((topic, idx) => (
// //                       <Box key={`${subject.subject}-${idx}`} mb={2} p={2} bgcolor="#fafafa" borderRadius={4}>
// //                         <Typography variant="body1" style={{ fontWeight: "600" }}>
// //                           {topic.name}
// //                         </Typography>
// //                         {topic.mcqSets.map((mcqSet, setIdx) => {
// //                           const progress = Math.round((mcqSet.solvedQuestions / mcqSet.totalQuestions) * 100);
// //                           const knowledgeLevel = progress < 40 ? "Low" : progress < 70 ? "Moderate" : "High";

// //                           return (
// //                             <Box key={`${topic.name}-${setIdx}`} mt={2} p={2} bgcolor="#fff" borderRadius={4}>
// //                               <Grid container alignItems="center">
// //                                 <Grid item xs={6}>
// //                                   <Typography variant="body1" style={{ fontWeight: 600 }}>
// //                                     {mcqSet.setName}
// //                                   </Typography>
// //                                   <Typography variant="body2" color="textSecondary">
// //                                     {mcqSet.solvedQuestions}/{mcqSet.totalQuestions} questions solved
// //                                   </Typography>
// //                                   <Typography variant="body2" color="textSecondary">
// //                                     Success Rate: {mcqSet.successRate}%
// //                                   </Typography>
// //                                 </Grid>
// //                                 <Grid item xs={3}>
// //                                   <CircularProgress variant="determinate" value={progress} size={50} />
// //                                   <Typography variant="body2" color="textSecondary">
// //                                     {progress}% ({knowledgeLevel})
// //                                   </Typography>
// //                                 </Grid>
// //                                 <Grid item xs={3} textAlign="center">
// //                                   <Button
// //                                     onClick={() => handleAiSuggestion(subject.subject, topic.name, mcqSet.setName)}
// //                                     disabled={
// //                                       aiLoading &&
// //                                       currentSubject === subject.subject &&
// //                                       currentTopic === topic.name &&
// //                                       currentSet === mcqSet.setName
// //                                     }
// //                                     sx={{ mt: 1 }}
// //                                   >
// //                                     {aiLoading &&
// //                                     currentSubject === subject.subject &&
// //                                     currentTopic === topic.name &&
// //                                     currentSet === mcqSet.setName
// //                                       ? "Loading..."
// //                                       : "AI Suggestion"}
// //                                   </Button>
// //                                   {mcqSet.canReattempt && (
// //                                     <Button
// //                                       variant="contained"
// //                                       sx={{
// //                                         mt: 1,
// //                                         ml: 1,
// //                                         backgroundColor: "#1976d2",
// //                                         color: "#fff",
// //                                       }}
// //                                       onClick={() => handleReattempt(subject.subject, topic.name, mcqSet.setName)}
// //                                     >
// //                                       Reattempt
// //                                     </Button>
// //                                   )}
// //                                 </Grid>
// //                               </Grid>
// //                               {aiSummary &&
// //                                 currentSubject === subject.subject &&
// //                                 currentTopic === topic.name &&
// //                                 currentSet === mcqSet.setName && (
// //                                   <Box mt={2} p={2} bgcolor="#f0f0f0" borderRadius={4}>
// //                                     <Typography variant="body2" color="textSecondary">
// //                                       {aiSummary}
// //                                     </Typography>
// //                                   </Box>
// //                                 )}
// //                             </Box>
// //                           );
// //                         })}
// //                       </Box>
// //                     ))}
// //                   </Box>
// //                 </Collapse>
// //               </SubjectCard>
// //             ))
// //           )}
// //         </Grid>

// //         {/* Right: Activity Feed */}
// //         <Grid item xs={12} md={4}>
// //           {!isMobile ? (
// //             <>
// //               <Typography variant="h5" gutterBottom sx={{ color: "#1976d2", fontWeight: "600" }}>
// //                 Activity Feed (Last 7 Days)
// //               </Typography>
// //               {activityLoading ? (
// //                 Array.from(new Array(3)).map((_, index) => (
// //                   <Box key={index} mb={2}>
// //                     <Skeleton variant="rectangular" height={80} />
// //                   </Box>
// //                 ))
// //               ) : (
// //                 <Box>
// //                   {Object.keys(activityFeed).length === 0 ? (
// //                     <Box mb={2} p={2} bgcolor="#f0f0f0" borderRadius={4}>
// //                       <Typography variant="body1" color="textSecondary">
// //                         No recent activity available. Start practicing to see your progress!
// //                       </Typography>
// //                     </Box>
// //                   ) : (
// //                     Object.keys(activityFeed).map((date, idx) => (
// //                       <Box key={date} mb={2} p={2} bgcolor="#f0f0f0" borderRadius={4}>
// //                         <Typography variant="body1" style={{ fontWeight: 600 }}>
// //                           {date}
// //                         </Typography>
// //                         {activityFeed[date].map((activity, actIdx) => (
// //                           <Typography key={`${date}-${actIdx}`} variant="body2" color="textSecondary">
// //                             - {activity.activity}
// //                           </Typography>
// //                         ))}
// //                       </Box>
// //                     ))
// //                   )}
// //                 </Box>
// //               )}
// //             </>
// //           ) : (
// //             <Drawer anchor="right" open={activityDrawerOpen} onClose={() => setActivityDrawerOpen(false)}>
// //               <Box sx={{ width: 300, p: 2 }}>
// //                 <Typography variant="h5" gutterBottom sx={{ fontWeight: "bold" }}>
// //                   Activity Feed (Last 7 Days)
// //                 </Typography>
// //                 {activityLoading ? (
// //                   Array.from(new Array(3)).map((_, index) => (
// //                     <Box key={index} mb={2}>
// //                       <Skeleton variant="rectangular" height={80} />
// //                     </Box>
// //                   ))
// //                 ) : (
// //                   <Box>
// //                     {Object.keys(activityFeed).length === 0 ? (
// //                       <Box mb={2} p={2} bgcolor="#f0f0f0" borderRadius={4}>
// //                         <Typography variant="body1" color="textSecondary">
// //                           No recent activity available. Start practicing to see your progress!
// //                         </Typography>
// //                       </Box>
// //                     ) : (
// //                       Object.keys(activityFeed).map((date, idx) => (
// //                         <Box key={date} mb={2} p={2} bgcolor="#f0f0f0" borderRadius={4}>
// //                           <Typography variant="body1" style={{ fontWeight: 600 }}>
// //                             {date}
// //                           </Typography>
// //                           {activityFeed[date].map((activity, actIdx) => (
// //                             <Typography key={`${date}-${actIdx}`} variant="body2" color="textSecondary">
// //                               - {activity.activity}
// //                             </Typography>
// //                           ))}
// //                         </Box>
// //                       ))
// //                     )}
// //                   </Box>
// //                 )}
// //               </Box>
// //             </Drawer>
// //           )}
// //         </Grid>
// //       </Grid>
// //     </>
// //   );
// // };

// // export default Dashboard;

// import React, { useState, useEffect } from "react";
// import {
//   Grid,
//   Typography,
//   Box,
//   Button,
//   CircularProgress,
//   Collapse,
//   Card,
//   CardContent,
//   Chip,
//   Avatar,
//   Skeleton,
//   AppBar,
//   Toolbar,
//   IconButton,
//   Drawer,
// } from "@mui/material";
// import MenuIcon from "@mui/icons-material/Menu";
// import { styled } from "@mui/system";
// import { useNavigate } from "react-router-dom";
// import HospitalIcon from "@mui/icons-material/LocalHospital";
// import { Link } from "react-router-dom";
// import { SignedIn, SignedOut, SignInButton, UserButton } from "@clerk/clerk-react";
// import ActivityFeed from "./ActivityFeed";  // Importing the new ActivityFeed component

// // Styling for the SubjectCard
// const SubjectCard = styled(Card)(({ theme }) => ({
//   backgroundColor: "#ffffff",
//   borderRadius: "10px",
//   transition: "0.3s",
//   boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
//   border: "1px solid #e0e0e0",
//   "&:hover": {
//     boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.15)",
//   },
// }));

// // RootLayout used in the TopBar
// const RootLayout = () => {
//   return (
//     <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
//       <Link to="/" style={{ display: "flex", alignItems: "center", textDecoration: "none", color: "inherit" }}>
//         <HospitalIcon sx={{ mr: 1 }} />
//         <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
//           Neetly AI
//         </Typography>
//       </Link>
//       <Box sx={{ flexGrow: 1 }} />
//       <Link
//         to="/dashboard"
//         style={{
//           textDecoration: "none",
//           display: "inline-block",
//         }}
//       >
//         <Button
//           variant="contained"
//           sx={{
//             background: "linear-gradient(135deg, #ffffff, #f0f0f0)",
//             color: "#333",
//             fontWeight: "bold",
//             borderRadius: "8px",
//             padding: { xs: "6px 12px", sm: "8px 16px" },
//             boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
//             "&:hover": {
//               background: "linear-gradient(135deg, #f0f0f0, #e0e0e0)",
//               boxShadow: "0 6px 12px rgba(0, 0, 0, 0.3)",
//             },
//             textTransform: "none",
//           }}
//         >
//           Ask MCQ's
//         </Button>
//       </Link>
//       <Box sx={{ flexGrow: 1 }} />
//       <SignedOut>
//         <SignInButton />
//       </SignedOut>
//       <SignedIn>
//         <UserButton />
//       </SignedIn>
//     </Box>
//   );
// };

// // TopBar component
// const TopBar = ({ isMobile, onToggleDrawer }) => (
//   <AppBar position="static">
//     <Toolbar>
//       {isMobile && (
//         <IconButton
//           color="inherit"
//           aria-label="open drawer"
//           edge="start"
//           onClick={onToggleDrawer}
//           sx={{ mr: 2 }}
//         >
//           <MenuIcon />
//         </IconButton>
//       )}
//       <RootLayout />
//     </Toolbar>
//   </AppBar>
// );

// const Dashboard = () => {
//   const [expanded, setExpanded] = useState(null); // Track the currently expanded subject
//   const [activityDrawerOpen, setActivityDrawerOpen] = useState(false);
//   const [subjects, setSubjects] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [aiLoading, setAiLoading] = useState(false);
//   const [aiSummary, setAiSummary] = useState("");
//   const [currentSubject, setCurrentSubject] = useState(null);
//   const [currentTopic, setCurrentTopic] = useState(null);
//   const [currentSet, setCurrentSet] = useState(null);
//   const [activityFeed, setActivityFeed] = useState({});
//   const [activityLoading, setActivityLoading] = useState(true);

//   const navigate = useNavigate();

//   useEffect(() => {
//     // Mock API calls to simulate fetching subjects and activity feed
//     setTimeout(() => {
//       const mockSubjects = [
//         {
//           subject: "Biology",
//           topics: [
//             {
//               name: "Genetics",
//               mcqSets: [
//                 { setName: "Set 1", solvedQuestions: 5, totalQuestions: 10, successRate: 50, canReattempt: true },
//                 { setName: "Set 2", solvedQuestions: 8, totalQuestions: 10, successRate: 80, canReattempt: true },
//               ],
//             },
//             {
//               name: "Microscience",
//               mcqSets: [
//                 { setName: "Set 1", solvedQuestions: 5, totalQuestions: 10, successRate: 50, canReattempt: true },
//                 { setName: "Set 2", solvedQuestions: 8, totalQuestions: 10, successRate: 80, canReattempt: true },
//               ],
//             },
//           ],
//         },
//         {
//           subject: "Chemistry",
//           topics: [
//             {
//               name: "Organic Chemistry",
//               mcqSets: [
//                 { setName: "Set 1", solvedQuestions: 7, totalQuestions: 10, successRate: 70, canReattempt: true },
//               ],
//             },
//           ],
//         },
//       ];
//       setSubjects(mockSubjects);
//       setExpanded(mockSubjects[0].subject); // Set the first subject as expanded initially
//       setLoading(false);
//     }, 1500);

//     setTimeout(() => {
//       const mockActivityFeed = {
//         "2024-10-01": [{ activity: "Completed Genetics Set 1" }],
//         "2024-10-03": [{ activity: "Completed Organic Chemistry Set 1" }],
//       };
//       setActivityFeed(mockActivityFeed);
//       setActivityLoading(false);
//     }, 1500);
//   }, []);

//   const handleAccordionChange = (subject) => {
//     setExpanded((prev) => (prev === subject ? null : subject));
//   };

//   const handleReattempt = (subject, topic, setName) => {
//     navigate(`/reattempt/${subject}/${topic}/${setName}`);
//   };

//   const handleAiSuggestion = (subject, topic, setName) => {
//     // Reset previous AI suggestion before fetching a new one
//     setAiSummary("");
//     setAiLoading(true);
//     setCurrentSubject(subject);
//     setCurrentTopic(topic);
//     setCurrentSet(setName);

//     // Mock API call to simulate AI summary generation
//     setTimeout(() => {
//       const summary = `AI Summary for ${topic} - ${setName}: This set focuses on understanding core concepts. Aim to improve accuracy.`;
//       setAiSummary(summary);
//       setAiLoading(false);
//     }, 1500);
//   };

//   const isMobile = window.innerWidth <= 600;

//   return (
//     <>
//       {/* Adding the TopBar component at the top */}
//       <Box sx={{ position: "fixed", top: 0, left: 0, right: 0, zIndex: (theme) => theme.zIndex.drawer + 1 }}>
//         <TopBar isMobile={isMobile} onToggleDrawer={() => setActivityDrawerOpen(true)} />
//       </Box>
//       <Box sx={{ height: 80 }} /> {/* Spacing to account for the AppBar height */}

//       <Grid container spacing={3}>
//         {/* Left: Subject Wise List */}
//         <Grid item xs={12} md={8} sx={{ overflowY: 'auto', maxHeight: '80vh' }}>
//           <Typography variant="h5" gutterBottom sx={{ color: "#1976d2", fontWeight: "600" }}>
//           </Typography>
//           {loading ? (
//             Array.from(new Array(2)).map((_, index) => (
//               <Box key={index} mb={2}>
//                 <Skeleton variant="rectangular" height={50} />
//                 <Skeleton variant="rectangular" height={100} sx={{ mt: 1 }} />
//               </Box>
//             ))
//           ) : (
//             subjects.map((subject, index) => (
//               <SubjectCard key={subject.subject} sx={{ mb: 2 }}>
//                 <CardContent onClick={() => handleAccordionChange(subject.subject)} style={{ cursor: "pointer" }}>
//                   <Box display="flex" alignItems="center" justifyContent="space-between">
//                     <Typography variant="h6" style={{ fontWeight: "600" }}>
//                       {subject.subject}
//                     </Typography>
//                     <Chip
//                       label={`${subject.topics.length} topics`}
//                       color="primary"
//                       avatar={<Avatar>{subject.topics.length}</Avatar>}
//                     />
//                   </Box>
//                 </CardContent>
//                 <Collapse in={expanded === subject.subject} timeout="auto" unmountOnExit>
//                   <Box mt={2}>
//                     {subject.topics.map((topic, idx) => (
//                       <Box key={`${subject.subject}-${idx}`} mb={2} p={2} bgcolor="#fafafa" borderRadius={4}>
//                         <Typography variant="body1" style={{ fontWeight: "600" }}>
//                           {topic.name}
//                         </Typography>
//                         {topic.mcqSets.map((mcqSet, setIdx) => {
//                           const progress = Math.round((mcqSet.solvedQuestions / mcqSet.totalQuestions) * 100);
//                           const knowledgeLevel = progress < 40 ? "Low" : progress < 70 ? "Moderate" : "High";

//                           return (
//                             <Box key={`${topic.name}-${setIdx}`} mt={2} p={2} bgcolor="#fff" borderRadius={4}>
//                               <Grid container alignItems="center">
//                                 <Grid item xs={6}>
//                                   <Typography variant="body1" style={{ fontWeight: 600 }}>
//                                     {mcqSet.setName}
//                                   </Typography>
//                                   <Typography variant="body2" color="textSecondary">
//                                     {mcqSet.solvedQuestions}/{mcqSet.totalQuestions} questions solved
//                                   </Typography>
//                                   <Typography variant="body2" color="textSecondary">
//                                     Success Rate: {mcqSet.successRate}%
//                                   </Typography>
//                                 </Grid>
//                                 <Grid item xs={3}>
//                                   <CircularProgress variant="determinate" value={progress} size={50} />
//                                   <Typography variant="body2" color="textSecondary">
//                                     {progress}% ({knowledgeLevel})
//                                   </Typography>
//                                 </Grid>
//                                 <Grid item xs={3} textAlign="center">
//                                   <Button
//                                     onClick={() => handleAiSuggestion(subject.subject, topic.name, mcqSet.setName)}
//                                     disabled={
//                                       aiLoading &&
//                                       currentSubject === subject.subject &&
//                                       currentTopic === topic.name &&
//                                       currentSet === mcqSet.setName
//                                     }
//                                     sx={{ mt: 1 }}
//                                   >
//                                     {aiLoading &&
//                                     currentSubject === subject.subject &&
//                                     currentTopic === topic.name &&
//                                     currentSet === mcqSet.setName
//                                       ? "Loading..."
//                                       : "AI Suggestion"}
//                                   </Button>
//                                   {mcqSet.canReattempt && (
//                                     <Button
//                                       variant="contained"
//                                       sx={{
//                                         mt: 1,
//                                         ml: 1,
//                                         backgroundColor: "#1976d2",
//                                         color: "#fff",
//                                       }}
//                                       onClick={() => handleReattempt(subject.subject, topic.name, mcqSet.setName)}
//                                     >
//                                       Reattempt
//                                     </Button>
//                                   )}
//                                 </Grid>
//                               </Grid>
//                               {aiSummary &&
//                                 currentSubject === subject.subject &&
//                                 currentTopic === topic.name &&
//                                 currentSet === mcqSet.setName && (
//                                   <Box mt={2} p={2} bgcolor="#f0f0f0" borderRadius={4}>
//                                     <Typography variant="body2" color="textSecondary">
//                                       {aiSummary}
//                                     </Typography>
//                                   </Box>
//                                 )}
//                             </Box>
//                           );
//                         })}
//                       </Box>
//                     ))}
//                   </Box>
//                 </Collapse>
//               </SubjectCard>
//             ))
//           )}
//         </Grid>

//         {/* Right: Activity Feed */}
//         <Grid item xs={12} md={4} sx={{ overflowY: 'auto', maxHeight: '80vh' }}>
//           {!isMobile ? (
//             <ActivityFeed activityFeed={activityFeed} activityLoading={activityLoading} />
//           ) : (
//             <Drawer anchor="right" open={activityDrawerOpen} onClose={() => setActivityDrawerOpen(false)}>
//               <Box sx={{ width: 300, p: 2 }}>
//                 <ActivityFeed activityFeed={activityFeed} activityLoading={activityLoading} />
//               </Box>
//             </Drawer>
//           )}
//         </Grid>
//       </Grid>
//     </>
//   );
// };

// export default Dashboard;
import React, { useState, useEffect } from "react";
import axios from "axios"; // Import axios for API calls
import {
  Grid,
  Typography,
  Box,
  Button,
  CircularProgress,
  Collapse,
  Card,
  CardContent,
  Chip,
  Avatar,
  Skeleton,
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { styled } from "@mui/system";
import { useNavigate } from "react-router-dom";
import HospitalIcon from "@mui/icons-material/LocalHospital";
import { Link } from "react-router-dom";
import { useAuth } from "../../services/authContext";
import ReactMarkdown from 'react-markdown';
import ActivityFeed from "./ActivityFeed"; 
import rehypeSanitize from 'rehype-sanitize';
 // Importing the new ActivityFeed component
import config from "../../config";

// Styling for the SubjectCard
const SubjectCard = styled(Card)(({ theme }) => ({
  backgroundColor: "#ffffff",
  borderRadius: "10px",
  transition: "0.3s",
  boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
  border: "1px solid #e0e0e0",
  "&:hover": {
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.15)",
  },
}));

// RootLayout used in the TopBar
const RootLayout = () => {
  const { isSignedIn, signOut } = useAuth();
  return (
    <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
      <Link to="/" style={{ display: "flex", alignItems: "center", textDecoration: "none", color: "inherit" }}>
        <HospitalIcon sx={{ mr: 1 }} />
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          Neetly AI
        </Typography>
      </Link>
      <Box sx={{ flexGrow: 1 }} />
      <Link
        to="/dashboard"
        style={{
          textDecoration: "none",
          display: "inline-block",
        }}
      >
        <Button
          variant="contained"
          sx={{
            background: "linear-gradient(135deg, #ffffff, #f0f0f0)",
            color: "#333",
            fontWeight: "bold",
            borderRadius: "8px",
            padding: { xs: "6px 12px", sm: "8px 16px" },
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
            "&:hover": {
              background: "linear-gradient(135deg, #f0f0f0, #e0e0e0)",
              boxShadow: "0 6px 12px rgba(0, 0, 0, 0.3)",
            },
            textTransform: "none",
          }}
        >
          Ask MCQ's
        </Button>
      </Link>
      <Box sx={{ flexGrow: 1 }} />
      {!isSignedIn ? (
        <Link to="/sign-in" style={{ textDecoration: 'none' }}>
          <Button variant="contained" color="primary">
            Sign In
          </Button>
        </Link>
      ) : (
        <Button variant="contained" color="primary" onClick={signOut}>
          Sign Out
        </Button>
      )}
    </Box>
  );
};

// TopBar component
const TopBar = ({ isMobile, onToggleDrawer }) => (
  <AppBar position="static">
    <Toolbar>
      {isMobile && (
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={onToggleDrawer}
          sx={{ mr: 2 }}
        >
          <MenuIcon />
        </IconButton>
      )}
      <RootLayout />
    </Toolbar>
  </AppBar>
);

const Dashboard = () => {
  const [expanded, setExpanded] = useState(null); // Track the currently expanded subject
  const [activityDrawerOpen, setActivityDrawerOpen] = useState(false);
  const [subjects, setSubjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activityFeed, setActivityFeed] = useState({});
  const [activityLoading, setActivityLoading] = useState(true);
  const [aiStates, setAiStates] = useState({});


  const navigate = useNavigate();
  const { getToken } = useAuth();

  useEffect(() => {
    // API call to fetch subjects metadata
    const fetchSubjects = async () => {
      try {
        const token = await getToken();
        const response = await axios.get(`${config.API_URL}/api/mcq/mcq-sets/meta`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setSubjects(response.data);
        setExpanded(response.data[0].subject); // Set the first subject as expanded initially        setSubjects(response.data);
      } catch (error) {
        console.error('Error fetching MCQ sets:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchSubjects();

    // Mock activity feed to simulate fetching activity data
    setTimeout(() => {
      const mockActivityFeed = {
        "2024-10-01": [{ activity: "Completed Genetics Set 1" }],
        "2024-10-03": [{ activity: "Completed Organic Chemistry Set 1" }],
      };
      setActivityFeed(mockActivityFeed);
      setActivityLoading(false);
    }, 1500);
  }, []);

  const handleAccordionChange = (subject) => {
    setExpanded((prev) => (prev === subject ? null : subject));
  };

  const handleReattempt = (subject, topic, uuid) => {
    navigate(`/explore/${uuid}`);
  };

  const handleAiSuggestion = async (subject, topic, setName, uuid) => {
    // Reset previous AI suggestion before fetching a new one
    setAiStates(prev => ({
      ...prev,
      [uuid]: { aiLoading: true, aiSummary: "" }
    }));
  
    try {
      // Get the authentication token
      const token = await getToken(); // Ensure you have a getToken function or adjust accordingly
  
      // Make the API call to fetch AI suggestions
      const response = await fetch(`${config.API_URL}/api/mcq/getAIsuggestion/${uuid}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
  
      if (!response.ok) {
        throw new Error('Failed to fetch AI suggestions');
      }
  
      const data = await response.json();
  
      // Extract the suggestions from the response
  
      // Combine suggestions into a summary
      const summary = data.suggestion;
  
      // Update the state with the fetched AI summary
      setAiStates(prev => ({
        ...prev,
        [uuid]: { aiLoading: false, aiSummary: summary }
      }));
    } catch (error) {
      console.error('Error fetching AI suggestions:', error);
      // Update the state to reflect the error
      setAiStates(prev => ({
        ...prev,
        [uuid]: { aiLoading: false, aiSummary: 'Error fetching AI suggestions. Please try again later.' }
      }));
    }
  };
  

  const isMobile = window.innerWidth <= 600;

  return (
    <>
      {/* Adding the TopBar component at the top */}
      <Box sx={{ position: "fixed", top: 0, left: 0, right: 0, zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <TopBar isMobile={isMobile} onToggleDrawer={() => setActivityDrawerOpen(true)} />
      </Box>
      <Box sx={{ height: 80 }} /> {/* Spacing to account for the AppBar height */}

      <Grid container spacing={3}>
        {/* Left: Subject Wise List */}
        <Grid item xs={12} md={8} sx={{ overflowY: 'auto', maxHeight: '80vh' }}>
         
          {loading ? (
            Array.from(new Array(2)).map((_, index) => (
              <Box key={index} mb={2}>
                <Skeleton variant="rectangular" height={50} />
                <Skeleton variant="rectangular" height={100} sx={{ mt: 1 }} />
              </Box>
            ))
          ) : (
            subjects.map((subject, index) => (
              <SubjectCard key={subject.subject} sx={{ mb: 2 }}>
                <CardContent onClick={() => handleAccordionChange(subject.subject)} style={{ cursor: "pointer" }}>
                  <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Typography variant="h6" style={{ fontWeight: "600" }}>
                      {subject.subject}
                    </Typography>
                    <Chip
                      label={`${subject.topics.length} topics`}
                      color="primary"
                      avatar={<Avatar>{subject.topics.length}</Avatar>}
                    />
                  </Box>
                </CardContent>
                <Collapse in={expanded === subject.subject} timeout="auto" unmountOnExit>
                  <Box mt={2}>
                    {subject.topics.map((topic, idx) => (
                      <Box key={`${subject.subject}-${idx}`} mb={2} p={2} bgcolor="#fafafa" borderRadius={4}>
                        <Typography variant="body1" style={{ fontWeight: "600" }}>
                          {topic.name}
                        </Typography>
                        {topic.mcqSets.map((mcqSet, setIdx) => {
                          const knowledgeLevel = mcqSet.successRate < 50 ? "Low" : mcqSet.successRate < 75 ? "Moderate" : "High";

                          return (
                            <Box key={`${topic.name}-${setIdx}`} mt={2} p={2} bgcolor="#fff" borderRadius={4}>
                              <Grid container alignItems="center">
                                <Grid item xs={6}>
                                  <Typography variant="body1" style={{ fontWeight: 600 }}>
                                    {mcqSet.setName}
                                  </Typography>
                                  <Typography variant="body2" color="textSecondary">
                                    {mcqSet.solvedQuestions}/{mcqSet.totalQuestions} questions solved
                                  </Typography>
                                  <Typography variant="body2" color="textSecondary">
                                    Success Rate: {mcqSet.successRate}%
                                  </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                  <CircularProgress variant="determinate" value={mcqSet.successRate} size={50} />
                                  <Typography variant="body2" color="textSecondary">
                                    {mcqSet.successRate}% ({knowledgeLevel})
                                  </Typography>
                                </Grid>
                                <Grid item xs={3} textAlign="center">
                                <Button
  onClick={() => handleAiSuggestion(subject.subject, topic.name, mcqSet.setName, mcqSet.uuid)}
  disabled={aiStates[mcqSet.uuid]?.aiLoading}
  sx={{ mt: 1 }}
>
  {aiStates[mcqSet.uuid]?.aiLoading ? "Loading..." : "AI Suggestion"}
</Button>

                                  {mcqSet.canReattempt && (
                                    <Button
                                      variant="contained"
                                      sx={{
                                        mt: 1,
                                        ml: 1,
                                        backgroundColor: "#1976d2",
                                        color: "#fff",
                                      }}
                                      onClick={() => handleReattempt(subject.subject, topic.name, mcqSet.uuid)}
                                    >
                                      Reattempt
                                    </Button>
                                  )}
                                </Grid>
                              </Grid>
                              {aiStates[mcqSet.uuid]?.aiSummary && (
  <Box mt={2} p={2} bgcolor="#f0f0f0" borderRadius={4}>
  <Typography variant="body2" color="textSecondary">
      <ReactMarkdown rehypePlugins={[rehypeSanitize]}>
          {aiStates[mcqSet.uuid]?.aiSummary}
      </ReactMarkdown>
  </Typography>
</Box>
)}
                            </Box>
                          );
                        })}
                      </Box>
                    ))}
                  </Box>
                </Collapse>
              </SubjectCard>
            ))
          )}
        </Grid>

        {/* Right: Activity Feed */}
        <Grid item xs={12} md={4} sx={{ overflowY: 'auto', maxHeight: '80vh' }}>
          {!isMobile ? (
            <ActivityFeed activityFeed={activityFeed} activityLoading={activityLoading} />
          ) : (
            <Drawer anchor="right" open={activityDrawerOpen} onClose={() => setActivityDrawerOpen(false)}>
              <Box sx={{ width: 300, p: 2 }}>
                <ActivityFeed activityFeed={activityFeed} activityLoading={activityLoading} />
              </Box>
            </Drawer>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default Dashboard;

