import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Typography, 
  TextField, 
  Button, 
  Grid, 
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Switch,
  IconButton,
  Tooltip,
  Paper,
  Divider,
  Rating,
  Chip,
  FormHelperText
} from '@mui/material';
import { 
  Add as AddIcon,
  Delete as DeleteIcon,
  Check as CheckIcon,
  Help as HelpIcon
} from '@mui/icons-material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const MCQEditor = ({ 
  mcq, 
  onSave,
  topics = [],
  subjects = [],
  selectedTopic,
  selectedSubject
}) => {
  const [formData, setFormData] = useState({
    question: '',
    options: [
      { text: '', isCorrect: true },
      { text: '', isCorrect: false },
      { text: '', isCorrect: false },
      { text: '', isCorrect: false }
    ],
    explanation: '',
    difficulty: 2,
    topicId: '',
    subjectId: '',
    isActive: true
  });
  const [errors, setErrors] = useState({});

  // Rich text editor modules configuration
  const quillModules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'script': 'sub'}, { 'script': 'super' }],
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      ['formula', 'link', 'image'],
      ['clean']
    ],
  };

  useEffect(() => {
    if (mcq) {
      setFormData({
        question: mcq.question || '',
        options: mcq.options || [
          { text: '', isCorrect: true },
          { text: '', isCorrect: false },
          { text: '', isCorrect: false },
          { text: '', isCorrect: false }
        ],
        explanation: mcq.explanation || '',
        difficulty: mcq.difficulty || 2,
        topicId: mcq.topicId || selectedTopic?.id || '',
        subjectId: mcq.subjectId || selectedSubject?.id || '',
        isActive: mcq.isActive !== undefined ? mcq.isActive : true
      });
    } else {
      // Initialize with default values and selected topic/subject if available
      setFormData(prev => ({
        ...prev,
        topicId: selectedTopic?.id || '',
        subjectId: selectedSubject?.id || ''
      }));
    }
  }, [mcq, selectedTopic, selectedSubject]);

  const handleInputChange = (e) => {
    const { name, value, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: name === 'isActive' ? checked : value
    }));
    
    // Clear error for this field if any
    if (errors[name]) {
      setErrors(prev => ({ ...prev, [name]: null }));
    }
  };

  const handleQuestionChange = (content) => {
    setFormData(prev => ({ ...prev, question: content }));
    
    // Clear error for this field if any
    if (errors.question) {
      setErrors(prev => ({ ...prev, question: null }));
    }
  };

  const handleExplanationChange = (content) => {
    setFormData(prev => ({ ...prev, explanation: content }));
  };

  const handleOptionTextChange = (index, content) => {
    const newOptions = [...formData.options];
    newOptions[index].text = content;
    setFormData(prev => ({ ...prev, options: newOptions }));
    
    // Clear error for options if any
    if (errors.options) {
      setErrors(prev => ({ ...prev, options: null }));
    }
  };

  const handleOptionCorrectChange = (index) => {
    const newOptions = [...formData.options].map((option, i) => ({
      ...option,
      isCorrect: i === index
    }));
    
    setFormData(prev => ({ ...prev, options: newOptions }));
  };

  const handleAddOption = () => {
    if (formData.options.length < 6) {
      setFormData(prev => ({
        ...prev,
        options: [...prev.options, { text: '', isCorrect: false }]
      }));
    }
  };

  const handleRemoveOption = (index) => {
    if (formData.options.length > 2) {
      // Check if we're removing the correct option
      const isRemovingCorrect = formData.options[index].isCorrect;
      
      // Create new options array without the removed option
      const newOptions = formData.options.filter((_, i) => i !== index);
      
      // If we removed the correct option, make the first option correct
      if (isRemovingCorrect) {
        newOptions[0].isCorrect = true;
      }
      
      setFormData(prev => ({
        ...prev,
        options: newOptions
      }));
    }
  };

  const handleDifficultyChange = (event, newValue) => {
    setFormData(prev => ({
      ...prev,
      difficulty: newValue
    }));
  };

  const validateForm = () => {
    const newErrors = {};
    
    // Validate question
    if (!formData.question.trim()) {
      newErrors.question = 'Question is required';
    }
    
    // Validate options
    const emptyOptions = formData.options.some(option => !option.text.trim());
    if (emptyOptions) {
      newErrors.options = 'All options must have content';
    }
    
    // Validate topic selection
    if (!formData.topicId) {
      newErrors.topicId = 'Topic is required';
    }
    
    // Validate subject selection
    if (!formData.subjectId) {
      newErrors.subjectId = 'Subject is required';
    }
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      onSave(formData);
    }
  };

  const getDifficultyLabel = (level) => {
    switch (level) {
      case 1: return 'Easy';
      case 2: return 'Medium';
      case 3: return 'Hard';
      default: return 'Medium';
    }
  };

  const getDifficultyColor = (level) => {
    switch (level) {
      case 1: return 'success';
      case 2: return 'warning';
      case 3: return 'error';
      default: return 'warning';
    }
  };

  return (
    <Box>
      <Grid container spacing={3}>
        {/* Question */}
        <Grid item xs={12}>
          <Typography variant="subtitle1" gutterBottom>
            Question
          </Typography>
          <ReactQuill 
            value={formData.question} 
            onChange={handleQuestionChange} 
            modules={quillModules}
            placeholder="Enter your question here..."
          />
          {errors.question && (
            <FormHelperText error>{errors.question}</FormHelperText>
          )}
        </Grid>
        
        {/* Options */}
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
            <Typography variant="subtitle1">
              Answer Options
            </Typography>
            <Tooltip title="Add Option">
              <IconButton 
                color="primary" 
                onClick={handleAddOption}
                disabled={formData.options.length >= 6}
              >
                <AddIcon />
              </IconButton>
            </Tooltip>
          </Box>
          
          {formData.options.map((option, index) => (
            <Paper 
              key={index} 
              sx={{ 
                p: 2, 
                mb: 2, 
                border: '1px solid', 
                borderColor: option.isCorrect ? 'success.main' : 'divider',
                bgcolor: option.isCorrect ? 'success.50' : 'background.paper'
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <Typography variant="subtitle2" sx={{ mr: 1 }}>
                  Option {String.fromCharCode(65 + index)}:
                </Typography>
                <Chip 
                  label={option.isCorrect ? "Correct Answer" : "Incorrect"} 
                  color={option.isCorrect ? "success" : "default"}
                  size="small"
                  sx={{ mr: 1 }}
                />
                <Box sx={{ flexGrow: 1 }} />
                <Tooltip title={option.isCorrect ? "This is the correct answer" : "Mark as correct answer"}>
                  <IconButton 
                    color={option.isCorrect ? "success" : "default"}
                    onClick={() => handleOptionCorrectChange(index)}
                    size="small"
                  >
                    <CheckIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Remove Option">
                  <IconButton 
                    color="error" 
                    onClick={() => handleRemoveOption(index)}
                    disabled={formData.options.length <= 2}
                    size="small"
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </Box>
              <ReactQuill 
                value={option.text} 
                onChange={(content) => handleOptionTextChange(index, content)} 
                modules={quillModules}
                placeholder={`Enter option ${String.fromCharCode(65 + index)} here...`}
              />
            </Paper>
          ))}
          
          {errors.options && (
            <FormHelperText error>{errors.options}</FormHelperText>
          )}
        </Grid>
        
        {/* Explanation */}
        <Grid item xs={12}>
          <Typography variant="subtitle1" gutterBottom>
            Explanation (Optional)
          </Typography>
          <ReactQuill 
            value={formData.explanation} 
            onChange={handleExplanationChange} 
            modules={quillModules}
            placeholder="Explain why the correct answer is right and others are wrong..."
          />
        </Grid>
        
        {/* Metadata */}
        <Grid item xs={12}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="subtitle1" gutterBottom>
              MCQ Metadata
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth error={!!errors.subjectId}>
                  <InputLabel id="subject-label">Subject</InputLabel>
                  <Select
                    labelId="subject-label"
                    name="subjectId"
                    value={formData.subjectId}
                    label="Subject"
                    onChange={handleInputChange}
                    disabled={!!selectedSubject}
                  >
                    {selectedSubject ? (
                      <MenuItem value={selectedSubject.id}>{selectedSubject.name}</MenuItem>
                    ) : (
                      subjects.map(subject => (
                        <MenuItem key={subject.id} value={subject.id}>{subject.name}</MenuItem>
                      ))
                    )}
                  </Select>
                  {errors.subjectId && (
                    <FormHelperText>{errors.subjectId}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth error={!!errors.topicId}>
                  <InputLabel id="topic-label">Topic</InputLabel>
                  <Select
                    labelId="topic-label"
                    name="topicId"
                    value={formData.topicId}
                    label="Topic"
                    onChange={handleInputChange}
                    disabled={!!selectedTopic}
                  >
                    {selectedTopic ? (
                      <MenuItem value={selectedTopic.id}>{selectedTopic.name}</MenuItem>
                    ) : (
                      topics.map(topic => (
                        <MenuItem key={topic.id} value={topic.id}>{topic.name}</MenuItem>
                      ))
                    )}
                  </Select>
                  {errors.topicId && (
                    <FormHelperText>{errors.topicId}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box sx={{ mt: 1 }}>
                  <Typography component="legend">Difficulty Level</Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Rating
                      name="difficulty"
                      value={formData.difficulty}
                      onChange={handleDifficultyChange}
                      max={3}
                    />
                    <Box sx={{ ml: 2 }}>
                      <Chip 
                        label={getDifficultyLabel(formData.difficulty)} 
                        size="small" 
                        color={getDifficultyColor(formData.difficulty)}
                      />
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={
                    <Switch
                      name="isActive"
                      checked={formData.isActive}
                      onChange={handleInputChange}
                      color="primary"
                    />
                  }
                  label={
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography>
                        {formData.isActive ? 'Active' : 'Draft'}
                      </Typography>
                      <Tooltip title={formData.isActive 
                        ? "This MCQ will be available to students" 
                        : "This MCQ will be saved as a draft and won't be available to students"}>
                        <IconButton size="small">
                          <HelpIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  }
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        
        {/* Submit Button */}
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button 
              variant="contained" 
              color="primary" 
              onClick={handleSubmit}
              size="large"
            >
              {mcq ? 'Update MCQ' : 'Create MCQ'}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MCQEditor;
