import React, { useState, useEffect, useCallback } from 'react';
import { 
  Box, 
  Typography, 
  TextField, 
  Button, 
  IconButton, 
  Paper, 
  CircularProgress, 
  Card, 
  CardContent, 
  Divider, 
  Chip, 
  Grid,
  InputAdornment,
  Alert,
  useMediaQuery,
  Drawer,
  Fab
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { 
  Send, 
  AttachFile,
  Menu as MenuIcon,
  Close as CloseIcon
} from '@mui/icons-material';
import { themeColors, shadows, spacing, borderRadius } from '../theme';
import { useCaseStudy } from './CaseStudyProvider';

// Styled components
const CaseStudyContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: 'calc(100vh - 64px)',
  overflow: 'hidden',
  backgroundColor: themeColors.background.paper
}));

const ContentContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flex: 1,
  overflow: 'hidden'
}));

const SidePanel = styled(Box)(({ theme, isMobile }) => ({
  width: isMobile ? '100%' : '280px',
  borderRight: `1px solid ${themeColors.neutral.grey[200]}`,
  height: '100%',
  overflowY: 'auto',
  backgroundColor: themeColors.background.default
}));

const MainPanel = styled(Box)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  padding: spacing(2),
  overflow: 'hidden',
  position: 'relative'
}));

const EmptyState = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  padding: spacing(4)
}));

const EmptyStateIllustration = styled('div')(({ theme }) => ({
  width: '200px',
  height: '200px',
  marginBottom: spacing(3),
  backgroundImage: 'url("https://cdn-icons-png.flaticon.com/512/1041/1041728.png")',
  backgroundSize: 'contain',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  opacity: 0.7
}));

const ChatItem = styled(Box)(({ theme, selected }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: spacing(1),
  borderBottom: `1px solid ${themeColors.neutral.grey[200]}`,
  backgroundColor: selected ? themeColors.background.default : 'transparent',
  '&:hover': {
    backgroundColor: themeColors.background.default
  }
}));

const MobileMenuButton = styled(Fab)(({ theme }) => ({
  position: 'fixed',
  bottom: spacing(2),
  left: spacing(2),
  zIndex: 1000,
  backgroundColor: themeColors.primary.main,
  color: themeColors.primary.contrastText,
  '&:hover': {
    backgroundColor: themeColors.primary.dark
  }
}));

/**
 * CaseStudy component
 * Provides a chat interface focused on medical case studies
 */
const CaseStudy = () => {
  const { 
    chats, 
    selectedChatId, 
    messages, 
    inputValue, 
    loading, 
    loadingChats, 
    loadingMessages, 
    file, 
    error, 
    messageContainerRef, 
    fileInputRef, 
    handleInputChange, 
    handleFileChange, 
    handleSubmit, 
    handleChatSelect, 
    handleNewChat, 
    renderMessage,
    setError,
    setFile
  } = useCaseStudy();
  
  // Responsive state
  const isMobile = useMediaQuery('(max-width:768px)');
  const [drawerOpen, setDrawerOpen] = useState(false);
  
  // Debounced drawer state to prevent ResizeObserver errors
  const [isDrawerMounted, setIsDrawerMounted] = useState(false);
  
  // Debounce function for handling resize events
  const debounce = (func, wait) => {
    let timeout;
    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  };
  
  // Memoized drawer toggle handler
  const handleDrawerToggle = useCallback(() => {
    setDrawerOpen(prev => !prev);
  }, []);
  
  // Close drawer when a chat is selected on mobile
  useEffect(() => {
    if (isMobile && selectedChatId) {
      setDrawerOpen(false);
    }
  }, [selectedChatId, isMobile]);
  
  // Mount drawer after a delay to prevent initial ResizeObserver errors
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsDrawerMounted(true);
    }, 300);
    
    return () => clearTimeout(timer);
  }, []);
  
  // Handle window resize events with debounce
  useEffect(() => {
    const handleResize = debounce(() => {
      // Force a re-render on resize to update layout
      setIsDrawerMounted(false);
      setTimeout(() => setIsDrawerMounted(true), 100);
    }, 300);
    
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Render the side panel content
  const renderSidePanelContent = () => (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2, p: 2 }}>
        <Typography variant="h6" sx={{ fontWeight: 600 }}>Case Studies</Typography>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Button 
            variant="contained" 
            size="small" 
            onClick={handleNewChat}
            sx={{ 
              backgroundColor: themeColors.primary.main,
              '&:hover': {
                backgroundColor: themeColors.primary.dark
              }
            }}
          >
            New Case
          </Button>
          {isMobile && (
            <IconButton 
              onClick={() => setDrawerOpen(false)}
              sx={{ ml: 1 }}
            >
              <CloseIcon />
            </IconButton>
          )}
        </Box>
      </Box>
      
      {loadingChats ? (
        <Box sx={{ p: 2, display: 'flex', justifyContent: 'center' }}>
          <CircularProgress size={24} />
        </Box>
      ) : (
        <Box sx={{ overflowY: 'auto' }}>
          {chats.map((chat) => (
            <ChatItem
              key={chat._id}
              selected={selectedChatId === chat._id}
              onClick={() => handleChatSelect(chat._id)}
            >
              <Box>
                <Typography 
                  variant="subtitle2" 
                  sx={{ 
                    fontWeight: selectedChatId === chat._id ? 600 : 400,
                    color: selectedChatId === chat._id ? themeColors.primary.main : themeColors.text.primary
                  }}
                >
                  {chat.title || 'Untitled Case Study'}
                </Typography>
                <Typography 
                  variant="caption" 
                  sx={{ color: themeColors.text.secondary }}
                >
                  {chat.formattedDate || new Date(chat.createdAt).toLocaleDateString('en-US', {
                    day: 'numeric',
                    month: 'long',
                    year: 'numeric'
                  })}
                </Typography>
              </Box>
            </ChatItem>
          ))}
        </Box>
      )}
    </>
  );

  return (
    <CaseStudyContainer>
      <ContentContainer>
        {/* Mobile drawer for the side panel */}
        {isMobile ? (
          isDrawerMounted && (
            <Drawer
              anchor="left"
              open={drawerOpen}
              onClose={handleDrawerToggle}
              PaperProps={{
                sx: {
                  width: '80%',
                  maxWidth: '300px',
                  backgroundColor: themeColors.background.default
                }
              }}
              ModalProps={{
                keepMounted: true, // Better mobile performance
                disablePortal: true, // Helps with ResizeObserver issues
              }}
              sx={{
                '& .MuiBackdrop-root': {
                  backgroundColor: 'rgba(0, 0, 0, 0.5)'
                }
              }}
            >
              {renderSidePanelContent()}
            </Drawer>
          )
        ) : (
          <SidePanel isMobile={false}>
            {renderSidePanelContent()}
          </SidePanel>
        )}
        
        <MainPanel>
          {/* Mobile menu button */}
          {isMobile && (
            <MobileMenuButton 
              size="small" 
              onClick={handleDrawerToggle}
              aria-label="Open chat history"
            >
              <MenuIcon />
            </MobileMenuButton>
          )}
          
          {selectedChatId || true ? (
            <>
              <Box 
                ref={messageContainerRef}
                sx={{ 
                  flex: 1, 
                  overflowY: 'auto', 
                  p: isMobile ? 1 : 2,
                  display: 'flex',
                  flexDirection: 'column',
                  position: 'relative',
                  minHeight: '100px',
                  maxHeight: 'calc(100vh - 180px)',
                  willChange: 'transform',
                  overscrollBehavior: 'contain'
                }}
              >
                {loadingMessages ? (
                  <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                    <CircularProgress size={24} />
                  </Box>
                ) : messages.length === 0 ? (
                  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                    <Typography variant="body2" sx={{ color: themeColors.text.secondary }}>
                      No messages yet. Start typing to begin the conversation.
                    </Typography>
                  </Box>
                ) : (
                  messages.map(message => renderMessage(message))
                )}
                
                {loading && (
                  <Box sx={{ display: 'flex', justifyContent: 'flex-start', mb: 2 }}>
                    <CircularProgress size={24} />
                  </Box>
                )}
              </Box>
              
              <Box
                component="form"
                onSubmit={handleSubmit}
                sx={{
                  mt: 2,
                  mb: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 1,
                  position: 'sticky',
                  bottom: 0,
                  backgroundColor: themeColors.background.paper,
                  zIndex: 10,
                  borderTop: `1px solid ${themeColors.neutral.grey[200]}`,
                  padding: { xs: '8px', sm: '12px' }
                }}
              >
                {error && (
                  <Alert severity="error" onClose={() => setError(null)} sx={{ mb: 2 }}>
                    {error}
                  </Alert>
                )}
                
                <Box sx={{ display: 'flex', gap: 1 }}>
                  <TextField
                    id="case-study-input"
                    fullWidth
                    multiline
                    maxRows={4}
                    placeholder="Type your message..."
                    value={inputValue}
                    onChange={handleInputChange}
                    disabled={loading}
                    InputProps={{
                      sx: {
                        borderRadius: '24px',
                        backgroundColor: 'rgba(33, 150, 243, 0.08)', // Light blue background
                        '&:hover': {
                          backgroundColor: 'rgba(33, 150, 243, 0.12)'
                        },
                        '&.Mui-focused': {
                          backgroundColor: 'rgba(33, 150, 243, 0.15)',
                          '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: themeColors.primary.main,
                            borderWidth: 2
                          }
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                          borderColor: 'rgba(33, 150, 243, 0.3)',
                          borderRadius: '24px'
                        }
                      },
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => fileInputRef.current.click()}
                            disabled={loading}
                            size="small"
                            sx={{ 
                              mr: 0.5,
                              display: { xs: 'none', sm: 'inline-flex' }
                            }}
                          >
                            <AttachFile fontSize="small" />
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        padding: '10px 14px'
                      }
                    }}
                  />
                  
                  <input
                    type="file"
                    ref={fileInputRef}
                    onChange={handleFileChange}
                    style={{ display: 'none' }}
                  />
                  
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={loading || (!inputValue.trim() && !file)}
                    sx={{
                      borderRadius: '24px',
                      minWidth: { xs: '40px', sm: '64px' },
                      backgroundColor: themeColors.primary.main,
                      '&:hover': {
                        backgroundColor: themeColors.primary.dark
                      }
                    }}
                  >
                    {loading ? (
                      <CircularProgress size={24} color="inherit" />
                    ) : (
                      <Send sx={{ fontSize: { xs: '1.2rem', sm: '1.5rem' } }} />
                    )}
                  </Button>
                </Box>
                
                {file && (
                  <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                    <Typography variant="body2" sx={{ mr: 1 }}>
                      {file.name}
                    </Typography>
                    <IconButton size="small" onClick={() => setFile(null)}>
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  </Box>
                )}
              </Box>
            </>
          ) : (
            <EmptyState>
              <EmptyStateIllustration />
              <Card 
                sx={{ 
                  maxWidth: 500, 
                  width: '100%', 
                  boxShadow: shadows.medium,
                  borderRadius: borderRadius.large
                }}
              >
                <CardContent sx={{ p: 3 }}>
                  <Typography variant="h5" sx={{ mb: 2, fontWeight: 600, textAlign: 'center' }}>
                    Welcome to Case Studies
                  </Typography>
                  
                  <Typography variant="body1" sx={{ mb: 3, textAlign: 'center', color: themeColors.text.secondary }}>
                    Explore medical case studies to enhance your clinical reasoning skills and knowledge.
                  </Typography>
                  
                  <Divider sx={{ mb: 3 }} />
                  
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 600 }}>
                        Popular Case Study Topics
                      </Typography>
                      
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                        {['Cardiology', 'Neurology', 'Pediatrics', 'Emergency Medicine', 'Surgery'].map(topic => (
                          <Chip 
                            key={topic}
                            label={topic}
                            onClick={() => {
                              handleNewChat();
                              setTimeout(() => {
                                document.getElementById('case-study-input').value = `Create a case study about ${topic}`;
                                document.getElementById('case-study-input').focus();
                              }, 100);
                            }}
                            sx={{ 
                              backgroundColor: themeColors.background.default,
                              '&:hover': {
                                backgroundColor: themeColors.primary.light,
                                color: themeColors.primary.contrastText
                              }
                            }}
                          />
                        ))}
                      </Box>
                    </Grid>
                    
                    <Grid item xs={12} sx={{ mt: 2 }}>
                      <Button
                        variant="contained"
                        fullWidth
                        onClick={handleNewChat}
                        sx={{ 
                          py: 1.5,
                          backgroundColor: themeColors.primary.main,
                          '&:hover': {
                            backgroundColor: themeColors.primary.dark
                          }
                        }}
                      >
                        Start a New Case Study
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </EmptyState>
          )}
        </MainPanel>
      </ContentContainer>
    </CaseStudyContainer>
  );
};

export default CaseStudy;
