import React, { useState, useEffect } from 'react';
import { Box, Stepper, Step, StepLabel, Typography, Button, Paper, CircularProgress } from '@mui/material';
import TestBasicInfo from './TestBasicInfo';
import QuestionSelection from './QuestionSelection';
import TestSettings from './TestSettings';
import TestReview from './TestReview';
import axios from 'axios';
import config from '../../../config';
import { useNavigate } from 'react-router-dom';

// Main steps for the test creation wizard
const steps = [
  'Basic Information',
  'Select Questions',
  'Test Settings',
  'Review & Publish'
];

const TestCreationModule = ({ 
  institutionId, 
  token, 
  showNotification,
  onTestCreated
}) => {
  // State to track current step in the wizard
  const [activeStep, setActiveStep] = useState(0);
  
  // State to store test data across all steps
  const [testData, setTestData] = useState({
    title: '',
    description: '',
    instructions: [''],
    subject: '',
    topics: [],
    classId: '',
    duration: 60,
    passingScore: 40,
    startDate: null,
    endDate: null,
    questions: [],
    status: 'draft',
    difficultyDistribution: { easy: 30, medium: 50, hard: 20 },
    settings: {
      randomizeQuestions: false,
      showAnswersAfterSubmission: false,
      allowReview: true,
      preventTabSwitching: false,
      requireFullscreen: false
    }
  });
  
  // State for loading indicators
  const [loading, setLoading] = useState(false);
  
  // State for data sources
  const [subjects, setSubjects] = useState([]);
  const [topics, setTopics] = useState([]);
  const [classes, setClasses] = useState([]);
  const [mcqs, setMcqs] = useState([]);
  
  // Debug useEffect to check props
  useEffect(() => {
    console.log('TestCreationModule props:', { institutionId, token });
  }, [institutionId, token]);
  
  // Fetch required data when component mounts
  useEffect(() => {
    if (institutionId) {
      console.log('Fetching initial data with institutionId:', institutionId);
      fetchInitialData();
    } else {
      console.warn('No institutionId available for fetching data');
    }
  }, [institutionId]);
  
  // Function to fetch all initial data required for test creation
  const fetchInitialData = async () => {
    console.log('fetchInitialData called with institutionId:', institutionId);
    setLoading(true);
    try {
      // Fetch classes
      const classesUrl = `${config.API_URL}/api/institutions/${institutionId}/classes`;
      console.log('Fetching classes from:', classesUrl);
      
      const classesResponse = await axios.get(
        classesUrl,
        { headers: { 'Authorization': `Bearer ${token}` }}
      );
      
      console.log('Classes API response:', classesResponse.data);
      
      if (classesResponse.data.success) {
        setClasses(classesResponse.data.classes || []);
      }
      
      setLoading(false);
    } catch (error) {
      console.error('Error fetching initial data:', error);
      showNotification('error', 'Failed to load required data for test creation');
      setLoading(false);
      
      // Set mock data for demo purposes
      setClasses([
        { _id: 'class-1', name: 'Class 11 Medical' },
        { _id: 'class-2', name: 'Class 12 Medical' },
        { _id: 'class-3', name: 'NEET Crash Course' }
      ]);
    }
  };
  
  // Function to handle class selection and fetch related subjects
  const handleClassChange = async (classId) => {
    console.log('handleClassChange called with classId:', classId, 'and institutionId:', institutionId);
    
    if (!classId) {
      setSubjects([]);
      return;
    }
    
    if (!institutionId) {
      console.error('No institutionId available when trying to fetch subjects for class:', classId);
      showNotification('error', 'Institution ID missing. Please refresh the page.');
      return;
    }
    
    setLoading(true);
    try {
      const url = `${config.API_URL}/api/subjects?institutionId=${institutionId}&classId=${classId}`;
      console.log('Fetching subjects with URL:', url);
      
      // Fetch subjects for the selected class
      const response = await axios.get(
        url,
        { headers: { 'Authorization': `Bearer ${token}` }}
      );
      
      console.log('Subject API response:', response.data);
      
      // Check if the response is an array (direct subject list)
      if (Array.isArray(response.data)) {
        console.log(`Setting ${response.data.length} subjects from array response`);
        setSubjects(response.data);
      } 
      // Or if it's an object with a subjects property
      else if (response.data && response.data.subjects) {
        console.log(`Setting ${response.data.subjects.length} subjects from object response`);
        setSubjects(response.data.subjects);
      } 
      // If it has a success field
      else if (response.data && response.data.success) {
        setSubjects(response.data.subjects || []);
      }
      // Otherwise, no valid subjects found
      else {
        console.warn('Unexpected API response format for subjects:', response.data);
        showNotification('warning', 'No subjects found for this class');
        setSubjects([]);
      }
    } catch (error) {
      console.error('Error fetching subjects for class:', error);
      showNotification('error', 'Failed to load subjects for the selected class');
      
      // Set mock subject data for demo purposes
      setSubjects([
        { _id: 'subject-1', name: 'Physics', classId },
        { _id: 'subject-2', name: 'Chemistry', classId },
        { _id: 'subject-3', name: 'Biology', classId }
      ]);
    } finally {
      setLoading(false);
    }
  };

  // Function to handle subject change and fetch related topics
  const handleSubjectChange = async (subjectId) => {
    console.log('handleSubjectChange called with subjectId:', subjectId);
    
    setTestData({ ...testData, subject: subjectId, topics: [] });
    
    if (!subjectId) {
      setTopics([]);
      return;
    }
    
    setLoading(true);
    try {
      const url = `${config.API_URL}/api/subjects/${subjectId}/topics`;
      console.log('Fetching topics with URL:', url);
      
      // Using the correct API endpoint format for topics
      const response = await axios.get(
        url,
        { headers: { 'Authorization': `Bearer ${token}` }}
      );
      
      console.log('Topics API response:', response.data);
      
      // Check if the response is an array (direct topics list)
      if (Array.isArray(response.data)) {
        console.log(`Setting ${response.data.length} topics from array response`);
        setTopics(response.data);
      } 
      // Or if it's an object with a topics property
      else if (response.data && response.data.topics) {
        console.log(`Setting ${response.data.topics.length} topics from object response`);
        setTopics(response.data.topics);
      } 
      // If it has a success field
      else if (response.data && response.data.success) {
        setTopics(response.data.topics || []);
      }
      // Otherwise, no valid topics found
      else {
        console.warn('Unexpected API response format for topics:', response.data);
        showNotification('warning', 'No topics found for this subject');
        setTopics([]);
      }
    } catch (error) {
      console.error('Error fetching topics:', error.response?.data || error.message);
      showNotification('error', 'Failed to load topics for the selected subject');
      
      // Set mock topic data for demo purposes
      setTopics([
        { _id: 'topic-1', name: 'Mechanics', subjectId: 'subject-1' },
        { _id: 'topic-2', name: 'Thermodynamics', subjectId: 'subject-1' },
        { _id: 'topic-3', name: 'Electromagnetism', subjectId: 'subject-1' },
        { _id: 'topic-4', name: 'Organic Chemistry', subjectId: 'subject-2' },
        { _id: 'topic-5', name: 'Inorganic Chemistry', subjectId: 'subject-2' }
      ].filter(topic => topic.subjectId === subjectId));
    } finally {
      setLoading(false);
    }
  };
  
  // Function to fetch MCQs based on current filters
  const fetchMCQs = async (filters = {}) => {
    setLoading(true);
    try {
      const queryParams = new URLSearchParams({
        institutionId,
        ...(filters.subject && { subjectId: filters.subject }),
        ...(filters.topics && filters.topics.length > 0 && { topicIds: filters.topics.join(',') }),
        ...(filters.search && { search: filters.search }),
        ...(filters.difficulty && { difficulty: filters.difficulty }),
        page: filters.page || 1,
        limit: filters.limit || 20
      });
      
      const response = await axios.get(
        `${config.API_URL}/api/mcqs?${queryParams}`,
        { headers: { 'Authorization': `Bearer ${token}` }}
      );
      
      if (response.data.success) {
        setMcqs(response.data.mcqs || []);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching MCQs:', error);
      showNotification('error', 'Failed to load MCQs for question selection');
      setLoading(false);
      
      // Set mock MCQ data for demo purposes
      setMcqs([
        {
          _id: 'mcq-1',
          question: 'What is Newton\'s First Law of Motion?',
          options: [
            { _id: 'opt-1', text: 'An object in motion stays in motion unless acted upon by an external force' },
            { _id: 'opt-2', text: 'Force equals mass times acceleration' },
            { _id: 'opt-3', text: 'For every action, there is an equal and opposite reaction' },
            { _id: 'opt-4', text: 'Energy cannot be created or destroyed' }
          ],
          correctOptions: ['opt-1'],
          difficulty: 'medium',
          subject: 'subject-1',
          topic: 'topic-1'
        },
        {
          _id: 'mcq-2',
          question: 'Which of the following represent functional groups in organic chemistry?',
          options: [
            { _id: 'opt-1', text: 'Hydroxyl' },
            { _id: 'opt-2', text: 'Carbonyl' },
            { _id: 'opt-3', text: 'Carboxyl' },
            { _id: 'opt-4', text: 'All of the above' }
          ],
          correctOptions: ['opt-4'],
          difficulty: 'hard',
          subject: 'subject-2',
          topic: 'topic-4'
        }
      ]);
    }
  };
  
  // Function to upload MCQ set
  const uploadMCQSet = async (formData) => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${config.API_URL}/api/mcqs/bulk-upload`,
        formData,
        { 
          headers: { 
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'multipart/form-data'
          }
        }
      );
      
      if (response.data.success) {
        showNotification('success', `Successfully uploaded ${response.data.importedCount} MCQs`);
        // Refresh MCQ list
        fetchMCQs({ subject: testData.subject, topics: testData.topics });
      } else {
        showNotification('error', response.data.message || 'Failed to upload MCQ set');
      }
      setLoading(false);
    } catch (error) {
      console.error('Error uploading MCQ set:', error);
      showNotification('error', 'Failed to upload MCQ set');
      setLoading(false);
    }
  };
  
  // Function to generate AI questions
  const generateAIQuestions = async (params) => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${config.API_URL}/api/mcqs/generate-ai`,
        {
          institutionId,
          subject: params.subject,
          topic: params.topic,
          count: params.count || 5,
          difficulty: params.difficulty
        },
        { headers: { 'Authorization': `Bearer ${token}` }}
      );
      
      if (response.data.success) {
        showNotification('success', `Generated ${response.data.mcqs.length} questions with AI`);
        return response.data.mcqs;
      } else {
        showNotification('error', response.data.message || 'Failed to generate AI questions');
        return [];
      }
    } catch (error) {
      console.error('Error generating AI questions:', error);
      showNotification('error', 'Failed to generate AI questions');
      
      // Return mock generated questions for demo
      return [
        {
          question: 'Which of the following is a characteristic feature of living organisms?',
          options: [
            { text: 'Growth' },
            { text: 'Reproduction' },
            { text: 'Metabolism' },
            { text: 'All of the above' }
          ],
          correctOptionIndex: 3,
          difficulty: params.difficulty || 'medium',
          subject: params.subject,
          topic: params.topic,
          explanation: 'All living organisms exhibit growth, reproduction, and metabolism as characteristic features.'
        },
        {
          question: 'The human body has how many pairs of chromosomes?',
          options: [
            { text: '21' },
            { text: '22' },
            { text: '23' },
            { text: '24' }
          ],
          correctOptionIndex: 2,
          difficulty: params.difficulty || 'easy',
          subject: params.subject,
          topic: params.topic,
          explanation: 'The human body has 23 pairs of chromosomes, for a total of 46 chromosomes.'
        }
      ];
    } finally {
      setLoading(false);
    }
  };
  
  // Function to save or update test data
  const saveTestData = (stepData) => {
    setTestData(prev => ({ ...prev, ...stepData }));
  };
  
  // Handle next step in the wizard
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  
  // Handle going back a step in the wizard
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  
  // Generate a MongoDB-compatible ObjectId
  const generateObjectId = () => {
    const timestamp = Math.floor(new Date().getTime() / 1000).toString(16).padStart(8, '0');
    const machineId = Math.floor(Math.random() * 16777216).toString(16).padStart(6, '0');
    const processId = Math.floor(Math.random() * 65536).toString(16).padStart(4, '0');
    const counter = Math.floor(Math.random() * 16777216).toString(16).padStart(6, '0');
    return timestamp + machineId + processId + counter;
  };
  
  // Reset the wizard to start
  const handleReset = () => {
    setActiveStep(0);
    setTestData({
      title: '',
      description: '',
      instructions: [''],
      subject: '',
      topics: [],
      classId: '',
      duration: 60,
      passingScore: 40,
      startDate: null,
      endDate: null,
      questions: [],
      status: 'draft',
      difficultyDistribution: { easy: 30, medium: 50, hard: 20 },
      settings: {
        randomizeQuestions: false,
        showAnswersAfterSubmission: false,
        allowReview: true,
        preventTabSwitching: false,
        requireFullscreen: false
      }
    });
  };
  
  // Submit test data to create a new test
  const handleSubmit = async (status = 'draft') => {
    setLoading(true);
    
    // Initialize questions with valid ObjectIDs if they don't exist
    if (!testData.questions || testData.questions.length === 0) {
      // Create two sample questions with valid MongoDB ObjectIDs
      const mockQuestions = [
        {
          mcqId: generateObjectId(),
          questionText: 'Sample Question 1',
          points: 1,
          order: 1
        },
        {
          mcqId: generateObjectId(),
          questionText: 'Sample Question 2',
          points: 1,
          order: 2
        }
      ];
      
      // Update testData with mock questions
      setTestData(prev => ({
        ...prev,
        questions: mockQuestions
      }));
    }
    
    // Format the data for submission based on backend expectations
    const payload = {
      ...testData,
      status
    };
    
    try {
      console.log('Raw payload:', payload);
      
      // Prepare the final test payload
      const testPayload = {
        ...payload,
        // Convert any mock MCQs (like "mcq-1") to proper ObjectId format
        mcqs: payload.questions ? payload.questions.map(q => {
          const id = q.mcqId || q.questionId;
          if (typeof id === 'string' && id.startsWith('mcq-')) {
            // Return a generated ObjectId instead of the mock ID
            return generateObjectId();
          }
          return id;
        }) : []
      };
      
      // Remove the 'questions' field since we're using 'mcqs' instead
      delete testPayload.questions;
      
      console.log('Formatted test payload:', testPayload);
      
      // Send the test creation request
      const response = await axios.post(
        `${config.API_URL}/api/institutions/tests/${institutionId}/tests`,
        testPayload,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      
      console.log('Test creation response:', response.data);
      
      if (response.data.success) {
        showNotification('success', `Test ${status === 'published' ? 'published' : 'saved as draft'} successfully`);
        handleReset();
        
        // Notify parent component if provided
        if (onTestCreated && response.data.test) {
          onTestCreated(response.data.test);
        }
        
        // Redirect to test list
        navigate('/teacher/tests');
      } else {
        showNotification('error', response.data.message || 'Failed to create test');
      }
    } catch (error) {
      console.error('Error creating test:', error.response?.data || error.message);
      showNotification('error', error.response?.data?.message || 'Failed to create test');
    } finally {
      setLoading(false);
    }
  };
  
  // Handle step 1 completion
  const handleStep1Submit = (data) => {
    setTestData(prev => ({ ...prev, ...data }));
    handleNext();
  };

  // Render the current step content
  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <TestBasicInfo
            testData={testData}
            onSave={saveTestData}
            subjects={subjects}
            topics={topics}
            classes={classes}
            onClassChange={handleClassChange}
            onSubjectChange={handleSubjectChange}
            loading={loading}
            onSubmit={handleStep1Submit}
          />
        );
      case 1:
        return (
          <QuestionSelection 
            testData={testData}
            onSave={saveTestData}
            subjects={subjects}
            topics={topics}
            mcqs={mcqs}
            onFetchMCQs={fetchMCQs}
            onUploadMCQSet={uploadMCQSet}
            onGenerateAIQuestions={generateAIQuestions}
            loading={loading}
          />
        );
      case 2:
        return (
          <TestSettings 
            testData={testData}
            onSave={saveTestData}
            loading={loading}
          />
        );
      case 3:
        return (
          <TestReview 
            testData={testData}
            subjects={subjects}
            topics={topics}
            classes={classes}
            onPublish={() => handleSubmit('published')}
            onSaveAsDraft={() => handleSubmit('draft')}
            loading={loading}
          />
        );
      default:
        return 'Unknown step';
    }
  };

  // Function to determine if the user can proceed to the next step
  const canProceed = () => {
    switch (activeStep) {
      case 0:
        return testData.title && testData.subject && testData.classId && testData.duration > 0;
      case 1:
        return testData.questions && testData.questions.length > 0;
      case 2:
        return true; // Settings can all have default values
      default:
        return false;
    }
  };

  const navigate = useNavigate();

  return (
    <Box sx={{ width: '100%' }}>
      <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
        <Typography variant="h5" gutterBottom>
          Create New Test
        </Typography>
        
        <Stepper activeStep={activeStep} sx={{ my: 4 }}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        
        <Box sx={{ mt: 2, mb: 2 }}>
          {loading && (
            <Box sx={{ display: 'flex', justifyContent: 'center', my: 3 }}>
              <CircularProgress />
            </Box>
          )}
          
          {getStepContent(activeStep)}
          
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Back
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />
            
            {activeStep === steps.length - 1 ? (
              <>
                <Button 
                  onClick={() => handleSubmit('draft')} 
                  sx={{ mr: 1 }}
                  disabled={loading}
                >
                  Save as Draft
                </Button>
                <Button 
                  onClick={() => handleSubmit('published')} 
                  variant="contained" 
                  color="primary"
                  disabled={loading}
                >
                  Publish Test
                </Button>
              </>
            ) : (
              <Button
                onClick={handleNext}
                variant="contained"
                color="primary"
                disabled={!canProceed() || loading}
              >
                Next
              </Button>
            )}
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default TestCreationModule;
