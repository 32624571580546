import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import config from '../config';

const API_URL = config.API_URL;

// Create the authentication context
const AuthContext = createContext(null);

// Custom hook to use the auth context
export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

// Provider component that wraps the app and makes auth object available to any child component that calls useAuth()
export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(localStorage.getItem('token'));
  const [refreshToken, setRefreshToken] = useState(localStorage.getItem('refreshToken'));
  const [isLoaded, setIsLoaded] = useState(false);
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [currentInstitution, setCurrentInstitution] = useState(null);

  // Initialize auth state from localStorage
  useEffect(() => {
    const initializeAuth = async () => {
      const storedToken = localStorage.getItem('token');
      const storedUser = localStorage.getItem('user');
      const storedInstitution = localStorage.getItem('currentInstitution');
      
      if (storedToken && storedUser) {
        setToken(storedToken);
        setUser(JSON.parse(storedUser));
        setIsSignedIn(true);
        
        if (storedInstitution) {
          setCurrentInstitution(JSON.parse(storedInstitution));
        }
      }
      
      setIsLoaded(true);
    };

    initializeAuth();
  }, []);

  // Setup axios interceptor for handling 401 errors
  useEffect(() => {
    const interceptor = axios.interceptors.response.use(
      response => response,
      error => {
        if (error.response && error.response.status === 401) {
          // Automatically sign out on 401 Unauthorized
          signOut();
          // You could redirect to login here if needed
          window.location.href = '/sign-in';
        }
        return Promise.reject(error);
      }
    );

    // Clean up interceptor on unmount
    return () => {
      axios.interceptors.response.eject(interceptor);
    };
  }, []);

  // Function to sign in a user
  const signIn = async (email, password) => {
    try {
      const response = await axios.post(`${API_URL}/api/signin`, {
        email,
        password
      });

      const { token, refreshToken, user } = response.data;
      
      // Store auth data in localStorage
      localStorage.setItem('token', token);
      localStorage.setItem('refreshToken', refreshToken);
      localStorage.setItem('user', JSON.stringify(user));
      
      // Update state
      setToken(token);
      setRefreshToken(refreshToken);
      setUser(user);
      setIsSignedIn(true);
      
      // If user belongs to an institution, fetch and store institution data
      if (user.institution) {
        await fetchAndSetInstitution(user.institution, token);
      }
      
      return { success: true, user };
    } catch (error) {
      console.error('Sign in error:', error.response?.data || error.message);
      return { 
        success: false, 
        error: error.response?.data?.message || 'Authentication failed' 
      };
    }
  };

  // Function to fetch and set institution data
  const fetchAndSetInstitution = async (institutionId, authToken) => {
    try {
      const response = await axios.get(`${API_URL}/api/institutions/${institutionId}`, {
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      });
      
      const institution = response.data.institution;
      localStorage.setItem('currentInstitution', JSON.stringify(institution));
      setCurrentInstitution(institution);
      
      return institution;
    } catch (error) {
      console.error('Error fetching institution:', error);
      return null;
    }
  };

  // Function to sign up a new user
  const signUp = async (userData) => {
    try {
      const response = await axios.post(`${API_URL}/api/signup`, userData);

      const { token, refreshToken, user } = response.data;
      
      // Store auth data in localStorage
      localStorage.setItem('token', token);
      localStorage.setItem('refreshToken', refreshToken);
      localStorage.setItem('user', JSON.stringify(user));
      
      // Update state
      setToken(token);
      setRefreshToken(refreshToken);
      setUser(user);
      setIsSignedIn(true);
      
      // If user belongs to an institution, fetch and store institution data
      if (user.institution) {
        await fetchAndSetInstitution(user.institution, token);
      }
      
      return { success: true, user };
    } catch (error) {
      console.error('Sign up error:', error.response?.data || error.message);
      return { 
        success: false, 
        error: error.response?.data?.message || 'Registration failed' 
      };
    }
  };

  // Function to sign out a user
  const signOut = async () => {
    try {
      // Call the backend logout endpoint if refresh token exists
      if (refreshToken) {
        await axios.post(`${API_URL}/api/logout`, { refreshToken });
      }
      
      // Clear auth data from localStorage
      localStorage.removeItem('token');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('user');
      localStorage.removeItem('currentInstitution');
      
      // Update state
      setToken(null);
      setRefreshToken(null);
      setUser(null);
      setCurrentInstitution(null);
      setIsSignedIn(false);
      
      return { success: true };
    } catch (error) {
      console.error('Sign out error:', error);
      
      // Even if the API call fails, we still want to clear local state
      localStorage.removeItem('token');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('user');
      localStorage.removeItem('currentInstitution');
      
      setToken(null);
      setRefreshToken(null);
      setUser(null);
      setCurrentInstitution(null);
      setIsSignedIn(false);
      
      return { success: true };
    }
  };

  // Function to get the current token (with refresh if needed)
  const getToken = async () => {
    // If no token exists, return null
    if (!token) return null;
    
    try {
      // TODO: Add token expiration check and refresh logic
      // For now, just return the current token
      return token;
    } catch (error) {
      console.error('Get token error:', error);
      return null;
    }
  };

  // Function to refresh the token
  const refreshAuthToken = async () => {
    if (!refreshToken) return { success: false };
    
    try {
      const response = await axios.post(`${API_URL}/refresh-token`, {
        refreshToken
      });
      
      const newToken = response.data.token;
      
      // Update token in localStorage and state
      localStorage.setItem('token', newToken);
      setToken(newToken);
      
      return { success: true, token: newToken };
    } catch (error) {
      console.error('Token refresh error:', error);
      
      // If refresh fails, sign out the user
      await signOut();
      
      return { success: false };
    }
  };

  // Function to check if user has a specific role
  const hasRole = (roles) => {
    if (!user) return false;
    if (Array.isArray(roles)) {
      return roles.includes(user.role);
    }
    return user.role === roles;
  };

  // Function to check if user has permission for a specific institution
  const hasInstitutionPermission = (institutionId) => {
    if (!user) return false;
    
    // Super admins have access to all institutions
    if (user.role === 'super_admin') return true;
    
    // Institution admins, teachers, and students only have access to their own institution
    return user.institution === institutionId;
  };

  // Function to check if user has permission based on role and institution
  const hasPermission = (roles, institutionId) => {
    return hasRole(roles) && hasInstitutionPermission(institutionId);
  };

  // Function to check if user has permission for a specific action within their institution
  const hasInstitutionActionPermission = (action, institutionId) => {
    if (!user) return false;
    
    // Super admins can perform any action on any institution
    if (user.role === 'super_admin') return true;
    
    // Institution admins can perform most actions on their own institution
    if (user.role === 'institution_admin' && user.institution === institutionId) {
      // Restrict certain actions if needed
      return true;
    }
    
    // Teachers can perform limited actions on their institution
    if (user.role === 'teacher' && user.institution === institutionId) {
      const teacherActions = ['view_content', 'create_content', 'edit_content', 'view_students'];
      return teacherActions.includes(action);
    }
    
    // Students can only perform very limited actions
    if (user.role === 'student' && user.institution === institutionId) {
      const studentActions = ['view_content', 'take_test'];
      return studentActions.includes(action);
    }
    
    return false;
  };

  // Function to switch institution (for users with multiple institutions)
  const switchInstitution = async (institutionId) => {
    if (!user) return { success: false, error: 'Not authenticated' };
    
    try {
      const authToken = await getToken();
      const institution = await fetchAndSetInstitution(institutionId, authToken);
      
      if (institution) {
        // Update user's current institution
        const updatedUser = { ...user, currentInstitution: institutionId };
        localStorage.setItem('user', JSON.stringify(updatedUser));
        setUser(updatedUser);
        
        return { success: true, institution };
      }
      
      return { success: false, error: 'Failed to switch institution' };
    } catch (error) {
      console.error('Switch institution error:', error);
      return { success: false, error: 'Failed to switch institution' };
    }
  };

  // Create the auth value object
  const authValue = {
    user,
    token,
    isLoaded,
    isSignedIn,
    currentInstitution,
    signIn,
    signUp,
    signOut,
    getToken,
    refreshAuthToken,
    hasRole,
    hasInstitutionPermission,
    hasPermission,
    hasInstitutionActionPermission,
    switchInstitution
  };

  return (
    <AuthContext.Provider value={authValue}>
      {children}
    </AuthContext.Provider>
  );
};