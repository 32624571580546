import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Paper, 
  Typography, 
  Button, 
  Grid, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow,
  TablePagination,
  Chip,
  IconButton,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress
} from '@mui/material';
import { 
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Visibility as ViewIcon,
  Assignment as AssignIcon,
  Search as SearchIcon,
  FilterList as FilterIcon
} from '@mui/icons-material';
import TestCreationModule from './TestCreation/TestCreationModule';
import axios from 'axios';
import config from '../../config';

const TestManagement = ({ 
  institutionId, 
  token, 
  showNotification 
}) => {
  // State variables
  const [tests, setTests] = useState([]);
  const [totalTests, setTotalTests] = useState(0);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [showCreateTest, setShowCreateTest] = useState(false);
  const [selectedTest, setSelectedTest] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [testToDelete, setTestToDelete] = useState(null);
  const [assignDialogOpen, setAssignDialogOpen] = useState(false);
  const [testToAssign, setTestToAssign] = useState(null);
  const [searchParams, setSearchParams] = useState({
    search: '',
    subject: '',
    status: ''
  });
  const [filterDialogOpen, setFilterDialogOpen] = useState(false);
  const [subjects, setSubjects] = useState([]);
  
  // Fetch tests on initial load
  useEffect(() => {
    if (institutionId) {
      fetchTests();
      fetchSubjects();
    }
  }, [institutionId, page, rowsPerPage]);
  
  // Fetch tests with pagination and filters
  const fetchTests = async () => {
    setLoading(true);
    try {
      const queryParams = new URLSearchParams({
        page: page + 1,
        limit: rowsPerPage,
        ...(searchParams.search && { search: searchParams.search }),
        ...(searchParams.subject && { subjectId: searchParams.subject }),
        ...(searchParams.status && { status: searchParams.status })
      });
      
      // Log the fetch attempt for debugging
      console.log(`Fetching tests for institution: ${institutionId}`);
      
      // The correct endpoint structure based on your backend setup
      const url = `${config.API_URL}/api/institutions/tests/${institutionId}/tests?${queryParams}`;
      console.log('Fetching tests from URL:', url);
      
      const response = await axios.get(
        url,
        { headers: { 'Authorization': `Bearer ${token}` }}
      );
      
      console.log('Tests API response:', response.data);
      
      // Check if the response contains tests and pagination data
      if (response.data && response.data.tests) {
        console.log(`Setting ${response.data.tests.length} tests to state`);
        setTests(response.data.tests);
        
        // Check for pagination fields
        if (response.data.pagination) {
          setTotalTests(response.data.pagination.total || 0);
        } else {
          setTotalTests(response.data.tests.length);
        }
      } else {
        console.error('Unexpected API response format:', response.data);
        showNotification('error', 'Failed to load tests - unexpected response format');
        setTests([]);
        setTotalTests(0);
      }
    } catch (error) {
      console.error('Error fetching tests:', error.response?.data || error.message);
      showNotification('error', 'Failed to load tests');
      
      // Set mock data for demo
      setTests([
        {
          _id: 'test-1',
          title: 'Physics Midterm Exam',
          subject: { _id: 'subject-1', name: 'Physics' },
          questions: new Array(25).fill({}),
          duration: 60,
          status: 'published',
          createdAt: new Date().toISOString(),
          startDate: new Date().toISOString(),
          endDate: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000).toISOString()
        },
        {
          _id: 'test-2',
          title: 'Chemistry Formulas Quiz',
          subject: { _id: 'subject-2', name: 'Chemistry' },
          questions: new Array(15).fill({}),
          duration: 30,
          status: 'draft',
          createdAt: new Date().toISOString()
        },
        {
          _id: 'test-3',
          title: 'Biology Final Exam',
          subject: { _id: 'subject-3', name: 'Biology' },
          questions: new Array(40).fill({}),
          duration: 120,
          status: 'published',
          createdAt: new Date().toISOString(),
          startDate: new Date(Date.now() - 10 * 24 * 60 * 60 * 1000).toISOString(),
          endDate: new Date(Date.now() - 9 * 24 * 60 * 60 * 1000).toISOString()
        }
      ]);
      setTotalTests(3);
    } finally {
      setLoading(false);
    }
  };
  
  // Fetch subjects for filter
  const fetchSubjects = async () => {
    try {
      const response = await axios.get(
        `${config.API_URL}/api/v1/subjects?institutionId=${institutionId}`,
        { headers: { 'Authorization': `Bearer ${token}` }}
      );
      
      if (response.data.success) {
        setSubjects(response.data.subjects || []);
      }
    } catch (error) {
      console.error('Error fetching subjects:', error);
      
      // Set mock data
      setSubjects([
        { _id: 'subject-1', name: 'Physics' },
        { _id: 'subject-2', name: 'Chemistry' },
        { _id: 'subject-3', name: 'Biology' },
        { _id: 'subject-4', name: 'Mathematics' }
      ]);
    }
  };
  
  // Handle page change
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  
  // Handle rows per page change
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  
  // Format date
  const formatDate = (dateString) => {
    if (!dateString) return 'Not scheduled';
    return new Date(dateString).toLocaleDateString();
  };
  
  // Toggle create test form
  const toggleCreateTest = () => {
    setShowCreateTest(!showCreateTest);
    setSelectedTest(null);
  };
  
  // Handle test creation
  const handleTestCreated = (test) => {
    fetchTests();
    setShowCreateTest(false);
  };
  
  // Open delete confirmation dialog
  const handleDeleteClick = (test) => {
    setTestToDelete(test);
    setDeleteDialogOpen(true);
  };
  
  // Confirm test deletion
  const confirmDelete = async () => {
    if (!testToDelete) return;
    
    setLoading(true);
    try {
      const response = await axios.delete(
        `${config.API_URL}/api/institutions/${institutionId}/tests/${testToDelete._id}`,
        { headers: { 'Authorization': `Bearer ${token}` }}
      );
      
      if (response.data.success) {
        showNotification('success', 'Test deleted successfully');
        fetchTests();
      } else {
        showNotification('error', response.data.message || 'Failed to delete test');
      }
    } catch (error) {
      console.error('Error deleting test:', error);
      showNotification('error', 'Failed to delete test');
    } finally {
      setLoading(false);
      setDeleteDialogOpen(false);
      setTestToDelete(null);
    }
  };
  
  // Open assign dialog
  const handleAssignClick = (test) => {
    setTestToAssign(test);
    setAssignDialogOpen(true);
  };
  
  // Close assign dialog
  const handleCloseAssignDialog = () => {
    setAssignDialogOpen(false);
    setTestToAssign(null);
  };
  
  // Handle search param change
  const handleSearchParamChange = (e) => {
    const { name, value } = e.target;
    setSearchParams(prev => ({ ...prev, [name]: value }));
  };
  
  // Apply filters
  const applyFilters = () => {
    setPage(0);
    fetchTests();
    setFilterDialogOpen(false);
  };
  
  // Reset filters
  const resetFilters = () => {
    setSearchParams({
      search: '',
      subject: '',
      status: ''
    });
    setPage(0);
    setFilterDialogOpen(false);
    fetchTests();
  };
  
  // Get status color
  const getStatusColor = (status) => {
    switch (status) {
      case 'published':
        return 'success';
      case 'draft':
        return 'warning';
      case 'archived':
        return 'error';
      default:
        return 'default';
    }
  };
  
  // Add a useEffect to debug tests state changes
  useEffect(() => {
    console.log(`Tests array has ${tests.length} items:`, tests);
  }, [tests]);
  
  // Add a debug function to help inspect test objects
  const debugTestObject = (test) => {
    console.log('Rendering test:', {
      id: test._id,
      title: test.title,
      subject: test.subject,
      questionsLength: test.questions?.length
    });
    return null; // This is just for debugging, doesn't affect rendering
  };
  
  return (
    <Box sx={{ width: '100%' }}>
      {showCreateTest ? (
        <>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
            <Typography variant="h5">Create New Test</Typography>
            <Button 
              variant="outlined" 
              onClick={toggleCreateTest}
            >
              Back to Tests
            </Button>
          </Box>
          
          <TestCreationModule 
            institutionId={institutionId} 
            token={token} 
            showNotification={showNotification}
            onTestCreated={handleTestCreated}
          />
        </>
      ) : (
        <>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
            <Typography variant="h5">Tests</Typography>
            <Box>
              <Button 
                variant="outlined" 
                startIcon={<FilterIcon />}
                onClick={() => setFilterDialogOpen(true)}
                sx={{ mr: 2 }}
              >
                Filters
              </Button>
              <Button 
                variant="contained" 
                startIcon={<AddIcon />}
                onClick={toggleCreateTest}
              >
                Create New Test
              </Button>
            </Box>
          </Box>
          
          <Paper sx={{ width: '100%', mb: 2 }}>
            <Box sx={{ p: 2, display: 'flex' }}>
              <TextField
                label="Search Tests"
                variant="outlined"
                name="search"
                value={searchParams.search}
                onChange={handleSearchParamChange}
                size="small"
                sx={{ mr: 2, width: 300 }}
                InputProps={{
                  endAdornment: (
                    <IconButton size="small" onClick={() => fetchTests()}>
                      <SearchIcon />
                    </IconButton>
                  )
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    fetchTests();
                  }
                }}
              />
            </Box>
            
            <TableContainer>
              <Table sx={{ minWidth: 750 }}>
                <TableHead>
                  <TableRow>
                    <TableCell>Title</TableCell>
                    <TableCell>Subject</TableCell>
                    <TableCell align="center">Questions</TableCell>
                    <TableCell align="center">Duration</TableCell>
                    <TableCell align="center">Status</TableCell>
                    <TableCell align="center">Schedule</TableCell>
                    <TableCell align="center">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading ? (
                    <TableRow>
                      <TableCell colSpan={7} align="center" sx={{ py: 3 }}>
                        <CircularProgress size={30} />
                      </TableCell>
                    </TableRow>
                  ) : tests.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={7} align="center" sx={{ py: 3 }}>
                        <Typography variant="body1" color="text.secondary">
                          No tests found. Create your first test.
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ) : (
                    tests.map((test) => (
                      <TableRow key={test._id}>
                        {debugTestObject(test)}
                        <TableCell>{test.title}</TableCell>
                        <TableCell>{test.subject ? test.subject.name : 'Unknown'}</TableCell>
                        <TableCell align="center">{test.questions?.length || 0}</TableCell>
                        <TableCell align="center">{test.duration} min</TableCell>
                        <TableCell align="center">
                          <Chip 
                            label={test.status.charAt(0).toUpperCase() + test.status.slice(1)} 
                            color={getStatusColor(test.status)}
                            size="small"
                          />
                        </TableCell>
                        <TableCell align="center">
                          {test.startDate ? (
                            <Box>
                              <Typography variant="caption" display="block">
                                Start: {formatDate(test.startDate)}
                              </Typography>
                              <Typography variant="caption" display="block">
                                End: {formatDate(test.endDate)}
                              </Typography>
                            </Box>
                          ) : (
                            'Not scheduled'
                          )}
                        </TableCell>
                        <TableCell align="center">
                          <Tooltip title="View">
                            <IconButton size="small">
                              <ViewIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Edit">
                            <IconButton size="small">
                              <EditIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Assign to Students">
                            <IconButton 
                              size="small"
                              onClick={() => handleAssignClick(test)}
                              disabled={test.status !== 'published'}
                            >
                              <AssignIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Delete">
                            <IconButton 
                              size="small" 
                              color="error"
                              onClick={() => handleDeleteClick(test)}
                            >
                              <DeleteIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={totalTests}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={(event, newPage) => {
                console.log('Page changed from', page, 'to', newPage);
                setPage(newPage);
              }}
              onRowsPerPageChange={(event) => {
                console.log('Rows per page changed to', event.target.value);
                setRowsPerPage(parseInt(event.target.value, 10));
                setPage(0);
              }}
            />
          </Paper>
          
          {/* Delete Confirmation Dialog */}
          <Dialog
            open={deleteDialogOpen}
            onClose={() => setDeleteDialogOpen(false)}
          >
            <DialogTitle>Delete Test</DialogTitle>
            <DialogContent>
              <Typography>
                Are you sure you want to delete the test: <strong>{testToDelete?.title}</strong>?
              </Typography>
              <Typography variant="body2" color="error" sx={{ mt: 2 }}>
                This action cannot be undone. All associated test assignments and attempts will also be deleted.
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
              <Button onClick={confirmDelete} color="error" variant="contained">
                Delete
              </Button>
            </DialogActions>
          </Dialog>
          
          {/* Assign Test Dialog */}
          <Dialog
            open={assignDialogOpen}
            onClose={handleCloseAssignDialog}
            maxWidth="md"
            fullWidth
          >
            <DialogTitle>Assign Test to Students</DialogTitle>
            <DialogContent>
              <Typography variant="h6" gutterBottom>
                {testToAssign?.title}
              </Typography>
              
              <Typography variant="body2" color="text.secondary" paragraph>
                Select students who should take this test. You can assign by class or individual students.
              </Typography>
              
              <Typography variant="subtitle2" color="primary" sx={{ mt: 3 }}>
                Student assignment functionality will be implemented in a future update.
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseAssignDialog}>Close</Button>
            </DialogActions>
          </Dialog>
          
          {/* Filter Dialog */}
          <Dialog
            open={filterDialogOpen}
            onClose={() => setFilterDialogOpen(false)}
          >
            <DialogTitle>Filter Tests</DialogTitle>
            <DialogContent>
              <Grid container spacing={2} sx={{ mt: 1 }}>
                <Grid item xs={12}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="filter-subject-label">Subject</InputLabel>
                    <Select
                      labelId="filter-subject-label"
                      name="subject"
                      value={searchParams.subject}
                      onChange={handleSearchParamChange}
                      label="Subject"
                    >
                      <MenuItem value="">All Subjects</MenuItem>
                      {subjects.map((subject) => (
                        <MenuItem key={subject._id} value={subject._id}>
                          {subject.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                
                <Grid item xs={12}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="filter-status-label">Status</InputLabel>
                    <Select
                      labelId="filter-status-label"
                      name="status"
                      value={searchParams.status}
                      onChange={handleSearchParamChange}
                      label="Status"
                    >
                      <MenuItem value="">All Statuses</MenuItem>
                      <MenuItem value="draft">Draft</MenuItem>
                      <MenuItem value="published">Published</MenuItem>
                      <MenuItem value="archived">Archived</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={resetFilters}>Reset</Button>
              <Button onClick={applyFilters} variant="contained">
                Apply Filters
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </Box>
  );
};

export default TestManagement;
