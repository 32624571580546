import React, { useState, useEffect, useRef } from 'react';
import { themeColors, shadows, spacing, borderRadius, animation } from '../theme';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import useMediaQuery from '@mui/material/useMediaQuery';

/**
 * Revamped Sidebar component with support for expanded and collapsed views
 * Now with hover-to-expand functionality on desktop and tablet
 */
const Sidebar = ({ expanded, onToggle, activeItem = 'dashboard', userInfo }) => {
  // State to track expanded submenu
  const [expandedSubmenu, setExpandedSubmenu] = useState('analytics');
  // State to track hover expansion
  const [hoverExpanded, setHoverExpanded] = useState(false);
  // Reference to the sidebar element
  const sidebarRef = useRef(null);
  // Media query for mobile view (less than 600px)
  const isMobile = useMediaQuery('(max-width:600px)');

  // Toggle submenu expanded/collapsed state
  const toggleSubmenu = (id) => {
    setExpandedSubmenu(expandedSubmenu === id ? null : id);
  };

  // Handle mouse enter - expand sidebar on hover for desktop/tablet
  const handleMouseEnter = () => {
    if (!isMobile) {
      setHoverExpanded(true);
    }
  };

  // Handle mouse leave - collapse sidebar when mouse leaves
  const handleMouseLeave = () => {
    if (!isMobile) {
      setHoverExpanded(false);
    }
  };

  // Handle click on sidebar - don't collapse when clicking inside
  const handleSidebarClick = (e) => {
    // Prevent collapsing when clicking inside the sidebar
    e.stopPropagation();
  };

  // Handle menu item click - collapse sidebar after clicking a menu item
  const handleMenuItemClick = (e, item) => {
    if (item.hasSubmenu) {
      e.preventDefault();
      toggleSubmenu(item.id);
    } else if (!isMobile) {
      setHoverExpanded(false);
    }
  };

  // Handle click outside the sidebar to collapse it when hover-expanded
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target) && hoverExpanded) {
        setHoverExpanded(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [hoverExpanded]);

  // Menu items configuration
  const menuItems = [
    {
      id: 'dashboard',
      label: 'Dashboard',
      icon: (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M3 13H11V3H3V13ZM3 21H11V15H3V21ZM13 21H21V11H13V21ZM13 3V9H21V3H13Z" fill="currentColor" />
        </svg>
      ),
      path: '/ui-revamp/dashboard',
    },
    {
      id: 'mcq',
      label: 'MCQ Practice',
      icon: (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M16.5 3C19.02 3 21 4.98 21 7.5C21 10.02 19.02 12 16.5 12C13.98 12 12 10.02 12 7.5C12 4.98 13.98 3 16.5 3ZM16.5 10.5C18.15 10.5 19.5 9.15 19.5 7.5C19.5 5.85 18.15 4.5 16.5 4.5C14.85 4.5 13.5 5.85 13.5 7.5C13.5 9.15 14.85 10.5 16.5 10.5ZM3 8.25V7.5H7.5V12.75C7.5 14.07 8.43 15 9.75 15C11.07 15 12 14.07 12 12.75V4.5C12 3.68 11.33 3 10.5 3H3V2.25C3 1.84 2.66 1.5 2.25 1.5C1.84 1.5 1.5 1.84 1.5 2.25V17.25C1.5 17.66 1.84 18 2.25 18C2.66 18 3 17.66 3 17.25V16.5H10.5C13.54 16.5 15 14.54 15 12.75V12H9.75C9.34 12 9 11.67 9 11.25V9H4.5V17.25C4.5 17.66 4.84 18 5.25 18C5.66 18 6 17.66 6 17.25V10.5H7.5V11.25C7.5 11.67 7.16 12 6.75 12C6.34 12 6 12.33 6 12.75C6 13.17 6.34 13.5 6.75 13.5H8.25C8.66 13.5 9 13.17 9 12.75C9 12.33 8.66 12 8.25 12C7.84 12 7.5 11.67 7.5 11.25V10.5H9V11.25C9 13.32 7.32 15 5.25 15C3.18 15 1.5 13.32 1.5 11.25V10.5C1.5 10.09 1.16 9.75 0.75 9.75C0.34 9.75 0 10.09 0 10.5V11.25C0 14.15 2.35 16.5 5.25 16.5C8.15 16.5 10.5 14.15 10.5 11.25V9.75H12C12.41 9.75 12.75 9.41 12.75 9C12.75 8.59 12.41 8.25 12 8.25H3Z" fill="currentColor" />
        </svg>
      ),
      path: '/ui-revamp/mcq',
    },
    {
      id: 'mcq-bank',
      label: 'MCQ Bank',
      icon: (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M4 6H2V20C2 21.1 2.9 22 4 22H18V20H4V6ZM20 2H8C6.9 2 6 2.9 6 4V16C6 17.1 6.9 18 8 18H20C21.1 18 22 17.1 22 16V4C22 2.9 21.1 2 20 2ZM20 16H8V4H20V16ZM10 9H18V11H10V9ZM10 12H14V14H10V12ZM10 6H18V8H10V6Z" fill="currentColor" />
        </svg>
      ),
      path: '/ui-revamp/mcq-bank',
      hasSubmenu: true,
      submenuItems: [
        {
          id: 'class-9',
          label: 'Class 9',
          path: '/ui-revamp/mcq-bank',
          state: { classId: 1 }
        },
        {
          id: 'class-10',
          label: 'Class 10',
          path: '/ui-revamp/mcq-bank',
          state: { classId: 2 }
        },
        {
          id: 'class-11',
          label: 'Class 11',
          path: '/ui-revamp/mcq-bank',
          state: { classId: 3 }
        },
        {
          id: 'class-12',
          label: 'Class 12',
          path: '/ui-revamp/mcq-bank',
          state: { classId: 4 }
        }
      ]
    },
    {
      id: 'tests',
      label: 'Tests',
      icon: (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M19 3H14.82C14.4 1.84 13.3 1 12 1C10.7 1 9.6 1.84 9.18 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3ZM12 3C12.55 3 13 3.45 13 4C13 4.55 12.55 5 12 5C11.45 5 11 4.55 11 4C11 3.45 11.45 3 12 3ZM14 17H7V15H14V17ZM17 13H7V11H17V13ZM17 9H7V7H17V9Z" fill="currentColor" />
        </svg>
      ),
      path: '/ui-revamp/tests',
    },
    {
      id: 'case-study',
      label: 'Case Studies',
      icon: (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M14 2H6C4.9 2 4 2.9 4 4V20C4 21.1 4.9 22 6 22H18C19.1 22 20 21.1 20 20V8L14 2ZM18 20H6V4H13V9H18V20ZM8 14H16V16H8V14ZM8 17H16V19H8V17ZM8 11H16V13H8V11Z" fill="currentColor" />
        </svg>
      ),
      path: '/ui-revamp/case-study',
    },
    {
      id: 'analytics',
      label: 'Analytics',
      icon: (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3ZM9 17H7V10H9V17ZM13 17H11V7H13V17ZM17 17H15V13H17V17Z" fill="currentColor" />
        </svg>
      ),
      path: '/ui-revamp/analytics',
      hasSubmenu: true,
      submenuItems: [
        {
          id: 'personalization',
          label: 'Personalization',
          path: '/ui-revamp/analytics/personalization',
        }
      ]
    },
  ];

  // User's recent topics (from history/sidebar)
  const recentTopics = [
    // {
    //   id: 'topic-1',
    //   title: 'TRAUMA',
    //   date: '16/03/2025, 21:25:13',
    //   isActive: false,
    // },
    // {
    //   id: 'topic-2',
    //   title: 'CORONA',
    //   date: '16/03/2025, 21:53:32',
    //   isActive: true,
    // },
    // {
    //   id: 'topic-3',
    //   title: 'NEUROLOGY',
    //   date: '11/03/2025, 23:11:59',
    //   isActive: false,
    // },
  ];

  const handleTopicClick = (topic) => {
    console.log(topic);
  };

  // Determine if sidebar should be shown as expanded
  const isExpanded = expanded || hoverExpanded;

  return (
    <aside
      ref={sidebarRef}
      style={{
        position: 'fixed',
        top: '64px', // Height of the TopBar
        left: 0,
        bottom: 0,
        width: isExpanded ? '240px' : '64px',
        backgroundColor: themeColors.background.sidebar,
        boxShadow: shadows.sm,
        transition: `width ${animation.normal} ${animation.timing}`,
        overflowX: 'hidden',
        zIndex: 1000,
        display: 'flex',
        flexDirection: 'column',
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleSidebarClick}
    >
      {/* Main navigation menu */}
      <nav
        style={{
          padding: isExpanded ? `${spacing(2)} ${spacing(1.5)}` : `${spacing(2)} ${spacing(1)}`,
          flex: '0 0 auto',
        }}
      >
        <ul
          style={{
            listStyle: 'none',
            padding: 0,
            margin: 0,
          }}
        >
          {menuItems.map((item) => (
            <li
              key={item.id}
              style={{
                marginBottom: spacing(0.5),
              }}
            >
              <a
                href={item.path}
                onClick={(e) => handleMenuItemClick(e, item)}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: spacing(1.5),
                  borderRadius: borderRadius.md,
                  textDecoration: 'none',
                  color: activeItem === item.id ? themeColors.primary.main : themeColors.text.primary,
                  backgroundColor: activeItem === item.id ? `${themeColors.primary.main}10` : 'transparent',
                  transition: `background-color ${animation.fast}`,
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                }}
                onMouseOver={(e) => {
                  if (activeItem !== item.id) {
                    e.currentTarget.style.backgroundColor = themeColors.neutral.grey[100];
                  }
                }}
                onMouseOut={(e) => {
                  if (activeItem !== item.id) {
                    e.currentTarget.style.backgroundColor = 'transparent';
                  }
                }}
              >
                {/* Icon container - always visible */}
                <div 
                  style={{ 
                    display: 'flex',
                    color: activeItem === item.id ? themeColors.primary.main : themeColors.text.secondary,
                  }}
                >
                  {item.icon}
                </div>
                
                {/* Label - only visible when expanded */}
                {isExpanded && (
                  <span
                    style={{
                      marginLeft: spacing(1.5),
                      fontWeight: activeItem === item.id ? 500 : 400,
                      fontSize: '0.875rem',
                    }}
                  >
                    {item.label}
                  </span>
                )}

                {/* Dropdown indicator for items with submenu - only visible when expanded */}
                {isExpanded && item.hasSubmenu && (
                  <div
                    style={{
                      marginLeft: 'auto',
                      transition: 'transform 0.2s ease',
                      transform: expandedSubmenu === item.id ? 'rotate(180deg)' : 'rotate(0deg)',
                    }}
                  >
                    <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M7 10l5 5 5-5z" fill="currentColor" />
                    </svg>
                  </div>
                )}
              </a>

              {/* Submenu items - only visible when parent is expanded */}
              {item.hasSubmenu && isExpanded && (
                <div
                  style={{
                    height: expandedSubmenu === item.id ? `${item.submenuItems.length * 40}px` : '0px',
                    overflow: 'hidden',
                    transition: 'height 0.2s ease',
                  }}
                >
                  <ul
                    style={{
                      listStyle: 'none',
                      padding: 0,
                      margin: 0,
                    }}
                  >
                    {item.submenuItems.map((subItem) => (
                      <li key={subItem.id}>
                        <a
                          href={subItem.path}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            padding: `${spacing(1)} ${spacing(1.5)} ${spacing(1)} ${spacing(4.5)}`,
                            borderRadius: borderRadius.md,
                            textDecoration: 'none',
                            color: themeColors.text.primary,
                            transition: `background-color ${animation.fast}`,
                            fontSize: '0.8125rem',
                          }}
                          onMouseOver={(e) => {
                            e.currentTarget.style.backgroundColor = themeColors.neutral.grey[100];
                          }}
                          onMouseOut={(e) => {
                            e.currentTarget.style.backgroundColor = 'transparent';
                          }}
                          onClick={() => {
                            if (hoverExpanded && !isMobile) {
                              // Close the sidebar if it was expanded by hover
                              setHoverExpanded(false);
                            }
                          }}
                        >
                          {subItem.label}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </li>
          ))}
        </ul>
      </nav>

      {/* Divider */}
      <div
        style={{
          height: '1px',
          backgroundColor: themeColors.neutral.grey[200],
          margin: `${spacing(1)} ${spacing(2)}`,
        }}
      />

      {/* Recent topics section - only visible when expanded */}
      <div
        style={{
          flex: '1 1 auto',
          overflowY: 'auto',
          padding: isExpanded ? 0 : `${spacing(2)} ${spacing(1)}`,
          display: isExpanded ? 'block' : 'none',
          backgroundColor: 'white',
        }}
      >
        {/* <h2
          style={{
            fontSize: '0.75rem',
            fontWeight: 700,
            textTransform: 'uppercase',
            color: '#333',
            margin: 0,
            padding: `${spacing(2)} ${spacing(2)}`,
            letterSpacing: '0.1em',
            backgroundColor: 'white',
          }}
        >
          Recent Topics
        </h2> */}

        <ul
          style={{
            listStyle: 'none',
            padding: 0,
            margin: 0,
          }}
        >
          {recentTopics.map((topic) => (
            <li
              key={topic.id}
              onClick={() => {
                handleTopicClick(topic);
                if (hoverExpanded && !isMobile) {
                  // Close the sidebar if it was expanded by hover
                  setHoverExpanded(false);
                }
              }}
              style={{
                padding: `${spacing(1.5)} ${spacing(2)}`,
                fontSize: '0.875rem',
                color: '#333',
                cursor: 'pointer',
                display: 'flex',
                flexDirection: 'column',
                transition: 'background-color 0.2s',
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.backgroundColor = '#f5f5f5';
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.backgroundColor = 'transparent';
              }}
            >
              <span style={{ marginBottom: spacing(0.5) }}>{topic.title}</span>
              <span style={{ fontSize: '0.75rem', color: '#999', display: 'flex', alignItems: 'center' }}>
                <AccessTimeIcon style={{ fontSize: '0.875rem', marginRight: spacing(0.5) }} />
                {topic.date}
              </span>
            </li>
          ))}
        </ul>
      </div>

      {/* Toggle expand/collapse button */}
      <div
        style={{
          flex: '0 0 auto',
          padding: spacing(2),
          display: 'flex',
          justifyContent: isExpanded ? 'flex-end' : 'center',
        }}
      >
        <button
          onClick={(e) => {
            e.stopPropagation(); // Prevent sidebar click handler from firing
            onToggle();
            setHoverExpanded(false); // Reset hover state when manually toggled
          }}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '36px',
            height: '36px',
            borderRadius: '50%',
            border: 'none',
            backgroundColor: themeColors.neutral.grey[100],
            cursor: 'pointer',
            transition: `background-color ${animation.fast}`,
          }}
          onMouseOver={(e) => {
            e.currentTarget.style.backgroundColor = themeColors.neutral.grey[200];
          }}
          onMouseOut={(e) => {
            e.currentTarget.style.backgroundColor = themeColors.neutral.grey[100];
          }}
          aria-label={isExpanded ? 'Collapse sidebar' : 'Expand sidebar'}
        >
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d={isExpanded 
              ? "M15.41 16.59L10.83 12L15.41 7.41L14 6L8 12L14 18L15.41 16.59Z" 
              : "M8.59 16.59L13.17 12L8.59 7.41L10 6L16 12L10 18L8.59 16.59Z"} 
              fill={themeColors.text.primary} />
          </svg>
        </button>
      </div>
    </aside>
  );
};

export default Sidebar;
