import React, { useState, useEffect, useRef, createContext, useContext } from 'react';
import axios from 'axios';
import config from '../../../config';
import { Fade, Avatar, Typography, Box, IconButton, Paper } from '@mui/material';
import { ThumbUp, ThumbDown, Flag } from '@mui/icons-material';
import ReactMarkdown from 'react-markdown';
import { themeColors, borderRadius, shadows } from '../theme';

// Create the context
const CaseStudyContext = createContext();

// User message component
const UserMessage = ({ text, timestamp }) => (
  <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2, width: '100%' }}>
    <Box sx={{ maxWidth: '75%' }}>
      <Paper
        elevation={1}
        sx={{
          p: 2,
          borderRadius: '12px 12px 0 12px',
          backgroundColor: themeColors.primary.main,
          color: themeColors.primary.contrastText,
        }}
      >
        <Typography>{text}</Typography>
      </Paper>
      <Typography 
        variant="caption" 
        sx={{ 
          display: 'block', 
          textAlign: 'right',
          color: themeColors.text.secondary,
          mt: 0.5
        }}
      >
        {timestamp}
      </Typography>
    </Box>
    <Avatar 
      sx={{ 
        bgcolor: themeColors.secondary.main, 
        width: 32, 
        height: 32, 
        ml: 1,
        mt: 1
      }}
    >
      U
    </Avatar>
  </Box>
);

// AI message component
const AIMessage = ({ text, timestamp, id, onFeedback, onReport }) => (
  <Box sx={{ display: 'flex', justifyContent: 'flex-start', mb: 2, width: '100%' }}>
    <Avatar 
      sx={{ 
        bgcolor: themeColors.primary.main, 
        width: 32, 
        height: 32, 
        mr: 1,
        mt: 1
      }}
    >
      AI
    </Avatar>
    <Box sx={{ maxWidth: '75%' }}>
      <Paper
        elevation={1}
        sx={{
          p: 2,
          borderRadius: '12px 12px 12px 0',
          backgroundColor: themeColors.background.default,
          color: themeColors.text.primary,
        }}
      >
        <div className="markdown-content">
          <ReactMarkdown>{text}</ReactMarkdown>
        </div>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            mt: 1
          }}
        >
          <IconButton 
            size="small" 
            onClick={() => onFeedback(id, 'like')}
            sx={{ color: themeColors.text.secondary }}
          >
            <ThumbUp fontSize="small" />
          </IconButton>
          <IconButton 
            size="small" 
            onClick={() => onFeedback(id, 'dislike')}
            sx={{ color: themeColors.text.secondary }}
          >
            <ThumbDown fontSize="small" />
          </IconButton>
          <IconButton 
            size="small" 
            onClick={() => onReport(id)}
            sx={{ color: themeColors.text.secondary }}
          >
            <Flag fontSize="small" />
          </IconButton>
        </Box>
      </Paper>
      <Typography 
        variant="caption" 
        sx={{ 
          display: 'block', 
          textAlign: 'left',
          color: themeColors.text.secondary,
          mt: 0.5
        }}
      >
        {timestamp}
      </Typography>
    </Box>
  </Box>
);

/**
 * Provider component for case study functionality
 * Manages state and API interactions for case studies
 */
const CaseStudyProvider = ({ children }) => {
  const [chats, setChats] = useState([]);
  const [selectedChatId, setSelectedChatId] = useState(null);
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingChats, setLoadingChats] = useState(false);
  const [loadingMessages, setLoadingMessages] = useState(false);
  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);
  
  const messageContainerRef = useRef(null);
  const fileInputRef = useRef(null);
  
  const API_URL = config.API_URL || 'http://localhost:2004';
  
  // Initialize with empty chat window
  useEffect(() => {
    // Set initial state to show an empty chat window but don't create a new chat
    setSelectedChatId(null);
    setMessages([]);
  }, []);
  
  // Fetch user chats on component mount
  useEffect(() => {
    fetchUserChats();
  }, []);
  
  // Reset messages when selectedChatId is cleared
  useEffect(() => {
    if (!selectedChatId) {
      setMessages([]);
    }
  }, [selectedChatId]);
  
  // Fetch chat messages when a chat is selected from history
  useEffect(() => {
    if (selectedChatId) {
      // Only fetch messages if the chat was selected from history
      // and not if it was just created
      if (!messages.length) {
        fetchChatMessages(selectedChatId);
      }
    }
  }, [selectedChatId, messages]);
  
  // Scroll to bottom when messages change
  useEffect(() => {
    if (messageContainerRef.current && messages.length > 0) {
      // Add a small delay to ensure DOM is fully rendered
      const timeoutId = setTimeout(() => {
        try {
          if (messageContainerRef.current) {
            messageContainerRef.current.scrollTop = messageContainerRef.current.scrollHeight;
          }
        } catch (error) {
          console.error('Error scrolling to bottom:', error);
        }
      }, 100);
      
      return () => clearTimeout(timeoutId);
    }
  }, [messages]);
  
  // Fetch user chats from API
  const fetchUserChats = async () => {
    try {
      setLoadingChats(true);
      const token = localStorage.getItem('token');
      
      if (!token) {
        throw new Error('Authentication token not found');
      }
      
      const response = await axios.get(`${API_URL}/api/userchats`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      
      // Sort chats in descending order by date
      const sortedChats = response.data.sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
      }).map(chat => ({
        ...chat,
        // Format the date correctly using the actual date from the API
        formattedDate: new Date(chat.createdAt).toLocaleString('en-US', {
          day: 'numeric',
          month: 'long',
          year: 'numeric'
        })
      }));
      
      setChats(sortedChats);
      setLoadingChats(false);
    } catch (error) {
      console.error('Error fetching user chats:', error);
      setError('Failed to load chat history. Please try again later.');
      setLoadingChats(false);
    }
  };
  
  // Fetch chat messages for a specific chat ID
  const fetchChatMessages = async (chatId) => {
    try {
      console.log("Fetching messages for chat ID:", chatId);
      setLoadingMessages(true);
      const token = localStorage.getItem('token');
      
      if (!token) {
        throw new Error('Authentication token not found');
      }
      
      const response = await axios.get(`${API_URL}/api/chats/${chatId}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      
      console.log("Chat messages response:", response.data);
      
      // Transform the API response to our message format
      const formattedMessages = [];
      
      if (response.data && response.data.history && Array.isArray(response.data.history)) {
        response.data.history.forEach(item => {
          if (item && item.parts && item.parts.length > 0) {
            formattedMessages.push({
              id: item._id || Date.now().toString(),
              sender: item.role === 'user' ? 'user' : 'model',
              text: item.parts[0].text || '',
              timestamp: new Date(item.createdAt || response.data.updatedAt).toLocaleString('en-US', {
                day: 'numeric',
                month: 'long',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit'
              })
            });
          }
        });
      }
      
      console.log("Formatted messages:", formattedMessages);
      
      setMessages(formattedMessages);
      setLoadingMessages(false);
    } catch (error) {
      console.error('Error fetching chat messages:', error);
      setError('Failed to load chat messages. Please try again later.');
      setLoadingMessages(false);
    }
  };
  
  // Generate text using AI
  const generateText = async (text, chatId) => {
    try {
      setLoading(true);
      const token = localStorage.getItem('token');
      
      if (!token) {
        throw new Error('Authentication token not found');
      }
      
      // Log the current messages before API call
      console.log("Messages before API call:", messages);
      
      // First, generate the AI response
      const generateResponse = await axios.post(`${API_URL}/api/generateText`, 
        {
          payload: {
            data: [text],
            chatId: chatId
          }
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      
      const aiResponseText = generateResponse.data.results;
      
      // Then, save the conversation with both user input and AI response
      const updateResponse = await axios.put(`${API_URL}/api/chats/${chatId}`,
        {
          question: text,
          answer: aiResponseText
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      
      console.log("Conversation saved:", updateResponse.data);
      
      // Add AI response to messages
      const newMessage = {
        id: updateResponse.data.id || Date.now().toString(),
        sender: 'model',
        text: aiResponseText,
        timestamp: new Date().toLocaleString('en-US', {
          day: 'numeric',
          month: 'long',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit'
        })
      };
      
      // Log the current messages and the new message
      console.log("Current messages:", messages);
      console.log("New AI message:", newMessage);
      
      // Make sure we update the messages state with the latest messages
      setMessages(prevMessages => {
        console.log("Previous messages in state update:", prevMessages);
        return [...prevMessages, newMessage];
      });
      
      setLoading(false);
      
      return newMessage;
    } catch (error) {
      console.error('Error generating text:', error);
      setError('Failed to generate response. Please try again later.');
      setLoading(false);
      return null;
    }
  };
  
  // Handle input change
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };
  
  // Handle file change
  const handleFileChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setFile(e.target.files[0]);
    }
  };
  
  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!inputValue.trim() && !file) return;
    
    const text = inputValue.trim();
    setInputValue('');
    
    try {
      setLoading(true);
      const token = localStorage.getItem('token');
      
      if (!token) {
        throw new Error('Authentication token not found');
      }
      
      // Create user message object
      const userMessage = {
        id: Date.now().toString(),
        sender: 'user',
        text: text,
        timestamp: new Date().toLocaleString('en-US', {
          day: 'numeric',
          month: 'long',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit'
        })
      };
      
      let chatId = selectedChatId;
      
      if (!chatId) {
        // Step 1: Create a new chat first
        console.log("Creating new chat with text:", text);
        const createChatResponse = await axios.post(
          `${API_URL}/api/chats`, 
          { text },
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );
        
        chatId = createChatResponse.data;
        console.log("New chat created with ID:", chatId);
        setSelectedChatId(chatId);
        
        // Step 2: Immediately add user message to UI
        setMessages([userMessage]);
        
        // Step 3: Generate AI response
        console.log("Generating AI response for new chat");
        const aiMessage = await generateTextOnly(text, chatId);
        
        // Step 4: Update the chat with both user and AI messages
        console.log("Updating chat with user and AI messages");
        await updateChat(chatId, text, aiMessage.text);
        
        // Step 5: Refresh chat list to show the new chat
        fetchUserChats();
      } else {
        // For existing chats, add user message to UI immediately
        setMessages(prevMessages => [...prevMessages, userMessage]);
        
        // Generate AI response
        console.log("Generating AI response for existing chat");
        const aiMessage = await generateTextOnly(text, chatId);
        
        // Update the chat with both user and AI messages
        console.log("Updating chat with user and AI messages");
        await updateChat(chatId, text, aiMessage.text);
      }
      
      setLoading(false);
    } catch (error) {
      console.error('Error in chat submission:', error);
      setError('Failed to process your request. Please try again later.');
      setLoading(false);
    }
  };
  
  // Generate text only (without updating UI)
  const generateTextOnly = async (text, chatId) => {
    try {
      const token = localStorage.getItem('token');
      
      if (!token) {
        throw new Error('Authentication token not found');
      }
      
      console.log("Calling generateText API with chatId:", chatId);
      const generateResponse = await axios.post(
        `${API_URL}/api/generateText`, 
        {
          payload: {
            data: [text],
            chatId: chatId
          }
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      
      const aiResponseText = generateResponse.data.results;
      
      // Create AI message object
      const aiMessage = {
        id: Date.now().toString(),
        sender: 'model',
        text: aiResponseText,
        timestamp: new Date().toLocaleString('en-US', {
          day: 'numeric',
          month: 'long',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit'
        })
      };
      
      // Update UI with AI message
      setMessages(prevMessages => [...prevMessages, aiMessage]);
      
      return aiMessage;
    } catch (error) {
      console.error('Error generating text:', error);
      setError('Failed to generate response. Please try again later.');
      return null;
    }
  };
  
  // Update chat with user and AI messages
  const updateChat = async (chatId, question, answer) => {
    try {
      const token = localStorage.getItem('token');
      
      if (!token) {
        throw new Error('Authentication token not found');
      }
      
      console.log("Updating chat with ID:", chatId);
      const updateResponse = await axios.put(
        `${API_URL}/api/chats/${chatId}`,
        {
          question: question,
          answer: answer
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      
      console.log("Chat updated successfully:", updateResponse.data);
      return updateResponse.data;
    } catch (error) {
      console.error('Error updating chat:', error);
      setError('Failed to save conversation. Please try again later.');
      return null;
    }
  };
  
  // Handle chat selection
  const handleChatSelect = (chatId) => {
    console.log("Chat selected with ID:", chatId);
    setSelectedChatId(chatId);
    // Clear existing messages to ensure we fetch fresh ones
    setMessages([]);
    // Explicitly fetch chat messages when a chat is selected
    fetchChatMessages(chatId);
  };
  
  // Handle new chat button click
  const handleNewChat = () => {
    setSelectedChatId(null);
    setMessages([]);
    setInputValue('');
    setFile(null);
    setError(null);
    
    // Focus on the input field
    setTimeout(() => {
      const inputField = document.getElementById('case-study-input');
      if (inputField) {
        inputField.focus();
      }
    }, 100);
  };
  
  // Handle like/dislike actions
  const handleFeedback = (messageId, type) => {
    // Implement feedback functionality
    console.log(`Message ${messageId} received ${type} feedback`);
  };
  
  // Handle report action
  const handleReport = (messageId) => {
    // Implement report functionality
    console.log(`Message ${messageId} reported`);
  };
  
  // Render a message
  const renderMessage = (message) => {
    const isUser = message.sender === 'user';
    
    // Create a UserMessage component
    const UserMessage = () => (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end',
          mb: 2,
          px: { xs: 1, sm: 2 }
        }}
      >
        <Box
          sx={{
            maxWidth: { xs: '85%', sm: '70%' },
            backgroundColor: themeColors.primary.main,
            color: '#fff',
            p: 2,
            borderRadius: '24px 24px 0 24px',
            boxShadow: shadows[1],
            wordBreak: 'break-word'
          }}
        >
          <Typography variant="body1">{message.text}</Typography>
        </Box>
        <Typography 
          variant="caption" 
          sx={{ 
            mt: 0.5, 
            mr: 1, 
            color: themeColors.text.secondary,
            fontSize: '0.7rem'
          }}
        >
          {message.timestamp}
        </Typography>
      </Box>
    );
    
    // Create an AIMessage component
    const AIMessage = () => (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          mb: 2,
          px: { xs: 1, sm: 2 }
        }}
      >
        <Box
          sx={{
            maxWidth: { xs: '85%', sm: '70%' },
            backgroundColor: 'rgba(33, 150, 243, 0.08)',
            p: 2,
            borderRadius: '24px 24px 24px 0',
            boxShadow: shadows[1],
            wordBreak: 'break-word'
          }}
        >
          <Typography variant="body1">{message.text}</Typography>
        </Box>
        <Typography 
          variant="caption" 
          sx={{ 
            mt: 0.5, 
            ml: 1, 
            color: themeColors.text.secondary,
            fontSize: '0.7rem'
          }}
        >
          {message.timestamp}
        </Typography>
      </Box>
    );
    
    return isUser ? <UserMessage key={message.id} /> : <AIMessage key={message.id} />;
  };
  
  // Context value
  const contextValue = {
    chats,
    selectedChatId,
    messages,
    inputValue,
    loading,
    loadingChats,
    loadingMessages,
    file,
    error,
    messageContainerRef,
    fileInputRef,
    API_URL,
    setSelectedChatId,
    fetchUserChats,
    fetchChatMessages,
    generateText,
    handleInputChange,
    handleFileChange,
    handleSubmit,
    handleChatSelect,
    handleNewChat,
    handleFeedback,
    handleReport,
    renderMessage,
    setInputValue,
    setFile,
    setError
  };
  
  return (
    <CaseStudyContext.Provider value={contextValue}>
      {children}
    </CaseStudyContext.Provider>
  );
};

// Custom hook to use the case study context
export const useCaseStudy = () => {
  const context = useContext(CaseStudyContext);
  if (!context) {
    throw new Error('useCaseStudy must be used within a CaseStudyProvider');
  }
  return context;
};

export default CaseStudyProvider;
