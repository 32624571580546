import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Paper, 
  Typography, 
  TextField, 
  Button, 
  Grid, 
  Card, 
  CardContent, 
  CardActions, 
  IconButton, 
  InputAdornment,
  Pagination,
  CircularProgress,
  Chip,
  Breadcrumbs,
  Link,
  useTheme,
  Tooltip,
  Avatar,
  CardHeader,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Switch,
  Divider,
  Rating
} from '@mui/material';
import { 
  Add as AddIcon, 
  Search as SearchIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  ArrowBack as ArrowBackIcon,
  Topic as TopicIcon,
  Class as ClassIcon,
  QuestionAnswer as QuestionAnswerIcon,
  BookmarkBorder as BookmarkIcon,
  Public as PublicIcon,
  Business as BusinessIcon,
  FilterList as FilterListIcon,
  Sort as SortIcon
} from '@mui/icons-material';
import API from '../../services/api';
import blueTheme from '../../theme/blueTheme';

const TopicManagement = ({ 
  topics,
  selectedSubject,
  setSelectedSubject,
  onAddTopic,
  onEditTopic,
  onDeleteTopic,
  onManageMCQs,
  getToken, 
  user, 
  showNotification
}) => {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredTopics, setFilteredTopics] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    difficulty: 2,
    isGlobal: false,
    subjectId: ''
  });
  const [sortOption, setSortOption] = useState('name-asc');
  const [filterOptions, setFilterOptions] = useState({
    visibility: 'all', // 'all', 'global', 'institute'
    difficulty: 'all' // 'all', 'easy', 'medium', 'hard'
  });

  useEffect(() => {
    if (topics && topics.length > 0) {
      applyFiltersAndSort();
    } else {
      setFilteredTopics([]);
    }
  }, [topics, searchTerm, filterOptions, sortOption]);

  useEffect(() => {
    if (selectedSubject) {
      setFormData(prev => ({
        ...prev,
        subjectId: selectedSubject.id
      }));
    }
  }, [selectedSubject]);

  const applyFiltersAndSort = () => {
    let result = [...topics];
    
    // Apply search filter
    if (searchTerm) {
      result = result.filter(topic => 
        topic.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        topic.description?.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
    
    // Apply visibility filter
    if (filterOptions.visibility !== 'all') {
      result = result.filter(topic => 
        filterOptions.visibility === 'global' ? topic.isGlobal : !topic.isGlobal
      );
    }
    
    // Apply difficulty filter
    if (filterOptions.difficulty !== 'all') {
      const difficultyMap = {
        'easy': 1,
        'medium': 2,
        'hard': 3
      };
      
      result = result.filter(topic => 
        topic.difficulty === difficultyMap[filterOptions.difficulty]
      );
    }
    
    // Apply sorting
    result.sort((a, b) => {
      const [field, direction] = sortOption.split('-');
      
      if (field === 'name') {
        return direction === 'asc' 
          ? a.name.localeCompare(b.name) 
          : b.name.localeCompare(a.name);
      }
      
      if (field === 'mcqs') {
        return direction === 'asc' 
          ? (a.mcqCount || 0) - (b.mcqCount || 0) 
          : (b.mcqCount || 0) - (a.mcqCount || 0);
      }
      
      if (field === 'difficulty') {
        return direction === 'asc' 
          ? (a.difficulty || 0) - (b.difficulty || 0) 
          : (b.difficulty || 0) - (a.difficulty || 0);
      }
      
      if (field === 'date') {
        return direction === 'asc' 
          ? new Date(a.createdAt) - new Date(b.createdAt) 
          : new Date(b.createdAt) - new Date(a.createdAt);
      }
      
      return 0;
    });
    
    setFilteredTopics(result);
  };

  const handleOpenDialog = (topic = null) => {
    if (topic) {
      setSelectedTopic(topic);
      setFormData({
        name: topic.name,
        description: topic.description || '',
        difficulty: topic.difficulty || 2,
        isGlobal: topic.isGlobal || false,
        subjectId: topic.subjectId || selectedSubject?.id || ''
      });
    } else {
      setSelectedTopic(null);
      setFormData({
        name: '',
        description: '',
        difficulty: 2,
        isGlobal: false,
        subjectId: selectedSubject?.id || ''
      });
    }
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedTopic(null);
  };

  const handleInputChange = (e) => {
    const { name, value, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: name === 'isGlobal' ? checked : value
    }));
  };

  const handleDifficultyChange = (event, newValue) => {
    setFormData(prev => ({
      ...prev,
      difficulty: newValue
    }));
  };

  const handleSubmit = async () => {
    // Validation
    if (!formData.name.trim()) {
      showNotification('error', 'Topic name is required');
      return;
    }
    
    if (!formData.subjectId) {
      showNotification('error', 'Subject is required');
      return;
    }
    
    try {
      setLoading(true);
      
      if (selectedTopic) {
        // Update existing topic
        await onEditTopic({
          ...selectedTopic,
          ...formData
        });
        showNotification('success', 'Topic updated successfully');
      } else {
        // Create new topic
        await onAddTopic(formData);
        showNotification('success', 'Topic created successfully');
      }
      
      handleCloseDialog();
    } catch (error) {
      console.error('Error saving topic:', error);
      showNotification('error', 'Failed to save topic');
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (topic) => {
    if (window.confirm(`Are you sure you want to delete "${topic.name}"?`)) {
      try {
        setLoading(true);
        await onDeleteTopic(topic);
        showNotification('success', 'Topic deleted successfully');
      } catch (error) {
        console.error('Error deleting topic:', error);
        showNotification('error', 'Failed to delete topic');
      } finally {
        setLoading(false);
      }
    }
  };

  const getDifficultyLabel = (level) => {
    switch (level) {
      case 1: return 'Easy';
      case 2: return 'Medium';
      case 3: return 'Hard';
      default: return 'Medium';
    }
  };

  const getDifficultyColor = (level) => {
    switch (level) {
      case 1: return 'success';
      case 2: return 'warning';
      case 3: return 'error';
      default: return 'warning';
    }
  };

  const getVisibilityLabel = (isGlobal) => {
    return isGlobal ? 'Global' : 'Institute';
  };

  const getVisibilityIcon = (isGlobal) => {
    return isGlobal ? <PublicIcon fontSize="small" /> : <BusinessIcon fontSize="small" />;
  };

  const getVisibilityColor = (isGlobal) => {
    return isGlobal ? 'primary' : 'default';
  };

  return (
    <Box>
      <Box sx={{ mb: 3 }}>
        <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2 }}>
          <Link
            underline="hover"
            color="inherit"
            sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
            onClick={() => setSelectedSubject(null)}
          >
            <ClassIcon sx={{ mr: 0.5 }} fontSize="small" />
            Subjects
          </Link>
          <Typography color="text.primary" sx={{ display: 'flex', alignItems: 'center' }}>
            <TopicIcon sx={{ mr: 0.5 }} fontSize="small" />
            {selectedSubject?.name || 'Topics'}
          </Typography>
        </Breadcrumbs>
        
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton 
              color="primary" 
              onClick={() => setSelectedSubject(null)}
              sx={{ mr: 1 }}
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h4" component="h1">
              {selectedSubject?.name || 'Topics'} - Topics
            </Typography>
          </Box>
          <Button 
            variant="contained" 
            color="primary" 
            startIcon={<AddIcon />}
            onClick={() => handleOpenDialog()}
            sx={{ mt: { xs: 2, sm: 0 } }}
          >
            Add Topic
          </Button>
        </Box>
      </Box>

      <Paper sx={{ p: 2, mb: 3 }}>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, alignItems: 'center', mb: 2 }}>
          <TextField
            placeholder="Search topics..."
            variant="outlined"
            size="small"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon fontSize="small" />
                </InputAdornment>
              ),
            }}
            sx={{ flexGrow: 1, maxWidth: { xs: '100%', sm: 300 } }}
          />
          
          <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
            <FormControl size="small" sx={{ minWidth: 120 }}>
              <InputLabel id="visibility-filter-label">Visibility</InputLabel>
              <Select
                labelId="visibility-filter-label"
                value={filterOptions.visibility}
                label="Visibility"
                onChange={(e) => setFilterOptions(prev => ({ ...prev, visibility: e.target.value }))}
              >
                <MenuItem value="all">All</MenuItem>
                <MenuItem value="global">Global</MenuItem>
                <MenuItem value="institute">Institute</MenuItem>
              </Select>
            </FormControl>
            
            <FormControl size="small" sx={{ minWidth: 120 }}>
              <InputLabel id="difficulty-filter-label">Difficulty</InputLabel>
              <Select
                labelId="difficulty-filter-label"
                value={filterOptions.difficulty}
                label="Difficulty"
                onChange={(e) => setFilterOptions(prev => ({ ...prev, difficulty: e.target.value }))}
              >
                <MenuItem value="all">All</MenuItem>
                <MenuItem value="easy">Easy</MenuItem>
                <MenuItem value="medium">Medium</MenuItem>
                <MenuItem value="hard">Hard</MenuItem>
              </Select>
            </FormControl>
            
            <FormControl size="small" sx={{ minWidth: 150 }}>
              <InputLabel id="sort-label">Sort By</InputLabel>
              <Select
                labelId="sort-label"
                value={sortOption}
                label="Sort By"
                onChange={(e) => setSortOption(e.target.value)}
              >
                <MenuItem value="name-asc">Name (A-Z)</MenuItem>
                <MenuItem value="name-desc">Name (Z-A)</MenuItem>
                <MenuItem value="mcqs-desc">Most MCQs</MenuItem>
                <MenuItem value="mcqs-asc">Least MCQs</MenuItem>
                <MenuItem value="difficulty-asc">Difficulty (Low-High)</MenuItem>
                <MenuItem value="difficulty-desc">Difficulty (High-Low)</MenuItem>
                <MenuItem value="date-desc">Newest</MenuItem>
                <MenuItem value="date-asc">Oldest</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Box>
        
        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
            <CircularProgress />
          </Box>
        ) : filteredTopics.length === 0 ? (
          <Box sx={{ textAlign: 'center', py: 4 }}>
            <Typography variant="body1" color="text.secondary">
              No topics found. {searchTerm && 'Try a different search term.'}
            </Typography>
            <Button 
              variant="contained" 
              color="primary" 
              startIcon={<AddIcon />}
              onClick={() => handleOpenDialog()}
              sx={{ mt: 2 }}
            >
              Add Topic
            </Button>
          </Box>
        ) : (
          <>
            <Grid container spacing={3}>
              {filteredTopics.map((topic) => (
                <Grid item xs={12} sm={6} md={4} key={topic.id}>
                  <Card sx={{ 
                    height: '100%', 
                    display: 'flex', 
                    flexDirection: 'column',
                    transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
                    '&:hover': {
                      transform: 'translateY(-4px)',
                      boxShadow: 4
                    }
                  }}>
                    <CardHeader
                      avatar={
                        <Avatar sx={{ bgcolor: theme.palette[getDifficultyColor(topic.difficulty)].main }}>
                          <TopicIcon />
                        </Avatar>
                      }
                      action={
                        <Chip 
                          icon={getVisibilityIcon(topic.isGlobal)}
                          label={getVisibilityLabel(topic.isGlobal)}
                          size="small"
                          color={getVisibilityColor(topic.isGlobal)}
                          variant={topic.isGlobal ? "filled" : "outlined"}
                        />
                      }
                      title={topic.name}
                      subheader={
                        <Box sx={{ display: 'flex', alignItems: 'center', mt: 0.5 }}>
                          <Chip 
                            label={getDifficultyLabel(topic.difficulty)} 
                            size="small" 
                            color={getDifficultyColor(topic.difficulty)}
                            sx={{ mr: 1 }}
                          />
                          <Typography variant="caption" color="text.secondary">
                            {topic.mcqCount || 0} MCQs
                          </Typography>
                        </Box>
                      }
                    />
                    <CardContent sx={{ flexGrow: 1, pt: 0 }}>
                      <Typography variant="body2" color="text.secondary" sx={{ 
                        display: '-webkit-box',
                        WebkitLineClamp: 3,
                        WebkitBoxOrient: 'vertical',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        minHeight: '60px'
                      }}>
                        {topic.description || 'No description provided.'}
                      </Typography>
                    </CardContent>
                    <Divider />
                    <CardActions sx={{ justifyContent: 'space-between', px: 2, py: 1 }}>
                      <Box>
                        <Tooltip title="Edit Topic">
                          <IconButton 
                            size="small" 
                            onClick={() => handleOpenDialog(topic)}
                            aria-label="edit topic"
                          >
                            <EditIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete Topic">
                          <IconButton 
                            size="small" 
                            onClick={() => handleDelete(topic)}
                            aria-label="delete topic"
                            color="error"
                          >
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </Box>
                      <Button
                        size="small"
                        variant="outlined"
                        startIcon={<QuestionAnswerIcon />}
                        onClick={() => onManageMCQs(topic)}
                      >
                        Manage MCQs
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </>
        )}
      </Paper>

      {/* Add/Edit Topic Dialog */}
      <Dialog 
        open={openDialog} 
        onClose={handleCloseDialog}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          {selectedTopic ? 'Edit Topic' : 'Add New Topic'}
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                name="name"
                label="Topic Name"
                fullWidth
                value={formData.name}
                onChange={handleInputChange}
                required
                margin="normal"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="description"
                label="Description"
                fullWidth
                multiline
                rows={3}
                value={formData.description}
                onChange={handleInputChange}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ mt: 2, mb: 1 }}>
                <Typography component="legend">Difficulty Level</Typography>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Rating
                    name="difficulty"
                    value={formData.difficulty}
                    onChange={handleDifficultyChange}
                    max={3}
                  />
                  <Box sx={{ ml: 2 }}>
                    <Chip 
                      label={getDifficultyLabel(formData.difficulty)} 
                      size="small" 
                      color={getDifficultyColor(formData.difficulty)}
                    />
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ 
                p: 2, 
                border: '1px solid', 
                borderColor: 'divider', 
                borderRadius: 1,
                bgcolor: formData.isGlobal ? 'primary.50' : 'background.paper',
                mt: 2
              }}>
                <Typography variant="subtitle1" gutterBottom>
                  Visibility Settings
                </Typography>
                <FormControlLabel
                  control={
                    <Switch
                      name="isGlobal"
                      checked={formData.isGlobal}
                      onChange={handleInputChange}
                      color="primary"
                    />
                  }
                  label={
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      {formData.isGlobal ? <PublicIcon sx={{ mr: 1 }} /> : <BusinessIcon sx={{ mr: 1 }} />}
                      <Typography>
                        {formData.isGlobal ? 'Global (available to all institutes)' : 'Institute-specific'}
                      </Typography>
                    </Box>
                  }
                />
                <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                  {formData.isGlobal 
                    ? 'Global topics are shared across all institutes and cannot be modified by individual institutes.'
                    : 'Institute-specific topics are only visible to your institute.'}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button 
            onClick={handleSubmit} 
            variant="contained" 
            color="primary"
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : (selectedTopic ? 'Update' : 'Create')}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default TopicManagement;
