import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Paper, 
  Typography, 
  TextField, 
  Button, 
  Grid, 
  Card, 
  CardContent, 
  CardActions,
  IconButton, 
  InputAdornment,
  Pagination,
  CircularProgress,
  Chip,
  MenuItem,
  Tooltip,
  Badge,
  FormControl,
  InputLabel,
  Select,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControlLabel,
  Switch,
  useTheme,
  Divider,
  Avatar
} from '@mui/material';
import { 
  Add as AddIcon, 
  Search as SearchIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Topic as TopicIcon,
  ArrowForward as ArrowForwardIcon,
  Public as PublicIcon,
  Business as BusinessIcon,
  FilterList as FilterListIcon,
  Sort as SortIcon,
  Close as CloseIcon
} from '@mui/icons-material';
import API from '../../services/api';
import blueTheme from '../../theme/blueTheme';

const SubjectManagement = ({ 
  subjects, 
  totalSubjects,
  page,
  rowsPerPage,
  onPageChange,
  onRowsPerPageChange,
  onAddSubject,
  onEditSubject,
  onDeleteSubject,
  onViewTopics,
  getToken, 
  user, 
  showNotification
}) => {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredSubjects, setFilteredSubjects] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    classId: '',
    isGlobal: false,
    icon: ''
  });
  const [classes, setClasses] = useState([]);
  const [filterOptions, setFilterOptions] = useState({
    visibility: 'all', // 'all', 'global', 'institute'
    classId: 'all'
  });
  const [sortOption, setSortOption] = useState('name-asc');
  const [openFilters, setOpenFilters] = useState(false);

  // Icons for subjects
  const subjectIcons = [
    { value: 'physics', label: 'Physics' },
    { value: 'chemistry', label: 'Chemistry' },
    { value: 'biology', label: 'Biology' },
    { value: 'mathematics', label: 'Mathematics' },
    { value: 'english', label: 'English' },
    { value: 'history', label: 'History' },
    { value: 'geography', label: 'Geography' },
    { value: 'computer', label: 'Computer Science' }
  ];

  useEffect(() => {
    fetchClasses();
  }, []);

  useEffect(() => {
    if (subjects && subjects.length > 0) {
      applyFiltersAndSort();
    } else {
      setFilteredSubjects([]);
    }
  }, [subjects, searchTerm, filterOptions, sortOption]);

  const fetchClasses = async () => {
    try {
      // In a real implementation, fetch classes from API
      // For now, use mock data
      setClasses([
        { id: '1', name: 'Class 6' },
        { id: '2', name: 'Class 7' },
        { id: '3', name: 'Class 8' },
        { id: '4', name: 'Class 9' },
        { id: '5', name: 'Class 10' },
        { id: '6', name: 'Class 11' },
        { id: '7', name: 'Class 12' },
        { id: '8', name: 'NEET' }
      ]);
    } catch (error) {
      console.error('Error fetching classes:', error);
      showNotification('error', 'Failed to load classes');
    }
  };

  const applyFiltersAndSort = () => {
    let result = [...subjects];
    
    // Apply search filter
    if (searchTerm) {
      result = result.filter(subject => 
        subject.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        subject.description?.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
    
    // Apply visibility filter
    if (filterOptions.visibility !== 'all') {
      result = result.filter(subject => 
        filterOptions.visibility === 'global' ? subject.isGlobal : !subject.isGlobal
      );
    }
    
    // Apply class filter
    if (filterOptions.classId !== 'all') {
      result = result.filter(subject => subject.classId === filterOptions.classId);
    }
    
    // Apply sorting
    result.sort((a, b) => {
      const [field, direction] = sortOption.split('-');
      
      if (field === 'name') {
        return direction === 'asc' 
          ? a.name.localeCompare(b.name) 
          : b.name.localeCompare(a.name);
      }
      
      if (field === 'topics') {
        return direction === 'asc' 
          ? (a.topicCount || 0) - (b.topicCount || 0) 
          : (b.topicCount || 0) - (a.topicCount || 0);
      }
      
      if (field === 'date') {
        return direction === 'asc' 
          ? new Date(a.createdAt) - new Date(b.createdAt) 
          : new Date(b.createdAt) - new Date(a.createdAt);
      }
      
      return 0;
    });
    
    setFilteredSubjects(result);
  };

  const handleOpenDialog = (subject = null) => {
    if (subject) {
      setSelectedSubject(subject);
      setFormData({
        name: subject.name,
        description: subject.description || '',
        classId: subject.classId,
        isGlobal: subject.isGlobal || false,
        icon: subject.icon || ''
      });
    } else {
      setSelectedSubject(null);
      setFormData({
        name: '',
        description: '',
        classId: '',
        isGlobal: false,
        icon: ''
      });
    }
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedSubject(null);
  };

  const handleInputChange = (e) => {
    const { name, value, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: name === 'isGlobal' ? checked : value
    }));
  };

  const handleSubmit = async () => {
    // Validation
    if (!formData.name.trim()) {
      showNotification('error', 'Subject name is required');
      return;
    }
    
    if (!formData.classId) {
      showNotification('error', 'Please select a class');
      return;
    }
    
    try {
      setLoading(true);
      
      if (selectedSubject) {
        // Update existing subject
        await onEditSubject({
          ...selectedSubject,
          ...formData
        });
        showNotification('success', 'Subject updated successfully');
      } else {
        // Create new subject
        await onAddSubject(formData);
        showNotification('success', 'Subject created successfully');
      }
      
      handleCloseDialog();
    } catch (error) {
      console.error('Error saving subject:', error);
      showNotification('error', 'Failed to save subject');
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (subject) => {
    if (window.confirm(`Are you sure you want to delete "${subject.name}"?`)) {
      try {
        setLoading(true);
        await onDeleteSubject(subject);
        showNotification('success', 'Subject deleted successfully');
      } catch (error) {
        console.error('Error deleting subject:', error);
        showNotification('error', 'Failed to delete subject');
      } finally {
        setLoading(false);
      }
    }
  };

  const getVisibilityLabel = (isGlobal) => {
    return isGlobal ? 'Global' : 'Institute';
  };

  const getVisibilityIcon = (isGlobal) => {
    return isGlobal ? <PublicIcon fontSize="small" /> : <BusinessIcon fontSize="small" />;
  };

  const getVisibilityColor = (isGlobal) => {
    return isGlobal ? 'primary' : 'default';
  };

  const getClassById = (classId) => {
    const foundClass = classes.find(c => c.id === classId);
    return foundClass ? foundClass.name : 'Unknown';
  };

  const getSubjectIconColor = (icon) => {
    switch (icon) {
      case 'physics': return '#1976d2';
      case 'chemistry': return '#9c27b0';
      case 'biology': return '#4caf50';
      case 'mathematics': return '#ff9800';
      case 'english': return '#f44336';
      case 'history': return '#795548';
      case 'geography': return '#009688';
      case 'computer': return '#607d8b';
      default: return '#1976d2';
    }
  };

  return (
    <Box>
      <Box sx={{ mb: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap' }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Subject Management
        </Typography>
        <Button 
          variant="contained" 
          color="primary" 
          startIcon={<AddIcon />}
          onClick={() => handleOpenDialog()}
        >
          Add Subject
        </Button>
      </Box>

      <Paper sx={{ p: 2, mb: 3 }}>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, alignItems: 'center', mb: 2 }}>
          <TextField
            placeholder="Search subjects..."
            variant="outlined"
            size="small"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon fontSize="small" />
                </InputAdornment>
              ),
            }}
            sx={{ flexGrow: 1, maxWidth: { xs: '100%', sm: 300 } }}
          />
          
          <Button 
            variant="outlined" 
            size="small"
            startIcon={<FilterListIcon />}
            onClick={() => setOpenFilters(!openFilters)}
          >
            Filters
          </Button>
          
          <FormControl size="small" sx={{ minWidth: 150 }}>
            <InputLabel id="sort-label">Sort By</InputLabel>
            <Select
              labelId="sort-label"
              value={sortOption}
              label="Sort By"
              onChange={(e) => setSortOption(e.target.value)}
              startAdornment={
                <InputAdornment position="start">
                  <SortIcon fontSize="small" />
                </InputAdornment>
              }
            >
              <MenuItem value="name-asc">Name (A-Z)</MenuItem>
              <MenuItem value="name-desc">Name (Z-A)</MenuItem>
              <MenuItem value="topics-desc">Most Topics</MenuItem>
              <MenuItem value="topics-asc">Least Topics</MenuItem>
              <MenuItem value="date-desc">Newest</MenuItem>
              <MenuItem value="date-asc">Oldest</MenuItem>
            </Select>
          </FormControl>
        </Box>
        
        {/* Filter options */}
        {openFilters && (
          <Box sx={{ mb: 2, p: 2, bgcolor: 'background.paper', borderRadius: 1, border: '1px solid', borderColor: 'divider' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
              <Typography variant="subtitle1" fontWeight="bold">Filters</Typography>
              <IconButton size="small" onClick={() => setOpenFilters(false)}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth size="small">
                  <InputLabel id="visibility-filter-label">Visibility</InputLabel>
                  <Select
                    labelId="visibility-filter-label"
                    value={filterOptions.visibility}
                    label="Visibility"
                    onChange={(e) => setFilterOptions(prev => ({ ...prev, visibility: e.target.value }))}
                  >
                    <MenuItem value="all">All</MenuItem>
                    <MenuItem value="global">Global</MenuItem>
                    <MenuItem value="institute">Institute-specific</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth size="small">
                  <InputLabel id="class-filter-label">Class</InputLabel>
                  <Select
                    labelId="class-filter-label"
                    value={filterOptions.classId}
                    label="Class"
                    onChange={(e) => setFilterOptions(prev => ({ ...prev, classId: e.target.value }))}
                  >
                    <MenuItem value="all">All Classes</MenuItem>
                    {classes.map(cls => (
                      <MenuItem key={cls.id} value={cls.id}>{cls.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        )}
        
        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
            <CircularProgress />
          </Box>
        ) : filteredSubjects.length === 0 ? (
          <Box sx={{ textAlign: 'center', py: 4 }}>
            <Typography variant="body1" color="text.secondary">
              No subjects found. {searchTerm && 'Try a different search term.'}
            </Typography>
            <Button 
              variant="contained" 
              color="primary" 
              startIcon={<AddIcon />}
              onClick={() => handleOpenDialog()}
              sx={{ mt: 2 }}
            >
              Add Subject
            </Button>
          </Box>
        ) : (
          <>
            <Grid container spacing={3}>
              {filteredSubjects.map((subject) => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={subject.id}>
                  <Card sx={{ 
                    height: '100%', 
                    display: 'flex', 
                    flexDirection: 'column',
                    transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
                    '&:hover': {
                      transform: 'translateY(-4px)',
                      boxShadow: 4
                    }
                  }}>
                    <CardContent sx={{ flexGrow: 1, pb: 1 }}>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: 1 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Avatar 
                            sx={{ 
                              bgcolor: getSubjectIconColor(subject.icon || 'default'),
                              width: 40,
                              height: 40,
                              mr: 1
                            }}
                          >
                            {subject.name.charAt(0).toUpperCase()}
                          </Avatar>
                          <Typography variant="h6" component="h2" noWrap>
                            {subject.name}
                          </Typography>
                        </Box>
                        <Chip 
                          icon={getVisibilityIcon(subject.isGlobal)}
                          label={getVisibilityLabel(subject.isGlobal)}
                          size="small"
                          color={getVisibilityColor(subject.isGlobal)}
                          variant={subject.isGlobal ? "filled" : "outlined"}
                        />
                      </Box>
                      <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                        {getClassById(subject.classId)}
                      </Typography>
                      <Typography variant="body2" color="text.secondary" sx={{ 
                        mb: 2,
                        display: '-webkit-box',
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: 'vertical',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        height: '40px'
                      }}>
                        {subject.description || 'No description provided.'}
                      </Typography>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <TopicIcon fontSize="small" color="action" sx={{ mr: 0.5 }} />
                        <Typography variant="body2" color="text.secondary">
                          {subject.topicCount || 0} Topics
                        </Typography>
                      </Box>
                    </CardContent>
                    <Divider />
                    <CardActions sx={{ justifyContent: 'space-between', px: 2, py: 1 }}>
                      <Box>
                        <Tooltip title="Edit Subject">
                          <IconButton 
                            size="small" 
                            onClick={() => handleOpenDialog(subject)}
                            aria-label="edit subject"
                          >
                            <EditIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete Subject">
                          <IconButton 
                            size="small" 
                            onClick={() => handleDelete(subject)}
                            aria-label="delete subject"
                            color="error"
                          >
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </Box>
                      <Tooltip title="View Topics">
                        <Button
                          size="small"
                          endIcon={<ArrowForwardIcon />}
                          onClick={() => onViewTopics(subject)}
                        >
                          Topics
                        </Button>
                      </Tooltip>
                    </CardActions>
                  </Card>
                </Grid>
              ))}
            </Grid>
            
            {totalSubjects > rowsPerPage && (
              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
                <Pagination 
                  count={Math.ceil(totalSubjects / rowsPerPage)} 
                  page={page + 1} 
                  onChange={(e, newPage) => onPageChange(e, newPage - 1)}
                  color="primary"
                />
              </Box>
            )}
          </>
        )}
      </Paper>

      {/* Add/Edit Subject Dialog */}
      <Dialog 
        open={openDialog} 
        onClose={handleCloseDialog}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          {selectedSubject ? 'Edit Subject' : 'Add New Subject'}
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                name="name"
                label="Subject Name"
                fullWidth
                value={formData.name}
                onChange={handleInputChange}
                required
                margin="normal"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="description"
                label="Description"
                fullWidth
                multiline
                rows={3}
                value={formData.description}
                onChange={handleInputChange}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth margin="normal">
                <InputLabel id="class-label">Class</InputLabel>
                <Select
                  labelId="class-label"
                  name="classId"
                  value={formData.classId}
                  label="Class"
                  onChange={handleInputChange}
                  required
                >
                  {classes.map(cls => (
                    <MenuItem key={cls.id} value={cls.id}>{cls.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth margin="normal">
                <InputLabel id="icon-label">Icon</InputLabel>
                <Select
                  labelId="icon-label"
                  name="icon"
                  value={formData.icon}
                  label="Icon"
                  onChange={handleInputChange}
                >
                  <MenuItem value="">None</MenuItem>
                  {subjectIcons.map(icon => (
                    <MenuItem key={icon.value} value={icon.value}>
                      {icon.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ 
                p: 2, 
                border: '1px solid', 
                borderColor: 'divider', 
                borderRadius: 1,
                bgcolor: formData.isGlobal ? 'primary.50' : 'background.paper'
              }}>
                <Typography variant="subtitle1" gutterBottom>
                  Visibility Settings
                </Typography>
                <FormControlLabel
                  control={
                    <Switch
                      name="isGlobal"
                      checked={formData.isGlobal}
                      onChange={handleInputChange}
                      color="primary"
                    />
                  }
                  label={
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      {formData.isGlobal ? <PublicIcon sx={{ mr: 1 }} /> : <BusinessIcon sx={{ mr: 1 }} />}
                      <Typography>
                        {formData.isGlobal ? 'Global (available to all institutes)' : 'Institute-specific'}
                      </Typography>
                    </Box>
                  }
                />
                <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                  {formData.isGlobal 
                    ? 'Global subjects are shared across all institutes and cannot be modified by individual institutes.'
                    : 'Institute-specific subjects are only visible to your institute.'}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button 
            onClick={handleSubmit} 
            variant="contained" 
            color="primary"
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : (selectedSubject ? 'Update' : 'Create')}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default SubjectManagement;
