import React, { useState, useRef } from 'react';
import { 
  Box, 
  Typography, 
  Button, 
  Grid, 
  Paper, 
  Stepper,
  Step,
  StepLabel,
  CircularProgress,
  Alert,
  AlertTitle,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
  IconButton,
  Tooltip
} from '@mui/material';
import { 
  CloudUpload as CloudUploadIcon,
  Description as DescriptionIcon,
  Check as CheckIcon,
  Error as ErrorIcon,
  Download as DownloadIcon,
  Delete as DeleteIcon,
  Help as HelpIcon,
  Info as InfoIcon
} from '@mui/icons-material';
import * as XLSX from 'xlsx';

const MCQBulkUpload = ({ 
  onUploadComplete,
  selectedTopic,
  selectedSubject
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [parsedData, setParsedData] = useState([]);
  const [errors, setErrors] = useState([]);
  const [validationResults, setValidationResults] = useState({
    valid: [],
    invalid: []
  });
  const fileInputRef = useRef(null);

  const steps = ['Select File', 'Validate Data', 'Review & Upload'];

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const droppedFile = event.dataTransfer.files[0];
    if (droppedFile) {
      setFile(droppedFile);
    }
  };

  const parseExcel = async () => {
    setLoading(true);
    try {
      const data = await readExcelFile(file);
      setParsedData(data);
      setActiveStep(1);
    } catch (error) {
      setErrors([`Failed to parse file: ${error.message}`]);
    } finally {
      setLoading(false);
    }
  };

  const readExcelFile = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      
      reader.onload = (e) => {
        try {
          const data = e.target.result;
          const workbook = XLSX.read(data, { type: 'array' });
          const firstSheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[firstSheetName];
          const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
          
          // Process the data
          const headers = jsonData[0];
          const rows = jsonData.slice(1);
          
          // Map to our expected format
          const processedData = rows.map((row, rowIndex) => {
            const mcq = {};
            
            headers.forEach((header, colIndex) => {
              const value = row[colIndex];
              
              switch (header.toLowerCase()) {
                case 'question':
                  mcq.question = value || '';
                  break;
                case 'option a':
                case 'option_a':
                  if (!mcq.options) mcq.options = [];
                  mcq.options[0] = { text: value || '', isCorrect: false };
                  break;
                case 'option b':
                case 'option_b':
                  if (!mcq.options) mcq.options = [];
                  mcq.options[1] = { text: value || '', isCorrect: false };
                  break;
                case 'option c':
                case 'option_c':
                  if (!mcq.options) mcq.options = [];
                  mcq.options[2] = { text: value || '', isCorrect: false };
                  break;
                case 'option d':
                case 'option_d':
                  if (!mcq.options) mcq.options = [];
                  mcq.options[3] = { text: value || '', isCorrect: false };
                  break;
                case 'correct answer':
                case 'correct_answer':
                  const correctAnswer = value?.toString().toUpperCase();
                  if (correctAnswer && mcq.options) {
                    const index = correctAnswer.charCodeAt(0) - 65; // Convert A->0, B->1, etc.
                    if (index >= 0 && index < mcq.options.length) {
                      mcq.options.forEach((opt, i) => {
                        opt.isCorrect = i === index;
                      });
                    }
                  }
                  break;
                case 'explanation':
                  mcq.explanation = value || '';
                  break;
                case 'difficulty':
                  // Convert text difficulty to number
                  if (typeof value === 'string') {
                    const lowerValue = value.toLowerCase();
                    if (lowerValue.includes('easy')) {
                      mcq.difficulty = 1;
                    } else if (lowerValue.includes('hard')) {
                      mcq.difficulty = 3;
                    } else {
                      mcq.difficulty = 2; // Default to medium
                    }
                  } else if (typeof value === 'number') {
                    mcq.difficulty = Math.min(Math.max(value, 1), 3); // Ensure between 1-3
                  } else {
                    mcq.difficulty = 2; // Default to medium
                  }
                  break;
                case 'topic':
                  mcq.topicName = value;
                  break;
                case 'subject':
                  mcq.subjectName = value;
                  break;
                default:
                  // Handle any other columns
                  mcq[header] = value;
              }
            });
            
            // Add row number for reference
            mcq.rowNumber = rowIndex + 2; // +2 because we're 0-indexed and skipped header
            
            // Add topic and subject IDs if selected
            if (selectedTopic) {
              mcq.topicId = selectedTopic.id;
              mcq.topicName = selectedTopic.name;
            }
            
            if (selectedSubject) {
              mcq.subjectId = selectedSubject.id;
              mcq.subjectName = selectedSubject.name;
            }
            
            // Set default values for any missing fields
            if (!mcq.options) mcq.options = [];
            while (mcq.options.length < 4) {
              mcq.options.push({ text: '', isCorrect: mcq.options.length === 0 });
            }
            
            if (mcq.difficulty === undefined) mcq.difficulty = 2;
            mcq.isActive = true;
            
            return mcq;
          });
          
          resolve(processedData);
        } catch (error) {
          reject(error);
        }
      };
      
      reader.onerror = (error) => {
        reject(error);
      };
      
      reader.readAsArrayBuffer(file);
    });
  };

  const validateData = () => {
    setLoading(true);
    
    const valid = [];
    const invalid = [];
    
    parsedData.forEach(mcq => {
      const mcqErrors = [];
      
      // Validate question
      if (!mcq.question || mcq.question.trim() === '') {
        mcqErrors.push('Question is required');
      }
      
      // Validate options
      if (!mcq.options || mcq.options.length < 2) {
        mcqErrors.push('At least 2 options are required');
      } else {
        // Check if all options have text
        const emptyOptions = mcq.options.filter(opt => !opt.text || opt.text.trim() === '');
        if (emptyOptions.length > 0) {
          mcqErrors.push(`${emptyOptions.length} option(s) are empty`);
        }
        
        // Check if there's a correct answer
        const correctOptions = mcq.options.filter(opt => opt.isCorrect);
        if (correctOptions.length === 0) {
          mcqErrors.push('No correct answer specified');
        } else if (correctOptions.length > 1) {
          mcqErrors.push('Multiple correct answers specified');
        }
      }
      
      // Validate topic and subject
      if (!selectedTopic && !mcq.topicId && !mcq.topicName) {
        mcqErrors.push('Topic is required');
      }
      
      if (!selectedSubject && !mcq.subjectId && !mcq.subjectName) {
        mcqErrors.push('Subject is required');
      }
      
      if (mcqErrors.length === 0) {
        valid.push(mcq);
      } else {
        invalid.push({
          ...mcq,
          errors: mcqErrors
        });
      }
    });
    
    setValidationResults({
      valid,
      invalid
    });
    
    setLoading(false);
    setActiveStep(2);
  };

  const handleUpload = () => {
    setLoading(true);
    try {
      // In a real implementation, we would send the data to the server
      onUploadComplete(validationResults.valid);
    } catch (error) {
      setErrors([`Failed to upload: ${error.message}`]);
    } finally {
      setLoading(false);
    }
  };

  const downloadTemplate = () => {
    // Create a template workbook
    const wb = XLSX.utils.book_new();
    
    // Define the headers
    const headers = [
      'Question', 
      'Option A', 
      'Option B', 
      'Option C', 
      'Option D', 
      'Correct Answer', 
      'Explanation', 
      'Difficulty', 
      'Topic', 
      'Subject'
    ];
    
    // Add a sample row
    const sampleRow = [
      'What is the capital of France?',
      'London',
      'Berlin',
      'Paris',
      'Madrid',
      'C',
      'Paris is the capital and most populous city of France.',
      'Easy',
      selectedTopic ? selectedTopic.name : 'Physics',
      selectedSubject ? selectedSubject.name : 'Science'
    ];
    
    // Create the worksheet with headers and sample data
    const ws = XLSX.utils.aoa_to_sheet([headers, sampleRow]);
    
    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, 'MCQs');
    
    // Generate the Excel file and trigger download
    XLSX.writeFile(wb, 'mcq_upload_template.xlsx');
  };

  const renderStepContent = () => {
    switch (activeStep) {
      case 0:
        return (
          <Box>
            <Paper 
              sx={{ 
                p: 3, 
                border: '2px dashed', 
                borderColor: 'primary.main', 
                borderRadius: 2,
                bgcolor: 'background.paper',
                textAlign: 'center',
                cursor: 'pointer',
                mb: 3
              }}
              onDragOver={handleDragOver}
              onDrop={handleDrop}
              onClick={() => fileInputRef.current.click()}
            >
              <input
                type="file"
                accept=".xlsx,.xls,.csv"
                style={{ display: 'none' }}
                ref={fileInputRef}
                onChange={handleFileChange}
              />
              <CloudUploadIcon color="primary" sx={{ fontSize: 60, mb: 2 }} />
              <Typography variant="h6" gutterBottom>
                Drag & Drop or Click to Upload
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Supported formats: Excel (.xlsx, .xls) or CSV
              </Typography>
              {file && (
                <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <DescriptionIcon color="primary" sx={{ mr: 1 }} />
                  <Typography>{file.name}</Typography>
                  <IconButton 
                    size="small" 
                    color="error" 
                    onClick={(e) => {
                      e.stopPropagation();
                      setFile(null);
                    }}
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Box>
              )}
            </Paper>
            
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Button 
                variant="outlined" 
                startIcon={<DownloadIcon />}
                onClick={downloadTemplate}
              >
                Download Template
              </Button>
              
              <Button 
                variant="contained" 
                color="primary" 
                disabled={!file || loading}
                onClick={parseExcel}
              >
                {loading ? <CircularProgress size={24} /> : 'Next'}
              </Button>
            </Box>
            
            <Box sx={{ mt: 3 }}>
              <Typography variant="subtitle1" gutterBottom>
                Instructions:
              </Typography>
              <List>
                <ListItem>
                  <ListItemIcon>
                    <InfoIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Upload an Excel file with MCQs in the specified format." />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <InfoIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Each row represents one MCQ with question, options, and metadata." />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <InfoIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Specify the correct answer as A, B, C, or D in the 'Correct Answer' column." />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <InfoIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Set difficulty as Easy, Medium, or Hard." />
                </ListItem>
              </List>
            </Box>
          </Box>
        );
      
      case 1:
        return (
          <Box>
            <Alert severity="info" sx={{ mb: 3 }}>
              <AlertTitle>Validating {parsedData.length} MCQs</AlertTitle>
              Click "Validate" to check your MCQs for any errors before uploading.
            </Alert>
            
            <TableContainer component={Paper} sx={{ mb: 3, maxHeight: 300, overflow: 'auto' }}>
              <Table stickyHeader size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Row</TableCell>
                    <TableCell>Question</TableCell>
                    <TableCell>Options</TableCell>
                    <TableCell>Correct</TableCell>
                    <TableCell>Difficulty</TableCell>
                    <TableCell>Topic</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {parsedData.slice(0, 10).map((mcq, index) => (
                    <TableRow key={index}>
                      <TableCell>{mcq.rowNumber}</TableCell>
                      <TableCell sx={{ maxWidth: 200, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                        {mcq.question}
                      </TableCell>
                      <TableCell>{mcq.options?.length || 0}</TableCell>
                      <TableCell>
                        {mcq.options?.findIndex(opt => opt.isCorrect) !== -1 
                          ? `Option ${String.fromCharCode(65 + mcq.options.findIndex(opt => opt.isCorrect))}` 
                          : 'None'}
                      </TableCell>
                      <TableCell>
                        {mcq.difficulty === 1 ? 'Easy' : mcq.difficulty === 3 ? 'Hard' : 'Medium'}
                      </TableCell>
                      <TableCell>
                        {mcq.topicName || (selectedTopic ? selectedTopic.name : 'Not specified')}
                      </TableCell>
                    </TableRow>
                  ))}
                  {parsedData.length > 10 && (
                    <TableRow>
                      <TableCell colSpan={6} align="center">
                        <Typography variant="body2" color="text.secondary">
                          {parsedData.length - 10} more rows not shown
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button 
                onClick={() => setActiveStep(0)}
              >
                Back
              </Button>
              <Button 
                variant="contained" 
                color="primary" 
                onClick={validateData}
                disabled={loading || parsedData.length === 0}
              >
                {loading ? <CircularProgress size={24} /> : 'Validate'}
              </Button>
            </Box>
          </Box>
        );
      
      case 2:
        return (
          <Box>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Alert 
                  severity={validationResults.invalid.length > 0 ? "warning" : "success"}
                  sx={{ mb: 3 }}
                >
                  <AlertTitle>
                    {validationResults.invalid.length > 0 
                      ? `${validationResults.valid.length} valid, ${validationResults.invalid.length} invalid MCQs`
                      : `All ${validationResults.valid.length} MCQs are valid!`}
                  </AlertTitle>
                  {validationResults.invalid.length > 0 
                    ? "Some MCQs have validation errors. You can proceed with uploading the valid ones, or go back to fix the issues."
                    : "All MCQs passed validation and are ready to be uploaded."}
                </Alert>
              </Grid>
              
              {validationResults.invalid.length > 0 && (
                <Grid item xs={12}>
                  <Typography variant="subtitle1" gutterBottom>
                    MCQs with Errors:
                  </Typography>
                  <TableContainer component={Paper} sx={{ mb: 3, maxHeight: 200, overflow: 'auto' }}>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>Row</TableCell>
                          <TableCell>Question</TableCell>
                          <TableCell>Errors</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {validationResults.invalid.map((mcq, index) => (
                          <TableRow key={index}>
                            <TableCell>{mcq.rowNumber}</TableCell>
                            <TableCell sx={{ maxWidth: 300, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                              {mcq.question || '<No question>'}
                            </TableCell>
                            <TableCell>
                              {mcq.errors.map((error, i) => (
                                <Chip 
                                  key={i} 
                                  label={error} 
                                  size="small" 
                                  color="error" 
                                  variant="outlined"
                                  sx={{ mr: 0.5, mb: 0.5 }}
                                />
                              ))}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              )}
              
              <Grid item xs={12}>
                <Typography variant="subtitle1" gutterBottom>
                  Summary of Valid MCQs:
                </Typography>
                <Paper sx={{ p: 2, mb: 3 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                      <Typography variant="body2" color="text.secondary">
                        Total MCQs:
                      </Typography>
                      <Typography variant="h6">
                        {validationResults.valid.length}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Typography variant="body2" color="text.secondary">
                        Topics:
                      </Typography>
                      <Typography variant="h6">
                        {new Set(validationResults.valid.map(mcq => 
                          mcq.topicName || (selectedTopic ? selectedTopic.name : '')
                        )).size}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Typography variant="body2" color="text.secondary">
                        Difficulty Distribution:
                      </Typography>
                      <Box sx={{ display: 'flex', gap: 1 }}>
                        <Chip 
                          label={`Easy: ${validationResults.valid.filter(mcq => mcq.difficulty === 1).length}`} 
                          size="small" 
                          color="success"
                        />
                        <Chip 
                          label={`Medium: ${validationResults.valid.filter(mcq => mcq.difficulty === 2).length}`} 
                          size="small" 
                          color="warning"
                        />
                        <Chip 
                          label={`Hard: ${validationResults.valid.filter(mcq => mcq.difficulty === 3).length}`} 
                          size="small" 
                          color="error"
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
            
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button 
                onClick={() => setActiveStep(1)}
              >
                Back
              </Button>
              <Button 
                variant="contained" 
                color="primary" 
                onClick={handleUpload}
                disabled={loading || validationResults.valid.length === 0}
              >
                {loading ? <CircularProgress size={24} /> : `Upload ${validationResults.valid.length} MCQs`}
              </Button>
            </Box>
          </Box>
        );
      
      default:
        return null;
    }
  };

  return (
    <Box>
      <Stepper activeStep={activeStep} sx={{ mb: 4 }}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      
      {errors.length > 0 && (
        <Alert severity="error" sx={{ mb: 3 }}>
          <AlertTitle>Error</AlertTitle>
          {errors.map((error, index) => (
            <Typography key={index} variant="body2">{error}</Typography>
          ))}
        </Alert>
      )}
      
      {renderStepContent()}
    </Box>
  );
};

export default MCQBulkUpload;
